import React, { useContext, Fragment, useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import ActionAddUser from './add_user'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { user, item } = props // action props

  return (
    <Fragment>
      <div className={classes.root}>
        <ActionAddUser {...props} />
      </div>
    </Fragment>
  )
}

export default Index
