import React, { useState } from 'react'

/* package */
import { GlobalProvider } from '../../lib-atomic-context'
import { SectionWrappSingle } from '../../lib-atomic-section'
/* package */

/* container */
import Container from '../../app-layout-container/default'
/* container */

/* form */
import Content from '../layout/content'
/* form */

const Index = (props) => {
  const [isNotCarousel, setIsNotCarousel] = useState(true)
  const [detailVisible, setDetailVisible] = useState(false)
  const toggleDetailVisibility = () => {
    setDetailVisible(!detailVisible)
  }
  return (
    <Container.LayoutCreate {...props}>
      <SectionWrappSingle
        {...{
          ...props,
          maxWidth: isNotCarousel ? (detailVisible ? 'lg' : 'sm') : 'sm',
        }}
      >
        <Content
          {...{
            ...props,
            isNotCarousel,
            setIsNotCarousel,
            detailVisible,
            toggleDetailVisibility,
          }}
        />
      </SectionWrappSingle>
    </Container.LayoutCreate>
  )
}

export default Index
