import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import { LocalStorage, WindowSize } from '../../lib-atomic-functions'
import { Buttons, Inputs } from '../../lib-atomic'

import CONFIG from '../config'

/* constant */
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '30%',
    minWidth: 200,
    maxWidth: 500,
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    margin: 'auto',
    textAlign: 'center',
    '&focus': {
      outline: 'none',
    },
  },
}))
/* constant */

const Index = (props) => {
  const { width, height } = WindowSize()
  const { toggle, setToggle, action } = props
  const classes = useStyles()

  const submitButton = { fullWidth: true }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={toggle}
      onClose={() => setToggle(!toggle)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
    >
      <Fade in={toggle}>
        <div className={classes.paper}>
          <Typography variant="h6">
            <b>Would you like to proceed?</b>
          </Typography>
          <br />
          <Buttons.Normal onClick={() => action()}>Yes</Buttons.Normal>
          &nbsp;&nbsp;
          <Buttons.Text onClick={() => setToggle()}>No</Buttons.Text>
        </div>
      </Fade>
    </Modal>
  )
}

export default Index
