import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'

/* container */
import { GlobalContext } from '../../lib-atomic-context'
import {
  LocalStorage,
  FormHook,
  WindowSize,
  FetchNotificationSendApi,
} from '../../lib-atomic-functions'
/* container */

/* modules */
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Buttons, Inputs } from '../../lib-atomic'
/* modules */

import CONFIG from '../config'

const OBJECT1 = CONFIG.application.cms.objects

/* constant */
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '30%',
    minWidth: 200,
    maxWidth: 500,
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    margin: 'auto',
    textAlign: 'center',
    '&focus': {
      outline: 'none',
    },
  },
}))
/* constant */

const Index = (props) => {
  const { token, username } = LocalStorage.getLS().strapi
  const { width, height } = WindowSize()
  const { buttonType, toggle, setToggle, action } = props
  const classes = useStyles()
  const { contentdetail, mastersetups, cognitouser } = useContext(GlobalContext)

  const handleSubmit = async () => {
    console.log(values)
    const cognitorUserObj = cognitouser.state.data[0]
    console.log(contentdetail.state.data)
    const x = FetchNotificationSendApi({
      queryType: buttonType === 'Accept' ? 'AcceptContent' : 'DeclineContent',
      userObj: cognitorUserObj,
      ref: contentdetail.state.data,
      token,
      comment: values.comment,
    })
  }
  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    OBJECT1
  )

  const comment = {
    ...OBJECT1.comment,
    error: error.comment,
    value: values.comment,
    onChange,
  }

  const submitButton = { fullWidth: true }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={toggle}
      onClose={() => setToggle(!toggle)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
    >
      <Fade in={toggle}>
        <form onSubmit={onSubmit} noValidate>
          <div className={classes.paper}>
            <h3
              id="transition-modal-title"
              style={{
                paddingBottom: 20,
                fontFamily: 'SubwayFootlong',
                fontSize: width < 500 ? 17 : 25,
              }}
            >
              Any Comments ?
            </h3>
            <Inputs.Text {...comment} />
            <div
              style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}
            >
              <div
                style={{
                  width: 150,
                  margin: 'auto',
                  marginBottom: 30,
                  marginRight: 5,
                }}
                // onClick={() => {
                //   action()
                // }}
              >
                {buttonType === 'Accept' ? (
                  <Buttons.Normal {...submitButton}>Approve</Buttons.Normal>
                ) : (
                  <Buttons.NormalRed {...submitButton}>
                    DECLINE
                  </Buttons.NormalRed>
                )}
              </div>
              <div
                style={{
                  width: 150,
                  margin: 'auto',
                  marginBottom: 30,
                  marginLeft: 5,
                }}
                onClick={(e) => {
                  console.log(e)
                  e.target.value = ''
                  comment.onChange(e)
                  setToggle(!toggle)
                }}
              >
                <Buttons.Text {...submitButton}>CANCEL</Buttons.Text>
              </div>
            </div>
          </div>
        </form>
      </Fade>
    </Modal>
  )
}

export default Index
