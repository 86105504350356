import React from 'react'
import { SectionWrappSingle } from '../../lib-atomic-section'
import AuthContainer from '../../app-auth-container/default'
import Content from '../layout'

const index = (props) => {
  return (
    <SectionWrappSingle>
      <AuthContainer.Login {...props}>
        <Content {...props} />
      </AuthContainer.Login>
    </SectionWrappSingle>
  )
}

export default index
