import React, { useState } from 'react'

/* package */
import { GlobalProvider } from '../../lib-atomic-context'
import { SectionWrappSingle } from '../../lib-atomic-section'
/* package */

/* container */
import Container from '../../app-layout-container/default'
/* container */

/* form */
import Content from '../layout/dragAndDrop'
/* form */

const Index = (props) => {
  return (
    <Container.LayoutSetupEdit {...props}>
      <SectionWrappSingle
        {...{
          ...props,
          maxWidth: 'lg',
        }}
      >
        <Content {...props} />
      </SectionWrappSingle>
    </Container.LayoutSetupEdit>
  )
}

export default Index
