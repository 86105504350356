import React, { useContext, Fragment, useState, useEffect } from 'react'

import { Buttons } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import { LocalStorage, FetchContextApiV2 } from '../../lib-atomic-functions'

import CheckerPopup from '../../app-popup-section-confirm/default'

import CONFIG from '../config'

const CMSENDPOINTS = CONFIG.application.cms.api.endpoints

const CMSAPPROVECONTENTCONFIG = {
  url: CMSENDPOINTS.approvesetup.url,
  method: CMSENDPOINTS.approvesetup.method,
  headers: CMSENDPOINTS.approvesetup.headers,
  responseType: 'json',
}

const Index = (props) => {
  const { token } = LocalStorage.getLS().strapi
  const { user, item, other } = props
  const { id, environment, mastersetupflag } = item
  const { isMobView, isPreLoginView, isPromotionView } = other

  const handleEdit = ({ id }) => {
    const url =
      '/layout/edit/' +
      (isMobView ? 'mob' : 'web') +
      '/' +
      (isPreLoginView ? 'pre' : 'post') +
      '/' +
      (isPromotionView ? 'offer' : 'home') +
      '/' +
      id
    props.history.push(url)
  }

  const isDraft =
    !mastersetupflag || mastersetupflag.status === 'draft' ? true : false

  const items = [
    {
      user: ['mastercreator', 'masterapprover', 'creator+approver+publisher'],
      action: handleEdit,
      label: 'Edit',
      status: true,
    },
  ]

  const checkItems = ({ status, user }) => {
    return items.filter(
      (item) => item.status === status && item.user.indexOf(user) > -1
    )
  }

  const menus = checkItems({ status: isDraft, user: user[0].user.username })

  if (environment === 'production') return null

  return (
    <Fragment>
      {menus.map((menu, index) => {
        return (
          <Fragment>
            <Buttons.Normal key={index} onClick={() => menu.action({ id })}>
              {menu.label}
            </Buttons.Normal>
            &nbsp;
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default Index
