import { useState, useEffect } from 'react'

import Validation from '../form-validation'

const Initial = (config) => {
  const initial = {}
  Object.keys(config).map((data, index) => {
    initial[data] = config[data].initial
  })
  return initial
}

const useFormHook = (callback, config) => {
  // debugger;

  const [values, setValues] = useState(Initial(config))
  const [errors, setErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    console.log(errors, isSubmit)
    if (Object.keys(errors).length === 0 && isSubmit) {
      callback()
      setIsSubmit(false)
    }
  }, [errors, values, isSubmit])

  const handleReset = (initial) => {
    // console.log(initial)
    setValues(Initial(initial))
  }

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    console.log('valuesvaluesvaluesvalues', values)
    const { dataError } = Validation.multiValidation(
      // dataset
      config,
      values,
      errors,
      values
    )
    console.log(dataError, ' CHECKING...')
    setErrors(dataError)
    setIsSubmit(true)
  }

  const handleChange = (event) => {
    event.persist()
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [event.target.name]: event.target.value },
      errors,
      values
    )
    setErrors(dataError)
    setIsSubmit(false)
  }

  const handlePick = (name, value) => {
    //event.persist();
    console.log(name, value)
    setValues((values) => ({ ...values, [name]: value }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [name]: value },
      errors,
      values
    )
    console.log(dataError)
    setErrors(dataError)
    setIsSubmit(false)
  }

  const handleCheck = (event) => {
    event.persist()
    console.log(event.target.value)
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [event.target.name]: event.target.checked },
      errors,
      values
    )
    setErrors(dataError)
    setIsSubmit(false)
  }

  const handleValue = (key, value) => {
    setValues((values) => ({ ...values, [key]: value }))
    const { dataError } = Validation.singleValidation(
      // dataset
      config,
      { [key]: value },
      errors
    )
    setErrors(dataError)
    setIsSubmit(false)
  }
  const handleSelectMultiple = (event) => {
    console.log(values, event.target.value)
    event.persist()
    const containsId = values[event.target.name].some(
      (o) => o.name === event.target.value.name
    )
    console.log(containsId)
    if (!containsId) {
      const newIds = JSON.parse(JSON.stringify(values[event.target.name]))
      // console.log(newIds, JSON.parse(event.target.value));
      newIds.push(event.target.value)
      setValues((values) => ({ ...values, [event.target.name]: newIds }))
    }
    // setValues(values => ({
    //   ...values,
    //   [event.target.name]: event.target.value
    // }));
    // const { dataError, dataset } = Validation.singleValidation(
    //   config,
    //   { [event.target.name]: event.target.value },
    //   errors,
    //   values
    // );
    // setErrors(dataError);
    setIsSubmit(false)
  }

  const handleDeselectMultiple = (event) => {
    // event.persist();
    const remov = values[event.target.name].filter((o) => {
      return o.id.toString().indexOf(event.target.value.id.toString()) === -1
    })
    setValues((values) => ({ ...values, [event.target.name]: remov }))
    // setValues(values => ({
    //   ...values,
    //   [event.target.name]: event.target.value
    // }));
    // const { dataError, dataset } = Validation.singleValidation(
    //   config,
    //   { [event.target.name]: event.target.value },
    //   errors,
    //   values
    // );
    // setErrors(dataError);
    setIsSubmit(false)
  }
  return {
    // addressChange:handleChange2,
    onReset: handleReset,
    onChange: handleChange,
    onPick: handlePick,
    onCheck: handleCheck,
    onMultiSelect: handleSelectMultiple,
    onMultiDeselect: handleDeselectMultiple,
    onSubmit: handleSubmit,
    handleValue,
    values,
    setValues,
    error: errors,
  }
}

export default {
  useFormHook,
}
