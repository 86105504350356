import React, { Fragment } from 'react'

import TextField from '@material-ui/core/TextField'

/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const textarea = (props) => {
  return (
    <Fragment>
      <TextField
        {...props}
        fullWidth
        multiline
        variant="outlined"
        margin="dense"
      />
      <Invalid {...props} />
    </Fragment>
  )
}

export default textarea
