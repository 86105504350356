import React, { lazy, Suspense, useContext, useState, useEffect } from 'react'

import moment from 'moment'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  WindowSize,
  WindowFont,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* modules */
const BodyItem = lazy(() =>
  import('../../app-layout-section-body-item/default')
)
/* modules */

/* constant */
// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//     overflow: 'hidden',
//     backgroundColor: theme.palette.background.paper,
//     paddingBottom: 80,
//   },
//   gridList: {
//     width: 800,
//   },
// }))
/* constant */
const sortContentByCoordinate = (arrayObject, isPreLoginView) => {
  if (isPreLoginView) {
    const x = arrayObject.sort((item1, item2) => {
      if (item1.startX < item2.startX) return -1
      if (item1.startX > item2.startX) return 1
    })
    const y = x.sort((item1, item2) => {
      if (item1.startY < item2.startY) return -1
      if (item1.startY > item2.startY) return 1
    })
    return y
  } else {
    const x = arrayObject.sort((item1, item2) => {
      if (item1.startXForPostLogin < item2.startXForPostLogin) return -1
      if (item1.startXForPostLogin > item2.startXForPostLogin) return 1
    })
    const y = x.sort((item1, item2) => {
      if (item1.startYForPostLogin < item2.startYForPostLogin) return -1
      if (item1.startYForPostLogin > item2.startYForPostLogin) return 1
    })
    return y
  }
}
const isBetweenTwoDate = (theDate, PublishFrom, PublishTo) => {
  const noFromDate = PublishFrom === null || PublishFrom === ''
  const noToDate = PublishTo === null || PublishTo === ''
  const publishFrom = noFromDate
    ? ' '
    : moment(PublishFrom).format('YYYY MM DD')
  const publishTo = noToDate ? ' ' : moment(PublishTo).format('YYYY MM DD')
  const isBetweenFromTo =
    publishFrom === ' ' && publishTo === ' '
      ? true
      : publishFrom === ' ' && publishTo !== ' '
      ? moment(theDate).isBefore(moment(publishTo).format('YYYY MM DD'))
      : publishFrom !== ' ' && publishTo === ' '
      ? moment(theDate).isAfter(moment(publishFrom).format('YYYY MM DD'))
      : moment(theDate).isBetween(
          moment(publishFrom).format('YYYY MM DD'),
          moment(publishTo).format('YYYY MM DD')
        )
  return isBetweenFromTo
}
const Index = (props) => {
  const [height1, setHeight1] = useState(0)
  const [showImage, setShowImage] = useState(false)
  useEffect(() => {
    setTimeout(() => setShowImage(true), 2000)
  }, [])
  // const isPostLogin = props.location.hash;
  const calcTotalHeight = (
    isPreLoginView,
    contents,
    PLATFORM,
    widthMultiplier
  ) => {
    var returnVal = 0
    const sorted = sortContentByCoordinate(contents, isPreLoginView)

    sorted.map((layout, index) => {
      if (index === sorted.length - 1) {
        const finalEndY = isPreLoginView
          ? layout.endY * widthMultiplier
          : layout.endYForPostLogin * widthMultiplier
        if (PLATFORM === 'mob') {
          returnVal = layout.endX > 2 ? finalEndY * 2 : finalEndY
        } else {
          returnVal = finalEndY
        }
      }
    })
    return returnVal + 50
  }

  const [visibleLogin, setVisibleLogin] = useState(false)
  const popupModal = () => {
    setVisibleLogin(!visibleLogin)
  }

  const { filteredContent, isPreLoginView, isPromotionView } = props

  const { width, height } = WindowSize()

  const PLATFORM = width < 650 ? 'mob' : 'web'
  // const PLATFORM = 'web'
  const { getFontSizes } = WindowFont()

  const cleanContents = filteredContent
  useEffect(() => {
    console.log('changed')
    setShowImage(false)
    setTimeout(() => setShowImage(true), 2000)
    console.log(showImage, cleanContents)
  }, [cleanContents])
  useEffect(() => {
    const height1 = calcTotalHeight(
      isPreLoginView,
      cleanContents,
      PLATFORM,
      widthMultiplier
    )
    setHeight1(height1)
  }, [cleanContents, isPreLoginView, isPromotionView])
  const fullWidth =
    width < 550
      ? width
      : width < 1000
      ? (width * 4) / 5
      : width < 1500
      ? (width * 3) / 5
      : (width * 2.5) / 5

  const widthMultiplierInit =
    PLATFORM.toLowerCase() === 'web' ? fullWidth / 4 : fullWidth / 2
  const widthMultiplier =
    PLATFORM.toLowerCase() === 'web'
      ? widthMultiplierInit > 180
        ? 180
        : widthMultiplierInit
      : widthMultiplierInit > 360
      ? 360
      : widthMultiplierInit
  const wrapperWidth =
    PLATFORM.toLowerCase() === 'web' ? widthMultiplier * 4 : widthMultiplier * 2
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'wrap',
        flexWrap: 'wrap',
        position: 'relative',
        width: wrapperWidth,
        height: height1,
        // margin: 'auto',
        marginTop: -5,
        alignContent: 'flex-start',
      }}
    >
      {cleanContents.map((layout, index) => {
        if (PLATFORM === 'mob') {
          const boxWidth = parseInt(layout.masterbox.width)
          const boxHeight = parseInt(layout.masterbox.height)

          const shrinkedWidth = boxWidth > 2 ? 2 : boxWidth
          const shrinkedHeight =
            boxWidth === boxHeight
              ? shrinkedWidth
              : boxHeight === 2
              ? 1
              : boxHeight === 1
              ? 1
              : boxHeight
          const boxWidthMultiplied = widthMultiplier * shrinkedWidth
          const boxHeightMultiplied = widthMultiplier * shrinkedHeight

          const fontSizes = getFontSizes(widthMultiplier, width)
          layout.mastercontents = {
            ...layout.mastercontents,
            ...fontSizes,
            colsWidth: boxWidthMultiplied,
            colsHeight: boxHeightMultiplied,
          }

          const theDate = moment()
          const isBetweenFromTo = isBetweenTwoDate(
            theDate,
            layout.mastercontents.PublishFrom,
            layout.mastercontents.PublishTo
          )
          // if (!isBetweenFromTo)
          //   return (
          //     <div
          //       key={index}
          //       style={{
          //         width: boxWidthMultiplied,
          //         height: boxHeightMultiplied,
          //         padding: 3,
          //       }}
          //     >
          //       <div
          //         style={{
          //           width: '100%',
          //           height: '100%',
          //           backgroundColor: '#008938',
          //           // borderRadius: 10,
          //           textAlign: 'center',
          //         }}
          //       ></div>
          //     </div>
          //   )

          /* data props */
          const boxData = { ...props, layout, popupModal }
          // console.log(boxData, ' BOXDATA.......................')
          /* data props */

          return (
            <div>
              {!showImage && (
                <div
                  style={{
                    position: 'absolute',
                    width: boxWidthMultiplied,
                    height: boxHeightMultiplied,
                    padding: 2,
                    zIndex: 9999,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#008938',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              )}
              <div
                key={index}
                style={{
                  width: boxWidthMultiplied,
                  height: boxHeightMultiplied,
                  padding: 3,
                }}
              >
                <Suspense fallback={null}>
                  <BodyItem key={index} {...boxData} />
                </Suspense>
              </div>
            </div>
          )
        } else {
          const boxWidth = parseInt(layout.masterbox.width)
          const boxWidthMultiplied = widthMultiplier * boxWidth
          const boxHeight = parseInt(layout.masterbox.height)
          const boxHeightMultiplied = widthMultiplier * boxHeight

          const topPos = isPreLoginView
            ? layout.startY * widthMultiplier
            : layout.startYForPostLogin * widthMultiplier
          const leftPos = isPreLoginView
            ? layout.startX * widthMultiplier
            : layout.startXForPostLogin * widthMultiplier
          const fontSizes = getFontSizes(widthMultiplier, width)
          layout.mastercontents = {
            ...layout.mastercontents,
            ...fontSizes,
            colsWidth: boxWidthMultiplied,
            colsHeight: boxHeightMultiplied,
          }
          const theDate = moment()
          const isBetweenFromTo = isBetweenTwoDate(
            theDate,
            layout.mastercontents.PublishFrom,
            layout.mastercontents.PublishTo
          )
          // if (!isBetweenFromTo)
          //   return (
          //     <div
          //       key={index}
          //       style={{
          //         position: 'absolute',
          //         top: topPos,
          //         left: leftPos,
          //         width: boxWidthMultiplied,
          //         height: boxHeightMultiplied,
          //         padding: 3,
          //       }}
          //     >
          //       <div
          //         style={{
          //           width: '100%',
          //           height: '100%',
          //           backgroundColor: '#008938',
          //           // borderRadius: 10,
          //           textAlign: 'center',
          //         }}
          //       ></div>
          //     </div>
          //   )

          /* data props */
          const boxData = { ...props, layout, popupModal }
          // console.log(boxData, ' BOXDATA.......................')
          /* data props */

          return (
            <div>
              {!showImage && (
                <div
                  style={{
                    position: 'absolute',
                    top: topPos,
                    left: leftPos,
                    width: boxWidthMultiplied,
                    height: boxHeightMultiplied,
                    padding: 2,
                    zIndex: 9999,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#008938',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              )}
              <div
                key={index}
                style={{
                  position: 'absolute',
                  top: topPos,
                  left: leftPos,
                  width: boxWidthMultiplied,
                  height: boxHeightMultiplied,
                  padding: 3,
                }}
              >
                <Suspense fallback={null}>
                  <BodyItem key={index} {...boxData} />
                </Suspense>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default Index
