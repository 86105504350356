import React, { lazy, Suspense, useContext, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2, LocalStorage } from '../../lib-atomic-functions'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
/* package */

import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'
/* modules */

import { useSnackbar } from 'notistack'
import LayoutBodyItem from '../../app-layout-section-body-item/default'

import CONFIG from '../config'

/* CONSTANTS */
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERSETUPCONFIG = {
  url: CMSENDPOINTS.updatemastersetup.url,
  method: CMSENDPOINTS.updatemastersetup.method,
  headers: CMSENDPOINTS.updatemastersetup.headers,
  responseType: 'json',
}

const CMSCONTENTVERSIONCONFIG = {
  url: CMSENDPOINTS.updatecontentversion.url,
  method: CMSENDPOINTS.updatecontentversion.method,
  headers: CMSENDPOINTS.updatecontentversion.headers,
  responseType: 'json',
}

const MEDIAPATH = CONFIG.setup.assets.media
/* CONSTANTS */

const checkItemIsPromotion = (item) => {
  return (
    item.mastercontents.type === 'Promotions' ||
    (item.mastercontents.type === 'Carousel' &&
      item.mastercontents.CarouselContents.some(
        (c) => c.mastercontents.type === 'Promotions'
      ))
  )
}
const checkItemIsNotPromotion2 = (item) => {
  return item.mastercontents.type === 'Promotions'
    ? false
    : item.mastercontents.type === 'Carousel'
    ? item.mastercontents.CarouselContents.some(
        (c) => c.mastercontents.type === 'Promotions'
      )
      ? false
      : item.mastercontents.CarouselContents.some(
          (c) => c.mastercontents.type !== 'Promotions'
        )
      ? true
      : false
    : true
}
const checkItemIsNotPromotion = (item) => {
  return (
    item.mastercontents.type === 'Carousel' &&
    !item.mastercontents.CarouselContents.some(
      (c) => c.mastercontents.type === 'Promotions'
    )
  )
}
const checkItemIsCarouselWithoutMatchingViewType = (item, viewtype) => {
  return (
    item.mastercontents.type === 'Carousel' &&
    !item.mastercontents.CarouselContents.some(
      (c) => c.mastercontents.type === viewtype
    )
  )
}
const updateMasterSetup = (
  newUpdate,
  numOfCol,
  isPreLogin,
  viewtype,
  shouldReturnObj
) => {
  var objToUse = shouldReturnObj
    ? JSON.parse(JSON.stringify(newUpdate))
    : newUpdate
  objToUse = objToUse.filter((i) =>
    viewtype === 'home' ? checkItemIsNotPromotion2(i) : checkItemIsPromotion(i)
  )
  console.log(objToUse)

  const xStartCoord = isPreLogin ? 'startX' : 'startXForPostLogin'
  const yStartCoord = isPreLogin ? 'startY' : 'startYForPostLogin'
  const xEndCoord = isPreLogin ? 'endX' : 'endXForPostLogin'
  const yEndCoord = isPreLogin ? 'endY' : 'endYForPostLogin'

  var currRowStartY = 0
  var currRowEndY = 0
  var prevBox = null
  objToUse.map((item, index) => {
    item[xStartCoord] = 0
    item[yStartCoord] = 0
    item[xEndCoord] = 0
    item[yEndCoord] = 0
    delete item.mastercontents['descriptionFontSize']
    delete item.mastercontents['titleFontSize']
    delete item.mastercontents['colsHeight']
    delete item.mastercontents['colsWidth']
    // delete item.mastercontents["example"];
    var highestYSoFar = 0
    objToUse.map((item2, index2) => {
      if (index2 < index) {
        if (item2[yEndCoord] < prevBox[yEndCoord]) {
          if (highestYSoFar < prevBox[yEndCoord]) {
            highestYSoFar = prevBox[yEndCoord]
          }
        } else {
          if (highestYSoFar < item2[yEndCoord]) {
            highestYSoFar = item2[yEndCoord]
          }
        }
      }
    })
    var rightMostX = 0
    objToUse.map((item2, index2) => {
      if (
        index2 < index &&
        item2[yStartCoord] >= currRowStartY &&
        item2[yEndCoord] <= currRowEndY
      ) {
        if (item2[xEndCoord] < item[xEndCoord]) {
          if (rightMostX < prevBox[xEndCoord]) {
            rightMostX = prevBox[xEndCoord]
          }
        } else {
          if (rightMostX < item2[xEndCoord]) {
            rightMostX = item2[xEndCoord]
          }
        }
      }
    })
    if (index === 0) {
      // when it is first item
      item[xStartCoord] = 0
      item[yStartCoord] = 0
      item[xEndCoord] = parseInt(item.masterbox.width)
      item[yEndCoord] = parseInt(item.masterbox.height)

      currRowStartY = item[yStartCoord]
      currRowEndY = item[yEndCoord]
      prevBox = item
    } else {
      if (prevBox[xEndCoord] === numOfCol) {
        // previous one was last item. so handle curr box as first item in a row OR this row's second item(double decked)
        const prevBoxFillTheRemainingHeight =
          currRowEndY - prevBox[yEndCoord] === 0 ? true : false
        if (prevBoxFillTheRemainingHeight) {
          // prev box already filled the height gap. go to new line
          console.log(highestYSoFar)
          item[xStartCoord] = 0
          item[yStartCoord] = highestYSoFar
          item[xEndCoord] = item[xStartCoord] + parseInt(item.masterbox.width)
          item[yEndCoord] = item[yStartCoord] + parseInt(item.masterbox.height)

          currRowStartY = item[yStartCoord]
          currRowEndY = item[yEndCoord]
          prevBox = item
        } else {
          // prev box did not filled the height gap. check if curr box can fit its height
          const boxFitHeight =
            currRowEndY - prevBox[yEndCoord] >= parseInt(item.masterbox.height)
              ? true
              : false
          if (boxFitHeight) {
            // check if width can fit as well
            const widthFit =
              prevBox[xStartCoord] + parseInt(item.masterbox.width) <= numOfCol
            if (widthFit) {
              item[xStartCoord] = prevBox[xStartCoord]
              item[yStartCoord] = prevBox[yEndCoord]
              item[xEndCoord] =
                item[xStartCoord] + parseInt(item.masterbox.width)
              item[yEndCoord] =
                item[yStartCoord] + parseInt(item.masterbox.height)
              prevBox = item
            } else {
              item[xStartCoord] = 0
              item[yStartCoord] = highestYSoFar
              item[xEndCoord] =
                item[xStartCoord] + parseInt(item.masterbox.width)
              item[yEndCoord] =
                item[yStartCoord] + parseInt(item.masterbox.height)

              currRowStartY = item[yStartCoord]
              currRowEndY = item[yEndCoord]
              prevBox = item
            }
          } else {
            item[xStartCoord] = 0
            item[yStartCoord] = highestYSoFar
            item[xEndCoord] = item[xStartCoord] + parseInt(item.masterbox.width)
            item[yEndCoord] =
              item[yStartCoord] + parseInt(item.masterbox.height)

            currRowStartY = item[yStartCoord]
            currRowEndY = item[yEndCoord]
            prevBox = item
          }
        }
      } else {
        // prev item is first in a row
        const isSecondOfRow =
          prevBox[yStartCoord] === currRowStartY &&
          prevBox[yEndCoord] === currRowEndY
            ? true
            : false

        if (isSecondOfRow) {
          // check if box fit to row height;
          const boxFitHeight =
            currRowEndY - currRowStartY >= parseInt(item.masterbox.height)
              ? true
              : false

          if (boxFitHeight) {
            // box does fit to row height.
            const boxFitToRemainingWidth =
              parseInt(item.masterbox.width) + prevBox[xEndCoord] <= numOfCol
                ? true
                : false
            if (boxFitToRemainingWidth) {
              item[xStartCoord] = prevBox[xEndCoord]
              item[yStartCoord] = currRowStartY
              item[xEndCoord] =
                item[xStartCoord] + parseInt(item.masterbox.width)
              item[yEndCoord] =
                item[yStartCoord] + parseInt(item.masterbox.height)
              prevBox = item
            } else {
              // box does not fit to width. go to new line
              item[xStartCoord] = 0
              item[yStartCoord] = highestYSoFar
              item[xEndCoord] =
                item[xStartCoord] + parseInt(item.masterbox.width)
              item[yEndCoord] =
                item[yStartCoord] + parseInt(item.masterbox.height)

              currRowStartY = item[yStartCoord]
              currRowEndY = item[yEndCoord]
              prevBox = item
            }
          } else {
            // box does not fit to row height. go to new line
            item[xStartCoord] = 0
            item[yStartCoord] = highestYSoFar
            item[xEndCoord] = item[xStartCoord] + parseInt(item.masterbox.width)
            item[yEndCoord] =
              item[yStartCoord] + parseInt(item.masterbox.height)

            currRowStartY = item[yStartCoord]
            currRowEndY = item[yEndCoord]
            prevBox = item
          }
        } else {
          // box is not second to it's row nor last of the row
          const prevBoxFillTheRemainingHeight =
            currRowEndY - prevBox[yEndCoord] === 0 ? true : false
          if (prevBoxFillTheRemainingHeight) {
            // prev box already filled the height gap. go to new line or go to start of row if width fit
            const widthFitToRemainingGap =
              numOfCol - prevBox[xEndCoord] - parseInt(item.masterbox.width) >=
              0
                ? true
                : false
            if (widthFitToRemainingGap) {
              if (currRowEndY === prevBox[yEndCoord]) {
                if (currRowStartY === prevBox[yStartCoord]) {
                  item[xStartCoord] = prevBox[xEndCoord]
                  item[yStartCoord] = currRowStartY
                  item[xEndCoord] =
                    item[xStartCoord] + parseInt(item.masterbox.width)
                  item[yEndCoord] =
                    item[yStartCoord] + parseInt(item.masterbox.height)
                  prevBox = item
                } else {
                  const goToStartOfRow =
                    rightMostX === prevBox[xEndCoord] ? true : false
                  if (goToStartOfRow) {
                    item[xStartCoord] = prevBox[xEndCoord]
                    item[yStartCoord] = currRowStartY
                    item[xEndCoord] =
                      item[xStartCoord] + parseInt(item.masterbox.width)
                    item[yEndCoord] =
                      item[yStartCoord] + parseInt(item.masterbox.height)
                    prevBox = item
                  } else {
                    if (
                      parseInt(item.masterbox.height) <=
                      prevBox[yEndCoord] - prevBox[yStartCoord]
                    ) {
                      item[xStartCoord] = prevBox[xEndCoord]
                      item[yStartCoord] = prevBox[yStartCoord]
                      item[xEndCoord] =
                        item[xStartCoord] + parseInt(item.masterbox.width)
                      item[yEndCoord] =
                        item[yStartCoord] + parseInt(item.masterbox.height)
                      prevBox = item
                    } else {
                      item[xStartCoord] = 0
                      item[yStartCoord] = highestYSoFar
                      item[xEndCoord] =
                        item[xStartCoord] + parseInt(item.masterbox.width)
                      item[yEndCoord] =
                        item[yStartCoord] + parseInt(item.masterbox.height)

                      currRowStartY = item[yStartCoord]
                      currRowEndY = item[yEndCoord]
                      prevBox = item
                    }
                  }
                }
              } else {
                item[xStartCoord] = prevBox[xEndCoord]
                item[yStartCoord] = prevBox[yStartCoord]
                item[xEndCoord] =
                  item[xStartCoord] + parseInt(item.masterbox.width)
                item[yEndCoord] =
                  item[yStartCoord] + parseInt(item.masterbox.height)
                prevBox = item
              }
            } else {
              const widthFitToRemainingGapAndBoxIsStartOfRow =
                rightMostX + parseInt(item.masterbox.width) <= numOfCol
                  ? true
                  : false
              if (widthFitToRemainingGapAndBoxIsStartOfRow) {
                item[xStartCoord] = rightMostX
                item[yStartCoord] = currRowStartY
                item[xEndCoord] =
                  item[xStartCoord] + parseInt(item.masterbox.width)
                item[yEndCoord] =
                  item[yStartCoord] + parseInt(item.masterbox.height)
                prevBox = item
              } else {
                item[xStartCoord] = 0
                item[yStartCoord] = highestYSoFar
                item[xEndCoord] =
                  item[xStartCoord] + parseInt(item.masterbox.width)
                item[yEndCoord] =
                  item[yStartCoord] + parseInt(item.masterbox.height)

                currRowStartY = item[yStartCoord]
                currRowEndY = item[yEndCoord]
                prevBox = item
              }
            }
          } else {
            // prev box did not filled the height gap. check if curr box can fit its height
            const boxFitHeight =
              currRowEndY - prevBox[yEndCoord] >=
              parseInt(item.masterbox.height)
                ? true
                : false
            if (boxFitHeight) {
              // fit the height and its second or more of the deck
              const widthFitToRemainingGap =
                numOfCol -
                  prevBox[xStartCoord] -
                  parseInt(item.masterbox.width) >=
                0
                  ? true
                  : false
              if (widthFitToRemainingGap) {
                item[xStartCoord] = prevBox[xStartCoord]
                item[yStartCoord] = prevBox[yEndCoord]
                item[xEndCoord] =
                  item[xStartCoord] + parseInt(item.masterbox.width)
                item[yEndCoord] =
                  item[yStartCoord] + parseInt(item.masterbox.height)
                prevBox = item
              } else {
                item[xStartCoord] = 0
                item[yStartCoord] = highestYSoFar
                item[xEndCoord] =
                  item[xStartCoord] + parseInt(item.masterbox.width)
                item[yEndCoord] =
                  item[yStartCoord] + parseInt(item.masterbox.height)

                currRowStartY = item[yStartCoord]
                currRowEndY = item[yEndCoord]
                prevBox = item
              }
            } else {
              // does not fit the height
              const widthFitToRemainingGap =
                prevBox[xEndCoord] + parseInt(item.masterbox.width) <= numOfCol
                  ? true
                  : false
              if (widthFitToRemainingGap) {
                const heightFitToRemainingGapFromStartOfRow =
                  currRowEndY - currRowStartY >= parseInt(item.masterbox.height)
                    ? true
                    : false
                if (heightFitToRemainingGapFromStartOfRow) {
                  item[xStartCoord] = prevBox[xEndCoord]
                  item[yStartCoord] = currRowStartY
                  item[xEndCoord] =
                    item[xStartCoord] + parseInt(item.masterbox.width)
                  item[yEndCoord] =
                    item[yStartCoord] + parseInt(item.masterbox.height)
                  prevBox = item
                } else {
                  item[xStartCoord] = 0
                  item[yStartCoord] = highestYSoFar
                  item[xEndCoord] =
                    item[xStartCoord] + parseInt(item.masterbox.width)
                  item[yEndCoord] =
                    item[yStartCoord] + parseInt(item.masterbox.height)

                  currRowStartY = item[yStartCoord]
                  currRowEndY = item[yEndCoord]
                  prevBox = item
                }
              } else {
                item[xStartCoord] = 0
                item[yStartCoord] = highestYSoFar
                item[xEndCoord] =
                  item[xStartCoord] + parseInt(item.masterbox.width)
                item[yEndCoord] =
                  item[yStartCoord] + parseInt(item.masterbox.height)

                currRowStartY = item[yStartCoord]
                currRowEndY = item[yEndCoord]
                prevBox = item
              }
            }
          }
        }
      }
    }
  })
  if (shouldReturnObj) {
    return objToUse
  }
}
const updatedNonSelectedItems = (
  mastersetup,
  newUpdate,
  visibility,
  viewtype
) => {
  // in case there is non selected items
  console.log(mastersetup, newUpdate)
  const returnObj = []
  mastersetup.map((layout) => {
    const inNewUpdate = newUpdate.find((item) => item.id === layout.id)
    console.log(inNewUpdate)
    if (inNewUpdate === undefined) {
      if (layout.mastercontents.type === 'Carousel') {
        const updatedOne = JSON.parse(JSON.stringify(layout))
        const isCarouselWithoutMatchingType =
          checkItemIsCarouselWithoutMatchingViewType(updatedOne, viewtype)
        if (isCarouselWithoutMatchingType) {
          if (visibility === 'pre') {
            updatedOne.startX = null
            updatedOne.startY = null
            updatedOne.endX = null
            updatedOne.endY = null
          } else {
            updatedOne['startXForPostLogin'] = null
            updatedOne['startYForPostLogin'] = null
            updatedOne['endXForPostLogin'] = null
            updatedOne['endYForPostLogin'] = null
          }
          returnObj.push(updatedOne)
        }
      } else {
        const updatedOne = JSON.parse(JSON.stringify(layout))
        if (visibility === 'pre') {
          updatedOne.startX = null
          updatedOne.startY = null
          updatedOne.endX = null
          updatedOne.endY = null
        } else {
          updatedOne['startXForPostLogin'] = null
          updatedOne['startYForPostLogin'] = null
          updatedOne['endXForPostLogin'] = null
          updatedOne['endYForPostLogin'] = null
        }
        returnObj.push(updatedOne)
      }
    }
  })
  return returnObj
}
const isBetweenTwoDate = (theDate, PublishFrom, PublishTo) => {
  const noFromDate = PublishFrom === null || PublishFrom === ''
  const noToDate = PublishTo === null || PublishTo === ''
  const publishFrom = noFromDate
    ? ' '
    : moment(PublishFrom).format('YYYY MM DD')
  const publishTo = noToDate ? ' ' : moment(PublishTo).format('YYYY MM DD')
  const isBetweenFromTo =
    publishFrom === ' ' && publishTo === ' '
      ? true
      : publishFrom === ' ' && publishTo !== ' '
      ? moment(theDate).isBefore(moment(publishTo).format('YYYY MM DD'))
      : publishFrom !== ' ' && publishTo === ' '
      ? moment(theDate).isAfter(moment(publishFrom).format('YYYY MM DD'))
      : moment(theDate).isBetween(
          moment(publishFrom).format('YYYY MM DD'),
          moment(publishTo).format('YYYY MM DD')
        )
  console.log('isBetweenFromTo ', isBetweenFromTo)
  return isBetweenFromTo
}

const sortByCord = (arrayObject) => {
  const x = arrayObject.sort(function (item1, item2) {
    if (item1.startX < item2.startX) return -1
    if (item1.startX > item2.startX) return 1
  })
  const y = x.sort(function (item1, item2) {
    if (item1.startY < item2.startY) return -1
    if (item1.startY > item2.startY) return 1
  })

  return y
}
const doUpdate = (
  platform,
  visibility,
  viewtype,
  filteredNewUpdateMiddle,
  filteredNewUpdate,
  setFilteredNewUpdateMiddle,
  setFilteredNewUpdate
) => {
  const numOfCol = platform.toLowerCase() === 'web' ? 4 : 2
  updateMasterSetup(
    filteredNewUpdateMiddle,
    numOfCol,
    visibility === 'pre' ? true : false,
    viewtype,
    false
  )
  updateMasterSetup(
    filteredNewUpdate,
    numOfCol,
    visibility === 'pre' ? true : false,
    viewtype,
    false
  )
}
const updatefilteredNewUpdate = (
  newUpdate,
  theDate,
  setFilteredNewUpdate,
  setFilteredNewUpdateMiddle,
  platform,
  visibility,
  viewtype,
  setInit2
) => {
  const numOfCol = platform.toLowerCase() === 'web' ? 4 : 2
  updateMasterSetup(
    newUpdate,
    numOfCol,
    visibility === 'pre' ? true : false,
    viewtype,
    false
  )
  const copyOfNewUpdate = JSON.parse(JSON.stringify(newUpdate))

  const filteredNewUpdate = []
  const filteredNewUpdateMiddle = []
  copyOfNewUpdate.map((layout) => {
    /* content publish checking  */
    /* --------------------------------- */
    const isCarousel = layout.mastercontents.type === 'Carousel'
    if (isCarousel) {
      const itemFiltered = layout.mastercontents.CarouselContents.filter(
        (item) =>
          (visibility === 'pre'
            ? item.mastercontents.contentVisibility === 'prelogin'
            : item.mastercontents.contentVisibility === 'postlogin') &&
          (viewtype === 'home'
            ? item.mastercontents.type !== 'Promotions'
            : item.mastercontents.type === 'Promotions') &&
          isBetweenTwoDate(
            theDate,
            item.mastercontents.PublishFrom,
            item.mastercontents.PublishTo
          )
      )
      const itemFilteredMiddle = layout.mastercontents.CarouselContents.filter(
        (item) =>
          (visibility === 'pre'
            ? item.mastercontents.contentVisibility === 'prelogin'
            : item.mastercontents.contentVisibility === 'postlogin') &&
          (viewtype === 'home'
            ? item.mastercontents.type !== 'Promotions'
            : item.mastercontents.type === 'Promotions')
      )
      console.log(itemFilteredMiddle)
      if (
        itemFiltered.length > 0 &&
        (visibility === 'pre'
          ? layout.mastercontents.contentVisibility === 'prelogin'
          : layout.mastercontents.contentVisibility === 'postlogin')
      ) {
        const layoutCopy = JSON.parse(JSON.stringify(layout))
        layoutCopy.mastercontents.CarouselContents = itemFiltered
        filteredNewUpdate.push(layoutCopy)
      }
      if (itemFilteredMiddle.length > 0) {
        const layoutCopy = JSON.parse(JSON.stringify(layout))
        layoutCopy.mastercontents.CarouselContents = itemFilteredMiddle
        filteredNewUpdateMiddle.push(layoutCopy)
      }
    } else {
      const layoutCopy = JSON.parse(JSON.stringify(layout))
      if (
        viewtype === 'home'
          ? layoutCopy.mastercontents.type !== 'Promotions'
          : layoutCopy.mastercontents.type === 'Promotions'
      ) {
        filteredNewUpdateMiddle.push(layoutCopy)
        if (
          visibility === 'pre'
            ? layoutCopy.mastercontents.contentVisibility === 'prelogin'
            : layoutCopy.mastercontents.contentVisibility === 'postlogin'
        ) {
          filteredNewUpdate.push(layoutCopy)
        }
      }
    }
    /* content publish checking  */
    /* --------------------------------- */
  })
  const sortedFilteredNewUpdate = sortByCord(filteredNewUpdate)
  const sortedFilteredNewUpdateMiddle = sortByCord(filteredNewUpdateMiddle)
  console.log(sortedFilteredNewUpdateMiddle)
  setFilteredNewUpdate(sortedFilteredNewUpdate)
  setFilteredNewUpdateMiddle(sortedFilteredNewUpdateMiddle)

  setInit2(true)
}
const initFilter = (
  newUpdate,
  theDate,
  setFilteredNewUpdate,
  setFilteredNewUpdateMiddle,
  platform,
  visibility,
  viewtype
) => {
  const copyOfNewUpdate = JSON.parse(JSON.stringify(newUpdate))

  const filteredNewUpdate = []
  const filteredNewUpdateMiddle = []
  copyOfNewUpdate.map((layout) => {
    /* content publish checking  */
    /* --------------------------------- */
    const isCarousel = layout.mastercontents.type === 'Carousel'
    if (isCarousel) {
      const itemForPreLogin = layout.mastercontents.CarouselContents.filter(
        (item) =>
          (visibility === 'pre'
            ? item.mastercontents.contentVisibility === 'prelogin'
            : item.mastercontents.contentVisibility === 'postlogin') &&
          (viewtype === 'home'
            ? item.mastercontents.type !== 'Promotions'
            : item.mastercontents.type === 'Promotions') &&
          isBetweenTwoDate(
            theDate,
            item.mastercontents.PublishFrom,
            item.mastercontents.PublishTo
          )
      )
      const itemForPreLoginMiddle =
        layout.mastercontents.CarouselContents.filter(
          (item) =>
            (visibility === 'pre'
              ? item.mastercontents.contentVisibility === 'prelogin'
              : item.mastercontents.contentVisibility === 'postlogin') &&
            (viewtype === 'home'
              ? item.mastercontents.type !== 'Promotions'
              : item.mastercontents.type === 'Promotions')
        )
      console.log(itemForPreLoginMiddle)
      if (
        itemForPreLogin.length > 0 &&
        (visibility === 'pre'
          ? layout.mastercontents.contentVisibility === 'prelogin'
          : layout.mastercontents.contentVisibility === 'postlogin')
      ) {
        const layoutCopy = JSON.parse(JSON.stringify(layout))
        layoutCopy.mastercontents.CarouselContents = itemForPreLogin
        filteredNewUpdate.push(layoutCopy)
      }
      if (itemForPreLoginMiddle.length > 0) {
        const layoutCopy = JSON.parse(JSON.stringify(layout))
        layoutCopy.mastercontents.CarouselContents = itemForPreLoginMiddle
        filteredNewUpdateMiddle.push(layoutCopy)
      }
    } else {
      const layoutCopy = JSON.parse(JSON.stringify(layout))
      if (
        viewtype === 'home'
          ? layoutCopy.mastercontents.type !== 'Promotions'
          : layoutCopy.mastercontents.type === 'Promotions'
      ) {
        filteredNewUpdateMiddle.push(layoutCopy)
        if (
          visibility === 'pre'
            ? layoutCopy.mastercontents.contentVisibility === 'prelogin'
            : layoutCopy.mastercontents.contentVisibility === 'postlogin'
        ) {
          filteredNewUpdate.push(layoutCopy)
        }
      }
    }
    /* content publish checking  */
    /* --------------------------------- */
  })
  console.log(filteredNewUpdate, filteredNewUpdateMiddle)
  const sortedFilteredNewUpdate = sortByCord(filteredNewUpdate)
  const sortedFilteredNewUpdateMiddle = sortByCord(filteredNewUpdateMiddle)
  setFilteredNewUpdate(sortedFilteredNewUpdate)
  setFilteredNewUpdateMiddle(sortedFilteredNewUpdateMiddle)
}
const updatePrePostCoord = async (
  platform,
  visibility,
  viewtype,
  newUpdate,
  preLoginItems,
  postLoginItems
) => {
  const numOfCol = platform === 'web' ? 4 : 2
  const itemsGot = visibility === 'pre' ? preLoginItems : postLoginItems
  const ItemsCoordUpdated = await updateMasterSetup(
    itemsGot,
    numOfCol,
    visibility === 'pre' ? true : false,
    viewtype,
    true
  )
  const newUpdateCopied = JSON.parse(JSON.stringify(newUpdate))
  newUpdateCopied.map((layout) => {
    if (
      viewtype === 'home'
        ? layout.mastercontents.type !== 'Promotions'
        : layout.mastercontents.type === 'Promotions'
    ) {
      if (ItemsCoordUpdated === undefined) {
        if (viewtype === 'home') {
          layout['startX'] = null
          layout['startY'] = null
          layout['endX'] = null
          layout['endY'] = null
        } else {
          layout['startXForPostLogin'] = null
          layout['startYForPostLogin'] = null
          layout['endXForPostLogin'] = null
          layout['endYForPostLogin'] = null
        }
      } else {
        const inNewUpdate = ItemsCoordUpdated.find(
          (item) => item.id === layout.id
        )
        if (inNewUpdate !== undefined) {
          if (viewtype === 'home') {
            layout['startX'] = inNewUpdate.startX
            layout['startY'] = inNewUpdate.startY
            layout['endX'] = inNewUpdate.endX
            layout['endY'] = inNewUpdate.endY
          } else {
            layout['startXForPostLogin'] = inNewUpdate.startXForPostLogin
            layout['startYForPostLogin'] = inNewUpdate.startYForPostLogin
            layout['endXForPostLogin'] = inNewUpdate.endXForPostLogin
            layout['endYForPostLogin'] = inNewUpdate.endYForPostLogin
          }
        } else {
          if (viewtype === 'home') {
            layout['startX'] = null
            layout['startY'] = null
            layout['endX'] = null
            layout['endY'] = null
          } else {
            layout['startXForPostLogin'] = null
            layout['startYForPostLogin'] = null
            layout['endXForPostLogin'] = null
            layout['endYForPostLogin'] = null
          }
        }
      }
    }
  })
  return newUpdateCopied
}
const separatePrePostLoginItems = async (
  newUpdate,
  platform,
  visibility,
  viewtype
) => {
  const preLoginItems = []
  const postLoginItems = []
  const copyOfFilteredNewUpdate = JSON.parse(JSON.stringify(newUpdate))
  copyOfFilteredNewUpdate.map((layout) => {
    /* content publish checking  */
    /* --------------------------------- */
    // pre login carousel
    const isCarousel = layout.mastercontents.type === 'Carousel'
    if (isCarousel) {
      const itemForPreLogin = layout.mastercontents.CarouselContents.filter(
        (item) =>
          item.mastercontents.contentVisibility === 'prelogin' &&
          (viewtype === 'home'
            ? item.mastercontents.type !== 'Promotions'
            : item.mastercontents.type === 'Promotions')
      )
      // post login carousel
      const itemForPostLogin = layout.mastercontents.CarouselContents.filter(
        (item) =>
          (item.mastercontents.contentVisibility === 'postlogin' ||
            item.mastercontents.contentVisibility === 'prelogin') &&
          (viewtype === 'home'
            ? item.mastercontents.type !== 'Promotions'
            : item.mastercontents.type === 'Promotions')
      )

      if (itemForPreLogin.length > 0) {
        const layoutCopy = JSON.parse(JSON.stringify(layout))
        layoutCopy.mastercontents.CarouselContents = itemForPreLogin
        preLoginItems.push(layoutCopy)
      }
      if (itemForPostLogin.length > 0) {
        const layoutCopy = JSON.parse(JSON.stringify(layout))
        layoutCopy.mastercontents.CarouselContents = itemForPostLogin
        postLoginItems.push(layoutCopy)
      }
    } else {
      if (layout.mastercontents.contentVisibility === 'postlogin') {
        postLoginItems.push(layout)
      } else {
        postLoginItems.push(layout)
        preLoginItems.push(layout)
      }
    }
  })
  return [preLoginItems, postLoginItems]
}
const updatedNewUpdate = async (newUpdate, platform, visibility, viewtype) => {
  const [preLoginItems, postLoginItems] = await separatePrePostLoginItems(
    newUpdate,
    platform,
    visibility,
    viewtype
  )
  /* content publish checking  */
  /* --------------------------------- */
  const updatedPrePostCoord = await updatePrePostCoord(
    platform,
    visibility,
    viewtype,
    newUpdate,
    preLoginItems,
    postLoginItems
  )
  return updatedPrePostCoord
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
  typography: {
    padding: theme.spacing(2),
  },
}))
const returnPreLoginContentsOnly = (content, visibility, viewtype) => {
  if (!content) return false

  if (
    viewtype === 'home'
      ? content.mastercontents.type === 'Promotions'
      : content.mastercontents.type !== 'Promotions' &&
        content.mastercontents.type !== 'Carousel'
  )
    return false

  var preLoginContents = false
  if (content.mastercontents.type === 'Carousel') {
    if (
      visibility === 'pre'
        ? content.mastercontents.contentVisibility !== 'prelogin'
        : content.mastercontents.contentVisibility !== 'postlogin'
    ) {
      return false
    }
    const filteredCarouselContent =
      content.mastercontents.CarouselContents.filter((item) => {
        return (
          (visibility === 'pre'
            ? item.mastercontents.contentVisibility === 'prelogin'
            : item.mastercontents.contentVisibility === 'postlogin') &&
          (viewtype === 'home'
            ? item.mastercontents.type !== 'Promotions'
            : item.mastercontents.type === 'Promotions')
        )
      })
    content.mastercontents.CarouselContents = filteredCarouselContent
    preLoginContents =
      (visibility === 'pre'
        ? content.mastercontents.contentVisibility === 'prelogin'
        : content.mastercontents.contentVisibility === 'postlogin') &&
      content.mastercontents.CarouselContents.length > 0
        ? content
        : false
  } else {
    preLoginContents =
      (visibility === 'pre'
        ? content.mastercontents.contentVisibility === 'prelogin'
        : content.mastercontents.contentVisibility === 'postlogin') &&
      (viewtype === 'home'
        ? content.mastercontents.type !== 'Promotions'
        : content.mastercontents.type === 'Promotions')
        ? content
        : false
  }
  return preLoginContents
}
const checkPreLoginContent = (content, visibility, viewtype) => {
  var returnFlag = false
  if (
    viewtype === 'home'
      ? content.mastercontents.type === 'Promotions'
      : content.mastercontents.type !== 'Promotions' &&
        content.mastercontents.type !== 'Carousel'
  )
    return false

  if (content.mastercontents.type === 'Carousel') {
    console.log('aa ', content.mastercontents.contentVisibility !== 'prelogin')
    if (
      visibility === 'pre'
        ? content.mastercontents.contentVisibility !== 'prelogin'
        : content.mastercontents.contentVisibility !== 'postlogin'
    )
      return false
    returnFlag = content.mastercontents.CarouselContents.some((item) => {
      console.log(content.mastercontents, item)
      return (
        (visibility === 'pre'
          ? content.mastercontents.contentVisibility === 'prelogin'
          : content.mastercontents.contentVisibility === 'postlogin') &&
        (viewtype === 'home'
          ? item.mastercontents.type !== 'Promotions'
          : item.mastercontents.type === 'Promotions')
      )
    })
  } else {
    returnFlag =
      (visibility === 'pre'
        ? content.mastercontents.contentVisibility === 'prelogin'
        : content.mastercontents.contentVisibility === 'postlogin') &&
      (viewtype === 'home'
        ? content.mastercontents.type !== 'Promotions'
        : content.mastercontents.type === 'Promotions')
  }
  return returnFlag
}
const Index = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { token, username } = LocalStorage.getLS().strapi
  const { id, platform, visibility, viewtype } = props.match.params
  const classes = useStyles()
  const { width, height } = WindowSize()
  const midRightContainerWidth =
    width > 1200 ? (1000 * 40) / 100 : ((width - 40) * 60) / 200

  /* state */
  const {
    mastersetups,
    allcontents,
    contents,
    contentcarouseldetail,
    updatesetup,
    updatecontentversion,
  } = useContext(GlobalContext)

  // const fetchResetExec = async () => {
  //   cognitouser.dispatch({
  //     type: 'RESET',
  //   })
  //   mastersetups.dispatch({
  //     type: 'RESET',
  //   })
  //   contents.dispatch({
  //     type: 'RESET',
  //   })
  //   contentcarouseldetail.dispatch({
  //     type: 'RESET',
  //   })
  // }
  const resetContextItems = async () => {
    mastersetups.dispatch({
      type: 'RESET',
    })
    allcontents.dispatch({
      type: 'RESET',
    })
    updatesetup.dispatch({
      type: 'RESET',
    })
    contents.dispatch({
      type: 'RESET',
    })
    contentcarouseldetail.dispatch({
      type: 'RESET',
    })
  }
  const mastersetupobj =
    platform === 'mob'
      ? mastersetups.state.data[0].MobContents
      : mastersetups.state.data[0].WebContents
  console.log(mastersetupobj)
  const { getColsWidthHeight } = WindowLayout(width, height, platform)
  const { getFontSizes } = WindowFont()

  // enable drag
  const [init, setInit] = useState(false)
  const [init2, setInit2] = useState(false)
  const [itemDropped, setItemDropped] = useState(false)
  const [currDragItem, setCurrDragItem] = useState(false)
  const [newUpdate, setNewUpdate] = useState([])
  const [filteredNewUpdate, setFilteredNewUpdate] = useState([])
  const [filteredNewUpdateMiddle, setFilteredNewUpdateMiddle] = useState([])
  const [theDate, setTheDate] = useState(moment())
  const [visibleModal, setVisibleModal] = useState(false)
  const [removeDone, setRemoveDone] = useState(false)

  const onDragStart = (e, content) => {
    setCurrDragItem(content)
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDrop = (e) => {
    const prepared = [...newUpdate]
    prepared.push(currDragItem)
    setNewUpdate(prepared)
    setItemDropped(true)
  }

  useEffect(() => {
    if (itemDropped) {
      console.log('itemDropped ', newUpdate)
      updatefilteredNewUpdate(
        newUpdate,
        theDate,
        setFilteredNewUpdate,
        setFilteredNewUpdateMiddle,
        platform,
        visibility,
        viewtype,
        setInit2
      )
    }
    setItemDropped(false)
  }, [itemDropped])

  // set newUpdate if there is coordinate preset from setup data
  useEffect(() => {
    if (init) {
      setInit(false)
      initFilter(
        newUpdate,
        theDate,
        setFilteredNewUpdate,
        setFilteredNewUpdateMiddle,
        platform,
        visibility,
        viewtype
      )
    }
  }, [init])
  useEffect(() => {
    if (init2) {
      doUpdate(
        platform,
        visibility,
        viewtype,
        filteredNewUpdateMiddle,
        filteredNewUpdate,
        setFilteredNewUpdateMiddle,
        setFilteredNewUpdate
      )
      setInit2(false)
    }
  }, [init2])
  useEffect(() => {
    const itemWithCoord = []
    mastersetupobj.map((contentItem) => {
      console.log(contentItem)
      const emptyCoordFound =
        contentItem.startX === null ||
        contentItem.startY === null ||
        contentItem.endX === null ||
        contentItem.endY === null ||
        (contentItem.startX.toString().replace(/\s/g, '') === '0' &&
          contentItem.startY.toString().replace(/\s/g, '') === '0' &&
          contentItem.endX.toString().replace(/\s/g, '') === '0' &&
          contentItem.endY.toString().replace(/\s/g, '') === '0') ||
        contentItem.startX.toString().replace(/\s/g, '') === '' ||
        contentItem.startY.toString().replace(/\s/g, '') === '' ||
        contentItem.endX.toString().replace(/\s/g, '') === '' ||
        contentItem.endY.toString().replace(/\s/g, '') === ''
      const emptyCoordFoundForPostLogin =
        contentItem.startXForPostLogin === null ||
        contentItem.startYForPostLogin === null ||
        contentItem.endXForPostLogin === null ||
        contentItem.endYForPostLogin === null ||
        (contentItem.startXForPostLogin.toString().replace(/\s/g, '') === '0' &&
          contentItem.startYForPostLogin.toString().replace(/\s/g, '') ===
            '0' &&
          contentItem.endXForPostLogin.toString().replace(/\s/g, '') === '0' &&
          contentItem.endYForPostLogin.toString().replace(/\s/g, '') === '0') ||
        contentItem.startXForPostLogin.toString().replace(/\s/g, '') === '' ||
        contentItem.startYForPostLogin.toString().replace(/\s/g, '') === '' ||
        contentItem.endXForPostLogin.toString().replace(/\s/g, '') === '' ||
        contentItem.endYForPostLogin.toString().replace(/\s/g, '') === ''
      if (visibility === 'pre') {
        if (!emptyCoordFound) {
          if (contentItem.mastercontents.contentVisibility === 'prelogin') {
            itemWithCoord.push(contentItem)
          }
        }
      } else {
        if (!emptyCoordFoundForPostLogin) {
          if (contentItem.mastercontents.contentVisibility === 'postlogin') {
            itemWithCoord.push(contentItem)
          }
        }
      }
    })
    const sortedItemWithCoord = sortByCord(itemWithCoord)
    console.log(sortedItemWithCoord)
    setNewUpdate(sortedItemWithCoord)
    setInit(true)
    return () => resetContextItems()
  }, [])

  useEffect(() => {
    if (removeDone) {
      setRemoveDone(false)
      props.history.push('/p/login')
    }
  }, [removeDone])
  useEffect(() => {
    if (updatesetup.state.data) {
      if (!updatesetup.state.isStatus) {
        LocalStorage.removeLS()
        setRemoveDone(true)
      }
    }
  }, [updatesetup.state])

  /* --- master setups */
  const fetchUpdateMasterSetupExecute = async ({ query, body }) => {
    CMSMASTERSETUPCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdateMasterSetup] = FetchContextApiV2(CMSMASTERSETUPCONFIG)
    const fetchResult = await fetchUpdateMasterSetup(
      { query, body },
      updatesetup
    )
    updatesetup.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchUpdateMasterContentVersionExecute = async ({ query, body }) => {
    CMSCONTENTVERSIONCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdateMasterSetup] = FetchContextApiV2(CMSCONTENTVERSIONCONFIG)
    const fetchResult = await fetchUpdateMasterSetup(
      { query, body },
      updatecontentversion
    )
    updatecontentversion.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- setup */

  const submitButton = {
    style: { marginLeft: 10 },
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
    onClick: async () => {
      setVisibleModal(!visibleModal)
      if (mastersetups.state.data[0].environment === 'production') {
        setVisibleModal(false)
        const message = 'Production setup cannot be modified :('
        enqueueSnackbar(message, {
          variant: 'warning',
        })
      } else {
        const nonSelectedItems = updatedNonSelectedItems(
          mastersetupobj,
          newUpdate,
          visibility,
          viewtype
        )
        const updatedNewUpdate2 = await updatedNewUpdate(
          newUpdate,
          platform,
          visibility,
          viewtype
        )
        const query = '/' + id
        const newSetup =
          platform.toLowerCase() === 'web'
            ? { WebContents: [...updatedNewUpdate2, ...nonSelectedItems] }
            : { MobContents: [...updatedNewUpdate2, ...nonSelectedItems] }
        console.log(newSetup, nonSelectedItems)
        await fetchUpdateMasterSetupExecute({ query, body: newSetup })
      }
    },
  }

  const incrementContentVersion = async () => {
    const mastersetupsobj =
      mastersetups.state.data && mastersetups.state.data[0]
    console.log(mastersetupsobj)
    const selectedNewMCV = mastersetupsobj.mastercontentsversions

    const versionArr = selectedNewMCV.version.split('.')
    const newVersion = versionArr
      .map((num, index) => {
        if (versionArr.length - 1 === index) {
          return (parseInt(num) + 1).toString()
        } else {
          return num
        }
      })
      .join('.')
    const body = {
      ...selectedNewMCV,
      version: newVersion,
      environment: 'development',
    }
    await fetchUpdateMasterContentVersionExecute({ query: '/' + body.id, body })
  }

  useEffect(() => {
    if (
      updatecontentversion.state.data &&
      updatecontentversion.state.isStatus &&
      !updatecontentversion.state.isLoading
    ) {
      setVisibleModal(!visibleModal)
      props.history.push('/layout/preview/' + id)
    }
  }, [updatecontentversion.state])
  useEffect(() => {
    if (
      updatesetup.state.data &&
      updatesetup.state.isStatus &&
      !updatesetup.state.isLoading
    ) {
      incrementContentVersion()
    }
  }, [updatesetup.state])

  const cancelButton = {
    style: { marginRight: 10 },
    fullWidth: true,
    onClick: () => {
      props.history.push('/layout/preview/' + id)
    },
  }
  const dateBox = {
    name: 'datePicker',
    initial: theDate,
    value: theDate,
    onPick: (name, date) => {
      setTheDate(date)
    },
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        marginBottom: 60,
        height: height,
      }}
    >
      {/* modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visibleModal}
        closeAfterTransition
      >
        <Fade in={visibleModal}>
          <CircularProgress
            className={classes.throbber}
            size={30}
            thickness={5}
          />
        </Fade>
      </Modal>
      {/* left box */}
      <div
        style={{
          width: midRightContainerWidth / 2,
          justifyContent: 'center',
          paddingRight: 10,
          margin: 'auto',
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{ paddingLeft: 5, paddingBottom: 16, fontWeight: 900 }}
        >
          Prelogin View
        </Typography>
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#eeeeee',
            borderRightWidth: 2,
            borderRightColor: 'red',
          }}
        >
          <Typography variant="h6" gutterBottom style={{ paddingLeft: 5 }}>
            Available Contents
          </Typography>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Buttons.Normal {...cancelButton}>Cancel</Buttons.Normal>
            <Buttons.Normal {...submitButton}>Update</Buttons.Normal>
          </div>
          {mastersetupobj.map((layout, index) => {
            console.log(layout)
            if (!checkPreLoginContent(layout, visibility, viewtype))
              return <div></div>
            const inNewUpdate = newUpdate.find((item) => item.id === layout.id)
            if (inNewUpdate !== undefined) return <div></div>

            const contentWidth = parseInt(layout.masterbox.width)
            const contentHeight = parseInt(layout.masterbox.height)

            const [colsWidth, colsHeight] = getColsWidthHeight(layout.masterbox)
            const fontSizes = getFontSizes(colsWidth, width)
            layout.mastercontents = {
              ...layout.mastercontents,
              ...fontSizes,
              colsWidth,
              colsHeight: (contentHeight * 250 * 25) / 100,
            }

            if (layout.mastercontents.type === 'Carousel') {
              console.log(layout)
            }
            const image =
              layout.mastercontents.type === 'Carousel'
                ? layout.mastercontents.CarouselContents[0].mastercontents
                    .homeImage
                  ? 'url(' +
                    MEDIAPATH +
                    layout.mastercontents.CarouselContents[0].mastercontents
                      .homeImage.url +
                    ')'
                  : ''
                : layout.mastercontents.homeImage
                ? 'url(' + MEDIAPATH + layout.mastercontents.homeImage.url + ')'
                : ''
            return (
              <div
                draggable={true}
                onDragStart={(e) => onDragStart(e, layout)}
                style={{
                  cursor: 'pointer',
                  margin: 10,
                  width: (contentWidth * 250 * 25) / 100,
                  height: (contentHeight * 250 * 25) / 100,
                  display: 'flex',
                  background:
                    'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),' +
                    image,
                  backgroundSize: 'cover',
                  backgroundPosition: '50% 50%',
                  borderRadius: 10,
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                  borderColor: 'green',
                }}
                // onClick={e => {
                //   const prepared = [...newUpdate];
                //   prepared.push(layout);
                //   setNewUpdate(prepared);
                // }}
              ></div>
            )
          })}
        </div>
      </div>
      {/* middle box */}
      <div
        style={{
          width: midRightContainerWidth,
          height: 'auto',
          marginLeft: 50,
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <Typography variant="h6" gutterBottom style={{ paddingLeft: 5 }}>
            Layout Preview
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'wrap',
            position: 'relative',
            width: '100%',
            height: 'auto',
            marginTop: 15,
          }}
          onDragOver={(e) => onDragOver(e)}
          onDrop={(e) => onDrop(e)}
        >
          {filteredNewUpdateMiddle.length === 0 ? null : (
            <div
              style={{
                width: midRightContainerWidth,
                height: '100vh',
                borderRadius: 12,
                backgroundColor: '#d1edd8',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: 'green',
              }}
              onDragOver={(e) => onDragOver(e)}
              onDrop={(e) => onDrop(e)}
            >
              <p
                style={{
                  textAlign: 'center',
                  width: '100%',
                  paddingTop: 100,
                  paddingLeft: 30,
                  paddingRight: 30,
                  fontWeight: 900,
                  fontSize: 24,
                  color: '#038d43',
                }}
              >
                Drag & Drop Contents from left to here!
              </p>
            </div>
          )}
          {filteredNewUpdateMiddle.length === 0 ? (
            <div
              style={{
                width: midRightContainerWidth,
                height: '100vh',
                borderRadius: 12,
                backgroundColor: '#d1edd8',
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  width: '100%',
                  paddingTop: 100,
                  paddingLeft: 30,
                  paddingRight: 30,
                  fontWeight: 900,
                  fontSize: 24,
                  color: '#038d43',
                }}
              >
                Drag & Drop Contents from left to here!
              </p>
            </div>
          ) : (
            filteredNewUpdateMiddle.map((layout, index) => {
              console.log(layout)
              const filteredLayout = returnPreLoginContentsOnly(
                layout,
                visibility,
                viewtype
              )
              if (!filteredLayout) return null
              const widthMultiplier =
                platform.toLowerCase() === 'web'
                  ? midRightContainerWidth / 4
                  : midRightContainerWidth / 2

              const boxWidth = parseInt(filteredLayout.masterbox.width)
              const boxWidthMultiplied = widthMultiplier * boxWidth
              const boxHeight = parseInt(filteredLayout.masterbox.height)
              const boxHeightMultiplied = widthMultiplier * boxHeight

              const topPos =
                visibility === 'pre'
                  ? filteredLayout.startY * widthMultiplier
                  : filteredLayout.startYForPostLogin * widthMultiplier
              const leftPos =
                visibility === 'pre'
                  ? filteredLayout.startX * widthMultiplier
                  : filteredLayout.startXForPostLogin * widthMultiplier
              const fontSizes = getFontSizes(widthMultiplier, width)
              filteredLayout.mastercontents = {
                ...filteredLayout.mastercontents,
                ...fontSizes,
                colsWidth: boxWidthMultiplied,
                colsHeight: boxHeightMultiplied,
              }

              /* data props */
              const boxData = {
                ...props,
                layout: filteredLayout,
                theDate,
              }
              /* data props */
              return (
                <div
                  style={{
                    position: 'absolute',
                    top: topPos,
                    left: leftPos,
                    width: boxWidthMultiplied,
                    height: boxHeightMultiplied,
                    padding: 3,
                  }}
                >
                  <div
                    onClick={async (e) => {
                      e.preventDefault()
                      const changedNewUpdate = [...newUpdate]
                      const indexFound = changedNewUpdate.findIndex((i) => {
                        return i.id === layout.id
                      })
                      await changedNewUpdate.splice(indexFound, 1)
                      setNewUpdate(changedNewUpdate)
                      setItemDropped(true)
                    }}
                    style={{
                      cursor: 'pointer',
                      zIndex: 99,
                      backgroundColor: 'red',
                      width: 30,
                      height: 30,
                      borderRadius: 15,
                      position: 'absolute',
                      top: 3,
                      right: 3,
                      padding: 5,
                    }}
                  >
                    <CloseIcon
                      style={{
                        color: 'ffffff',
                        paddingRight: 9,
                        paddingBottom: 9,
                        fontSize: 30,
                      }}
                    />
                  </div>
                  <Suspense fallback={null}>
                    <LayoutBodyItem {...boxData} />
                  </Suspense>
                </div>
              )
            })
          )}
        </div>
      </div>
      {/* right box */}
      <div
        style={{
          width: midRightContainerWidth,
          height: 'auto',
          marginLeft: 50,
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              justifyContent: 'center',
              paddingRight: 10,
              margin: 'auto',
              flex: 1,
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{ paddingLeft: 5, width: '100%' }}
            >
              Actual&nbsp;Preview
            </Typography>
          </div>
          <div>
            <Inputs.Date {...dateBox} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'wrap',
            position: 'relative',
            width: '100%',
            height: 'auto',
          }}
        >
          {filteredNewUpdate.map((layout, index) => {
            const widthMultiplier =
              platform.toLowerCase() === 'web'
                ? midRightContainerWidth / 4
                : midRightContainerWidth / 2

            const boxWidth = parseInt(layout.masterbox.width)
            const boxWidthMultiplied = widthMultiplier * boxWidth
            const boxHeight = parseInt(layout.masterbox.height)
            const boxHeightMultiplied = widthMultiplier * boxHeight

            const topPos =
              visibility === 'pre'
                ? layout.startY * widthMultiplier
                : layout.startYForPostLogin * widthMultiplier
            const leftPos =
              visibility === 'pre'
                ? layout.startX * widthMultiplier
                : layout.startXForPostLogin * widthMultiplier
            const fontSizes = getFontSizes(widthMultiplier, width)
            layout.mastercontents = {
              ...layout.mastercontents,
              ...fontSizes,
              colsWidth: boxWidthMultiplied,
              colsHeight: boxHeightMultiplied,
            }
            /////////////...
            const isBetweenFromTo = isBetweenTwoDate(
              theDate,
              layout.mastercontents.PublishFrom,
              layout.mastercontents.PublishTo
            )
            // if (!isBetweenFromTo)
            //   return (
            //     <div
            //       style={{
            //         position: 'absolute',
            //         top: topPos,
            //         left: leftPos,
            //         width: boxWidthMultiplied,
            //         height: boxHeightMultiplied,
            //         padding: 3,
            //       }}
            //     >
            //       <div
            //         style={{
            //           width: '100%',
            //           height: '100%',
            //           backgroundColor: '#098d42',
            //           borderRadius: 10,
            //           textAlign: 'center',
            //         }}
            //       >
            //       </div>
            //     </div>
            //   )

            /* data props */
            const boxData = {
              ...props,
              layout,
              hideDate: true,
              removeOpacity: platform === 'mob' ? true : false,
            }
            /* data props */
            return (
              <div
                style={{
                  position: 'absolute',
                  top: topPos,
                  left: leftPos,
                  width: boxWidthMultiplied,
                  height: boxHeightMultiplied,
                  padding: 3,
                }}
              >
                <Suspense fallback={null}>
                  <LayoutBodyItem {...boxData} />
                </Suspense>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Index
