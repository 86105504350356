import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import AccountIcon from '@material-ui/icons/AccountCircle'

import { LocalStorage } from '../../lib-atomic-functions'

const Index = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => setAnchorEl(null)

  const menuItems = [
    { label: 'Profile', url: '/userprofile' },
    { label: 'Logout', url: '/auth/logout' },
  ]

  return (
    <>
      <IconButton
        color="inherit"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <AccountIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem onClick={handleClose} key={index}>
            <Link to={item.url}>{item.label}</Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
export default Index
