import React, { lazy, Suspense, useContext, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
  FetchLoggerApi,
} from '../../lib-atomic-functions'
import Chip from '@material-ui/core/Chip'
import CancelIcon from '@material-ui/icons/Cancel'
/* package */

import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'
/* modules */
import CONFIG from '../config'

import SectionCountrySelect from '../../app-country-section-select/default'
import SectionRoleSelect from '../../app-role-section-select/default'

import Spinner from '../../app-module-section-spinner/default'

/* CONSTANTS */
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects
const USEREDITOBJECTS = CONFIG.application.cms.objects

const CMSMASTERCOGNITOUSERCONFIG = {
  url: CMSENDPOINTS.updatecognitouser.url,
  method: CMSENDPOINTS.updatecognitouser.method,
  headers: CMSENDPOINTS.updatecognitouser.headers,
  responseType: 'json',
}
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chip: {
    margin: 2,
    height: 37,
    borderRadius: 18.5,
    color: 'white',
    backgroundColor: '#42a5f5',
    cursor: 'auto',
    '&:hover': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
    '&:focus': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
  },
  chipClose: {
    color: 'white',
    '&:hover': {
      color: '#e6e6e6',
    },
    '&:focus': {
      color: '#e6e6e6',
    },
  },
}))
const Index = (props) => {
  const classes = useStyles()
  const { id } = props.match.params
  const { token, username } = LocalStorage.getLS().strapi
  const {
    login,
    cognitouser,
    cognitouserdetail,
    updatecognitouser,
    role,
    country,
  } = useContext(GlobalContext)
  console.log(LocalStorage.getLS().strapi)
  const [loading, setLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(
    cognitouserdetail.state.data[0].MasterUCCountries.map(
      (userCountry) => userCountry.mastercountry
    )
  )
  const [selectedRole, setSelectedRole] = useState(
    role.state.data.filter(
      (role) => role.user.role === cognitouserdetail.state.data[0].user.role
    )
  )

  useEffect(() => {}, [])

  const updateDetailExec = async ({ query, body, oldData }) => {
    CMSMASTERCOGNITOUSERCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSERCONFIG)
    const fetchResult = await fetchCognitoUser(
      { query, body },
      updatecognitouser
    )
    updatecognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
    updatecognitouser.dispatch({
      type: 'RESET',
    })
    props.history.push('/user/list')

    /* -------------- */
    /* start: logger */
    await FetchLoggerApi({
      username,
      api: CMSMASTERCOGNITOUSERCONFIG,
      page: 'user-detail',
      oldData: oldData,
      newData: body,
    })
    /* end: logger */
    /* -------------- */
  }

  const handleCancel = () => {
    props.history.push('/user/list')
  }

  const handleUpdate = async () => {
    if (!loading) {
      setLoading(true)

      const roleSets = selectedRole.map((role) => role.name)
      values.userroles = role.state.data.filter(
        (role) => roleSets.indexOf(role.name) > -1
      )
      const countrySets = selectedCountry.map((country) => country.name)
      values.mastercountries = country.state.data.filter(
        (country) => countrySets.indexOf(country.name) > -1
      )
      values.mastercountries = values.mastercountries.map((country) => {
        return { mastercountry: country }
      })
      const query = '/' + id
      const body = {
        id: id,
        name: cognitouserdetail.state.data.name,
        cognitoId: cognitouserdetail.state.data.cognitoId,
        user: values.userroles[0].user,
        MasterUCCountries: values.mastercountries,
      }
      // console.log(values)
      // return false
      updateDetailExec({ query, body, oldData: cognitouserdetail.state.data })
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleUpdate,
    USEREDITOBJECTS
  )

  USEREDITOBJECTS.cognitoId.initial = cognitouserdetail.state.data.cognitoId
  USEREDITOBJECTS.name.initial = cognitouserdetail.state.data.name
  const cognitoId = {
    ...USEREDITOBJECTS.cognitoId,
    error: error.cognitoId,
    value: values.cognitoId,
    onChange,
  }
  const name = {
    ...USEREDITOBJECTS.name,
    error: error.name,
    value: values.name,
    onChange,
  }
  const userroles = {
    ...USEREDITOBJECTS.userroles,
    error: error.userroles,
    value: values.userroles,
    onChange,
  }
  const mastercountries = {
    ...USEREDITOBJECTS.mastercountries,
    error: error.mastercountries,
    value: values.mastercountries,
    onChange,
  }

  /* -- data props --   */
  const countryData = {
    setSelected: setSelectedCountry,
    itemsSelected: selectedCountry,
  }
  const roleData = {
    setSelected: setSelectedRole,
    itemsSelected: selectedRole,
  }
  const submitButton = { fullWidth: true }
  const cancelButton = { fullWidth: true, onClick: () => handleCancel() }
  /* -- data props --   */

  return (
    <form onSubmit={onSubmit} noValidate>
      {/* <Inputs.Text {...cognitoId} /> */}
      <Typography variant="body2">
        <b>Roles</b>
      </Typography>
      <SectionRoleSelect {...roleData} />
      <br />
      <br />
      <Typography variant="body2">
        <b>Countries</b>
      </Typography>
      <SectionCountrySelect {...countryData} />
      <br />
      <br />
      <Buttons.Normal {...submitButton}>Update</Buttons.Normal>
      <br />
      <br />
      <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
      <Spinner.Loader color="#fff" size={24} loading={loading} />
    </form>
  )
}

export default Index
