import React from 'react'

/* packages */
import Privilege from '../app-conf-privilege';
import { LocalStorage } from '../lib-atomic-functions';
/* packages */

import CONFIG from './config';

const Links = (availableContainers) => {
  return availableContainers.map((Module, index) => {
    const { Links } = Module;
    if (Links) return <Links key={index} />
  });
}

const Atom =  props => {
  const { rolesHandler, rolesModules } = Privilege[process.env.REACT_APP_CLIENT];
  const availableModules = rolesHandler(LocalStorage, rolesModules);
  const { label } = CONFIG.routes;
  return <div>
    {label}
    { Links(availableModules) }
  </div>
}

export default Atom;
