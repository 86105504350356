import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import SectionPaper from './section_paper'

const useStyles = makeStyles((theme) => ({
  // root: { height: '90vh' },
}))

const Index = (props) => {
  const { maxWidth } = props
  const classes = useStyles()

  return (
    <Container maxWidth={maxWidth ? maxWidth : 'sm'}>
      <Grid
        container
        spacing={1}
        className={classes.root}
      >
        <Grid item xs={12}>
          <SectionPaper>{props.children}</SectionPaper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Index
