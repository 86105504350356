const isNumberLetter = data => {
  const pattern = /^[a-zA-Z0-9]+$/
  const { value } = data
  value.trim()
  return value.match(pattern) ? true : 'must be either letters or numbers'

  // let value = data.value
  // value = value.trim()
  // return !isNaN(value) ? true : 'must be all numbers'
}

export default isNumberLetter
