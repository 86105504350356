import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import ImageRender from '../image-picker/image-picker'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
/* package */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'
/* modules */
import CONFIG from '../config'

/* CONSTANTS */
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects

const CMSMASTERSETUPSCREATECONFIG = {
  url: CMSENDPOINTS.createsetup.url,
  method: CMSENDPOINTS.createsetup.method,
  headers: CMSENDPOINTS.createsetup.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTSVERSIONSCONFIG = {
  url: CMSENDPOINTS.createmastercontentsversions.url,
  method: CMSENDPOINTS.createmastercontentsversions.method,
  headers: CMSENDPOINTS.createmastercontentsversions.headers,
  responseType: 'json',
}
const CMSCHECKMASTERBANNERCONFIG = {
  url: CMSENDPOINTS.checkmasterbanner.url,
  method: CMSENDPOINTS.checkmasterbanner.method,
  headers: CMSENDPOINTS.checkmasterbanner.headers,
  responseType: 'json',
}
const CMSCREATEMASTERBANNERCONFIG = {
  url: CMSENDPOINTS.createmasterbanner.url,
  method: CMSENDPOINTS.createmasterbanner.method,
  headers: CMSENDPOINTS.createmasterbanner.headers,
  responseType: 'json',
}
/* CONSTANTS */
/* CONSTANTS */
const FormControlLabelDetails = withStyles((theme) => ({
  root: {
    left: '100px',
    // position: "relative"
  },
  paper: {
    // padding: theme.spacing(3),
    textAlign: 'center',
    color: 'black',
    marginBottom: '50px',
  },
  inline: {
    display: 'flex',
  },
}))(FormControlLabel)
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#098d42',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chip: {
    margin: 2,
    height: 37,
    borderRadius: 18.5,
    color: 'white',
    backgroundColor: '#42a5f5',
    cursor: 'auto',
    '&:hover': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
    '&:focus': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
  },
  chipClose: {
    color: 'white',
    '&:hover': {
      color: '#e6e6e6',
    },
    '&:focus': {
      color: '#e6e6e6',
    },
  },
}))
const flagMap = {
  live: {
    draft: 4,
    approved: 5,
    published: 6,
  },
  uat: {
    draft: 1,
    approved: 2,
    published: 3,
  },
}
const flagMapSelected =
  process.env.REACT_APP_BUILD_ENV === 'production' ? flagMap.live : flagMap.uat

const Index = (props) => {
  const { token } = LocalStorage.getLS().strapi
  const {
    isNotCarousel,
    setIsNotCarousel,
    detailVisible,
    toggleDetailVisibility,
  } = props
  const { width, height } = WindowSize()
  const [loading, setLoading] = useState(false)
  const [imageUploadIndex, setImageUploadIndex] = useState(0)
  const [imageUploadList, setImageUploadList] = useState([])
  const [countryLangList, setCountryLangList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [langList, setLangList] = useState([])
  const [createBanner, setCreateBanner] = useState(false)
  const classes = useStyles()
  const {
    countrylanguage,
    cognitouser,
    country,
    mastersetups,
    createmastersetup,
    mastercontentsversions,
    createcontentversion,
    checkmasterbanner,
    createmasterbanner,
  } = useContext(GlobalContext)
  const resetContextItems = async () => {
    countrylanguage.dispatch({
      type: 'RESET',
    })
    cognitouser.dispatch({
      type: 'RESET',
    })
    country.dispatch({
      type: 'RESET',
    })
    mastersetups.dispatch({
      type: 'RESET',
    })
    mastercontentsversions.dispatch({
      type: 'RESET',
    })
    createcontentversion.dispatch({
      type: 'RESET',
    })
    checkmasterbanner.dispatch({
      type: 'RESET',
    })
    createmasterbanner.dispatch({
      type: 'RESET',
    })
  }

  const mastercountrylanguageobj = countrylanguage.state.data

  /* --- cognitouser */
  const fetchCreateMasterSetupsExecute = async ({ query, body }) => {
    CMSMASTERSETUPSCREATECONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCreateContent] = FetchContextApiV2(CMSMASTERSETUPSCREATECONFIG)
    const fetchResult = await fetchCreateContent(
      { query, body },
      createmastersetup
    )
    createmastersetup.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCreateMasterContentsVersionsExecute = async ({ body }) => {
    CMSMASTERCONTENTSVERSIONSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCreateContent] = FetchContextApiV2(
      CMSMASTERCONTENTSVERSIONSCONFIG
    )
    const fetchResult = await fetchCreateContent({ body }, createcontentversion)
    createcontentversion.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchCheckBannerExec = async ({ query }) => {
    CMSCHECKMASTERBANNERCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSCHECKMASTERBANNERCONFIG)
    const fetchResult = await fetchCall({ query }, checkmasterbanner)
    checkmasterbanner.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchCreateBannerExec = async ({ body }) => {
    CMSCREATEMASTERBANNERCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSCREATEMASTERBANNERCONFIG)
    const fetchResult = await fetchCall({ body }, createmasterbanner)
    createmasterbanner.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    const countryLangList = mastercountrylanguageobj
      .map((item) => {
        return { name: item.name, value: item }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    setCountryLangList(countryLangList)

    return () => resetContextItems()
  }, [])

  useEffect(() => {
    if (
      createcontentversion.state.data &&
      createcontentversion.state.isStatus &&
      !createcontentversion.state.isLoading
    ) {
      // content version exist
      const contentVersionObj =
        createcontentversion && createcontentversion.state.data
      console.log('contentVersionObj ', contentVersionObj)
      const body = {
        environment: 'development',
        mastersetupflag: flagMapSelected.draft,
        mastercontentsversions: contentVersionObj && contentVersionObj.id,
        mastercountries: values.mastercountrylanguages.mastercountries.id,
        masterlanguages: values.mastercountrylanguages.masterlanguages.id,
        name: values.name,
        publish_date: values.publish_date ? values.publish_date : null,
      }

      fetchCreateMasterSetupsExecute({ body })
    }
  }, [createcontentversion.state])

  useEffect(() => {
    if (
      createmastersetup.state.data &&
      createmastersetup.state.isStatus &&
      !createmastersetup.state.isLoading
    ) {
      console.log(createmastersetup)
      fetchCheckBannerExec({
        query:
          '?mastercountries.abbr=' +
          values.mastercountrylanguages.mastercountries.abbr +
          '&masterlanguages.abbr=' +
          values.mastercountrylanguages.masterlanguages.abbr,
      })
      // props.history.push('/layout/list')
    }
  }, [createmastersetup.state])

  useEffect(() => {
    if (
      checkmasterbanner.state.data &&
      checkmasterbanner.state.isStatus &&
      !checkmasterbanner.state.isLoading
    ) {
      console.log(checkmasterbanner)
      const checkmasterbannerObj =
        checkmasterbanner && checkmasterbanner.state.data
      if (checkmasterbannerObj.length === 0) {
        // no banner obj found; create one
        const body = {
          id: null,
          name:
            values.mastercountrylanguages.mastercountries.abbr.toUpperCase() +
            '-' +
            values.mastercountrylanguages.masterlanguages.abbr.toUpperCase() +
            '-PAGES',
          mastercountries: values.mastercountrylanguages.mastercountries.id,
          masterlanguages: values.mastercountrylanguages.masterlanguages.id,
          pages: [],
        }
        fetchCreateBannerExec({ body })
      } else {
        props.history.push('/layout/list')
      }
    }
  }, [checkmasterbanner.state])

  useEffect(() => {
    if (
      createmasterbanner.state.data &&
      createmasterbanner.state.isStatus &&
      !createmasterbanner.state.isLoading
    ) {
      props.history.push('/layout/list')
    }
  }, [createmasterbanner.state])
  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true)
      console.log(values)
      const contentVersionObj =
        mastercontentsversions &&
        mastercontentsversions.state.data &&
        mastercontentsversions.state.data.filter((contentversionobj) => {
          return (
            contentversionobj.mastercountries.abbr ===
              values.mastercountrylanguages.mastercountries.abbr &&
            contentversionobj.masterlanguages.abbr ===
              values.mastercountrylanguages.masterlanguages.abbr
          )
        })

      if (contentVersionObj.length === 0) {
        // no content version exist;
        // create one and then create setup
        const body = {
          device: 'mob',
          environment: 'development',
          version: '1.0',
          name:
            values.mastercountrylanguages.mastercountries.abbr.toUpperCase() +
            '-' +
            values.mastercountrylanguages.masterlanguages.abbr.toUpperCase() +
            '-DEV',
          mastercountries: values.mastercountrylanguages.mastercountries.id,
          masterlanguages: values.mastercountrylanguages.masterlanguages.id,
        }
        fetchCreateMasterContentsVersionsExecute({ body })
      } else {
        // content version exist
        const body = {
          environment: 'development',
          mastersetupflag: flagMapSelected.draft,
          mastercontentsversions: contentVersionObj[0].id,
          mastercountries: values.mastercountrylanguages.mastercountries.id,
          masterlanguages: values.mastercountrylanguages.masterlanguages.id,
          name: values.name,
          publish_date: values.publish_date ? values.publish_date : null,
        }

        fetchCreateMasterSetupsExecute({ body })
      }
    }
  }

  const {
    values,
    onChange,
    onCheck,
    onPick,
    onMultiDeselect,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleSubmit, OBJECT1)

  const formObject = {}
  Object.keys(OBJECT1).map((fieldItemName) => {
    if (fieldItemName === 'mastercountries') {
      OBJECT1[fieldItemName].options = countryList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'masterlanguages') {
      OBJECT1[fieldItemName].options = langList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'mastercountrylanguages') {
      OBJECT1[fieldItemName].options = countryLangList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'publish_date') {
      formObject[fieldItemName] = {
        ...props,
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: moment(values[fieldItemName]).format('LL'),
        formatDisplay: 'dd MMM yyyy',
        onPick,
      }
    } else {
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    }
  })
  const submitBtn = {
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }
  const toggleBtn = {
    style: { marginTop: 30, marginBottom: 40, float: 'right' },
    onClick: () => {
      toggleDetailVisibility()
    },
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflow: 'wrap',
          position: 'relative',
          width: '100%',
          height: 'auto',
          marginTop: 15,
        }}
      >
        <form onSubmit={onSubmit} noValidate>
          <>
            <div
              style={{
                display: width > 1000 ? 'flex' : 'block',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: isNotCarousel
                    ? width > 1000
                      ? detailVisible
                        ? '50%'
                        : '100%'
                      : '100%'
                    : '100%',
                  padding: 10,
                }}
              >
                <Inputs.Text {...formObject.name} />
                <Inputs.Select {...formObject.mastercountrylanguages} />
                {/* <Inputs.Select {...formObject.mastercountries} />
                <Inputs.Select {...formObject.masterlanguages} /> */}
                {/* <Inputs.Select {...formObject.mastercontentsversions} /> */}

                <Inputs.Date {...formObject.publish_date} />
              </div>
            </div>
          </>
          <div style={{ height: 50 }} />
          <Buttons.Normal {...submitBtn}>CREATE</Buttons.Normal>
        </form>{' '}
        <Spinner.Loader color="#fff" size={24} loading={loading} />
      </div>
    </div>
  )
}

export default Index
