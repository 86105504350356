import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// import { Carousel } from 'react-responsive-carousel'

import {
  SectionWrappList,
  SectionWrappListItem,
} from '../../lib-atomic-section'

const useStyles = makeStyles((theme) => ({
  assets: {
    height: '130px',
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Index = (props) => {
  const classes = useStyles()
  return (
    <SectionWrappList {...props}>
      <SectionWrappListItem>
        <Typography variant="body2">
          <b>Draft</b>
        </Typography>
        <div className={classes.assets}>
          <img
            width="100%"
            src="https://d6goh5w562lzz.cloudfront.net/uploads/615efb5a3da34ebe8d0ec14e4e3bc4de.jpg"
            alt=""
          />
        </div>
        <Typography variant="body2">Name</Typography>
        <Typography variant="body2">Title</Typography>
        <Typography variant="body2">
          <small>
            <b>News</b> &nbsp; <b>Prelogin</b>
          </small>
        </Typography>
        <Typography variant="body2">
          <small>
            <b>from</b>
          </small>
        </Typography>
        <Typography variant="body2">
          <small>
            <b>to</b>
          </small>
        </Typography>
      </SectionWrappListItem>

      <SectionWrappListItem>
        <Typography variant="body2">Pratz</Typography>
        <Typography variant="body2">
          <b>Approver</b>
        </Typography>
      </SectionWrappListItem>
      <SectionWrappListItem>
        <Typography variant="body2">Pratz</Typography>
        <Typography variant="body2">
          <b>Approver</b>
        </Typography>
      </SectionWrappListItem>
      <SectionWrappListItem>
        <Typography variant="body2">Pratz</Typography>
        <Typography variant="body2">
          <b>Approver</b>
        </Typography>
      </SectionWrappListItem>
    </SectionWrappList>
  )
}

export default Index
