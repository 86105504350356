import isNotNull from './isNotNull'
import isEmail from './isEmail'
import isPassword from './isPassword'
import isNumber from './isNumber'
import isNumberAndLetter from './isNumberAndLetter'
import isLetter from './isLetter'
import isMin from './isMin'
import isMinMax from './isMinMax'
import isMinMaxPhoneNumber from './isMinMaxPhoneNumber'
import isDate from './isDate'
import isJson from './isJson'
import isConfirm from './isConfirm'
import isMinVal from './isMinVal'
import isMaxVal from './isMaxVal'
import isName from './isName'
import isNull from './isNull'

export default {
  isNull,
  isNotNull,
  isEmail,
  isPassword,
  isNumber,
  isNumberAndLetter,
  isLetter,
  isMin,
  isMinMax,
  isMinMaxPhoneNumber,
  isDate,
  isJson,
  isConfirm,
  isMinVal,
  isMaxVal,
  isName,
}
