import React, { Fragment, useState, useContext } from 'react'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
/* package */
import SectionContentImage from '../../app-content-section-image/default'
import SectionContentImageCarousel from '../../app-content-section-image-carousel/default'

/* modules */
import CONFIG from '../config'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    textAlign: 'center',
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { notifications } = useContext(GlobalContext)
  const [showDetails, setShowDetails] = useState(false)

  const toggleBtn = {
    style: { marginTop: 30, marginBottom: 40, float: 'right' },
    onClick: () => {
      setShowDetails(!showDetails)
    },
  }
  return (
    <Fragment>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          margin: 'auto',
          height: 70,
        }}
      >
        <Buttons.Text {...toggleBtn}>
          {!showDetails ? 'Show Action History' : 'Hide Action History'}
        </Buttons.Text>
      </div>

      <br />
      <br />
      {showDetails && notifications.state.data.length === 0 && (
        <Fragment>
          <Typography variant="body2">
            <small style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ justifyContent: 'center' }}>
                <b>No History</b>
              </div>
            </small>
          </Typography>
        </Fragment>
      )}
      {showDetails &&
        notifications.state.data.map((noti) => {
          const action =
            noti.emailType === 'ApprovalRequest'
              ? 'approval request'
              : noti.emailType === 'PublishRequest'
              ? 'publish request'
              : noti.emailType === 'ContentAcceptedRequest' ||
                noti.emailType === 'SetupAcceptedRequest'
              ? 'acceptance'
              : noti.emailType === 'ContentDeclinedRequest'
              ? 'decline'
              : 'reject'
          return (
            <Fragment>
              <Typography variant="body2">
                <small style={{ flexDirection: 'row', display: 'flex' }}>
                  <div>
                    <b>#{noti.id}</b>&nbsp;&nbsp;&nbsp;
                  </div>
                  <div>
                    <span>
                      <b>
                        {noti.sender}&nbsp;
                        {noti.senderEmail && '(' + noti.senderEmail + ')'}
                      </b>
                      &nbsp;sent&nbsp;<b>{action}</b>&nbsp;email to&nbsp;
                      <b>
                        {noti.recipientObject.cognitoId}&nbsp;
                        {noti.recipientObject.cognitoEmail &&
                          '(' + noti.recipientObject.cognitoEmail + ')'}
                      </b>
                    </span>
                  </div>
                  <br />
                </small>
              </Typography>
              <br />
            </Fragment>
          )
        })}
    </Fragment>
  )
}

export default Index
