import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'
/* package */
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { SectionListItem } from '../../lib-atomic-section'
import { WindowSize } from '../../lib-atomic-functions'
/* package */

/* modules */

/* CONSTANTS */

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const Index = (props) => {
  const { id } = props.match.params
  const { width, height } = WindowSize()
  const { cognitouser } = useContext(GlobalContext)
  const {
    rawMobContent,
    rawWebContent,
    setFilteredContent,
    isMobView,
    setIsMobView,
    isPreLoginView,
    setIsPreLoginView,
    isPromotionView,
    setIsPromotionView,
  } = props
  console.log(cognitouser)
  const mastercountrylist = cognitouser.state.data[0].MasterUCCountries.map(
    (country) => {
      return {
        name: country.mastercountry.name,
        value: country.mastercountry,
      }
    }
  )

  const isInvalidCoordinate = (layout, togglePreLogin) => {
    const emptyCoordFound =
      layout.startX === null ||
      layout.startY === null ||
      layout.endX === null ||
      layout.endY === null ||
      (layout.startX.toString().replace(/\s/g, '') === '0' &&
        layout.startY.toString().replace(/\s/g, '') === '0' &&
        layout.endX.toString().replace(/\s/g, '') === '0' &&
        layout.endY.toString().replace(/\s/g, '') === '0') ||
      layout.startX.toString().replace(/\s/g, '') === '' ||
      layout.startY.toString().replace(/\s/g, '') === '' ||
      layout.endX.toString().replace(/\s/g, '') === '' ||
      layout.endY.toString().replace(/\s/g, '') === ''
    const emptyCoordFoundForPostLogin =
      layout.startXForPostLogin === null ||
      layout.startYForPostLogin === null ||
      layout.endXForPostLogin === null ||
      layout.endYForPostLogin === null ||
      (layout.startXForPostLogin.toString().replace(/\s/g, '') === '0' &&
        layout.startYForPostLogin.toString().replace(/\s/g, '') === '0' &&
        layout.endXForPostLogin.toString().replace(/\s/g, '') === '0' &&
        layout.endYForPostLogin.toString().replace(/\s/g, '') === '0') ||
      layout.startXForPostLogin.toString().replace(/\s/g, '') === '' ||
      layout.startYForPostLogin.toString().replace(/\s/g, '') === '' ||
      layout.endXForPostLogin.toString().replace(/\s/g, '') === '' ||
      layout.endYForPostLogin.toString().replace(/\s/g, '') === ''
    if (togglePreLogin) {
      return emptyCoordFound
    } else {
      return emptyCoordFoundForPostLogin
    }
  }

  useEffect(() => {
    const filteredMob = rawMobContent.filter((content) => {
      const condition1 = isPreLoginView
        ? content.mastercontents.contentVisibility === 'prelogin'
        : content.mastercontents.contentVisibility === 'postlogin'
      const condition2 = isPromotionView
        ? content.mastercontents.type === 'Promotions' ||
          (content.mastercontents.type === 'Carousel' &&
            content.mastercontents.CarouselContents.some(
              (i) => i.mastercontents.type === 'Promotions'
            ))
        : (content.mastercontents.type !== 'Promotions' &&
            content.mastercontents.type !== 'Carousel') ||
          (content.mastercontents.type === 'Carousel' &&
            content.mastercontents.CarouselContents.some(
              (i) => i.mastercontents.type !== 'Promotions'
            ))
      console.log('should be false ; ', condition2, content.mastercontents)
      const condition3 = isInvalidCoordinate(content, isPreLoginView)
      return condition1 && condition2 && !condition3
    })
    const filteredWeb = rawWebContent.filter((content) => {
      const condition1 = isPreLoginView
        ? content.mastercontents.contentVisibility === 'prelogin'
        : content.mastercontents.contentVisibility === 'postlogin'
      const condition2 = isPromotionView
        ? content.mastercontents.type === 'Promotions' ||
          (content.mastercontents.type === 'Carousel' &&
            content.mastercontents.CarouselContents.some(
              (i) => i.mastercontents.type === 'Promotions'
            ))
        : (content.mastercontents.type !== 'Promotions' &&
            content.mastercontents.type !== 'Carousel') ||
          (content.mastercontents.type === 'Carousel' &&
            content.mastercontents.CarouselContents.some(
              (i) => i.mastercontents.type !== 'Promotions'
            ))
      const condition3 = isInvalidCoordinate(content, isPreLoginView)
      return condition1 && condition2 && !condition3
    })
    const finalFilterd = isMobView ? filteredMob : filteredWeb
    setFilteredContent(finalFilterd)
  }, [rawMobContent, rawWebContent, isMobView, isPreLoginView, isPromotionView])

  const optionAll = [{ name: 'All', value: 'All' }]
  const platformToggle = {
    name: 'platformtoggle',
    initial: [],
    label: 'Platform',
    type: 'select',
    options: [
      { name: 'Mobile View', value: true },
      { name: 'Website View', value: false },
    ],
    helperText: false,
    rules: false,
    value: isMobView,
    onChange: (e) => {
      setIsMobView(e.target.value)
    },
  }
  const visibilitySelector = {
    name: 'visibilityToggle',
    initial: [],
    label: 'Visibility',
    type: 'select',
    options: [
      { name: 'Pre Login View', value: true },
      { name: 'Post Login View', value: false },
    ],
    helperText: false,
    rules: false,
    value: isPreLoginView,
    onChange: (e) => {
      setIsPreLoginView(e.target.value)
    },
  }
  const typeSelector = {
    name: 'typeToggle',
    initial: [],
    label: 'Type',
    type: 'select',
    options: [
      { name: 'Offer Page', value: true },
      { name: 'Home Page', value: false },
    ],
    helperText: false,
    rules: false,
    value: isPromotionView,
    onChange: (e) => {
      setIsPromotionView(e.target.value)
    },
  }

  const submitButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/layout/add/' + id)
    },
  }
  const bannerContentButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/layout/banner/' + id)
    },
  }
  const editBtn = {
    fullWidth: true,
    onClick: () => {
      console.log(isMobView, isPreLoginView, isPromotionView, id)
      const url =
        '/layout/edit/' +
        (isMobView ? 'mob' : 'web') +
        '/' +
        (isPreLoginView ? 'pre' : 'post') +
        '/' +
        (isPromotionView ? 'offer' : 'home') +
        '/' +
        id
      props.history.push(url)
    },
  }
  return (
    <Fragment>
      <div
        style={{
          display: width < 1000 ? 'inline' : 'flex',
          height: '100%',
          width: '100%',
          margin: 'auto',
          // justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            display: width < 1000 ? 'inline' : 'inline-block',
            width: 200,
            margin: 10,
            justifyContent: 'flex-start',
          }}
        >
          <Inputs.Select {...platformToggle} />
        </div>
        <div
          style={{
            display: width < 1000 ? 'inline' : 'inline-block',
            width: 200,
            margin: 10,
          }}
        >
          <Inputs.Select {...visibilitySelector} />
        </div>
        <div
          style={{
            display: width < 1000 ? 'inline' : 'inline-block',
            width: 200,
            margin: 10,
          }}
        >
          <Inputs.Select {...typeSelector} />
        </div>
        {/* <div
          style={{
            display: width < 1000 ? 'inline' : 'inline-block',
            height: '100%',
            width: 100,
            justifyContent: 'center',
            alignItems: 'center',
            margin: width < 1000 ? 10 : 'auto',
          }}
        >
          <Buttons.Normal {...editBtn}>Edit</Buttons.Normal>
        </div>
        <div
          style={{
            display: width < 1000 ? 'inline' : 'inline-block',
            height: '100%',
            width: 200,
            justifyContent: 'center',
            alignItems: 'center',
            margin: width < 1000 ? 10 : 'auto',
          }}
        >
          <Buttons.Normal {...submitButton}>+ Add Setup Content</Buttons.Normal>
        </div> 
        <div
          style={{
            display: width < 1000 ? 'inline' : 'inline-block',
            height: '100%',
            width: 200,
            justifyContent: 'center',
            alignItems: 'center',
            margin: width < 1000 ? 10 : 'auto',
          }}
        >
          <Buttons.Normal {...bannerContentButton}>
            + Add Banner Content
          </Buttons.Normal>
        </div>
        */}
      </div>
    </Fragment>
  )
}

export default Index
