import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERSETUPS = {
  url: CMSENDPOINTS.mastersetups.url,
  method: CMSENDPOINTS.mastersetups.method,
  headers: CMSENDPOINTS.mastersetups.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTSCONFIG = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const CMSMASTERBOXESCONFIG = {
  url: CMSENDPOINTS.masterboxes.url,
  method: CMSENDPOINTS.masterboxes.method,
  headers: CMSENDPOINTS.masterboxes.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const [finalProcessDone, setFinalProcessDone] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const {
    cognitouser,
    mastersetups,
    contents,
    allcontents,
    contentcarouseldetail,
    masterboxes,
    updatesetup,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await updatesetup.dispatch({
      type: 'RESET',
    })
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await mastersetups.dispatch({
      type: 'RESET',
    })
    await contents.dispatch({
      type: 'RESET',
    })
    await allcontents.dispatch({
      type: 'RESET',
    })
    await contentcarouseldetail.dispatch({
      type: 'RESET',
    })
    await masterboxes.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  const fetchCognitoUserExec = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master setups */
  const fetchMasterSetupsExecute = async ({ query }) => {
    CMSMASTERSETUPS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPS)
    mastersetups.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterSetups({ query }, mastersetups)
    mastersetups.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- master contents */
  const fetchMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    contents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContents({ query }, contents)
    contents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCarouselContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCarouselContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    contentcarouseldetail.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCarouselContents(
      { query },
      contentcarouseldetail
    )
    contentcarouseldetail.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const resetCarouselContentExecute = async () => {
    contentcarouseldetail.dispatch({
      type: 'RESET',
    })
  }

  const fetchAllMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchAllMasterContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    allcontents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchAllMasterContents({ query }, allcontents)
    allcontents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchBoxesExecute = async ({ query }) => {
    CMSMASTERBOXESCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchBoxes] = FetchContextApiV2(CMSMASTERBOXESCONFIG)
    masterboxes.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchBoxes({ query }, masterboxes)
    masterboxes.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      fetchBoxesExecute({})
      const query = '?cognitoId=' + username
      fetchCognitoUserExec({ query })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      let query = '?id_in=' + id + '&'
      const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
      mastercountrylist.map((country, index) => {
        if (index === 0) {
          query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
        } else {
          query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
        }
      })
      fetchMasterSetupsExecute({ query })
    }
  }, [cognitouser.state])

  useEffect(() => {
    if (
      loading &&
      mastersetups.state.data &&
      mastersetups.state.isStatus &&
      !mastersetups.state.isLoading &&
      !finalProcessDone
    ) {
      if (mastersetups.state.data.length === 0) {
        props.history.push('/layout/list')
      }
      const webContentsIds = mastersetups.state.data[0]['WebContents']
        ? mastersetups.state.data[0]['WebContents'].map((content) => {
            return content.mastercontents.id
          })
        : []
      const mobContentsIds = mastersetups.state.data[0]['MobContents']
        ? mastersetups.state.data[0]['MobContents'].map((content) => {
            return content.mastercontents.id
          })
        : []
      const combinedIds = [...webContentsIds, ...mobContentsIds]
      const qs =
        combinedIds.length > 0
          ? QueryString.stringify({ id_in: combinedIds })
          : false
      const query = qs ? '?' + qs : '?id_in=-1'
      fetchMasterContentsExecute({ query })

      const countryAbbr = mastersetups.state.data[0]
        ? mastersetups.state.data[0].mastercountries.abbr
        : false
      const langAbbr = mastersetups.state.data[0]
        ? mastersetups.state.data[0].masterlanguages.abbr
        : false
      const qs2 =
        'mastercountries.abbr=' +
        countryAbbr +
        '&masterlanguages.abbr=' +
        langAbbr +
        '&mastercontentsflag.status=approved&mastercontentsflag.status=draft&mastercontentsflag.status=pending&Environment=development'
      fetchAllMasterContentsExecute({ query: '?' + qs2 })
    }
  }, [mastersetups.state])

  const processFinal = async () => {
    if (mastersetups.state.data && contents.state.data) {
      const finalWebContents = mastersetups.state.data[0].WebContents.map(
        (webcontent) => {
          const webContentId = webcontent.mastercontents.id
          let matchingContentFound = contents.state.data.filter((content) => {
            return webContentId.toString() === content.id.toString()
          })[0]
          const carouselFound =
            contentcarouseldetail.state.data &&
            contentcarouseldetail.state.data.filter((carouselcontent) => {
              return (
                matchingContentFound.CarouselContents.length > 0 &&
                matchingContentFound.CarouselContents.some(
                  (i) => i.mastercontents.id === carouselcontent.id
                )
              )
            })
          if (carouselFound.length > 0) {
            const newCarouselContents =
              matchingContentFound.CarouselContents.map((carouselCont) => {
                const carouselWithImg = carouselFound.filter(
                  (i) => i.id === carouselCont.mastercontents.id
                )
                return {
                  id: carouselCont.id,
                  mastercontents: carouselWithImg[0],
                }
              })
            matchingContentFound.CarouselContents = newCarouselContents
          }
          webcontent.mastercontents = matchingContentFound

          return webcontent
        }
      )

      const finalMobContents = mastersetups.state.data[0].MobContents.map(
        (mobcontent) => {
          const mobContentId = mobcontent.mastercontents.id
          let matchingContentFound = contents.state.data.filter((content) => {
            return mobContentId.toString() === content.id.toString()
          })[0]
          const carouselFound =
            contentcarouseldetail.state.data &&
            contentcarouseldetail.state.data.filter((carouselcontent) => {
              return (
                matchingContentFound.CarouselContents.length > 0 &&
                matchingContentFound.CarouselContents.some(
                  (i) => i.mastercontents.id === carouselcontent.id
                )
              )
            })
          if (carouselFound.length > 0) {
            const newCarouselContents =
              matchingContentFound.CarouselContents.map((carouselCont) => {
                const carouselWithImg = carouselFound.filter(
                  (i) => i.id === carouselCont.mastercontents.id
                )
                return {
                  id: carouselCont.id,
                  mastercontents: carouselWithImg[0],
                }
              })
            matchingContentFound.CarouselContents = newCarouselContents
          }
          mobcontent.mastercontents = matchingContentFound

          return mobcontent
        }
      )
      console.log(finalMobContents)
      let finalMasterSetup = JSON.parse(
        JSON.stringify(mastersetups.state.data[0])
      )
      finalMasterSetup.WebContents = finalWebContents
      finalMasterSetup.MobContents = finalMobContents
      setFinalProcessDone(true)
      mastersetups.dispatch({
        type: 'SET',
        data: { status: true, data: { blob: false, json: [finalMasterSetup] } },
      })
      await resetCarouselContentExecute()
    }
  }

  useEffect(() => {
    if (
      loading &&
      contents.state.data &&
      contents.state.isStatus &&
      !contents.state.isLoading
    ) {
      const mastercontents = contents.state.data
      console.log(mastercontents)
      const CarouselContentsList = mastercontents.filter(
        (content) => content.type === 'Carousel'
      )
      console.log(CarouselContentsList)
      const carouselsItems = []
      CarouselContentsList.map((carousel) => {
        carousel.CarouselContents &&
          carousel.CarouselContents.map((items) => {
            if (items && items.mastercontents && items.mastercontents.id) {
              if (
                !(
                  carouselsItems &&
                  carouselsItems.findIndex(
                    (i) => i === items.mastercontents.id
                  ) > -1
                )
              ) {
                carouselsItems.push(items.mastercontents.id)
              }
            }
          })
      })
      if (carouselsItems.length > 0) {
        const qs = QueryString.stringify({ id_in: carouselsItems })
        // console.log('QS', qs, mastercontents)
        fetchCarouselContentsExecute({
          query: '?' + qs,
        })
      } else {
        processFinal()
      }
    }
  }, [contents.state])
  useEffect(() => {
    if (
      loading &&
      contentcarouseldetail.state.data &&
      contentcarouseldetail.state.isStatus &&
      !contentcarouseldetail.state.isLoading
    ) {
      console.log('setup ', mastersetups.state.data)
      console.log('content ', contents.state.data)
      console.log('carouselcontents ', contentcarouseldetail.state.data)

      processFinal()
    }
  }, [contentcarouseldetail.state])

  const isBoxReady =
    masterboxes.state.data &&
    masterboxes.state.isStatus &&
    !masterboxes.state.isLoading
      ? true
      : false
  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isMasterSetupReady =
    mastersetups.state.data &&
    mastersetups.state.data.length > 0 &&
    mastersetups.state.isStatus &&
    !mastersetups.state.isLoading
      ? true
      : false

  const isAllContentsReady =
    allcontents.state.data &&
    allcontents.state.isStatus &&
    !allcontents.state.isLoading
      ? true
      : false
  const isContentReady =
    contents.state.data && contents.state.isStatus && !contents.state.isLoading
      ? true
      : false
  const isCarouselContentReady =
    contentcarouseldetail.state.data &&
    contentcarouseldetail.state.isStatus &&
    !contentcarouseldetail.state.isLoading
      ? true
      : false
  if (
    !loading ||
    !isBoxReady ||
    !isCognitoUserReady ||
    !isMasterSetupReady ||
    !isAllContentsReady ||
    !isContentReady ||
    (!isCarouselContentReady && !finalProcessDone) ||
    !finalProcessDone
  ) {
    return <Spinner.Popup />
  }
  return props.children
}

export default Index
