import React, { useContext, Fragment, useState, useEffect } from 'react'

import { Buttons } from '../../lib-atomic'

const Index = (props) => {
  const { user, item } = props
  const { id, mastercontentsflag } = item

  const handleEdit = ({ id }) => {
    props.history.push('/campaign/edit/' + id)
  }

  const isDraft =
    !mastercontentsflag || mastercontentsflag.status === 'draft' ? true : false

  const items = [
    {
      user: ['mastercreator', 'creator+approver+publisher'],
      action: handleEdit,
      label: 'Edit',
      status: true,
    },
  ]

  const checkItems = ({ status, user }) => {
    return items.filter(
      (item) => item.status === status && item.user.indexOf(user) > -1
    )
  }

  const menus = checkItems({ status: isDraft, user: user[0].user.username })

  return (
    <Fragment>
      {menus.map((menu, index) => {
        return (
          <Fragment>
            <Buttons.Normal key={index} onClick={() => menu.action({ id })}>
              {menu.label}
            </Buttons.Normal>
            &nbsp;
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default Index
