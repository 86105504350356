import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
import { init } from '../../lib-atomic-context/init'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERSETUPS = {
  url: CMSENDPOINTS.mastersetups.url,
  method: CMSENDPOINTS.mastersetups.method,
  headers: CMSENDPOINTS.mastersetups.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTSCONFIG = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const [finalProcessDone, setFinalProcessDone] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const {
    cognitouser,
    mastersetups,
    countrylanguage,
    mastersetupspartner,
    contents,
    contentcarouseldetail,
    createlivesetup,
    updatesetup,
    updatecontentversion,
    createmastercountrylang,
    createcontents,
    createcontentversion,
    updatecontent,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await mastersetups.dispatch({
      type: 'RESET',
    })
    await createlivesetup.dispatch({
      type: 'RESET',
    })
    await updatesetup.dispatch({
      type: 'RESET',
    })
    await updatecontentversion.dispatch({
      type: 'RESET',
    })
    await createmastercountrylang.dispatch({
      type: 'RESET',
    })
    await createcontents.dispatch({
      type: 'RESET',
    })
    await createcontentversion.dispatch({
      type: 'RESET',
    })
    await updatecontent.dispatch({
      type: 'RESET',
    })
    await contents.dispatch({
      type: 'RESET',
    })
    setFinalProcessDone(false)
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await contentcarouseldetail.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await mastersetupspartner.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  const fetchCognitoUserExec = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master setups */
  const fetchMasterSetupsExecute = async ({ query }) => {
    CMSMASTERSETUPS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPS)
    mastersetups.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterSetups({ query }, mastersetups)
    mastersetups.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCountryLanguagesExecute = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- master setups partner */
  const fetchMasterSetupsPartnerExecute = async ({ query }) => {
    CMSMASTERSETUPS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPS)
    mastersetupspartner.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterSetups({ query }, mastersetupspartner)
    const finalResult = fetchResult.data.json.filter(
      (setup) => setup.id.toString() !== id.toString()
    )
    console.log(finalResult)
    mastersetupspartner.dispatch({
      type: 'SET',
      data: { status: true, data: { blob: false, json: finalResult } },
    })
  }

  /* --- master contents */
  const fetchMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    contents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContents({ query }, contents)
    contents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchCarouselContentsExecute = async ({ query, idInOrder }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCarouselContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    contentcarouseldetail.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCarouselContents(
      { query },
      contentcarouseldetail
    )
    console.log(fetchResult)
    // const processed = {
    //   status: fetchResult.status,
    //   data: {
    //     blob: false,
    //     json: fetchResult.data.json.sort(
    //       (a, b) => idInOrder.indexOf(a.id) - idInOrder.indexOf(b.id)
    //     ),
    //   },
    // }
    contentcarouseldetail.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const resetCarouselContentExecute = async () => {
    contentcarouseldetail.dispatch({
      type: 'RESET',
    })
  }

  useEffect(() => {
    if (loading) {
      setFinalProcessDone(false)
      const query = '?cognitoId=' + username
      fetchCognitoUserExec({ query })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      let query = '?id_in=' + id + '&'
      const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
      mastercountrylist.map((country, index) => {
        if (index === 0) {
          query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
        } else {
          query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
        }
      })
      fetchMasterSetupsExecute({ query })
    }
    return () => {
      console.log('unmounting preview detail...')
    }
  }, [cognitouser.state])
  useEffect(() => {
    if (
      loading &&
      mastersetupspartner.state.data &&
      mastersetupspartner.state.isStatus &&
      !mastersetupspartner.state.isLoading &&
      !finalProcessDone
    ) {
      const webContentsIds = mastersetups.state.data[0]['WebContents']
        ? mastersetups.state.data[0]['WebContents'].map((content) => {
            return content.mastercontents.id
          })
        : []
      const mobContentsIds = mastersetups.state.data[0]['MobContents']
        ? mastersetups.state.data[0]['MobContents'].map((content) => {
            return content.mastercontents.id
          })
        : []

      const webContentsIdsForParter =
        mastersetupspartner.state &&
        mastersetupspartner.state.data &&
        mastersetupspartner.state.data.length > 0 &&
        mastersetupspartner.state.data[0]['WebContents']
          ? mastersetupspartner.state.data[0]['WebContents'].map((content) => {
              return content.mastercontents.id
            })
          : []
      const mobContentsIdsForParter =
        mastersetupspartner.state &&
        mastersetupspartner.state.data &&
        mastersetupspartner.state.data.length > 0 &&
        mastersetupspartner.state.data[0]['MobContents']
          ? mastersetupspartner.state.data[0]['MobContents'].map((content) => {
              return content.mastercontents.id
            })
          : []
      console.log(webContentsIds, mobContentsIds)
      const combinedIds = [
        ...webContentsIds,
        ...mobContentsIds,
        ...webContentsIdsForParter,
        ...mobContentsIdsForParter,
      ]
      console.log('combinedIds ', combinedIds)
      const qs =
        combinedIds.length > 0
          ? QueryString.stringify({ id_in: combinedIds })
          : false
      const query = qs ? '?' + qs : '?id_in=-1'
      fetchMasterContentsExecute({ query })
    }
  }, [mastersetupspartner.state])
  useEffect(() => {
    if (
      loading &&
      mastersetups.state.data &&
      mastersetups.state.isStatus &&
      !mastersetups.state.isLoading &&
      !finalProcessDone
    ) {
      console.log(mastersetups)
      if (mastersetups.state.data.length === 0) {
        props.history.push('/layout/list')
      }

      const countryId = mastersetups.state.data[0].mastercountries.id
      const langId = mastersetups.state.data[0].masterlanguages.id

      const queryForPartnerSetupCall =
        '?mastercountries.id=' + countryId + '&masterlanguages.id=' + langId

      fetchCountryLanguagesExecute({ query: queryForPartnerSetupCall })
      fetchMasterSetupsPartnerExecute({ query: queryForPartnerSetupCall })
    }
  }, [mastersetups.state])

  const processFinal = async () => {
    console.log('setup ', mastersetups.state.data)
    console.log('content ', contents.state.data)
    console.log('carouselcontents ', contentcarouseldetail.state.data)
    if (mastersetups.state.data && contents.state.data) {
      const finalWebContents = mastersetups.state.data[0].WebContents.map(
        (webcontent) => {
          const webContentId = webcontent.mastercontents.id
          let matchingContentFound = contents.state.data.filter((content) => {
            return webContentId.toString() === content.id.toString()
          })[0]
          console.log(webcontent, matchingContentFound)
          const carouselFound =
            contentcarouseldetail.state.data &&
            contentcarouseldetail.state.data.length > 0 &&
            contentcarouseldetail.state.data.filter((carouselcontent) => {
              return (
                matchingContentFound.CarouselContents.length > 0 &&
                matchingContentFound.CarouselContents.some(
                  (i) => i.mastercontents.id === carouselcontent.id
                )
              )
            })
          if (carouselFound && carouselFound.length > 0) {
            const newCarouselContents =
              matchingContentFound.CarouselContents.map((carouselCont) => {
                const carouselWithImg = carouselFound.filter(
                  (i) => i.id === carouselCont.mastercontents.id
                )
                return {
                  id: carouselCont.id,
                  mastercontents: carouselWithImg[0],
                }
              })
            matchingContentFound.CarouselContents = newCarouselContents
          }
          webcontent.mastercontents = matchingContentFound

          return webcontent
        }
      )
      console.log(contents.state.data, mastersetups.state.data[0].MobContents)
      const finalMobContents = mastersetups.state.data[0].MobContents.map(
        (mobcontent) => {
          const mobContentId = mobcontent.mastercontents.id
          let matchingContentFound = contents.state.data.filter((content) => {
            return mobContentId.toString() === content.id.toString()
          })[0]
          const carouselFound =
            contentcarouseldetail.state.data &&
            contentcarouseldetail.state.data.length > 0 &&
            contentcarouseldetail.state.data.filter((carouselcontent) => {
              return (
                matchingContentFound.CarouselContents.length > 0 &&
                matchingContentFound.CarouselContents.some(
                  (i) => i.mastercontents.id === carouselcontent.id
                )
              )
            })
          if (carouselFound && carouselFound.length > 0) {
            const newCarouselContents =
              matchingContentFound.CarouselContents.map((carouselCont) => {
                const carouselWithImg = carouselFound.filter(
                  (i) => i.id === carouselCont.mastercontents.id
                )
                return {
                  id: carouselCont.id,
                  mastercontents: carouselWithImg[0],
                }
              })
            matchingContentFound.CarouselContents = newCarouselContents
          }
          mobcontent.mastercontents = matchingContentFound

          return mobcontent
        }
      )
      console.log(finalWebContents)
      console.log(finalMobContents)
      let finalMasterSetup = JSON.parse(
        JSON.stringify(mastersetups.state.data[0])
      )
      finalMasterSetup.WebContents = finalWebContents
      finalMasterSetup.MobContents = finalMobContents
      setFinalProcessDone(true)
      mastersetups.dispatch({
        type: 'SET',
        data: { status: true, data: { blob: false, json: [finalMasterSetup] } },
      })
      // setLoading(false)
    }
    // prepare mastersetupspartner carousel contents
    if (
      mastersetupspartner.state.data &&
      mastersetupspartner.state.data.length > 0 &&
      contents.state.data
    ) {
      const finalWebContents =
        mastersetupspartner.state.data[0].WebContents.map((webcontent) => {
          const webContentId = webcontent.mastercontents.id
          let matchingContentFound = contents.state.data.filter((content) => {
            return webContentId.toString() === content.id.toString()
          })[0]
          console.log(webcontent, matchingContentFound)
          const carouselFound =
            contentcarouseldetail.state.data &&
            contentcarouseldetail.state.data.length > 0 &&
            contentcarouseldetail.state.data.filter((carouselcontent) => {
              return (
                matchingContentFound.CarouselContents.length > 0 &&
                matchingContentFound.CarouselContents.some(
                  (i) => i.mastercontents.id === carouselcontent.id
                )
              )
            })
          if (carouselFound && carouselFound.length > 0) {
            const newCarouselContents =
              matchingContentFound.CarouselContents.map((carouselCont) => {
                const carouselWithImg = carouselFound.filter(
                  (i) => i.id === carouselCont.mastercontents.id
                )
                return {
                  id: carouselCont.id,
                  mastercontents: carouselWithImg[0],
                }
              })
            matchingContentFound.CarouselContents = newCarouselContents
          }
          webcontent.mastercontents = matchingContentFound

          return webcontent
        })
      console.log(
        contents.state.data,
        mastersetupspartner.state.data[0].MobContents
      )
      const finalMobContents =
        mastersetupspartner.state.data[0].MobContents.map((mobcontent) => {
          const mobContentId = mobcontent.mastercontents.id
          let matchingContentFound = contents.state.data.filter((content) => {
            return mobContentId.toString() === content.id.toString()
          })[0]
          const carouselFound =
            contentcarouseldetail.state.data &&
            contentcarouseldetail.state.data.length > 0 &&
            contentcarouseldetail.state.data.filter((carouselcontent) => {
              return (
                matchingContentFound.CarouselContents.length > 0 &&
                matchingContentFound.CarouselContents.some(
                  (i) => i.mastercontents.id === carouselcontent.id
                )
              )
            })
          if (carouselFound && carouselFound.length > 0) {
            const newCarouselContents =
              matchingContentFound.CarouselContents.map((carouselCont) => {
                const carouselWithImg = carouselFound.filter(
                  (i) => i.id === carouselCont.mastercontents.id
                )
                return {
                  id: carouselCont.id,
                  mastercontents: carouselWithImg[0],
                }
              })
            matchingContentFound.CarouselContents = newCarouselContents
          }
          mobcontent.mastercontents = matchingContentFound

          return mobcontent
        })
      console.log(finalWebContents)
      console.log(finalMobContents)
      let finalMasterSetup = JSON.parse(
        JSON.stringify(mastersetupspartner.state.data[0])
      )
      finalMasterSetup.WebContents = finalWebContents
      finalMasterSetup.MobContents = finalMobContents
      setFinalProcessDone(true)
      mastersetupspartner.dispatch({
        type: 'SET',
        data: { status: true, data: { blob: false, json: [finalMasterSetup] } },
      })
      await resetCarouselContentExecute()
    }
  }
  useEffect(() => {
    if (
      loading &&
      contents.state.data &&
      contents.state.isStatus &&
      !contents.state.isLoading &&
      !finalProcessDone
    ) {
      const mastercontents = contents.state.data
      console.log(mastercontents)
      const CarouselContentsList = mastercontents.filter(
        (content) => content.type === 'Carousel'
      )
      console.log(CarouselContentsList)
      const carouselsItems = []
      CarouselContentsList.map((carousel) => {
        carousel.CarouselContents &&
          carousel.CarouselContents.map((items) => {
            if (items && items.mastercontents && items.mastercontents.id) {
              if (
                !(
                  carouselsItems &&
                  carouselsItems.findIndex(
                    (i) => i === items.mastercontents.id
                  ) > -1
                )
              ) {
                carouselsItems.push(items.mastercontents.id)
              }
            }
          })
      })
      if (carouselsItems.length > 0) {
        const qs = QueryString.stringify({ id_in: carouselsItems })
        // console.log('QS', qs, mastercontents)
        fetchCarouselContentsExecute({
          query: '?' + qs,
          idInOrder: carouselsItems,
        })
      } else {
        processFinal()
      }
    }
  }, [contents.state])

  useEffect(() => {
    if (
      loading &&
      contentcarouseldetail.state.data &&
      contentcarouseldetail.state.isStatus &&
      !contentcarouseldetail.state.isLoading &&
      !finalProcessDone
    ) {
      processFinal()
    }
  }, [contentcarouseldetail.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isMasterSetupReady =
    mastersetups.state.data &&
    mastersetups.state.data.length > 0 &&
    mastersetups.state.isStatus &&
    !mastersetups.state.isLoading
      ? true
      : false
  const isPartnerReady =
    mastersetupspartner.state.data &&
    mastersetupspartner.state.isStatus &&
    !mastersetupspartner.state.isLoading
      ? true
      : false
  const isContentReady =
    contents.state.data && contents.state.isStatus && !contents.state.isLoading
      ? true
      : false
  const isCarouselContentReady =
    contentcarouseldetail.state.data &&
    contentcarouseldetail.state.isStatus &&
    !contentcarouseldetail.state.isLoading
      ? true
      : false

  if (
    !loading ||
    !isCognitoUserReady ||
    !isMasterSetupReady ||
    !isPartnerReady ||
    !isContentReady ||
    (!isCarouselContentReady && !finalProcessDone) ||
    !finalProcessDone
  ) {
    return <Spinner.Popup />
  }
  console.log(mastersetups.state.data)
  return props.children
}

export default Index
