import React, { lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { GlobalProvider } from "../../lib-atomic-context";

import { SectionWrappSingle } from "../../lib-atomic-section";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Index = (props) => {
  const classes = useStyles();

  return (
    <SectionWrappSingle {...props}>
      <b>Registration/Login/Password/Create/Edit Single Page Wrapper</b>
    </SectionWrappSingle>
  );
};

export default Index;
