const isPassword = (data) => {
  let value = data.value
  value = value.trim()
  if (value.length < 6) {
    return 'Minimum length is 6'
  }
  if (!/\d/.test(value.password) === false) {
    return 'Must contains at least 1 numeric character '
  } else {
    const pattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
    return !pattern.test(value) ? true : "must not contain =!@#$%^&*()_+'"
  }
}
export default isPassword
