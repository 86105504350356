import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'CMS'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          mastercongnitouser: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercognitousers',
            method: 'GET',
          },
          mastercountrylanguages: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url:
              setup.setup.api.cms.url[environment] + '/mastercountrylanguages',
            method: 'GET',
          },
          mastercontents: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'GET',
          },
          contentdetail: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'GET',
          },
          mastersetups: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastersetups',
            method: 'GET',
          },
          notifications: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/notifications',
            method: 'GET',
          },
          mastercountries: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercountries',
            method: 'GET',
          },
        },
      },
    },
  },
  routes: {
    label: 'User Manage',
    prefix: '/content/container',
  },
}

export default config
