import React, { useEffect, useContext } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERNOTESCONFIG = {
  url: CMSENDPOINTS.masternotes.url,
  method: CMSENDPOINTS.masternotes.method,
  headers: CMSENDPOINTS.masternotes.headers,
  responseType: 'json',
}

const Index = (props) => {
  const { token, username } = LocalStorage.getLS().strapi
  const { masternotes } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    masternotes.dispatch({
      type: 'RESET',
    })
  }

  /* --- masternotes */
  const fetchMasterNotesExec = async ({ query }) => {
    CMSMASTERNOTESCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterNotes] = FetchContextApiV2(CMSMASTERNOTESCONFIG)
    masternotes.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterNotes({ query }, masternotes)
    masternotes.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- masternotes */

  useEffect(() => {
    console.log(' RUN LIST...')
    fetchMasterNotesExec({})
    return () => {
      fetchResetExec()
    }
  }, [])

  const isMasterNotesReady =
    masternotes.state.data &&
    masternotes.state.isStatus &&
    !masternotes.state.isLoading
      ? true
      : false
  if (!isMasterNotesReady) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
