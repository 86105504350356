import Normal from './normal'
import NormalRed from './normalRed'
import NormalText from './normalText'
import Primary from './primary'
import Text from './text'
import Box from './box'

export default {
  Normal,
  NormalRed,
  NormalText,
  Primary,
  Text,
  Box,
}
