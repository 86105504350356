import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Change Password'
const server = null
const service = null

const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          createsetup: {
            headers: {
              'Content-Type': 'application/json',
            },
            // url: setup.setup.api.cms.url[environment] + '/upload',
            url: setup.setup.api.cms.url[environment] + '/mastersetups',
            method: 'POST',
          },
          createmastercountrylang: {
            headers: {
              'Content-Type': 'application/json',
            },
            url:
              setup.setup.api.cms.url[environment] + '/mastercountrylanguages',
            // url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'POST',
          },
          createmastercontentsversions: {
            headers: {
              'Content-Type': 'application/json',
            },
            url:
              setup.setup.api.cms.url[environment] + '/mastercontentsversions',
            // url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'POST',
          },
          checkmasterbanner: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[environment] + '/masterbanners',
            // url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'GET',
          },
          createmasterbanner: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[environment] + '/masterbanners',
            // url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'POST',
          },
        },
      },
      objects: {
        name: {
          placeholder: '',
          name: 'name',
          initial: '',
          label: 'Setup Name',
          helperText: false,
          rules: ['isNotNull'],
        },
        // environment: {
        //   name: 'environment',
        //   initial: '',
        //   label: 'Environment',
        //   type: 'select',
        //   options: [],
        //   helperText: false,
        //   rules: ['isNotNull'],
        // },
        publish_date: {
          placeholder: '',
          name: 'publish_date',
          initial: '',
          label: 'Publish Date',
          helperText: false,
          rules: false,
        },
        mastercountrylanguages: {
          name: 'mastercountrylanguages',
          initial: '',
          label: 'Country Language',
          type: 'select',
          options: [],
          helperText: false,
          // rules: false,
          rules: ['isNotNull'],
        },
        // mastercountries: {
        //   name: 'mastercountries',
        //   initial: '',
        //   label: 'Country',
        //   type: 'select',
        //   options: [],
        //   helperText: false,
        //   // rules: ['isNotNull'],
        //   rules: false,
        // },
        // masterlanguages: {
        //   name: 'masterlanguages',
        //   initial: '',
        //   label: 'Language',
        //   type: 'select',
        //   options: [],
        //   helperText: false,
        //   // rules: ['isNotNull'],
        //   rules: false,
        // },
        // mastercontentsversions: {
        //   name: 'mastercontentsversions',
        //   initial: '',
        //   label: 'Content Version',
        //   type: 'select',
        //   options: [],
        //   helperText: false,
        //   rules: ['isNotNull'],
        // },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Create Content Page',
    prefix: '/layout/create',
    // prefix: "/:country/:lang/drag_and_drop"
  },
  environment,
  setup,
}

export default config
