import React, { Fragment, useState } from 'react'
import moment from 'moment'
/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons } from '../../lib-atomic'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

/* package */
import SectionContentImage from '../../app-content-section-image/default'
import SectionContentImageCarousel from '../../app-content-section-image-carousel/default'

/* modules */
import CONFIG from '../config'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    textAlign: 'center',
  },
}))
const formatDate = (from, to) => {
  return {
    formattedFrom:
      from !== null && from !== ''
        ? moment(from).format('DD-MMM-YYYY  HH:mm:ss')
        : 'Not set',
    formattedTo:
      to !== null && to !== ''
        ? moment(to).format('DD-MMM-YYYY  HH:mm:ss')
        : 'Not set',
  }
}
const Index = (props) => {
  const classes = useStyles()
  const { info, answerData, imageData } = props
  const {
    id,
    name,
    publishFrom,
    publishTo,
    questions,
    mastercountries,
    masterlanguages,
    mastercontents,
    campaignType,
    campaignCode,
  } = info
  console.log(answerData)
  const { answers } = answerData.length > 0 ? answerData[0] : {}
  const { title, description, homeImage } = mastercontents ? mastercontents : {}
  const { formattedFrom, formattedTo } = formatDate(publishFrom, publishTo)
  const [showDetails, setShowDetails] = useState(false)

  console.log(info)

  const toggleBtn = {
    style: { marginTop: 30, marginBottom: 40, float: 'right' },
    onClick: () => {
      setShowDetails(!showDetails)
    },
  }
  return (
    <Fragment>
      <Typography variant="body2">
        <small className={classes.upper}>
          <b>{mastercountries.abbr}</b>
        </small>
        &nbsp; / &nbsp;
        <small className={classes.upper}>
          <b>{masterlanguages.abbr}</b>
        </small>
      </Typography>
      <Typography variant="h5">
        <b>#{id}</b>&nbsp;<b>{name}</b>
        <b
          style={{
            color: '#098d42',
            cursor: 'pointer',
          }}
          onClick={() => {
            window.open('/content/detail/' + mastercontents.id, '_blank')
          }}
        >
          (Content Link)
        </b>
      </Typography>
      <SectionContentImage {...{ ...imageData, isDetail: false }} />
      <br />
      <Typography variant="h6">
        <p style={{ fontFamily: 'SubwayFootLong' }}>{title}</p>
      </Typography>
      <br />
      <Typography variant="h8">
        <p style={{ fontFamily: 'SubwaySixInch', color: 'black' }}>
          {description}
        </p>
      </Typography>
      <br />
      <br />
      <Typography variant="body2">
        <small className={classes.upper}>
          <span>Publish From</span> <br />
          <b>&nbsp;&nbsp;{formattedFrom}</b>
        </small>
        <br />
        <small className={classes.upper}>
          <span>Publish To</span> <br />
          <b>&nbsp;&nbsp;{formattedTo}</b>
        </small>
      </Typography>
      <br />

      <Typography variant="h8">
        <small className={classes.upper}>
          <span>Campaign Type</span> <br />
          <b>&nbsp;&nbsp;{campaignType}</b>
        </small>
        <br />
        <small className={classes.upper}>
          <span>Campaign Code</span> <br />
          <b>
            &nbsp;&nbsp;
            {campaignCode === null ||
            campaignCode === undefined ||
            campaignCode === ''
              ? 'NOT SET'
              : campaignCode}
          </b>
        </small>
      </Typography>
      {/* <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          margin: 'auto',
          height: 70,
        }}
      >
        <Buttons.Text {...toggleBtn}>
          {!showDetails ? 'Show Questions' : 'Hide Questions'}
        </Buttons.Text>
      </div> */}
      <br />
      <br />
      {/* {showDetails && ( */}
      <Fragment>
        {questions.map((question) => {
          return (
            <div
              style={{
                paddingTop: 25,
                color: '#111111',
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              <Typography variant="body2">
                <small className={classes.upper}>
                  <b>Question ID: {question.id} </b> <br />
                  <b
                    style={{
                      paddingLeft: 30,
                    }}
                  >
                    {question.question}
                  </b>
                </small>
              </Typography>
              {question.options.map((option, index) => {
                return (
                  <div
                    style={{
                      paddingLeft: 30,
                      paddingTop: 10,
                      color: '#111111',
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                  >
                    <Typography variant="body2">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {answers.some(
                          (answerObj) =>
                            answerObj.questionId === question.id &&
                            answerObj.answer === option.option
                        ) ? (
                          <CheckCircleIcon
                            style={{
                              color: 'green',
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                        ) : (
                          <div style={{ width: 30 }} />
                        )}
                        <span style={{ display: 'inline-block' }}>
                          {option.option}.{' '}
                        </span>
                        <b>{option.optionText}</b>
                      </div>
                    </Typography>
                  </div>
                )
              })}
            </div>
          )
        })}
      </Fragment>
      {/* )} */}
    </Fragment>
  )
}

export default Index
