import React from 'react'

import Body from './body'
import appConf from '../../components/app-conf'

import { GlobalProvider } from '../../components/lib-atomic-context'

const Themes = appConf[process.env.REACT_APP_CLIENT].themes

const Main = (props) => (
  <Themes>
    <GlobalProvider>
      <Body {...props} />
    </GlobalProvider>
  </Themes>
)

export default Main
