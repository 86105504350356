import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { Buttons, Inputs } from '../../lib-atomic'
import {
  LocalStorage,
  FormHook,
  WindowSize,
  FetchContextApiV2,
  FetchLoggerApi,
  FetchNotificationSendApi,
} from '../../lib-atomic-functions'
import { useSnackbar } from 'notistack'

import { GlobalContext } from '../../lib-atomic-context'

import CONFIG from '../config'

import Spinner from '../../app-module-section-spinner/default'
import Popup from '../../app-popup-section-confirm/default'
import LastPopup from '../lastPopup/index'

import Info from './info'

const CMSENDPOINTS = CONFIG.application.cms.api.endpoints

const CMSAPPROVECONTENTCONFIG = {
  url: CMSENDPOINTS.approvecontent.url,
  method: CMSENDPOINTS.approvecontent.method,
  headers: CMSENDPOINTS.approvecontent.headers,
  responseType: 'json',
}
const CMSUPDATETOPENDINGCONFIG = {
  url: CMSENDPOINTS.updateflagtopending.url,
  method: CMSENDPOINTS.updateflagtopending.method,
  headers: CMSENDPOINTS.updateflagtopending.headers,
  responseType: 'json',
}

const OBJECT1 = CONFIG.application.cms.objects

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  assets: {
    height: 'auto',
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))
const flagMap = {
  live: {
    draft: 5,
    pending: 6,
    approved: 7,
    live: 8,
  },
  uat: {
    draft: 1,
    pending: 4,
    approved: 2,
    live: 3,
  },
}
const flagMapSelected =
  process.env.REACT_APP_BUILD_ENV === 'production' ? flagMap.live : flagMap.uat

const Index = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { user, item } = props
  const { id, mastercontentsflag, mastercontents } = item
  const { token, username } = LocalStorage.getLS().strapi
  const { contentdetail, mastersetups, cognitouser, updateflagtopending } =
    useContext(GlobalContext)

  const [visibleApprovalRequestPopup, setVisibleApprovalRequestPopup] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [visibleApprovePopup, setVisibleApprovePopup] = useState(false)
  const [visibleRejectPopup, setVisibleRejectPopup] = useState(false)
  const [visibleDeclinePopup, setVisibleDeclinePopup] = useState(false)
  const [visibleLastPopup, setVisibleLastPopup] = useState(false)

  const classes = useStyles()
  const { width, height } = WindowSize()

  const fetchUpdateFlagToPendingExec = async ({ query, body }) => {
    CMSUPDATETOPENDINGCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdate] = FetchContextApiV2(CMSUPDATETOPENDINGCONFIG)
    const fetchResult = await fetchUpdate({ query, body }, updateflagtopending)
    updateflagtopending.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  // ============================================================
  // =============================== Approve Request Block=======
  const handleApprove = async () => {
    console.log('Approval Request!')
    setVisibleApprovalRequestPopup(!visibleApprovalRequestPopup)
  }
  const approvalRequestContentExec = async () => {
    console.log('processing request for approval...')
    setVisibleApprovalRequestPopup(!visibleApprovalRequestPopup)
    setLoading(true)
    /* -------------- */
    const detailObj = contentdetail.state.data
    console.log(detailObj)
    const query = '/' + detailObj.id
    const body = {
      ...detailObj,
      mastercontentsflag: flagMapSelected.pending,
    }
    fetchUpdateFlagToPendingExec({ query, body })
    /* start: notification */
    const cognitorUserObj = cognitouser.state.data[0]
    const x = await FetchNotificationSendApi({
      queryType: 'ApprovalRequest',
      userObj: cognitorUserObj,
      ref: contentdetail.state.data,
      token,
      comment: values.comment,
    })
    /* end: notification */
    /* -------------- */
    setLoading(false)
    setVisibleLastPopup(!visibleLastPopup)
  }
  // =============================== Approve Request Block=======
  // ============================================================

  // ============================================================
  // =============================== Reject Block=======
  const handleReject = async () => {
    console.log('Reject Request!')
    setVisibleRejectPopup(!visibleRejectPopup)
  }
  const rejectContentExecute = async () => {
    console.log('processing reject...')
    setVisibleRejectPopup(!visibleRejectPopup)
    setLoading(true)

    const detailObj = contentdetail.state.data
    console.log(detailObj)
    const query = '/' + detailObj.id
    const body = {
      ...detailObj,
      mastercontentsflag: flagMapSelected.draft,
    }
    fetchUpdateFlagToPendingExec({ query, body })

    /* -------------- */
    /* start: notification */
    const cognitorUserObj = cognitouser.state.data[0]
    const x = await FetchNotificationSendApi({
      queryType: 'RejectContent',
      userObj: cognitorUserObj,
      ref: contentdetail.state.data,
      token,
      comment: values.comment,
    })
    /* end: notification */
    /* -------------- */
    setLoading(false)
    setVisibleLastPopup(!visibleLastPopup)
  }
  // =============================== Reject Block=======
  // ============================================================

  // ============================================================
  // ======================================= Approve Block=======

  const handleAccept = async () => {
    console.log('Approve Content!')
    setVisibleApprovePopup(!visibleApprovePopup)
  }
  const approveContentExec = async () => {
    console.log('processing acceptance...')

    setVisibleApprovePopup(!visibleApprovePopup)
    setLoading(true)
    const oldData = contentdetail.state.data
    contentdetail.state.data = {
      ...contentdetail.state.data,
      mastercontentsflag: flagMapSelected.approved,
    }
    const body = contentdetail.state.data
    CMSAPPROVECONTENTCONFIG.headers.Authorization = 'Bearer ' + token
    const [approveContent] = FetchContextApiV2(CMSAPPROVECONTENTCONFIG)
    const fetchResult = await approveContent(
      { query: '/' + id, body },
      contentdetail
    )
    // await contentdetail.dispatch({
    //   type: 'RESET',
    // })
    // await contentdetail.dispatch({
    //   type: 'SET',
    //   data: fetchResult,
    // })

    /* -------------- */
    /* start: logger */
    const y = await FetchLoggerApi({
      username,
      api: CMSAPPROVECONTENTCONFIG,
      page: 'content-draft-approve',
      oldData: oldData,
      newData: body,
    })
    /* end: logger */
    /* -------------- */

    /* -------------- */
    /* start: notification */
    const cognitorUserObj = cognitouser.state.data[0]
    const x = await FetchNotificationSendApi({
      queryType: 'AcceptContent',
      userObj: cognitorUserObj,
      ref: contentdetail.state.data,
      token,
      comment: values.comment,
    })
    /* end: notification */
    /* -------------- */
    setLoading(false)
    setVisibleLastPopup(!visibleLastPopup)
  }
  // ======================================= Approve Block=======
  // ============================================================

  // ============================================================
  // ======================================= Decline Block=======

  const handleDecline = async () => {
    console.log('Decline Content!')
    setVisibleDeclinePopup(!visibleDeclinePopup)
  }
  const declineContentExec = async () => {
    console.log('processing decline...')

    const oldData = contentdetail.state.data
    if (oldData.Environment === 'production') {
      setVisibleDeclinePopup(!visibleDeclinePopup)
      const message =
        'You are not allowed to modify the content assigned to production setup :('
      enqueueSnackbar(message, {
        variant: 'warning',
      })
    } else {
      console.log('correct2 ', mastersetups.state.data, id)
      const checkIfContentFound =
        mastersetups.state.data &&
        mastersetups.state.data.some((setup) => {
          if (setup.environment === 'production') {
            const webHas = setup.WebContents.some((content1) => {
              if (content1.type === 'Carousel') {
                return content1.CarouselContents.some(
                  (innerCarouselContent) => {
                    return innerCarouselContent.mastercontents.id === id
                  }
                )
              } else {
                return content1.mastercontents.id === id
              }
            })
            const mobHas = setup.MobContents.some((content1) => {
              if (content1.type === 'Carousel') {
                return content1.CarouselContents.some(
                  (innerCarouselContent) => {
                    return innerCarouselContent.mastercontents.id === id
                  }
                )
              } else {
                return content1.mastercontents.id === id
              }
            })
            return webHas || mobHas
          } else {
            return false
          }
        })
      if (checkIfContentFound) {
        setVisibleDeclinePopup(!visibleDeclinePopup)
        const message =
          'This content is in the setups :( Please remove this item from the setups and try again.'
        enqueueSnackbar(message, {
          variant: 'warning',
        })
      } else {
        setVisibleDeclinePopup(!visibleDeclinePopup)
        setLoading(true)
        const oldData = contentdetail.state.data
        contentdetail.state.data = {
          ...contentdetail.state.data,
          mastercontentsflag: flagMapSelected.draft,
        }
        const body = contentdetail.state.data
        CMSAPPROVECONTENTCONFIG.headers.Authorization = 'Bearer ' + token
        const [approveContent] = FetchContextApiV2(CMSAPPROVECONTENTCONFIG)
        const fetchResult = await approveContent(
          { query: '/' + id, body },
          contentdetail
        )
        // await contentdetail.dispatch({
        //   type: 'RESET',
        // })
        // await contentdetail.dispatch({
        //   type: 'SET',
        //   data: fetchResult,
        // })

        /* -------------- */
        /* start: logger */
        await FetchLoggerApi({
          username,
          api: CMSAPPROVECONTENTCONFIG,
          page: 'content-draft-approve',
          oldData: oldData,
          newData: body,
        })
        /* end: logger */
        /* -------------- */

        /* -------------- */
        /* start: notification */
        const cognitorUserObj = cognitouser.state.data[0]
        const x = await FetchNotificationSendApi({
          queryType: 'DeclineContent',
          userObj: cognitorUserObj,
          ref: contentdetail.state.data,
          token,
          comment: values.comment,
        })
        /* end: notification */
        /* -------------- */
        setLoading(false)
        setVisibleLastPopup(!visibleLastPopup)
      }
    }
  }
  // ======================================= Decline Block=======
  // ============================================================

  const handleSubmit = async () => {
    // console.log(values)
    // // const cognitorUserObj = cognitouser.state.data[0]
    // // console.log(contentdetail.state.data)
    // // const x = await FetchNotificationSendApi({
    // //   queryType: buttonType === 'Accept' ? 'AcceptContent' : 'DeclineContent',
    // //   userObj: cognitorUserObj,
    // //   ref: contentdetail.state.data,
    // //   token,
    // //   comment: values.comment,
    // // })
  }
  console.log(item)
  const isDraft =
    !mastercontentsflag ||
    !mastercontentsflag.status ||
    (mastercontentsflag &&
      mastercontentsflag.status &&
      mastercontentsflag.status === 'draft')
      ? true
      : false
  const isPending =
    mastercontentsflag && mastercontentsflag.status === 'pending' ? true : false
  const isApprove =
    !isDraft && mastercontentsflag && mastercontentsflag.status === 'approved'
      ? true
      : false
  const isInApprovedDevSetup =
    mastersetups.state.data &&
    mastersetups.state.data.some((setup) => {
      if (setup.environment === 'development') {
        if (
          setup.mastersetupflag &&
          setup.mastersetupflag.status === 'approved'
        ) {
          return (
            setup.MobContents.some(
              (content) => content.mastercontents.id === id
            ) ||
            setup.WebContents.some(
              (content) => content.mastercontents.id === id
            )
          )
        } else {
          return false
        }
      }
    })
  console.log(isInApprovedDevSetup)

  const allInnerApproved =
    item.type === 'Carousel'
      ? item.CarouselContents &&
        !item.CarouselContents.some((item) => {
          return !(
            item.mastercontents &&
            item.mastercontents.mastercontentsflag &&
            (item.mastercontents.mastercontentsflag ===
              flagMapSelected.approved ||
              item.mastercontents.mastercontentsflag.status === 'approved')
          )
        })
      : true
  const items = [
    {
      user: ['mastercreator', 'creator+approver+publisher'],
      action: handleApprove,
      label: 'Request Approval',
      status: isDraft,
    },
    {
      user: ['masterapprover', 'creator+approver+publisher'],
      action: handleAccept,
      label: 'Approve',
      status: (isDraft || isPending) && allInnerApproved,
    },
    {
      user: ['masterapprover', 'creator+approver+publisher'],
      action: handleReject,
      label: 'Reject',
      status: isPending,
    },
    {
      user: ['masterapprover', 'creator+approver+publisher'],
      action: handleDecline,
      label: 'Back To Draft',
      status: isApprove && !isInApprovedDevSetup,
    },
  ]

  const checkItems = ({ status, user }) => {
    return items.filter(
      (item) => item.status === status && item.user.indexOf(user) > -1
    )
  }

  const menus = checkItems({ status: true, user: user[0].user.username })
  const approvalRequestPopupData = {
    ...props,
    toggle: visibleApprovalRequestPopup,
    setToggle: setVisibleApprovalRequestPopup,
    action: approvalRequestContentExec,
  }
  const approvePopupData = {
    ...props,
    toggle: visibleApprovePopup,
    setToggle: setVisibleApprovePopup,
    action: approveContentExec,
  }
  const rejectPopupData = {
    ...props,
    toggle: visibleRejectPopup,
    setToggle: setVisibleRejectPopup,
    action: rejectContentExecute,
  }
  const declinePopupData = {
    ...props,
    toggle: visibleDeclinePopup,
    setToggle: setVisibleDeclinePopup,
    action: declineContentExec,
  }
  const lastPopupData = {
    ...props,
    toggle: visibleLastPopup,
    setToggle: setVisibleLastPopup,
    action: () => {
      props.history.push('/content/detail/' + id)
    },
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    OBJECT1
  )

  const comment = {
    ...OBJECT1.comment,
    rows: 3,
    error: error.comment,
    value: values.comment,
    onChange,
  }

  const submitButton = { fullWidth: true }

  const cancelButton = {
    fullWidth: true,
    style: { width: 150, marginLeft: 5 },
    onClick: () => {
      props.history.push('/content/detail/' + id)
    },
  }

  return (
    <Fragment>
      <div>
        <form onSubmit={onSubmit} noValidate>
          <div className={classes.paper}>
            <h3
              id="transition-modal-title"
              style={{
                paddingBottom: 5,
                fontFamily: 'SubwayFootlong',
                fontSize: width < 500 ? 17 : 25,
              }}
            >
              Any Comments ?
            </h3>
            <Inputs.Textarea {...comment} />

            <div className={classes.root}>
              {menus.map((menu, index) => {
                if (menu.label === 'Reject' || menu.label === 'Back To Draft') {
                  return (
                    <Fragment>
                      <Buttons.NormalRed
                        key={index}
                        onClick={() => menu.action()}
                      >
                        {menu.label}
                      </Buttons.NormalRed>
                      &nbsp;
                    </Fragment>
                  )
                } else {
                  return (
                    <Fragment>
                      <Buttons.Normal key={index} onClick={() => menu.action()}>
                        {menu.label}
                      </Buttons.Normal>
                      &nbsp;
                    </Fragment>
                  )
                }
              })}
              <Fragment>
                <Buttons.Text {...cancelButton}>CANCEL</Buttons.Text>
              </Fragment>
            </div>
          </div>
        </form>
      </div>
      <Popup {...approvalRequestPopupData} />
      <Popup {...approvePopupData} />
      <Popup {...rejectPopupData} />
      <Popup {...declinePopupData} />
      <LastPopup {...lastPopupData} />
      {loading && <Spinner.Popup />}
    </Fragment>
  )
}

export default Index
