import Amplify from 'aws-amplify'
import { Auth } from 'aws-amplify'
import appConf from '../../components/app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const env = process.env.NODE_ENV
const cognitoConfig = setup.setup.api.cognito[env].cognito

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: cognitoConfig.region,
    userPoolId: cognitoConfig.user_pool_id,
    userPoolWebClientId: cognitoConfig.app_client_id,
  },
})

const signin = async ({ username, password }) => {
  try {
    const user = await Auth.signIn(username, password)
    console.log(user)
    return { response: user, error: false }
  } catch (e) {
    return { response: false, error: { ...e } }
  }
}

const signup = async (data) => {
  try {
    const user = await Auth.signUp(data)
    return { response: user, error: false }
  } catch (e) {
    return { response: false, error: { ...e } }
  }
}

const signout = async () => {
  await Auth.signOut()
}

export default {
  signin,
  signup,
  signout,
}
