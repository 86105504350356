import React, { lazy, Suspense, Fragment } from 'react'

/* package */
import { SectionWrappSingle } from '../../lib-atomic-section'
/* package */

/* container */
import Container from '../../app-layout-container/default'

/* form */
import Content from '../layout/content'
/* form */

const Index = (props) => {
  return (
    <Container.LayoutAddBannerContent {...props}>
      {/* <SectionWrappSingle
        {...{
          ...props,
          maxWidth: 'lg',
        }}
      > */}
      <Content {...props} />
      {/* </SectionWrappSingle> */}
    </Container.LayoutAddBannerContent>
  )
}

export default Index
