import axios from 'axios'
import appConf from '../../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const loggerApi = setup.setup.api.logger.url[process.env.NODE_ENV]

const index = async ({ username, page, oldData, newData, api }) => {
  const logs = await axios({
    method: 'post',
    url: loggerApi,
    data: { username, page, oldData, newData, api },
    headers: { 'Content-Type': 'application/json' },
  })
}

export default index
