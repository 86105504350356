import React, { lazy, Suspense, useContext, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
import { SectionPaper } from '../../lib-atomic-section'
import { useSnackbar } from 'notistack'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
/* package */

import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'
/* modules */
import CONFIG from '../config'

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

/* CONSTANTS */
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects

const CMSMASTERSETUPCONFIG = {
  url: CMSENDPOINTS.updatemastersetup.url,
  method: CMSENDPOINTS.updatemastersetup.method,
  headers: CMSENDPOINTS.updatemastersetup.headers,
  responseType: 'json',
}
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
}))
const Index = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const [loading, setLoading] = useState(false)
  const [webContents, setWebContents] = useState([])
  const [mobContents, setMobContents] = useState([])
  const initialForm = {
    contentselect: '',
  }

  // assign initial value
  Object.keys(OBJECT1).map((key) => {
    OBJECT1[key].initial = initialForm[key]
  })
  const classes = useStyles()
  const { setupname } = props.match.params
  const { width, height } = WindowSize()

  const { getFontSizes } = WindowFont()

  /* state */
  const { masterboxes, mastersetups, allcontents, updatesetup } = useContext(
    GlobalContext
  )
  const resetContextItems = async () => {
    masterboxes.dispatch({
      type: 'RESET',
    })
    mastersetups.dispatch({
      type: 'RESET',
    })
    allcontents.dispatch({
      type: 'RESET',
    })
    updatesetup.dispatch({
      type: 'RESET',
    })
  }
  const masterboxesobj = masterboxes.state.data
  const allcontentsobj = allcontents.state.data
  const mastersetupsobj = mastersetups.state.data
  console.log(mastersetupsobj)
  /* --- master setups */
  const fetchUpdateMasterSetupsExecute = async ({ query, body }) => {
    CMSMASTERSETUPCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdateMasterSetups] = FetchContextApiV2(CMSMASTERSETUPCONFIG)
    const fetchResult = await fetchUpdateMasterSetups(
      { query, body },
      updatesetup
    )
    updatesetup.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* state */

  const handleAdd = async () => {
    console.log('Adding...')
    console.log('values: ', values)
    const objectToBeAdded = {
      // id: null,
      mastercontents: values.contentselect,
      masterbox: values.boxobjects,
      startX: null,
      startY: null,
      endX: null,
      endY: null,
      startXForPostLogin: null,
      startYForPostLogin: null,
      endXForPostLogin: null,
      endYForPostLogin: null,
    }
    // const concat1 = JSON.parse(JSON.stringify(webContents))
    if (values.platform === 'Web') {
      setWebContents(sortArray([...webContents, objectToBeAdded]))
    } else {
      setMobContents(sortArray([...mobContents, objectToBeAdded]))
    }
  }
  const { values, onChange, onCheck, onSubmit, error } = FormHook.useFormHook(
    handleAdd,
    OBJECT1
  )

  OBJECT1.platform.options = [
    { name: 'Website', value: 'Web' },
    { name: 'Mobile', value: 'Mob' },
  ]

  const boxObjectList = masterboxesobj.sort().map((item) => {
    return { name: item.name, value: item }
  })
  OBJECT1.boxobjects.options = boxObjectList
  const contentNameList = allcontentsobj
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((item) => {
      return { name: item.name, value: item }
    })
  OBJECT1.contentselect.options = contentNameList

  const platform = {
    ...OBJECT1.platform,
    error: error.platform,
    value: values.platform,
    onChange,
  }
  const boxobjects = {
    ...OBJECT1.boxobjects,
    error: error.boxobjects,
    value: values.boxobjects,
    onChange,
  }
  const contentselect = {
    ...OBJECT1.contentselect,
    error: error.contentselect,
    value: values.contentselect,
    onChange,
  }
  const sortArray = (arrayPassed) => {
    return arrayPassed.sort((a, b) =>
      a.mastercontents.name > b.mastercontents.name ? 1 : -1
    )
  }
  useEffect(() => {
    console.log(mastersetupsobj)
    setWebContents(sortArray(mastersetupsobj[0].WebContents))
    setMobContents(sortArray(mastersetupsobj[0].MobContents))

    return () => resetContextItems()
  }, [])
  const addButton = {
    style: { marginTop: 10 },
    fullWidth: true,
  }
  const submitButton = {
    style: { marginRight: 10, width: 150, height: 35 },
    // fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }

  const removeButton = {
    // style: { marginLeft: 10 },
    fullWidth: true,
    // disabled: true,
  }
  const cancelButton = {
    style: { marginRight: 10, width: 150, height: 35 },
    // fullWidth: true,
  }
  useEffect(() => {
    if (updatesetup.state.data) {
      if (!updatesetup.state.isStatus) {
        console.log('wrong')
      } else {
        props.history.push('/layout/preview/' + id)
      }
    }
  }, [updatesetup.state])
  const isInLive = (row, platform) => {
    return mastersetupsobj
      .filter((setup) => setup.environment === 'development')[0]
      [platform].some(
        (content) =>
          content.mastercontents.id === row.mastercontents.id &&
          content.mastercontents.mastercontentsflag.status === 'live'
      )
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          style={{ paddingLeft: 5, paddingBottom: 16, fontWeight: 900 }}
        >
          Add Setup Contents: #{mastersetupsobj[0].id}&nbsp;
          {mastersetupsobj[0].name}
        </Typography>
        <div
          style={{
            display: 'flex',
            // margin: 'auto',
            justifyContent: 'flex-end',
          }}
        >
          <Buttons.Normal
            {...submitButton}
            onClick={async () => {
              if (!loading) {
                setLoading(true)
                if (mastersetupsobj[0].environment === 'production') {
                  const message =
                    'Production setup content cannot be modified :('
                  enqueueSnackbar(message, {
                    variant: 'warning',
                  })
                  setLoading(false)
                } else {
                  const from = props.location.state
                    ? props.location.state.platform
                      ? props.location.state.platform
                      : 'web'
                    : 'web'
                  const newSetup = JSON.parse(
                    JSON.stringify(mastersetupsobj[0])
                  )
                  const query = '/' + newSetup.id
                  const finalNewSetup = {
                    ...newSetup,
                    WebContents: webContents,
                    MobContents: mobContents,
                  }
                  await fetchUpdateMasterSetupsExecute(
                    { query, body: finalNewSetup },
                    updatesetup
                  )
                }
              }
            }}
          >
            Save
          </Buttons.Normal>
          <Spinner.Loader color="#fff" size={24} loading={loading} />
          <Buttons.Normal
            {...cancelButton}
            onClick={() => {
              const from = props.location.state
                ? props.location.state.platform
                  ? props.location.state.platform
                  : 'web'
                : 'web'

              props.history.push('/layout/preview/' + id)
              // props.history.push("/p/preview/" + from + "/" + setupname);
            }}
          >
            Cancel
          </Buttons.Normal>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
          height: height,
        }}
      >
        {/* left box */}
        <div
          style={{
            width: '25%',
            minWidth: '25%',
            justifyContent: 'flex-start',
            paddingRight: 10,
            //   margin: "auto",
          }}
        >
          <div style={{ height: 45 }} />
          <SectionPaper>
            <Typography variant="h6" gutterBottom>
              Available Contents
            </Typography>
            <form onSubmit={onSubmit} noValidate>
              <Inputs.Select {...platform} />
              <Inputs.Select {...boxobjects} />
              <Inputs.Select {...contentselect} />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {/* <Buttons.Normal {...cancelButton}>Cancel</Buttons.Normal> */}
                <Buttons.Normal {...addButton}>Add</Buttons.Normal>
              </div>
            </form>
          </SectionPaper>
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 'auto',
            }}
          >
            {/* middle box */}
            <div
              style={{
                width: '45%',
                minWidth: '45%',
                height: 'auto',
                marginLeft: 50,
                marginBottom: 50,
              }}
            >
              {/* <div style={{ display: "flex", width: "100%" }}> */}
              <Typography variant="h6" gutterBottom>
                Website Contents
              </Typography>
              {/* </div> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'wrap',
                  position: 'relative',
                  width: '100%',
                  height: 'auto',
                  marginTop: 15,
                }}
              >
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Box</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {webContents.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.mastercontents.id}
                          </TableCell>
                          <TableCell align="left">
                            {row.mastercontents.name}
                          </TableCell>
                          <TableCell align="left">
                            {row.masterbox.width}&nbsp;{'X'}&nbsp;
                            {row.masterbox.height}
                          </TableCell>
                          <TableCell align="left">
                            {row.mastercontents.mastercontentsflag.status}
                          </TableCell>
                          <TableCell align="left">
                            <Buttons.Normal
                              {...{
                                ...removeButton,
                                disabled: isInLive(row, 'WebContents'),
                              }}
                              onClick={() => {
                                const currIndex = webContents
                                  .map((i) => {
                                    return i.mastercontents.id
                                  })
                                  .indexOf(row.mastercontents.id)
                                const newWebContents = JSON.parse(
                                  JSON.stringify(webContents)
                                )
                                newWebContents.splice(currIndex, 1)
                                setWebContents(sortArray(newWebContents))
                              }}
                            >
                              Remove
                            </Buttons.Normal>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            {/* right box */}
            <div
              style={{
                width: '45%',
                minWidth: '45%',
                height: 'auto',
                marginLeft: 50,
                marginBottom: 50,
              }}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Mobile Contents
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'wrap',
                  position: 'relative',
                  width: '100%',
                  height: 'auto',
                  marginTop: 15,
                }}
              >
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Box</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mobContents.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.mastercontents.id}
                          </TableCell>
                          <TableCell align="left">
                            {row.mastercontents.name}
                          </TableCell>
                          <TableCell align="left">
                            {row.masterbox.width}&nbsp;{'X'}&nbsp;
                            {row.masterbox.height}
                          </TableCell>
                          <TableCell align="left">
                            {row.mastercontents.mastercontentsflag.status}
                          </TableCell>
                          <TableCell align="left">
                            <Buttons.Normal
                              {...{
                                ...removeButton,
                                disabled: isInLive(row, 'MobContents'),
                              }}
                              onClick={() => {
                                const currIndex = mobContents
                                  .map((i) => {
                                    return i.mastercontents.id
                                  })
                                  .indexOf(row.mastercontents.id)
                                const newMobContents = JSON.parse(
                                  JSON.stringify(mobContents)
                                )
                                newMobContents.splice(currIndex, 1)
                                setMobContents(newMobContents)
                              }}
                            >
                              Remove
                            </Buttons.Normal>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
