import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const env = process.env.NODE_ENV;
const config = {
  details: {
    env,
  },
  application: {
    login: {
      api: false,
      objects: {
        email: {
          name: "email",
          initial: "",
          placeholder: "Email address",
          helperText: false,
          rules: ["isNotNull"],
        },
        password: {
          name: "password",
          initial: "",
          placeholder: "Password",
          helperText: false,
          rules: ["isNotNull"],
        },
      },
    },
  },
  routes: {
    label: "Registration",
    prefix: "/auth/beta",
    // prefix: "/:country/:lang/drag_and_drop"
  },
  env,
  setup,
};

export default config;
