import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const env = process.env.NODE_ENV
const config = {
  details: {
    env,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          deletecampaign: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercampaigns',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'DELETE',
          },
          deletecampaignanswer: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercampaignanswersheets',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'DELETE',
          },
          approvecontent: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercontents',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'PUT',
          },
        },
      },
      objects: {
        comment: {
          name: 'comment',
          initial: '',
          label: 'Comments',
          helperText: false,
          rules: false,
        },
      },
    },
  },
  routes: {
    label: false,
    prefix: false,
  },
  env,
  setup,
}

export default config
