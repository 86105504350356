import pageBeta from '../../../app-beta-components'
import pageRegistration from '../../../app-auth-page-registration'
import pageLogin from '../../../app-auth-page-login'
import pageSessionExpired from '../../../app-section-token-expire-landing'
import pageLogout from '../../../app-auth-page-logout'

import pageUserList from '../../../app-user-page-list'
import pageUserDetail from '../../../app-user-page-detail'

import contentList from '../../../app-content-page-list'
import contentDetail from '../../../app-content-page-detail'
import contentDetailNoti from '../../../app-content-page-detail-noti'
import contentCreate from '../../../app-content-page-create'
import contentEdit from '../../../app-content-page-edit'
import contentCarouselEdit from '../../../app-content-page-carousel-edit'

import userProfile from '../../../app-user-profile'

import layoutList from '../../../app-layout-page-list'
import layoutCreate from '../../../app-layout-page-create'
import layoutPreview from '../../../app-layout-page-preview'
import layoutAddSetupContent from '../../../app-layout-page-add-setup-content'
import layoutAddBannerContent from '../../../app-layout-page-add-banner-content'
import layoutEditSetup from '../../../app-layout-page-edit-setup'

import noteList from '../../../app-note-page-list'

import campaignList from '../../../app-campaign-page-list'
import campaignDetail from '../../../app-campaign-page-detail'
import campaignCreate from '../../../app-campaign-page-create'
import campaignEdit from '../../../app-campaign-page-edit'

/* auth: start */
const moduleAuth = [
  pageRegistration,
  pageLogin,
  pageLogout,
  pageSessionExpired,
  pageBeta,
]
/* auth: end */

/* user manage: start */
const moduleUser = [pageUserList, pageUserDetail]
/* user manage: end */

const moduleContent = [
  contentList,
  contentDetail,
  contentDetailNoti,
  contentCreate,
  contentEdit,
  contentCarouselEdit,
]

const moduleUserProfile = [userProfile]

const moduleLayout = [
  layoutList,
  layoutCreate,
  layoutPreview,
  layoutAddSetupContent,
  layoutAddBannerContent,
  layoutEditSetup,
]

const moduleNote = [noteList]

const moduleCampaign = [
  campaignList,
  campaignDetail,
  campaignCreate,
  campaignEdit,
]

const modules = {
  auth: moduleAuth,
  user: moduleUser,
  content: moduleContent,
  userprofile: moduleUserProfile,
  layout: moduleLayout,
  note: moduleNote,
  campaign: moduleCampaign,
}

export default modules
