import React from "react";
import Typography from "@material-ui/core/Typography";

import {
  SectionWrappList,
  SectionWrappListItem,
} from "../../lib-atomic-section";

const index = (props) => {
  return (
    <SectionWrappList {...props}>
      <SectionWrappListItem>
        <Typography variant="body2">Pratz</Typography>
        <Typography variant="body2">
          <b>Approver</b>
        </Typography>
      </SectionWrappListItem>
      <SectionWrappListItem>
        <Typography variant="body2">Pratz</Typography>
        <Typography variant="body2">
          <b>Approver</b>
        </Typography>
      </SectionWrappListItem>
      <SectionWrappListItem>
        <Typography variant="body2">Pratz</Typography>
        <Typography variant="body2">
          <b>Approver</b>
        </Typography>
      </SectionWrappListItem>
      <SectionWrappListItem>
        <Typography variant="body2">Pratz</Typography>
        <Typography variant="body2">
          <b>Approver</b>
        </Typography>
      </SectionWrappListItem>
    </SectionWrappList>
  );
};

export default index;
