import React from 'react';
import moment from 'moment';

/* atoms */
import { Invalid } from '../feedbacks';
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const Date = props => {

  //const defaultValue = props.value ? props.value : new Date();
  const [selectedDate, setSelectedDate] = React.useState(props.value);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const { name, onPick } = props
    onPick(name, moment(date).format("YYYY-MM-DD"));
  }

  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    {/* disableToolbar */}
    <KeyboardDatePicker
      {...props}
      autoOk
      fullWidth
      variant="modal"
      format="dd/MM/yyyy"
      id="date-picker-dialog"
      value={selectedDate}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
    <Invalid {...props} />
  </MuiPickersUtilsProvider>
}

export default Date;
