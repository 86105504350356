const isMinMaxPhoneNumber = data => {
  const value = data.value.trim()
  const config = data.config
  const limit = config.minmax_limit
  const minLimit = 6
  const suffix = config.minmax_suffix
  const nullAllow = config.allow_null ? true : false
  if (nullAllow && value.length < 1) return true
  return value.length === limit
    ? true
    : 'must be ' + minLimit + -+limit + ' ' + suffix
}

export default isMinMaxPhoneNumber
