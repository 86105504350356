import React, { useContext, Fragment, useState, useEffect } from 'react'

import { Buttons } from '../../lib-atomic'

const Index = (props) => {
  const { user, item } = props
  const { id, mastercontentsflag } = item

  const handleEdit = ({ id, type }) => {
    if (type !== 'Carousel') {
      props.history.push('/content/edit/' + id)
    } else {
      props.history.push('/content/carousel/edit/' + id)
    }
  }

  const isDraft =
    !mastercontentsflag ||
    !mastercontentsflag.status ||
    (mastercontentsflag &&
      mastercontentsflag.status &&
      (mastercontentsflag.status === 'draft' ||
        mastercontentsflag.status === 'pending'))
      ? true
      : false

  const items = [
    {
      user: ['mastercreator', 'creator+approver+publisher'],
      action: handleEdit,
      label: 'Edit',
      status: true,
    },
  ]

  const checkItems = ({ status, user }) => {
    return items.filter(
      (item) => item.status === status && item.user.indexOf(user) > -1
    )
  }

  const menus = checkItems({ status: isDraft, user: user[0].user.username })

  return (
    <Fragment>
      {menus.map((menu, index) => {
        return (
          <Fragment>
            <Buttons.Normal
              key={index}
              onClick={() => menu.action({ id, type: item.type })}
            >
              {menu.label}
            </Buttons.Normal>
            &nbsp;
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default Index
