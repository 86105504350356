import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const env = process.env.NODE_ENV
const config = {
  details: {
    env,
  },
  application: false,
  routes: {
    label: 'Logout',
    prefix: '/auth/logout',
  },
  env,
  setup,
}

export default config
