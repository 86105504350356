import React from 'react'
import { Link } from 'react-router-dom';

import CONFIG from './config';

const Links =  props => {
  const { label, prefix } = CONFIG.routes.label;
  return <div>
    <Link to={prefix}>{label}</Link>
  </div>
}

export default Links;
