import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const env = process.env.NODE_ENV
const config = {
  details: {
    env,
  },
  application: {
    api: {
      endpoints: {
        auth: {
          headers: {
            'Content-Type': 'application/json',
          },
          url: setup.setup.api.cms.url[env] + '/mastercountries',
          // url: setup.setup.api.cms.url[env] + '/auth/local',
          method: 'POST',
        },
      },
    },
    objects: {
      username: {
        name: 'username',
        initial: '',
        placeholder: 'Username',
        helperText: false,
        rules: ['isNull'],
      },
      email: {
        name: 'email',
        initial: '',
        placeholder: 'email',
        helperText: false,
        rules: ['isNull'],
      },
      password: {
        name: 'password',
        initial: '',
        placeholder: 'Password',
        helperText: false,
        rules: ['isNull'],
      },
    },
    responses: {
      countryroleFail: 'User role and country cannot be empty',
    },
  },
  routes: {
    label: 'Registration',
    prefix: '/auth/registration',
  },
  env,
  setup,
}

export default config
