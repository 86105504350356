import React, { useEffect } from 'react'

import ServiceAmplify from '../../../services/AWS-amplify'

import { LocalStorage } from '../../lib-atomic-functions'

const Index = (props) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      signoutExec()
    }, 3000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const signoutExec = async () => {
    await ServiceAmplify.signout()
    LocalStorage.removeLS()
    props.history.push('/auth/login')
  }

  return <div>Logging out...</div>
}

export default Index
