import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Change Password'
const server = null
const service = null
const options = {
  preference_options: [
    { name: 'Preference Type 1', value: '1' },
    { name: 'Preference Type 2', value: '2' },
  ],
}
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Content-Type': 'application/json',
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'GET',
          },
          updatemastercontent: {
            headers: {
              'Content-Type': 'application/json',
              // Authorization: null
            },
            url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'PUT',
          },
          updatemastersetup: {
            headers: {
              'Content-Type': 'application/json',
              // Authorization: null
            },
            url: setup.setup.api.cms.url[environment] + '/mastersetups',
            method: 'PUT',
          },
        },
      },
      objects: {
        carouselselect: {
          name: 'carouselselect',
          initial: '',
          label: 'Carousel',
          type: 'select',
          options: [],
          helperText: false,
          rules: false,
        },
        contentselect: {
          name: 'contentselect',
          initial: '',
          label: 'Contents',
          type: 'select',
          options: [],
          helperText: false,
          rules: false,
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Layout Edit',
    prefix: '/content/carousel/edit/:id',
    // prefix: "/:country/:lang/drag_and_drop"
  },
  environment,
  setup,
}

export default config
