import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { selected } = props

  if (selected.length == 0) return null

  return (
    <div className={classes.chips}>
      {selected.map((value) => (
        <Chip key={value} label={value} className={classes.chip} />
      ))}
    </div>
  )
}

export default Index
