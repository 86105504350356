import React from 'react'

import UserListContainer from '../sub-container/user-list-container'
import UserDetailContainer from '../sub-container/user-detail-container'

const UserList = (props) => {
  return <UserListContainer {...props}>{props.children}</UserListContainer>
}
const UserDetail = (props) => {
  return <UserDetailContainer {...props}>{props.children}</UserDetailContainer>
}

export default { UserList, UserDetail }
