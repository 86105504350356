const isBlob = async response => {
  try {
    return await response.blob()
  } catch (e) {
    return false
  }
}

const isJson = async response => {
  try {
    return await response.json()
  } catch (e) {
    return false
  }
}

const useFetch = async ({ url, config }) => {
  try {
    let blob = false
    let json = false
    const response = await fetch(url, config)
    const { status } = response
    if (status === 200) blob = await isBlob(response)
    if (status !== 200) json = await isJson(response)
    if (!response.ok) throw [json, blob]
    return [true, { blob, json }]
  } catch (e) {
    console.log(e)
    return [false, e]
  }
}

export default useFetch
