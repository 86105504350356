import React from 'react'

import LayoutListContainer from '../sub-container/layout-list-container'
import LayoutCreateContainer from '../sub-container/layout-create-container'
import LayoutPreviewContainer from '../sub-container/layout-preview-container'
import LayoutSetupEditContainer from '../sub-container/layout-setup-edit-container'
import LayoutAddSetupContentContainer from '../sub-container/layout-add-setup-content-container'
import LayoutAddBannerContentContainer from '../sub-container/layout-add-setup-banner-container'

const LayoutList = (props) => {
  return <LayoutListContainer {...props}>{props.children}</LayoutListContainer>
}
const LayoutCreate = (props) => {
  return (
    <LayoutCreateContainer {...props}>{props.children}</LayoutCreateContainer>
  )
}
const LayoutPreview = (props) => {
  return (
    <LayoutPreviewContainer {...props}>{props.children}</LayoutPreviewContainer>
  )
}
const LayoutSetupEdit = (props) => {
  return (
    <LayoutSetupEditContainer {...props}>
      {props.children}
    </LayoutSetupEditContainer>
  )
}
const LayoutAddSetupContent = (props) => {
  return (
    <LayoutAddSetupContentContainer {...props}>
      {props.children}
    </LayoutAddSetupContentContainer>
  )
}
const LayoutAddBannerContent = (props) => {
  return (
    <LayoutAddBannerContentContainer {...props}>
      {props.children}
    </LayoutAddBannerContentContainer>
  )
}

export default {
  LayoutList,
  LayoutCreate,
  LayoutPreview,
  LayoutSetupEdit,
  LayoutAddSetupContent,
  LayoutAddBannerContent,
}
