import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Buttons, Inputs } from '../../lib-atomic'

import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'

import { GlobalContext } from '../../lib-atomic-context'

import CONFIG from '../config'

import SectionContentControls from '../../app-campaign-section-controls/default'
import Info from './info'

const useStyles = makeStyles((theme) => ({
  assets: {
    height: 'auto',
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { cognitouser, campaign, mastercampaignanswersheet } =
    useContext(GlobalContext)
  const {
    id,
    name,
    questions,
    mastercountries,
    masterlanguages,
    mastercontents,
  } = campaign.state.data

  const { homeImage } = mastercontents ? mastercontents : {}

  const imageData = {
    ...props,
    images: [{ name: 'Home Image', img: homeImage }],
    size: 'large',
  }
  const controlData = {
    ...props,
    user: cognitouser.state.data,
    item: campaign.state.data,
  }
  const infoData = {
    ...props,
    info: campaign.state.data,
    answerData: mastercampaignanswersheet.state.data,
    imageData,
  }

  return (
    <Fragment>
      <SectionContentControls {...controlData} />
      <div>
        <Info {...infoData} />
      </div>
    </Fragment>
  )
}

export default Index
