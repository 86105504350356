import React, { useContext, Fragment, useState, useEffect } from 'react'
import marked from 'marked'

/* package */
import { SectionWrappListItem } from '../../lib-atomic-section'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { WindowSize } from '../../lib-atomic-functions'
import { GlobalContext } from '../../lib-atomic-context'
/* package */

/* modules */
import CONFIG from '../config'

import SectionContentFilter from '../../app-filter-section-select-content/default'
import SectionContentImage from '../../app-content-section-image/default'

/* CONSTANTS */

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    height: 140,
  },
  upper: {
    textTransform: 'uppercase',
  },
  camel: {
    textTransform: 'capitalize',
  },
}))

const Index = (props) => {
  const { width, height } = WindowSize()
  const classes = useStyles()
  const { masternotes } = useContext(GlobalContext)

  const [rawContent, setRawContent] = useState([])
  const [filteredContent, setFilteredContent] = useState([])

  useEffect(() => {
    const masternoteslist = masternotes.state.data
    setRawContent(masternoteslist)
    setFilteredContent(
      masternoteslist.sort((a, b) => (a.color > b.color ? 1 : -1))
    )
  }, [])

  return (
    <Fragment>
      {/* <SectionContentFilter {...filterProps} /> */}
      {filteredContent.map((note, index) => {
        const {
          id,
          title,
          notes,
          other,
          type,
          created_at,
          updated_at,
          date,
        } = note
        const html = { __html: marked(notes, { sanitize: true }) }

        return (
          <>
            <div>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body2">
                <small>{date}</small>
              </Typography>
            </div>
            <div className="post">
              <span dangerouslySetInnerHTML={html} />
            </div>
          </>
        )
      })}
    </Fragment>
  )
}

export default Index
