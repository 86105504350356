import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'

import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'

import { GlobalContext } from '../../lib-atomic-context'

import CONFIG from '../config'

import SectionContentFilter from '../../app-filter-section-select-preview/default'
import SectionControls from '../../app-layout-section-controls/default'
import SectionBody from '../../app-layout-section-body/default'

import Info from './info'

const useStyles = makeStyles((theme) => ({
  assets: {
    height: 'auto',
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Index = (props) => {
  const [isPromotionView, setIsPromotionView] = useState(false)
  const [isPreLoginView, setIsPreLoginView] = useState(true)
  const [isMobView, setIsMobView] = useState(true)
  const [rawMobContent, setRawMobContent] = useState([])
  const [rawWebContent, setRawWebContent] = useState([])
  const [filteredContent, setFilteredContent] = useState([])
  const classes = useStyles()
  const { cognitouser, mastersetups, mastersetupspartner } = useContext(
    GlobalContext
  )

  const resetContextItems = async () => {
    cognitouser.dispatch({
      type: 'RESET',
    })
    mastersetups.dispatch({
      type: 'RESET',
    })
    mastersetupspartner.dispatch({
      type: 'RESET',
    })
  }
  useEffect(() => {
    const mastersetupsData = mastersetups.state.data[0]
    setRawMobContent(mastersetupsData.MobContents)
    setRawWebContent(mastersetupsData.WebContents)
    const x = mastersetupsData.MobContents.filter(
      (i) => i.mastercontents.contentVisibility === 'prelogin'
    )
    console.log(x)
    setFilteredContent(x)

    return () => resetContextItems()
  }, [])

  // data props
  const filterProps = {
    ...props,
    rawMobContent,
    rawWebContent,
    setFilteredContent,
    isMobView,
    setIsMobView,
    isPreLoginView,
    setIsPreLoginView,
    isPromotionView,
    setIsPromotionView,
  }
  const layoutBodyProps = {
    ...props,
    filteredContent,
    isPreLoginView,
    isPromotionView,
    isMobView,
    removeOpacity: isMobView ? true : false,
  }
  console.log(mastersetupspartner, 'mastersetupspartner')
  const controlData = {
    ...props,
    user: cognitouser.state.data,
    item: mastersetups.state.data[0],
    other: {
      partner: mastersetupspartner.state.data[0],
      isPreLoginView,
      isPromotionView,
      isMobView,
    },
  }
  const infoData = {
    ...props,
    info: mastersetups.state.data[0],
  }
  return (
    <Fragment>
      <SectionContentFilter {...filterProps} />
      <SectionControls {...controlData} />
      <div>
        <Info {...infoData} />
        <SectionBody {...layoutBodyProps} />
      </div>
    </Fragment>
  )
}

export default Index
