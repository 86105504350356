import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { LocalStorage, WindowSize } from '../../lib-atomic-functions'
import { Buttons, Inputs } from '../../lib-atomic'

import CONFIG from '../config'

/* constant */
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    marginTop: 50,
    width: '30%',
    minWidth: 200,
    maxWidth: 800,
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    margin: 'auto',
    textAlign: 'center',
    '&focus': {
      outline: 'none',
    },
  },
}))
/* constant */

const Index = (props) => {
  const classes = useStyles()

  const submitButton = {
    fullWidth: true,
    onClick: () => {
      props.history.push('/auth/logout')
    },
  }
  return (
    <div className={classes.modal}>
      <div className={classes.paper}>
        <Typography variant="h6">
          <b>Token has been expired. Please login again.</b>
        </Typography>
        <br />
        <Buttons.Normal {...submitButton}>Login</Buttons.Normal>
      </div>
    </div>
  )
}

export default Index
