import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'
/* package */
import { SectionWrappListItem } from '../../lib-atomic-section'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { WindowSize } from '../../lib-atomic-functions'
import { GlobalContext } from '../../lib-atomic-context'

import Chip from '@material-ui/core/Chip'

/* package */

/* modules */
import CONFIG from '../config'

import SectionContentFilterLayout from '../../app-filter-section-select-layout/default'
import SectionContentImage from '../../app-content-section-image/default'

/* CONSTANTS */

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    height: 170,
  },
  upper: {
    textTransform: 'uppercase',
  },
  camel: {
    textTransform: 'capitalize',
  },
  chip: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
    padding: theme.spacing(0.3),
  },
  development: {
    backgroundColor: '#fbc02d',
    color: '#fafafa',
  },
  production: {
    backgroundColor: 'red',
    color: '#fafafa',
  },
}))

const formatDate = (from, to) => {
  return {
    formattedFrom: moment(from).format('DD-MMM-YYYY  HH:mm:ss'),
    formattedTo: moment(to).format('DD-MMM-YYYY  HH:mm:ss'),
  }
}
const Index = (props) => {
  const { width, height } = WindowSize()
  const classes = useStyles()
  const { mastersetups } = useContext(GlobalContext)

  const resetContextItems = async () => {
    mastersetups.dispatch({
      type: 'RESET',
    })
  }
  const [rawContent, setRawContent] = useState([])
  const [filteredContent, setFilteredContent] = useState([])

  useEffect(() => {
    const mastersetupsData = mastersetups.state.data
    setRawContent(mastersetupsData)
    setFilteredContent(mastersetupsData)

    return () => resetContextItems()
  }, [])

  const handleToPreview = ({ id }) => {
    props.history.push('/layout/preview/' + id)
  }

  // data props
  const filterProps = {
    ...props,
    rawContent,
    filteredContent,
    setFilteredContent,
  }
  // data props

  return (
    <Fragment>
      <SectionContentFilterLayout {...filterProps} />
      {filteredContent.map((setup, index) => {
        console.log(setup)
        const {
          id,
          name,
          environment,
          contentVisibility,
          mastercountries,
          masterlanguages,
          mastersetupflag,
          homeImage,
          listImage,
          detailImage,
          PublishFrom,
          PublishTo,
        } = setup

        const { formattedFrom, formattedTo } = formatDate(
          PublishFrom,
          PublishTo
        )

        const imageData = {
          images: [homeImage],
          size: 'small',
        }

        return (
          <SectionWrappListItem
            key={index}
            noOfCol={width < 820 ? 12 : width < 1150 ? 6 : 3}
            customStyle={{
              cursor: 'pointer',
              overflow: 'auto',
              display: 'block',
            }}
            onClickFunc={() => handleToPreview({ id })}
          >
            <Typography variant="body2">
              <small className={classes.upper}>
                <b>{mastercountries.abbr}</b>
              </small>
              &nbsp; / &nbsp;
              <small className={classes.upper}>
                <b>{masterlanguages.abbr}</b>
              </small>
              &nbsp; / &nbsp;
              <small>
                <b>{mastersetupflag ? mastersetupflag.status : 'Draft'}</b>
              </small>
            </Typography>
            <div style={{ margin: 10 }}>
              <img
                style={{ width: '100%', height: 150 }}
                src={`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${mastercountries.abbr}.svg`}
                alt={mastercountries.abbr}
              />
            </div>
            <div className={classes.root}>
              <Typography
                variant="body2"
                style={{
                  justifyContent: 'center',
                  margin: 'auto',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <b>
                  #{id}&nbsp;{name}&nbsp;&nbsp;&nbsp;
                  <Chip
                    size="small"
                    label={environment}
                    className={
                      (classes.chip,
                      environment === 'production'
                        ? classes.production
                        : classes.development)
                    }
                  />
                </b>
              </Typography>
              <Typography variant="body2">
                <small>
                  <span>Publish From</span> <br />
                  <b>{formattedFrom}</b>
                </small>
                <br />
                <small>
                  <span>Publish To</span> <br />
                  <b>{formattedTo}</b>
                </small>
              </Typography>
            </div>
          </SectionWrappListItem>
        )
      })}
    </Fragment>
  )
}

export default Index
