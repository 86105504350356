import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTS = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTDETAILCONFIG = {
  url: CMSENDPOINTS.contentdetail.url,
  method: CMSENDPOINTS.contentdetail.method,
  headers: CMSENDPOINTS.contentdetail.headers,
  responseType: 'json',
}
const flagMapForContent = {
  live: {
    draft: 5,
    pending: 6,
    approved: 7,
    live: 8,
  },
  uat: {
    draft: 1,
    pending: 4,
    approved: 2,
    live: 3,
  },
}
const flagMapSelectedForContent =
  process.env.REACT_APP_BUILD_ENV === 'production'
    ? flagMapForContent.live
    : flagMapForContent.uat

const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { params } = props.match
  const { id } = params
  const {
    cognitouser,
    countrylanguage,
    contents,
    contentdetail,
    updatecontent,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await updatecontent.dispatch({
      type: 'RESET',
    })
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await contents.dispatch({
      type: 'RESET',
    })
    await contentdetail.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  /* --- cognitouser */
  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- cognitouser */
  const fetchCountryLanguagesExecute = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master contents */
  const fetchMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTS)
    contents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContents({ query }, contents)
    contents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master contents */
  const fetchMasterContentDetailExecute = async ({ query }) => {
    CMSMASTERCONTENTDETAILCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContentDetail] = FetchContextApiV2(
      CMSMASTERCONTENTDETAILCONFIG
    )
    contentdetail.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContentDetail({ query }, contentdetail)
    contentdetail.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    console.log('3', loading)
    if (loading) {
      const query = '?cognitoId=' + username
      fetchCognitoUserExecute({ query })
    }
  }, [loading])
  const initFunc = async () => {
    await fetchResetExec()
  }
  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      if (cognitouser.state.data.length > 0) {
        let query = '?'
        const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
        mastercountrylist.map((country, index) => {
          if (index === 0) {
            query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
          } else {
            query =
              query + '&mastercountries.abbr=' + country.mastercountry.abbr
          }
        })
        fetchCountryLanguagesExecute({ query })
      }
    }
  }, [cognitouser.state])

  useEffect(() => {
    if (
      loading &&
      countrylanguage.state.data &&
      countrylanguage.state.isStatus &&
      !countrylanguage.state.isLoading
    ) {
      const query = '/' + id
      fetchMasterContentDetailExecute({ query })
    }
  }, [countrylanguage.state])

  useEffect(() => {
    if (
      loading &&
      contentdetail.state.data &&
      contentdetail.state.isStatus &&
      !contentdetail.state.isLoading
    ) {
      const objFetched = contentdetail.state.data
      console.log(objFetched)
      if (objFetched.type !== 'Carousel') {
        props.history.push('/content/edit/' + id)
      } else {
        let query =
          '?_limit=1000&mastercontentsflag.id=' +
          flagMapSelectedForContent.draft +
          '&mastercontentsflag.id=' +
          flagMapSelectedForContent.pending +
          '&mastercontentsflag.id=' +
          flagMapSelectedForContent.approved +
          '&mastercountries.abbr=' +
          objFetched.mastercountries.abbr +
          '&masterlanguages.abbr=' +
          objFetched.masterlanguages.abbr

        if (objFetched.mastercountries.abbr == 'gb') {
          query = query + '&mastercountries.abbr=ni'
        }

        fetchMasterContentsExecute({ query })
      }
    }
  }, [contentdetail.state])
  // useEffect(() => {
  //   if (
  //     loading &&
  //     contents.state.data &&
  //     contents.state.isStatus &&
  //     !contents.state.isLoading
  //   ) {
  //     setLoading(false)
  //   }
  // }, [contents.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isCountryLangReady =
    countrylanguage.state.data &&
    countrylanguage.state.isStatus &&
    !countrylanguage.state.isLoading
      ? true
      : false
  const isContentsReady =
    contents.state.data && contents.state.isStatus && !contents.state.isLoading
      ? true
      : false
  const isContentDetailReady =
    contentdetail.state.data &&
    contentdetail.state.isStatus &&
    !contentdetail.state.isLoading
      ? true
      : false
  if (
    !loading ||
    !isCognitoUserReady ||
    !isCountryLangReady ||
    !isContentDetailReady ||
    !isContentsReady
  ) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
