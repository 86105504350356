import React, { useEffect, useContext } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}

const Index = (props) => {
  const { token, username } = LocalStorage.getLS().strapi
  const { cognitouser } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    cognitouser.dispatch({
      type: 'RESET',
    })
  }

  /* --- cognitouser */
  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- cognitouser */

  useEffect(() => {
    console.log(' RUN LIST...')
    const query = '?cognitoId=' + username
    fetchCognitoUserExecute({ query })
    return () => {
      fetchResetExec()
    }
  }, [])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false

  if (!isCognitoUserReady) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
