import React, { Fragment } from 'react'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
/* package */

/* modules */
import CONFIG from '../config'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    textAlign: 'center',
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { info } = props
  console.log(info)
  const {
    id,
    name,
    mastersetupflag,
    mastercountries,
    environment,
    PublishFrom,
    PublishTo,
  } = info

  return (
    <Fragment>
      <Typography variant="body2">
        <small className={classes.upper}>
          <b>{mastercountries.abbr}</b>
        </small>
        &nbsp; / &nbsp;
        <small className={classes.camel}>
          <b>{environment}</b>
        </small>
        &nbsp; / &nbsp;
        <small>
          <b>{mastersetupflag ? mastersetupflag.status : 'Draft'}</b>
        </small>
      </Typography>
      <Typography variant="h5">
        <b>#{id}</b>&nbsp;<b>{name}</b>
      </Typography>
      <br />
    </Fragment>
  )
}

export default Index
