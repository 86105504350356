import React, { useContext, useState, useEffect, Fragment } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
  FetchLoggerApi,
} from '../../lib-atomic-functions'

import { green } from '@material-ui/core/colors'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import ImageRender from '../image-picker/image-picker'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Grid from '@material-ui/core/Grid'

/* package */
import axios from 'axios'
import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'

import { SectionPaper } from '../../lib-atomic-section'
/* modules */
import CONFIG from '../config'

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

/* CONSTANTS */
const REMOVEICON = CONFIG.setup.assets.removeIcon
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects

const CMSMASTERTANDCCREATECONFIG = {
  url: CMSENDPOINTS.createtandc.url,
  method: CMSENDPOINTS.createtandc.method,
  headers: CMSENDPOINTS.createtandc.headers,
  responseType: 'json',
}
const CMSMASTERCampaignCREATECONFIG = {
  url: CMSENDPOINTS.createmastercampaign.url,
  method: CMSENDPOINTS.createmastercampaign.method,
  headers: CMSENDPOINTS.createmastercampaign.headers,
  responseType: 'json',
}
const CMSMASTERCampaignANSWERCREATECONFIG = {
  url: CMSENDPOINTS.createmastercampaignanswersheet.url,
  method: CMSENDPOINTS.createmastercampaignanswersheet.method,
  headers: CMSENDPOINTS.createmastercampaignanswersheet.headers,
  responseType: 'json',
}
/* CONSTANTS */
/* CONSTANTS */
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    paddingTop: '10px',
    paddingBottom: '10px',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const FormControlLabelDetails = withStyles((theme) => ({
  root: {
    left: '100px',
    // position: "relative"
  },
  paper: {
    // padding: theme.spacing(3),
    textAlign: 'center',
    color: 'black',
    marginBottom: '50px',
  },
  inline: {
    display: 'flex',
  },
}))(FormControlLabel)
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#098d42',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chip: {
    margin: 2,
    height: 37,
    borderRadius: 18.5,
    color: 'white',
    backgroundColor: '#42a5f5',
    cursor: 'auto',
    '&:hover': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
    '&:focus': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
  },
  chipClose: {
    color: 'white',
    '&:hover': {
      color: '#e6e6e6',
    },
    '&:focus': {
      color: '#e6e6e6',
    },
  },
}))

/* constant */
function onlyOne({ name, value }) {
  var checkboxes = document.getElementsByName(name)
  console.log(checkboxes, value)
  checkboxes.forEach((item) => {
    if (item.defaultValue !== value.toString()) item.checked = false
  })
}

const Index = (props) => {
  const { token, username } = LocalStorage.getLS().strapi
  const {
    isNotCarousel,
    setIsNotCarousel,
    detailVisible,
    toggleDetailVisibility,
  } = props
  const { width, height } = WindowSize()
  const [loading, setLoading] = useState(false)
  const [visibleHiddenField, setVisibleHiddenField] = useState(false)
  const [questionItems, setQuestionItems] = useState([])
  const [countryList, setCountryList] = useState([])
  const [langList, setLangList] = useState([])
  const [contentList, setContentList] = useState([])
  const [tandcList, setTandcList] = useState([])
  const [warningMessage, setWarningMessage] = useState(false)
  const [tAndCCheckBox, setTAndCCheckBox] = useState(false)
  const [tAndCCheckBox2, setTAndCCheckBox2] = useState(false)
  const [isOverWriteTrans, setIsOverWriteTrans] = useState(false)
  const [acceptVisible, setAcceptVisible] = useState(false)
  const [cancelVisible, setCancelVisible] = useState(false)
  const [isOverWriteTAndCTrans, setIsOverWriteTAndCTrans] = useState(false)
  const [isOverWriteSuccessTrans, setIsOverWriteSuccessTrans] = useState(false)

  const classes = useStyles()
  const {
    countrylanguage,
    cognitouser,
    country,
    createmastercampaign,
    contents,
    mastertandc,
    createmastercampaignanswersheet,
    campaignlist,
    createtandc,
  } = useContext(GlobalContext)
  const mastercountriesobj = country.state.data
  const mastercountrylanguageobj = countrylanguage.state.data

  /* --- cognitouser */
  const fetchCreateTandCExecute = async ({ body }) => {
    CMSMASTERTANDCCREATECONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSMASTERTANDCCREATECONFIG)
    const fetchResult = await fetchCall({ body }, createtandc)
    createtandc.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCreateCampaignExecute = async ({ query, body }) => {
    CMSMASTERCampaignCREATECONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSMASTERCampaignCREATECONFIG)
    const fetchResult = await fetchCall({ query, body }, createmastercampaign)
    createmastercampaign.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const resetCreateCampaignExecute = () => {
    createmastercampaign.dispatch({
      type: 'RESET',
    })
  }

  const fetchCreateCampaignAnswerExecute = async ({ query, body }) => {
    CMSMASTERCampaignANSWERCREATECONFIG.headers.Authorization =
      'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSMASTERCampaignANSWERCREATECONFIG)
    const fetchResult = await fetchCall(
      { query, body },
      createmastercampaignanswersheet
    )
    createmastercampaignanswersheet.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const resetCreateCampaignAnswerExecute = () => {
    createmastercampaignanswersheet.dispatch({
      type: 'RESET',
    })
  }

  useEffect(() => {
    const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
    const campaignlistobj = campaignlist.state.data
    const countryList = mastercountrylist
      .sort((a, b) => (a.mastercountry.name > b.mastercountry.name ? 1 : -1))
      // .filter(
      //   (item) =>
      //     !campaignlistobj.some(
      //       (pd) => pd.mastercountries.abbr === item.mastercountry.abbr
      //     )
      // )
      .map((item) => {
        return { name: item.mastercountry.name, value: item.mastercountry }
      })

    const langList1 = mastercountrylanguageobj
      // .filter(
      //   (item) =>
      //     !campaignlistobj.some(
      //       (pd) =>
      //         pd.mastercountries.abbr === item.mastercountries.abbr &&
      //         pd.masterlanguages.abbr === item.masterlanguages.abbr
      //     )
      // )
      .map((item) => {
        return { name: item.masterlanguages.name, value: item.masterlanguages }
      })

    const langList = mastercountrylanguageobj
      // .filter(
      //   (item) =>
      //     !campaignlistobj.some(
      //       (pd) =>
      //         pd.mastercountries.abbr === item.mastercountries.abbr &&
      //         pd.masterlanguages.abbr === item.masterlanguages.abbr
      //     )
      // )
      .map((item) => {
        return { name: item.masterlanguages.name, value: item.masterlanguages }
      })
      .filter((item, pos) => {
        return langList1.findIndex((t) => t.name === item.name) == pos
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))

    setCountryList(countryList)
    setLangList(langList)
  }, [])
  useEffect(() => {
    if (createmastercampaignanswersheet.state.data) {
      const createmastercampaignanswersheetobj =
        createmastercampaignanswersheet.state.data

      console.log('body2 ', createmastercampaignanswersheetobj)
      props.history.push('/campaign/list')
    }
  }, [createmastercampaignanswersheet.state])

  useEffect(() => {
    if (createmastercampaign.state.data) {
      console.log('aaaa   ', createmastercampaign.state.data)
      const createmastercampaignobj = createmastercampaign.state.data
      const values2 = Array.from(
        document.querySelectorAll('input[type=checkbox]:checked')
      ).map((item) => item.value)
      if (tAndCCheckBox) {
        values2.splice(0, 1)
      }
      if (tAndCCheckBox2) {
        values2.splice(0, 1)
      }
      if (isOverWriteTrans) {
        values2.splice(0, 1)
      }
      if (acceptVisible) {
        values2.splice(0, 1)
      }
      if (cancelVisible) {
        values2.splice(0, 1)
      }
      if (isOverWriteTAndCTrans) {
        values2.splice(0, 1)
      }
      if (isOverWriteSuccessTrans) {
        values2.splice(0, 1)
      }
      const body2 = {
        masterCampaignId: createmastercampaignobj.id,
        answers: createmastercampaignobj.questions.map((question, index) => {
          return {
            questionId: question.id,
            answer: questionItems[index].options[values2[index]].option,
          }
        }),
      }
      console.log('body2 ', body2)
      fetchCreateCampaignAnswerExecute({ body: body2 })
    }
  }, [createmastercampaign.state])
  const handleCreateCampaign = async () => {
    const tandcObj = createtandc.state.data
    console.log(tandcObj)
    const body = {
      name: values.name,
      mastercontents: values.mastercontents,
      mastercountries: values.mastercountries,
      masterlanguages: values.masterlanguages,
      publishFrom:
        values.publishfrom === null || values.publishfrom === ''
          ? null
          : moment(values.publishfrom),
      publishTo:
        values.publishto === null || values.publishto === ''
          ? null
          : moment(values.publishto),
      mastertandc: tandcObj.id,
      campaignType: values.campaigntype,
      campaignCode: values.campaigncode,
      questions: questionItems.map((question) => {
        question.options.map((option, index) => {
          option['option'] = (index + 1).toString()
        })
        return question
      }),
      json: {
        isOverWriteTranslation: isOverWriteTrans,
        isAcceptVisible: acceptVisible,
        acceptBtnText: !isOverWriteTrans ? '' : values.acceptText,
        isCancelVisible: cancelVisible,
        cancelBtnText: !isOverWriteTrans ? '' : values.cancelText,
        isOverWriteTAndCTranslation: isOverWriteTAndCTrans,
        custom_t_and_c_phrase_1: !isOverWriteTAndCTrans
          ? ''
          : values.tAndCPhrase1,
        custom_t_and_c_phrase_2: !isOverWriteTAndCTrans
          ? ''
          : values.tAndCPhrase2,
        custom_t_and_c_phrase_3: !isOverWriteTAndCTrans
          ? ''
          : values.tAndCPhrase3,
        isOverWriteSuccessTranslation: isOverWriteSuccessTrans,
        successMessage: !isOverWriteSuccessTrans
          ? ''
          : values.successMessageOverWrite,
      },
    }
    console.log('submitted', body, questionItems)
    await fetchCreateCampaignExecute({ body })
  }
  useEffect(() => {
    if (createtandc.state.data) {
      handleCreateCampaign()
    }
  }, [createtandc.state])

  const handleTandC = async () => {
    const body = {
      name: '',
      type: 'Prizedraw',
      mastercountries: values.mastercountries,
      masterlanguages: values.masterlanguages,
      json: {
        title: values.tandcTitle,
        content: values.tandcContent,
        haveTickBox: tAndCCheckBox,
        isLink: tAndCCheckBox2,
        url: values.tandcURL,
      },
    }
    console.log('submitted', body, questionItems)
    await fetchCreateTandCExecute({ body })
  }
  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true)

      const values2 = Array.from(
        document.querySelectorAll('input[type=checkbox]:checked')
      ).map((item) => item.value)
      if (tAndCCheckBox) {
        values2.splice(0, 1)
      }
      if (tAndCCheckBox2) {
        values2.splice(0, 1)
      }
      if (isOverWriteTrans) {
        values2.splice(0, 1)
      }
      if (acceptVisible) {
        values2.splice(0, 1)
      }
      if (cancelVisible) {
        values2.splice(0, 1)
      }
      if (isOverWriteTAndCTrans) {
        values2.splice(0, 1)
      }
      if (isOverWriteSuccessTrans) {
        values2.splice(0, 1)
      }
      console.log(values2)
      if (
        values.campaigntype === 'Choice Type' &&
        values2.length !== questionItems.length
      ) {
        setWarningMessage('Please provide an answer to all questions')
        setLoading(false)
      } else {
        setWarningMessage('')
        handleTandC()
      }
    }
  }
  // useEffect(() => {
  //   OBJECT1['mastercontents'].options = contentList
  //   OBJECT1['mastertandc'].options = tandcList
  // }, [contentList, tandcList])
  const prepareHiddenListOptions = () => {
    const mastercontentslist = contents.state.data
    const filteredContentList = mastercontentslist
      .map((item) => {
        return { name: item.name, value: item }
      })
      .filter((item, pos) => {
        console.log(item, values)
        return (
          values.mastercountries.abbr === item.value.mastercountries.abbr &&
          values.masterlanguages.abbr === item.value.masterlanguages.abbr
        )
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    setContentList(filteredContentList)
  }
  const {
    values,
    onChange,
    onCheck,
    onPick,
    onMultiDeselect,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleSubmit, OBJECT1)

  useEffect(() => {
    if (values.campaigntype !== 'ChoiceType') {
      setQuestionItems([])
    }
  }, [values.campaigntype])
  useEffect(() => {
    if (
      Object.getPrototypeOf(values.mastercountries) === Object.prototype &&
      Object.getPrototypeOf(values.masterlanguages) === Object.prototype
    ) {
      // console.log('both value has been assigned!')
      prepareHiddenListOptions()
      if (!visibleHiddenField) setVisibleHiddenField(true)
    } else {
      if (visibleHiddenField) setVisibleHiddenField(false)
    }
  }, [values.mastercountries, values.masterlanguages])

  const formObject = {}
  Object.keys(OBJECT1).map((fieldItemName) => {
    if (fieldItemName === 'mastercountries') {
      OBJECT1[fieldItemName].options = countryList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'masterlanguages') {
      OBJECT1[fieldItemName].options = langList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'mastercontents') {
      OBJECT1[fieldItemName].options = contentList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'campaigntype') {
      OBJECT1[fieldItemName].options = [
        { name: 'Choice Type', value: 'ChoiceType' },
        { name: 'Boolean Type', value: 'BooleanType' },
      ]
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (
      fieldItemName === 'publishfrom' ||
      fieldItemName === 'publishto'
    ) {
      formObject[fieldItemName] = {
        ...props,
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: moment(values[fieldItemName]).format('LL'),
        formatDisplay: 'dd MMM yyyy',
        onPick,
      }
    } else if (fieldItemName === 'tandcContent') {
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else {
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    }
  })
  const submitBtn = {
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }
  const toggleBtn = {
    style: { marginTop: 30, marginBottom: 40, float: 'right' },
    onClick: () => {
      toggleDetailVisibility()
    },
  }
  const handleChangeterms = () => {
    setTAndCCheckBox(!tAndCCheckBox)
  }
  const handleChangeterms2 = () => {
    setTAndCCheckBox2(!tAndCCheckBox2)
  }
  const handleChangeTrans = () => {
    setIsOverWriteTrans(!isOverWriteTrans)
  }
  const handleAcceptVisible = () => {
    setAcceptVisible(!acceptVisible)
  }
  const handleCancelVisible = () => {
    setCancelVisible(!cancelVisible)
  }
  const handleChangeTAndCTrans = () => {
    setIsOverWriteTAndCTrans(!isOverWriteTAndCTrans)
  }
  const handleChangeSuccessMessageTrans = () => {
    setIsOverWriteSuccessTrans(!isOverWriteSuccessTrans)
  }
  return (
    <form onSubmit={onSubmit} noValidate>
      <div
        style={{
          padding: 10,
          // cursor:
        }}
      >
        <Inputs.Text {...formObject.name} />
        <Inputs.Date {...formObject.publishfrom} />
        <Inputs.Date {...formObject.publishto} />
        <Inputs.Select {...formObject.mastercountries} />
        <Inputs.Select {...formObject.masterlanguages} />
        {visibleHiddenField && (
          <>
            <Inputs.Select {...formObject.mastercontents} />
            {/* <Inputs.Select {...formObject.mastertandc} /> */}
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={tAndCCheckBox}
                  onChange={handleChangeterms}
                />
              }
              label={
                <span style={{ display: 'flex' }}>
                  Show Terms and Conditions tick box?
                </span>
              }
            />
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={tAndCCheckBox2}
                  onChange={handleChangeterms2}
                />
              }
              label={
                <span style={{ display: 'flex' }}>
                  Terms and Conditions is URL link?
                </span>
              }
            />
            <Inputs.Text {...formObject.tandcURL} />
            <Inputs.Text {...formObject.tandcTitle} />
            <Inputs.Textarea
              rowsMax={8}
              rows={4}
              {...formObject.tandcContent}
            />
          </>
        )}
        <Inputs.Select {...formObject.campaigntype} />
        <Inputs.Text {...formObject.campaigncode} />

        <FormControlLabel
          control={
            <GreenCheckbox
              checked={acceptVisible}
              onChange={handleAcceptVisible}
            />
          }
          label={<span style={{ display: 'flex' }}>Accept Button Visible</span>}
        />
        <br />

        <FormControlLabel
          control={
            <GreenCheckbox
              checked={cancelVisible}
              onChange={handleCancelVisible}
            />
          }
          label={<span style={{ display: 'flex' }}>Cancel Button Visible</span>}
        />
        <br />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={isOverWriteTrans}
              onChange={handleChangeTrans}
            />
          }
          label={
            <span style={{ display: 'flex' }}>
              Overwrite default translation?
            </span>
          }
        />
        <br />
        {isOverWriteTrans && (
          <>
            <Inputs.Text {...formObject.acceptText} />

            <Inputs.Text {...formObject.cancelText} />
          </>
        )}

        <FormControlLabel
          control={
            <GreenCheckbox
              checked={isOverWriteTAndCTrans}
              onChange={handleChangeTAndCTrans}
            />
          }
          label={
            <span style={{ display: 'flex' }}>
              Overwrite default T & C print translation?
            </span>
          }
        />
        <br />
        {isOverWriteTAndCTrans && (
          <>
            <Inputs.Text {...formObject.tAndCPhrase1} />
            <Inputs.Text {...formObject.tAndCPhrase2} />
            <Inputs.Text {...formObject.tAndCPhrase3} />
          </>
        )}

        <FormControlLabel
          control={
            <GreenCheckbox
              checked={isOverWriteSuccessTrans}
              onChange={handleChangeSuccessMessageTrans}
            />
          }
          label={
            <span style={{ display: 'flex' }}>
              Overwrite default success message translation?
            </span>
          }
        />
        <br />
        {isOverWriteSuccessTrans && (
          <>
            <Inputs.Text {...formObject.successMessageOverWrite} />
          </>
        )}
        {values.campaigntype === 'ChoiceType' && (
          <Grid
            container
            direction="row"
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            style={{ marginTop: 20 }}
          >
            <Grid item>Questions</Grid>
            <Grid item>
              <Buttons.Normal
                key={0}
                style={{ marginLeft: 10 }}
                onClick={(e) => {
                  e.preventDefault()
                  setQuestionItems([
                    ...questionItems,
                    {
                      question: '',
                      options: [
                        {
                          option: '',
                          optionText: '',
                        },
                      ],
                    },
                  ])
                }}
              >
                Add more question +
              </Buttons.Normal>
            </Grid>
          </Grid>
        )}
        <br />
        {questionItems.map((question, index) => {
          return (
            <div style={{ margin: 10, marginTop: 15, marginBottom: 25 }}>
              <SectionPaper>
                <div style={{ position: 'relative' }}>
                  {index !== 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        top: -25,
                        right: -25,
                        width: 25,
                        height: 25,
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        questionItems.splice(index, 1)
                        setQuestionItems([...questionItems])
                      }}
                    >
                      <img src={REMOVEICON} />
                    </div>
                  )}
                  {/* <label>question</label> */}
                  {/* <br /> */}
                  <Inputs.Text
                    {...{
                      name: 'question' + index,
                      initial: '',
                      label: 'Question',
                      helperText: false,
                      rules: ['isNotNull'],
                      error: null,
                      value: question['question'],
                      onChange: (e) => {
                        question['question'] = e.target.value
                        setQuestionItems([...questionItems])
                      },
                    }}
                  />
                  {/* <input
                        onChange={(e) =>
                          (question['question'] = e.target.value)
                        }
                      /> */}
                  <br />
                  <br />
                  <label>options </label>
                  <br />
                  <div style={{ padding: 5 }}>
                    {questionItems[index].options.map((option, indexinner) => {
                      if (indexinner === question.options.length - 1) {
                        return (
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                alignSelf: 'center',
                              }}
                            >
                              <input
                                id={index + indexinner}
                                style={{
                                  justifyContent: 'center',
                                  alignSelf: 'center',
                                  marginRight: 10,
                                }}
                                type="checkbox"
                                value={indexinner}
                                onClick={(e) =>
                                  onlyOne({
                                    name: 'option[' + index + '][]',
                                    value: indexinner,
                                  })
                                }
                                name={'option[' + index + '][]'}
                              />
                              <Inputs.Text
                                {...{
                                  name: 'optionText' + index + indexinner,
                                  initial: '',
                                  label: 'Value',
                                  helperText: false,
                                  rules: ['isNotNull'],
                                  error: null,
                                  value:
                                    questionItems[index].options[indexinner][
                                      'optionText'
                                    ],
                                  onChange: (e) => {
                                    questionItems[index].options[indexinner][
                                      'optionText'
                                    ] = e.target.value
                                    setQuestionItems([...questionItems])
                                  },
                                }}
                              />

                              <Buttons.Normal
                                key={index}
                                disabled={indexinner === 0}
                                style={{ marginLeft: 10 }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  questionItems[index].options.splice(
                                    indexinner,
                                    1
                                  )
                                  setQuestionItems([...questionItems])
                                }}
                              >
                                —
                              </Buttons.Normal>
                            </div>

                            <br />
                            <Fragment style={{ marginLeft: 10 }}>
                              <Buttons.Normal
                                key={index}
                                onClick={(e) => {
                                  e.preventDefault()
                                  questionItems[index].options = [
                                    ...questionItems[index].options,
                                    {
                                      option: '',
                                      optionText: '',
                                    },
                                  ]
                                  setQuestionItems([...questionItems])
                                }}
                              >
                                Add more option +
                              </Buttons.Normal>
                              &nbsp;
                            </Fragment>
                            <br />
                          </div>
                        )
                      } else {
                        return (
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                alignSelf: 'center',
                              }}
                            >
                              <input
                                id={index + indexinner}
                                style={{
                                  justifyContent: 'center',
                                  alignSelf: 'center',
                                  marginRight: 10,
                                }}
                                type="checkbox"
                                value={indexinner}
                                onClick={(e) =>
                                  onlyOne({
                                    name: 'option[' + index + '][]',
                                    value: indexinner,
                                  })
                                }
                                name={'option[' + index + '][]'}
                              />
                              <Inputs.Text
                                {...{
                                  name: 'optionText' + index + indexinner,
                                  initial: '',
                                  label: 'Value',
                                  helperText: false,
                                  rules: ['isNotNull'],
                                  error: null,
                                  value:
                                    questionItems[index].options[indexinner][
                                      'optionText'
                                    ],
                                  onChange: (e) => {
                                    questionItems[index].options[indexinner][
                                      'optionText'
                                    ] = e.target.value
                                    setQuestionItems([...questionItems])
                                  },
                                }}
                              />
                              <Buttons.Normal
                                key={index}
                                disabled={indexinner === 0}
                                style={{ marginLeft: 10 }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  questionItems[index].options.splice(
                                    indexinner,
                                    1
                                  )
                                  setQuestionItems([...questionItems])
                                }}
                              >
                                —
                              </Buttons.Normal>
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </SectionPaper>
            </div>
          )
        })}
      </div>
      <div style={{ height: 50 }} />
      <Spinner.Loader color="#fff" size={24} loading={loading} />

      <div style={{ marginTop: 20, marginBottom: 35, color: '#ef9a9a' }}>
        {warningMessage}
      </div>
      <Buttons.Normal {...submitBtn}>CREATE</Buttons.Normal>
    </form>
  )
}

export default Index
