// import { useState, useEffect } from 'react'

/* packages */
// import { WindowSize } from '../../lib-atomic-functions'
/* packages */
const windowFont = () => {
  const plusSize = width => {
    if (width >= 900) {
      // console.log('+3')
      return 9
    } else if (width >= 700) {
      // console.log('+2')
      return 6
    } else {
      return 2
    }
  }

  const getFontSizes = (colsWidth, width) => {
    // console.log(width);

    const plus = plusSize(width)
    const titleFontSize =
      colsWidth.toString() === '3'
        ? 32 + plus
        : colsWidth.toString() === '2'
        ? 22 + plus
        : 17 + plus
    const descriptionFontSize =
      colsWidth.toString() === '3'
        ? 23 + plus
        : colsWidth.toString() === '2'
        ? 14 + plus
        : 11 + plus
    return { titleFontSize, descriptionFontSize }
  }

  const layout = {
    getFontSizes,
  }

  return layout
}

export default windowFont
