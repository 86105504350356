import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const env = process.env.NODE_ENV
const config = {
  details: {
    env,
  },
  application: {
    api: {
      endpoints: {
        auth: {
          headers: {
            'Content-Type': 'application/json',
          },
          url: setup.setup.api.cms.url[env] + '/mastercountries',
          // url: setup.setup.api.cms.url[env] + '/auth/local',
          method: 'GET',
        },
      },
    },
  },
  routes: {
    label: false,
    prefix: false,
  },
  env,
  setup,
}

export default config
