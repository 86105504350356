import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTS = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { cognitouser, contents } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await contents.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  /* --- cognitouser */
  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- cognitouser */

  /* --- master contents */
  const fetchMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTS)
    contents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContents({ query }, contents)
    contents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      const query = '?cognitoId=' + username
      fetchCognitoUserExecute({ query })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      let query = '?_limit=500&_sort=updated_at:DESC&'
      // let query = '?_limit=500&'
      const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
      mastercountrylist.map((country, index) => {
        if (index === 0) {
          query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
        } else {
          query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
        }
      })
      fetchMasterContentsExecute({ query })
    }
  }, [cognitouser.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isContentsReady =
    contents.state.data && contents.state.isStatus && !contents.state.isLoading
      ? true
      : false
  if (!loading || !isCognitoUserReady || !isContentsReady) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
