import React, { useState } from 'react'

/* mui */
import Popover from '@material-ui/core/Popover'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { ModuleText } from '../../lib-atomic-section'
/* mui */

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import CONFIG from '../config'

/* CONSTANTS */
const MEDIAPATH = CONFIG.setup.assets.media
/* CONSTANTS */
var Format1 = {
  container: {
    height: '100%',
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: 'transparent',
  },
  title: {
    flex: '0 1 auto',
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
    paddingBottom: 10,
  },
  description: {
    flex: '0 1 auto',
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
    paddingBottom: 10,
  },
}
var Format2 = {
  container: {
    height: '100%',
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: 'transparent',
  },
  title: {
    flex: '0 1 auto',
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
    paddingBottom: 10,
  },
  description: {
    flex: '0 1 auto',
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
    paddingBottom: 0,
  },
}
var Format3 = {
  container: {
    height: '100%',
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignContent: 'stretch',
    alignItems: 'stretch',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: 'transparent',
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
    paddingBottom: 10,
    //  order: "0",
    flex: '0 1 auto',
    alignSelf: 'center',
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
    //  order: "0",
    flex: '0 1 auto',
    alignSelf: 'center',
    textAlign: 'center',
  },
}
var Format4 = {
  container: {
    height: '100%',
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'stretch',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: 'transparent',
  },
  title: {
    flex: '0 1 auto',
    position: 'absolute',
    top: 20,
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
  },
  description: {
    flex: '0 1 auto',
    position: 'absolute',
    bottom: 20,
    justifyContent: 'flex-end',
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
  },
}
const fetchFormat = (formatName) => {
  if (formatName === 'Format1') {
    return Format1
  } else if (formatName === 'Format2') {
    return Format2
  } else if (formatName === 'Format3') {
    return Format3
  } else {
    return Format4
  }
}
/* CONSTANTS */
const useStyles = ({ formatt }) => {
  const formatFetched = fetchFormat(Object.keys(formatt)[0])
  formatFetched.title.fontSize = Object.values(formatt)[0].titleFontSize
  formatFetched.description.fontSize =
    Object.values(formatt)[0].descriptionFontSize
  return makeStyles((theme) => formatFetched)()
}
/* CONSTANTS */

const isBetweenTwoDate = (theDate, PublishFrom, PublishTo) => {
  console.log(theDate)
  const today = theDate ? theDate : new Date()
  const noFromDate = PublishFrom === null || PublishFrom === ''
  const noToDate = PublishTo === null || PublishTo === ''
  const publishFrom = noFromDate
    ? ' '
    : moment(PublishFrom).format('YYYY MM DD')
  const publishTo = noToDate ? ' ' : moment(PublishTo).format('YYYY MM DD')
  const isBetweenFromTo =
    publishFrom === ' ' && publishTo === ' '
      ? true
      : publishFrom === ' ' && publishTo !== ' '
      ? moment(today).isBefore(moment(publishTo).format('YYYY MM DD'))
      : publishFrom !== ' ' && publishTo === ' '
      ? moment(today).isAfter(moment(publishFrom).format('YYYY MM DD'))
      : moment(today).isBetween(
          moment(publishFrom).format('YYYY MM DD'),
          moment(publishTo).format('YYYY MM DD')
        )
  return { publishFrom, publishTo, isBetweenFromTo }
}
const Index = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { country, lang } = props.match.params
  const { layout, hideDate, theDate, mastercontents, removeOpacity } = props
  const { CarouselContents, colsHeight } = mastercontents
  const { type } = mastercontents

  const { publishFrom, publishTo, isBetweenFromTo } = isBetweenTwoDate(
    theDate,
    mastercontents.PublishFrom,
    mastercontents.PublishTo
  )
  // once format field added we can replace it
  var format = fetchFormat(mastercontents.contentFormat)

  format.title = { ...format.title, fontSize: mastercontents.titleFontSize }
  format.description = {
    ...format.description,
    fontSize: mastercontents.descriptionFontSize,
  }

  const formatt = {}
  formatt[mastercontents.contentFormat] = {
    titleFontSize: mastercontents.titleFontSize,
    descriptionFontSize: mastercontents.descriptionFontSize,
  }
  const classes = useStyles({ formatt })

  // const image = mastercontents.image
  //   ? "url(" + MEDIAPATH + mastercontents.image.url + ")"
  //   : "";

  switch (type) {
    case 'News': {
      // console.log("Excellent");
      break
    }
    case 'Promotions': {
      // console.log("Good");
      break
    }
    default: {
      // console.log("Invalid choice");
      break
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div>
      {/* detail of item */}
      {hideDate ? null : (
        <div>
          <div
            onClick={handleClick}
            style={{
              cursor: 'pointer',
              zIndex: 99,
              backgroundColor: '#098d42',
              width: 30,
              height: 30,
              borderRadius: 10,
              position: 'absolute',
              top: 10,
              left: 10,
              padding: 5,
            }}
          >
            <FindInPageIcon
              style={{
                color: '#ffffff',
                paddingRight: 9,
                paddingBottom: 9,
                fontSize: 30,
              }}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {/* setup id */}
            <div
              style={{
                padding: 10,
                height: 'auto',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Setup Content ID:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">{layout.id}</ModuleText>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: 'black',
                    margin: 'auto',
                    marginTop: 10,
                    marginBottom: -10,
                  }}
                ></div>
              </div>
            </div>
            {/* setup id */}
            {/* parent detail */}
            <div
              style={{
                padding: 10,
                height: 'auto',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Parent Carousel ID:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">
                  <span
                    style={{
                      color: '#098d42',
                      cursor: 'pointer',
                      fontWeight: 700,
                    }}
                    onClick={() => {
                      window.open(
                        '/content/detail/' + mastercontents.id,
                        '_blank'
                      )
                      // props.history.push(
                      //   '/content/detail/' + mastercontents.id,
                      //   '_blank'
                      // )
                    }}
                  >
                    {mastercontents.id}
                  </span>
                </ModuleText>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Name:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">{mastercontents.name}</ModuleText>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Content Expire:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">
                  {publishFrom === ' ' && publishTo === ' '
                    ? 'No Expiration'
                    : publishFrom + ' ~ ' + publishTo}
                </ModuleText>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: 'black',
                    margin: 'auto',
                    marginTop: 10,
                    marginBottom: -10,
                  }}
                ></div>
              </div>
            </div>
            {/* parent detail */}

            {CarouselContents.map((carousel, index2) => {
              console.log(carousel)
              const { type, contentFormat, homeImage, PublishFrom, PublishTo } =
                carousel.mastercontents
              const typeUrl =
                type.toLowerCase() === 'promotions'
                  ? 'offers'
                  : type.toLowerCase()
              const imageItem = homeImage
                ? 'url(' + MEDIAPATH + homeImage.url + ')'
                : ''

              const { publishFrom, publishTo, isBetweenFromTo } =
                isBetweenTwoDate(theDate, PublishFrom, PublishTo)
              return (
                <div
                  style={{
                    padding: 10,
                    marginLeft: 25,
                    height: 'auto',
                    textAlign: 'left',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      margin: 'auto',
                    }}
                  >
                    <ModuleText fontWeight={700}>
                      Mastercontents ID:&nbsp;&nbsp;&nbsp;&nbsp;
                    </ModuleText>
                    <ModuleText variant="caption">
                      <span
                        style={{
                          color: '#098d42',
                          cursor: 'pointer',
                          fontWeight: 700,
                        }}
                        onClick={() => {
                          props.history.push(
                            '/content/detail/' + carousel.mastercontents.id
                          )
                        }}
                      >
                        {carousel.mastercontents.id}
                      </span>
                    </ModuleText>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      margin: 'auto',
                    }}
                  >
                    <ModuleText fontWeight={700}>
                      Name:&nbsp;&nbsp;&nbsp;&nbsp;
                    </ModuleText>
                    <ModuleText variant="caption">
                      {carousel.mastercontents.name}
                    </ModuleText>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      margin: 'auto',
                    }}
                  >
                    <ModuleText fontWeight={700}>
                      Content Expire:&nbsp;&nbsp;&nbsp;&nbsp;
                    </ModuleText>
                    <ModuleText variant="caption">
                      {publishFrom === ' ' && publishTo === ' '
                        ? 'No Expiration'
                        : publishFrom + ' ~ ' + publishTo}
                    </ModuleText>
                  </div>

                  {layout.mastercontents.CarouselContents.length - 1 >
                  index2 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 1,
                          backgroundColor: 'black',
                          margin: 'auto',
                          marginTop: 10,
                          marginBottom: -10,
                        }}
                      ></div>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </Popover>
        </div>
      )}
      <Carousel
        className="abc"
        showStatus={false}
        showThumbs={false}
        width={'100%'}
        infiniteLoop={true}
      >
        {CarouselContents.map((carouselitem) => {
          console.log('carousel : ', carouselitem)
          const { type, contentFormat, homeImage, PublishFrom, PublishTo } =
            carouselitem.mastercontents
          const typeUrl =
            type.toLowerCase() === 'promotions' ? 'offers' : type.toLowerCase()
          const imageItem = homeImage ? homeImage.url : ''

          const { publishFrom, publishTo, isBetweenFromTo } = isBetweenTwoDate(
            theDate,
            PublishFrom,
            PublishTo
          )
          return (
            <div
              style={{
                position: 'relative',
                flex: '1 1 0%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                alignContent: 'stretch',
                alignItems: 'stretch',
                height: colsHeight - 5,

                // background: hideDate
                //   ? 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                //   : isBetweenFromTo
                //   ? 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                //   : 'linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: removeOpacity ? 0 : isBetweenFromTo ? 1 : 0.5,
                  backgroundColor: isBetweenFromTo ? 'transparent' : 'white',
                }}
              />
              <img
                src={imageItem}
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
              {hideDate ? null : (
                <div
                  style={{
                    zIndex: 99,
                    backgroundColor: '#098d42',
                    width: 'auto',
                    height: 'auto',
                    borderRadius: 10,
                    position: 'absolute',
                    bottom: 10,
                    left: 10,
                  }}
                >
                  {publishFrom === ' ' && publishTo === ' ' ? (
                    <p style={{ color: '#ffffff', margin: 6 }}>No Expiration</p>
                  ) : (
                    <p style={{ color: '#ffffff', margin: 6 }}>
                      {publishFrom} ~ {publishTo}
                    </p>
                  )}
                </div>
              )}
              {/* {contentFormat === "Format0" ? null : (
                <div>
                  <div className={classes.title}>{carousel.Title}</div>
                  <div className={classes.description}>
                    {carousel.Description}
                  </div>
                </div>
              )} */}
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

export default Index
