import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import GlobalContainer from '../../components/app-global-container/default'
import Header from '../../components/app-global-header/default'
import Body from './body'
// import Footer from '../../components/app-global-footer/default'
// import FooterSticky from '../../components/app-global-sticky-footer/default'
import appConf from '../../components/app-conf'

import Single from '../single'

import { GlobalProvider } from '../../components/lib-atomic-context'

const Themes = appConf[process.env.REACT_APP_CLIENT].themes

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(10),
  },
}))

const Main = (props) => {
  const classes = useStyles()

  /* start: if module a single independent page */
  const { module } = props.match.params
  if (module === 'auth') return <Single {...props} />
  /* end: if module a single independent page */

  return (
    <Themes>
      <GlobalProvider>
        <GlobalContainer {...props}>
          <div className={classes.root}>
            <Header {...props} />
            <main className={classes.content}>
              <Body {...props} />
            </main>
            {/* <FooterSticky {...props} /> */}
          </div>
        </GlobalContainer>
      </GlobalProvider>
    </Themes>
  )
}

export default Main
