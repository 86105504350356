import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Change Password'
const server = null
const service = null

const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: false,
      objects: false,
    },
  },
  routes: {
    label: 'Edit Content Page',
    prefix: '/layout/preview/:id',
  },
  environment,
  setup,
}

export default config
