import React, { useContext, Fragment, useState, useEffect } from 'react'

import { Buttons } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  LocalStorage,
  FetchContextApiV2,
  FetchLoggerApi,
} from '../../lib-atomic-functions'

import CheckerPopup from '../../app-popup-section-confirm/default'

import CONFIG from '../config'

const CMSENDPOINTS = CONFIG.application.cms.api.endpoints

const CMSDELETECampaignCONFIG = {
  url: CMSENDPOINTS.deletecampaign.url,
  method: CMSENDPOINTS.deletecampaign.method,
  headers: CMSENDPOINTS.deletecampaign.headers,
  responseType: 'json',
}
const CMSDELETECampaignANSWERCONFIG = {
  url: CMSENDPOINTS.deletecampaignanswer.url,
  method: CMSENDPOINTS.deletecampaignanswer.method,
  headers: CMSENDPOINTS.deletecampaignanswer.headers,
  responseType: 'json',
}

const Index = (props) => {
  const { token, username } = LocalStorage.getLS().strapi
  const { user, item } = props
  const { id, mastercontentsflag } = item

  const {
    deletecampaign,
    mastercampaignanswersheet,
    deletecampaignanswer,
  } = useContext(GlobalContext)
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [deleteExec, setDeleteExec] = useState(false)
  console.log(mastercampaignanswersheet)
  const deleteCampaignExecute = async () => {
    const oldData = deletecampaign.state.data
    CMSDELETECampaignCONFIG.headers.Authorization = 'Bearer ' + token
    const [deleteCampaign] = FetchContextApiV2(CMSDELETECampaignCONFIG)
    const fetchResult = await deleteCampaign(
      { query: '/' + id },
      deletecampaign
    )
    const campaignanswerid = mastercampaignanswersheet.state.data[0].id
    CMSDELETECampaignANSWERCONFIG.headers.Authorization = 'Bearer ' + token
    const [deleteCampaignAnswer] = FetchContextApiV2(
      CMSDELETECampaignANSWERCONFIG
    )
    const fetchResult2 = await deleteCampaignAnswer(
      { query: '/' + campaignanswerid },
      deletecampaignanswer
    )
    props.history.push('/campaign/list')

    /* -------------- */
    /* start: logger */
    await FetchLoggerApi({
      username,
      api: CMSDELETECampaignCONFIG,
      page: 'campaign-delete',
      oldData: oldData,
      newData: null,
    })
    /* end: logger */
    /* -------------- */
  }

  const handleDelete = ({ id }) => {
    setVisiblePopup(!visiblePopup)
  }

  const isDraft =
    !mastercontentsflag || mastercontentsflag.status === 'draft' ? true : false

  const items = [
    {
      user: ['mastercreator', 'creator+approver+publisher'],
      action: handleDelete,
      label: 'Delete',
      status: true,
    },
  ]

  const checkItems = ({ status, user }) => {
    return items.filter(
      (item) => item.status === status && item.user.indexOf(user) > -1
    )
  }

  const menus = checkItems({ status: isDraft, user: user[0].user.username })
  const popupData = {
    ...props,
    toggle: visiblePopup,
    setToggle: setVisiblePopup,
    action: deleteCampaignExecute,
  }
  return (
    <Fragment>
      {menus.map((menu, index) => {
        return (
          <Fragment>
            <Buttons.Normal key={index} onClick={() => menu.action({ id })}>
              {menu.label}
            </Buttons.Normal>
            &nbsp;
          </Fragment>
        )
      })}
      <CheckerPopup {...popupData} />
    </Fragment>
  )
}

export default Index
