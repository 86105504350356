import pageBeta from '../../../app-beta-components'
import pageRegistration from '../../../app-auth-page-registration'
import pageLogin from '../../../app-auth-page-login'
import pageSessionExpired from '../../../app-section-token-expire-landing'
import pageLogout from '../../../app-auth-page-logout'

import pageUserList from '../../../app-user-page-list'
import pageUserDetail from '../../../app-user-page-detail'

import contentList from '../../../app-content-page-list'
import contentDetail from '../../../app-content-page-detail'
import contentCreate from '../../../app-content-page-create'
import contentEdit from '../../../app-content-page-edit'
import contentCarouselEdit from '../../../app-content-page-carousel-edit'

import userProfile from '../../../app-user-profile'

import layoutList from '../../../app-layout-page-list'
import layoutCreate from '../../../app-layout-page-create'
import layoutPreview from '../../../app-layout-page-preview'
import layoutAddSetupContent from '../../../app-layout-page-add-setup-content'
import layoutAddBannerContent from '../../../app-layout-page-add-banner-content'
import layoutEditSetup from '../../../app-layout-page-edit-setup'

/* auth: start */
const moduleAuth = [
  pageRegistration,
  pageLogin,
  pageLogout,
  pageSessionExpired,
  pageBeta,
]
/* auth: end */

/* user manage: start */
const moduleUser = [pageUserList, pageUserDetail]
/* user manage: end */

const moduleContent = [
  contentList,
  contentDetail,
  // contentCreate,
  // contentEdit,
  // contentCarouselEdit,
]

const moduleUserProfile = [userProfile]

const moduleLayout = [
  layoutList,
  // layoutCreate,
  layoutPreview,
  // layoutAddSetupContent,
  // layoutAddBannerContent,
  // layoutEditSetup,
]

const modules = {
  auth: moduleAuth,
  // user: moduleUser,
  content: moduleContent,
  userprofile: moduleUserProfile,
  layout: moduleLayout,
}

export default modules
