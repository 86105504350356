import React, { Fragment, useState } from 'react'
import moment from 'moment'
/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons } from '../../lib-atomic'
/* package */
import SectionContentImage from '../../app-content-section-image/default'
import SectionContentImageCarousel from '../../app-content-section-image-carousel/default'

/* modules */
import CONFIG from '../config'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    textAlign: 'center',
  },
}))
const formatDate = (from, to) => {
  console.log(from, to)
  return {
    formattedFrom: moment(from).format('DD-MMM-YYYY  HH:mm:ss'),
    formattedTo: moment(to).format('DD-MMM-YYYY  HH:mm:ss'),
  }
}
const Index = (props) => {
  const classes = useStyles()
  const { info, carouselData, imageData } = props
  const {
    id,
    title,
    description,
    name,
    type,
    contentVisibility,
    mastercountries,
    mastercontentsflag,
    PublishFrom,
    PublishTo,

    Code,
    contentFormat,
    externalLink,
    externalLinkAndroid,
    externalLinkiOS,
    buttonVisibility,
    ButtonTitle,
    buttonDestination,
    buttonDestinationInApp,
    termsAndConditionsVisibility,
    termsAndConditionsLink,
    expandedTermsAndConditionsVisibility,
    expandedTermsAndConditionsCopy,
  } = info
  const { formattedFrom, formattedTo } = formatDate(PublishFrom, PublishTo)
  const isCarouselContent = type === 'Carousel'
  const [showDetails, setShowDetails] = useState(false)

  console.log(info)

  const toggleBtn = {
    style: { marginTop: 30, marginBottom: 40, float: 'right' },
    onClick: () => {
      setShowDetails(!showDetails)
    },
  }
  return (
    <Fragment>
      <Typography variant="body2">
        <small className={classes.upper}>
          <b>{mastercountries.abbr}</b>
        </small>
        &nbsp; / &nbsp;
        <small className={classes.camel}>
          <b>{type}</b>
        </small>
        &nbsp; / &nbsp;
        <small className={classes.camel}>
          <b>{contentVisibility}</b>
        </small>
        &nbsp; / &nbsp;
        <small>
          <b>{mastercontentsflag ? mastercontentsflag.status : 'Draft'}</b>
        </small>
      </Typography>
      <Typography variant="h5">
        <b>#{id}</b>&nbsp;<b>{name}</b>
      </Typography>
      <SectionContentImageCarousel {...carouselData} />
      <SectionContentImage {...{ ...imageData, isDetail: true }} />
      <br />
      <Typography variant="h6">
        <p style={{ fontFamily: 'SubwayFootLong' }}>{title}</p>
      </Typography>
      <br />
      <Typography variant="h8">
        <p style={{ fontFamily: 'SubwaySixInch', color: 'black' }}>
          {description}
        </p>
      </Typography>
      <br />
      <br />
      <Typography variant="body2">
        <small className={classes.upper}>
          <span>Publish From</span> <br />
          <b>{formattedFrom}</b>
        </small>
        <br />
        <small className={classes.upper}>
          <span>Publish To</span> <br />
          <b>{formattedTo}</b>
        </small>
      </Typography>
      <br />
      {!isCarouselContent && (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            margin: 'auto',
            height: 70,
          }}
        >
          <Buttons.Text {...toggleBtn}>
            {!showDetails
              ? 'Show Detailed Functionality'
              : 'Hide Detailed Functionality'}
          </Buttons.Text>
        </div>
      )}
      <br />
      <br />
      {showDetails && !isCarouselContent && (
        <Fragment>
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Code</span> <br />
              <b>{Code === '' ? 'N/A' : Code}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>ContentFormat</span> <br />
              <b>{contentFormat === '' ? 'N/A' : contentFormat}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>External Link</span> <br />
              <b>{externalLink === '' ? 'N/A' : externalLink}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>External Link For Android</span> <br />
              <b>{externalLinkAndroid === '' ? 'N/A' : externalLinkAndroid}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>External Link For IOS</span> <br />
              <b>{externalLinkiOS === '' ? 'N/A' : externalLinkiOS}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Button Visible</span> <br />
              <b>{buttonVisibility ? 'YES' : 'NO'}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Button Title</span> <br />
              <b>{ButtonTitle === '' ? 'N/A' : ButtonTitle}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Button Destination</span> <br />
              <b>{buttonDestination === '' ? 'N/A' : buttonDestination}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Button Destination In Mobile App</span> <br />
              <b>
                {buttonDestinationInApp === '' ? 'N/A' : buttonDestinationInApp}
              </b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Terms And Conditions Visibility</span> <br />
              <b>{termsAndConditionsVisibility ? 'YES' : 'NO'}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Terms And Conditions Link</span> <br />
              <b>
                {termsAndConditionsLink === '' ? 'N/A' : termsAndConditionsLink}
              </b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Expanded Terms And Conditions Visibility</span> <br />
              <b>{expandedTermsAndConditionsVisibility ? 'YES' : 'NO'}</b>
            </small>
          </Typography>
          <br />
          <Typography variant="body2">
            <small className={classes.upper}>
              <span>Expanded Terms And Conditions Copy</span> <br />
              <b>
                {expandedTermsAndConditionsCopy === ''
                  ? 'N/A'
                  : expandedTermsAndConditionsCopy}
              </b>
            </small>
          </Typography>
          <br />
          <SectionContentImage {...{ ...imageData, isDetail: false }} />
        </Fragment>
      )}
    </Fragment>
  )
}

export default Index
