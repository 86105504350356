import React from 'react'

/* package */
import { SectionWrappSingle } from '../../lib-atomic-section'
/* package */

/* container */
import Container from '../../app-note-container/default'
/* container */

/* form */
import Content from '../layout/content'
/* form */

const index = (props) => {
  return (
    <SectionWrappSingle {...props}>
      <Container.NoteList {...props}>
        <Content {...props} />
      </Container.NoteList>
    </SectionWrappSingle>
  )
}

export default index
