import React, { Fragment } from 'react'

/* modules */
import BoxCarousel from '../../app-layout-section-body-item-carousel/default'
import BoxNonCarousel from '../../app-layout-section-body-item-non-carousel/default'
/* modules */

const index = (props) => {
  // console.log('props: ', props)
  const { layout } = props
  const { mastercontents } = layout
  const { type } = mastercontents

  /* data props */
  const contentData = { ...props, type, mastercontents }
  /* data props */
  // console.log('content: ', contentData)
  let content = null
  switch (type) {
    case 'Carousel': {
      content = <BoxCarousel {...contentData} />
      break
    }
    default: {
      content = <BoxNonCarousel {...contentData} />
      break
    }
  }

  return <Fragment>{content}</Fragment>
}

export default index
