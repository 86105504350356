import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTDETAILCONFIG = {
  url: CMSENDPOINTS.contentdetail.url,
  method: CMSENDPOINTS.contentdetail.method,
  headers: CMSENDPOINTS.contentdetail.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { params } = props.match
  const { id } = params
  const {
    cognitouser,
    countrylanguage,
    contentdetail,
    contentdetailvalidity,
    updatecontent,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await updatecontent.dispatch({
      type: 'RESET',
    })
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await contentdetail.dispatch({
      type: 'RESET',
    })
    await contentdetailvalidity.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  /* --- cognitouser */

  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchCountryLanguagesExecute = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchMasterContentDetailValidityExecute = async ({ query }) => {
    CMSMASTERCONTENTDETAILCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContentDetail] = FetchContextApiV2(
      CMSMASTERCONTENTDETAILCONFIG
    )
    contentdetailvalidity.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContentDetail(
      { query },
      contentdetailvalidity
    )
    contentdetailvalidity.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      const query = '?cognitoId=' + username
      fetchCognitoUserExecute({ query })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      if (cognitouser.state.data.length > 0) {
        let query = '?'
        const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
        mastercountrylist.map((country, index) => {
          if (index === 0) {
            query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
          } else {
            query =
              query + '&mastercountries.abbr=' + country.mastercountry.abbr
          }
        })
        fetchCountryLanguagesExecute({ query })
      }
    }
  }, [cognitouser.state])

  useEffect(() => {
    if (
      loading &&
      countrylanguage.state.data &&
      countrylanguage.state.isStatus &&
      !countrylanguage.state.isLoading
    ) {
      let query = '?id_in=' + id + '&'
      const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
      mastercountrylist.map((country, index) => {
        if (index === 0) {
          query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
        } else {
          query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
        }
      })
      fetchMasterContentDetailValidityExecute({ query })
    }
  }, [countrylanguage.state])

  useEffect(() => {
    if (
      loading &&
      contentdetailvalidity.state.data &&
      contentdetailvalidity.state.isStatus &&
      !contentdetailvalidity.state.isLoading
    ) {
      if (contentdetailvalidity.state.data.length === 0) {
        props.history.push('/content/list')
        return
      }

      if (contentdetailvalidity.state.data[0].type === 'Carousel') {
        props.history.push('/content/carousel/edit/' + id)
        return
      }
      console.log(contentdetail, contentdetailvalidity)
      contentdetail.dispatch({
        type: 'SET',
        data: {
          status: true,
          data: { json: contentdetailvalidity.state.data[0] },
        },
      })
    }
  }, [contentdetailvalidity.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isCountryLangReady =
    countrylanguage.state.data &&
    countrylanguage.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isContentDetailValidityReady =
    contentdetailvalidity.state.data &&
    contentdetailvalidity.state.data.length > 0 &&
    contentdetailvalidity.state.isStatus &&
    !contentdetailvalidity.state.isLoading
      ? true
      : false
  const isContentDetailReady =
    contentdetail.state.data &&
    contentdetail.state.isStatus &&
    !contentdetail.state.isLoading
      ? true
      : false
  if (
    !loading ||
    !isCognitoUserReady ||
    !isCountryLangReady ||
    !isContentDetailValidityReady ||
    !isContentDetailReady
  ) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
