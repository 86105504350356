import React, { useContext, Fragment } from 'react'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { GlobalContext } from '../../lib-atomic-context'
import Grid from '@material-ui/core/Grid'
/* package */

/* modules */
import CONFIG from '../config'

/* CONSTANTS */
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    textAlign: 'center',
  },
}))
const Index = (props) => {
  const classes = useStyles()
  const { cognitouserdetail, role } = useContext(GlobalContext)
  const cognitouserdetailobj = cognitouserdetail.state.data
  const masteruserrolesobj = role.state.data

  return (
    <Fragment>
      {cognitouserdetailobj.map((user, index) => {
        const { id, name, cognitoId } = user
        const roles = masteruserrolesobj.filter(
          (i) => i.user.role.toString() === user.user.role.toString()
        )
        return (
          <Grid
            container
            spacing={1}
            justify="center"
            alignItems="center"
            className={classes.root}
          >
            <Grid item xs={12}>
              <div>
                <Typography variant="h4">
                  <b>{cognitoId}</b>
                </Typography>
                <Typography variant="body">
                  {roles.map((role) => {
                    return (
                      <small>
                        <b>{role.name.toUpperCase()}</b>
                      </small>
                    )
                  })}
                </Typography>
              </div>
            </Grid>
          </Grid>
        )
      })}
    </Fragment>
  )
}

export default Index
