import React from 'react'
import { Link } from 'react-router-dom'

import { Card, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10),
    padding: theme.spacing(10),
  },
}))

const Index = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <h1>Sorry</h1>
      <p>
        The page you're trying to view might not exist or you may need to login
        to access the page.
      </p>
      <p>
        <Link to="/home">Home</Link>
      </p>
    </Card>
  )
}

export default Index
