import React, { Fragment } from 'react'
// import { Link, Redirect } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
// import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
// import Moment from 'react-moment';
import { ModuleImage } from '../../lib-atomic-section'
import { useSnackbar } from 'notistack'

// drop zone style
const dropzoneContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 20,
  borderRadius: 60,
  width: 120,
  height: 120,
  backgroundColor: 'rgba(56, 90, 232, 0.05)',
  color: 'rgba(56, 90, 232, 0.2)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  // minHeight: 116
}
const galleryContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  minHeight: 116 * 2,
}

const thumb = {
  position: 'relative',
  display: 'inline-flex',
  //   borderRadius: 60,
  // border: "1px solid #eaeaea",
  width: 100,
  height: 100,
  //   padding: 4,
  margin: 10,
  marginBottom: 24,
  boxSizing: 'border-box',
}

const thumbInner = {
  // display: 'flex',
  minWidth: 0,
  borderRadius: 12,
  overflow: 'hidden',
  display: 'block',
  margin: '0 auto',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  //   marginBottom: 15,
}

const polaroid = {
  width: '80%',
  backgroundColor: 'white',
  marginBottom: 25,
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
}

const txtContainer = {
  textAlign: 'left',
  padding: '10 20',
}
const Index = ({
  OBJECT1,
  fieldName,
  imgFile,
  handleImg,
  spliceImage,
  imgWidth,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 1000000,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      console.log('home img file ', acceptedFiles)
      if (acceptedFiles.length === 0) {
        const message = 'Image size should be less than 300KB :('
        enqueueSnackbar(message, {
          variant: 'warning',
        })
      } else {
        handleImg(acceptedFiles)
      }
    },
  })
  const thumbs =
    imgFile.length === 0 ? (
      [OBJECT1[fieldName].initial].map((file, index) => null)
    ) : typeof imgFile[0] === 'string' ? (
      imgFile[0] === '' ? null : (
        <div style={thumb} key={imgFile[0]}>
          <div
            style={{
              width: 23,
              height: 23,
              borderRadius: 11.5,
              backgroundColor: 'white',
              position: 'absolute',
              top: -5,
              right: -8,
              cursor: 'pointer',
            }}
            onClick={spliceImage.bind(this, 0)}
          >
            <ModuleImage>
              <RemoveCircleOutlineIcon />
            </ModuleImage>
          </div>
          <div style={thumbInner}>
            <img src={imgFile[0]} style={img} />
          </div>
        </div>
      )
    ) : imgFile[0] === undefined ? null : (
      <div style={thumb} key={imgFile[0].name}>
        <div
          style={{
            width: 23,
            height: 23,
            borderRadius: 11.5,
            backgroundColor: 'white',
            position: 'absolute',
            top: -5,
            right: -8,
            cursor: 'pointer',
          }}
          onClick={spliceImage.bind(this, 0)}
        >
          <ModuleImage>
            <RemoveCircleOutlineIcon />
          </ModuleImage>
        </div>
        <div style={thumbInner}>
          <img src={imgFile[0].preview} style={img} />
        </div>
      </div>
    )
  return (
    <Fragment>
      {imgFile[0] !== '' &&
      imgFile[0] !== undefined &&
      imgFile.length !== 0 ? null : (
        <div
          style={dropzoneContainer}
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} />

          <div
            style={{
              width: 48,
              height: 48,
              borderRadius: 24,
              margin: 'auto',
            }}
          >
            <AddIcon
              style={{
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
                marginLeft: 12,
              }}
            />
          </div>
        </div>
      )}
      <aside style={thumbsContainer}>{thumbs}</aside>
    </Fragment>
  )
}

export default Index
