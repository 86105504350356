import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV

const config = {
  routes: {
    label: 'User Profile',
    prefix: '/userprofile',
  },
  environment,
  setup,
}

export default config
