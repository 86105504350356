import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    left: 0,
    width: 200,
    background: 'transparent',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  brand: {
    color: '#000',
  },
  logo: {
    width: '40%',
  },
  text: {
    fontSize: '11px',
    lineHeight: '.5',
  },
}))

export default function BottomAppBar() {
  const classes = useStyles()

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar>
        <div className={classes.brand}>
          <p className={classes.text}>Powered by</p>
          <img className={classes.logo} src="/assets/stormborn/tx_logo.png" />
          <p className={classes.text}>© 2020 Tranxactor</p>
          <p className={classes.text}>New Zealand Limited</p>
          <p className={classes.text}>All rights reserved</p>
        </div>
      </Toolbar>
    </AppBar>
  )
}
