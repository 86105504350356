import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Change Password'
const server = null
const service = null

const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Content-Type': 'application/json',
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'GET',
          },
          updatemastersetup: {
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: null
            // },
            url: setup.setup.api.cms.url[environment] + '/mastersetups',
            method: 'PUT',
          },
        },
      },
      objects: {
        platform: {
          name: 'platform',
          initial: '',
          label: 'Platform',
          type: 'select',
          options: [],
          helperText: false,
          rules: false,
        },
        boxobjects: {
          name: 'boxobjects',
          initial: '',
          label: 'Box',
          type: 'select',
          options: [],
          helperText: false,
          rules: false,
        },
        contentselect: {
          name: 'contentselect',
          initial: '',
          label: 'Contents',
          type: 'select',
          options: [],
          helperText: false,
          rules: false,
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'User Manage List',
    prefix: '/content/list',
    // prefix: "/:country/:lang/drag_and_drop"
  },
  environment,
  setup,
}

export default config
