import React from 'react'
import { Link } from 'react-router-dom'

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import PostAddIcon from '@material-ui/icons/PostAdd'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import PeopleIcon from '@material-ui/icons/People'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'

import Privilege from '../../app-conf-privilege'
import { LocalStorage } from '../../lib-atomic-functions'

const Index = (props) => {
  const { rolesModules, rolesHandler } = Privilege[process.env.REACT_APP_CLIENT]
  const availableContainers = rolesHandler(LocalStorage, rolesModules)
  const menuItems = [
    { name: 'user', label: 'Users', icon: <PeopleIcon />, url: '/user/list' },
    {
      name: 'content',
      label: 'Contents',
      icon: <PostAddIcon />,
      url: '/content/list',
    },
    {
      name: 'layout',
      label: 'Layouts',
      icon: <ViewQuiltIcon />,
      url: '/layout/list',
    },
    {
      name: 'campaign',
      label: 'Campaign',
      icon: <CardGiftcardIcon />,
      url: '/campaign/list',
    },
    {
      name: 'note',
      label: 'Notes',
      icon: <SpeakerNotesIcon />,
      url: '/note/list',
    },
  ]

  return (
    <>
      <List>
        {menuItems
          .filter(({ name, force }) =>
            Object.keys(availableContainers).some((i) => i.toString() === name)
          )
          .map(({ icon, label, url }, index) => (
            <Link to={url} key={index}>
              <ListItem button>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            </Link>
          ))}
      </List>
    </>
  )
}
export default Index
