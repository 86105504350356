import auth from '../../../app-auth-container'
import user from '../../../app-user-container'
import userprofile from '../../../app-user-profile-container'
import content from '../../../app-content-container'
import layout from '../../../app-layout-container'
import note from '../../../app-note-container'

const Modules = {
  auth,
  // userprofile,
  // user,
  content,
  layout,
  note,
}

export default Modules
