import React, { useEffect, useState, useContext } from 'react'

import Typography from '@material-ui/core/Typography'

import ServiceAmplify from '../../../services/AWS-amplify'
import { GlobalContext } from '../../lib-atomic-context'
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import {
  FormHook,
  WindowSize,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'

import CONFIG from '../config'

import SectionCountrySelect from '../../app-country-section-select/default'
import SectionRoleSelect from '../../app-role-section-select/default'

/* CONSTANTS */
const REGISTRATIONOBJECTS = CONFIG.application.objects
const ENDPOINTS = CONFIG.application.api.endpoints
const REGISTRATIONCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}
const COUNTRYROLEFAILRESPONSE = CONFIG.application.responses.countryroleFail
/* CONSTANTS */

const Index = (props) => {
  const { token, cognitouser } = useContext(GlobalContext)
  const [pageError, setPageError] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState([])
  const [selectedRole, setSelectedRole] = useState([])

  const handleCancel = () => {
    props.history.push('/user/list')
  }

  const handleRegistration = async () => {
    try {
      if (selectedCountry.length < 1 || selectedRole.length < 1)
        throw COUNTRYROLEFAILRESPONSE
      setPageError(false)
      const countryIds = selectedCountry.map((country) => country.id)
      // const roleId = selectedRole.map((role) => role.id)
      const setup = { mastercountries: countryIds, user: selectedRole[0] }
      const signupData = {
        username: values.username,
        password: values.password,
        attributes: {
          email: values.email,
          profile: JSON.stringify(selectedRole[0].id),
          'custom:masterUCCountries': JSON.stringify(setup),
        },
      }
      console.log(signupData, ' signupData')
      const signupResponse = await ServiceAmplify.signup(signupData)
      props.history.push('/user/list')
    } catch (e) {
      setPageError(e)
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleRegistration,
    REGISTRATIONOBJECTS
  )

  const username = {
    ...REGISTRATIONOBJECTS.username,
    error: error.username,
    value: values.username,
    onChange,
  }
  const email = {
    ...REGISTRATIONOBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
  }
  const password = {
    ...REGISTRATIONOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
  }

  /* -- data props --   */
  const invlidData = { error: [pageError] }
  const submitButton = { fullWidth: true }
  const cancelButton = { fullWidth: true, onClick: () => handleCancel() }
  const countryData = {
    setSelected: setSelectedCountry,
  }
  const roleData = {
    setSelected: setSelectedRole,
  }
  /* -- data props --   */

  console.log(cognitouser, ' Ccognitouser')

  return (
    <form onSubmit={onSubmit} noValidate>
      {pageError && <Invalid {...invlidData} />}
      <Inputs.Text {...username} />
      <Inputs.Text {...email} />
      <Inputs.Password {...password} />
      <br />
      <br />
      <Typography variant="body2">
        <b>Roles</b>
      </Typography>
      <SectionRoleSelect {...roleData} />
      <br />
      <br />
      <Typography variant="body2">
        <b>Countries</b>
      </Typography>
      <SectionCountrySelect {...countryData} />
      <br />
      <br />
      <Buttons.Normal {...submitButton}>Register</Buttons.Normal>
      <br />
      <br />
      <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
    </form>
  )
}

export default Index
