import React, { createContext, useReducer, useState } from 'react'

import monoReducer from './reducer'
import UseAsyncReducer from './useAsyncReducer'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const modules = [
    'logon',
    'country',
    'role',
    'authuser',
    'cognitouser',
    'cognitouserlist',
    'updatecognitouser',
    'countrylanguage',
    'contents',
    'allcontents',
    'contentdetail',
    'contentcarouseldetail',
    'createcontents',
    'uploadhomeimage',
    'createcontent',
    'updatecontent',
    'deletecontent',
    'mastersetups',
    'mastersetupspartner',
    'createmastersetup',
    'mastercontentsversions',
    'masterboxes',
    'updatesetup',
    'masterbanners',
    'updatemasterbanners',
    'createmastercountrylang',
    'createcontentversion',
    'updatecontentversion',
    'masternotes',
    'contentdetailvalidity',
    'notifications',
    'campaignlist',
    'campaign',
    'deletecampaign',
    'deletecampaignanswer',
    'mastertandc',
    'createmastercampaign',
    'updatemastercampaign',
    'mastercampaignanswersheet',
    'createmastercampaignanswersheet',
    'updatemastercampaignanswersheet',
    'updateflagtopending',
    'createlivesetup',
    'updatedevsetup',
    'createtandc',
    'updatetandc',
    'deletetandc',
    'checkmasterbanner',
    'createmasterbanner',
    'cognitouserdetail',
  ]

  let reducerObj = {}
  modules.map((module) => {
    reducerObj[module] = {
      ...monoReducer,
      method: UseAsyncReducer(monoReducer.reducer, {
        ...monoReducer.init,
      }),
      reducerName: module,
    }
  })

  const stores = {}
  Object.keys(reducerObj).map((reducer) => {
    stores[reducer] = {
      state: reducerObj[reducer].method[0],
      dispatch: reducerObj[reducer].method[1],
    }
  })

  return (
    <GlobalContext.Provider value={stores}>{children}</GlobalContext.Provider>
  )
}
