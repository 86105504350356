const CLIENT = process.env.REACT_APP_CLIENT
export default {
  // local(non-beta)
  // media: 'https://strapi.tranxactor.com',
  // live(beta)
  media: '',
  // old uat(beta)
  // media: 'https://stormborn-strapi.tr4ns.com',
  logo: '/assets/' + CLIENT + '/logo.png',
  logo_green: '/assets/' + CLIENT + '/subwayGreenLogo.png',
  logo_v2: '/assets/' + CLIENT + '/cookiesLogo.png',
  logo_sm: '/assets/' + CLIENT + '/logo_sm.png',
  logo_facebook: '/assets/' + CLIENT + '/facebook.png',
  logo_instagram: '/assets/' + CLIENT + '/instagram.png',
  logo_twitter: '/assets/' + CLIENT + '/twitter.png',
  subsquad: '/assets/' + CLIENT + '/subsquad.png',
  claim_points: '/assets/' + CLIENT + '/claim_points.png',
  my_history: '/assets/' + CLIENT + '/my_history.png',
  my_profile: '/assets/' + CLIENT + '/my_profile.png',
  temp_points: '/assets/' + CLIENT + '/temp_points.jpg',
  google_play: '/assets/' + CLIENT + '/google_play.png',
  app_store: '/assets/' + CLIENT + '/app_store.png',
  hotdrink: '/assets/' + CLIENT + '/hotdrink.png',
  subsixinch: '/assets/' + CLIENT + '/subsixinch.png',
  subfootlong: '/assets/' + CLIENT + '/subfootlong.png',

  coffee: '/assets/' + CLIENT + '/coffee5.png',
  phone: '/assets/' + CLIENT + '/phone.png',
  map_placeholder: '/assets/' + CLIENT + '/MapPlaceholder.png',
  landing: {
    default: '/assets/' + CLIENT + '/landing/index.jpg',
  },
  cards: {
    df: {
      name: 'default',
      image: '/assets/' + CLIENT + '/cards/724975.png',
    },
    hb: {
      name: 'happy birthday',
      image: '/assets/' + CLIENT + '/cards/724986.png',
    },
    ty: {
      name: 'thank you',
      image: '/assets/' + CLIENT + '/cards/724985.png',
    },
  },
  carriers: {
    df: {
      name: 'default',
      image: '/assets/' + CLIENT + '/cards/724988.png',
    },
    hb: {
      name: 'happy birthday',
      image: '/assets/' + CLIENT + '/cards/724989.png',
    },
    ty: {
      name: 'thank you',
      image: '/assets/' + CLIENT + '/cards/724987.png',
    },
  },
  login: {
    background: '/assets/' + CLIENT + '/login.jpg',
  },
  register: {
    background: '/assets/' + CLIENT + '/register.jpg',
  },
  removeIcon: '/assets/' + CLIENT + '/icons/minus-circle-solid.svg',
}
