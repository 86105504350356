import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTS = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { path, params } = props.match
  const {
    cognitouser,
    countrylanguage,
    contents,
    createcontent,
    uploadhomeimage,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await createcontent.dispatch({
      type: 'RESET',
    })
    await uploadhomeimage.dispatch({
      type: 'RESET',
    })
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await contents.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  /* --- cognitouser */
  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- cognitouser */
  const fetchCountryLanguagesExecute = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master contents */
  const fetchMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTS)
    contents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContents({ query }, contents)
    contents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      const query = '?cognitoId=' + username
      fetchCognitoUserExecute({ query })
    }
  }, [loading])
  const initFunc = async () => {
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      if (cognitouser.state.data.length > 0) {
        let query = '?'
        const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
        mastercountrylist.map((country, index) => {
          if (index === 0) {
            query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
          } else {
            query =
              query + '&mastercountries.abbr=' + country.mastercountry.abbr
          }
        })
        fetchCountryLanguagesExecute({ query })
        // setLoading(false)
      }
    }
  }, [cognitouser.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isCountryLangReady =
    countrylanguage.state.data &&
    countrylanguage.state.isStatus &&
    !countrylanguage.state.isLoading
      ? true
      : false
  if (!loading || !isCognitoUserReady || !isCountryLangReady) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
