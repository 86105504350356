import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERSETUPS = {
  url: CMSENDPOINTS.mastersetups.url,
  method: CMSENDPOINTS.mastersetups.method,
  headers: CMSENDPOINTS.mastersetups.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTSCONFIG = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const [finalProcessDone, setFinalProcessDone] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const {
    cognitouser,
    mastersetups,
    contents,
    contentcarouseldetail,
    updatesetup,
    updatecontentversion,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    setFinalProcessDone(false)
    await updatesetup.dispatch({
      type: 'RESET',
    })
    await updatecontentversion.dispatch({
      type: 'RESET',
    })
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await mastersetups.dispatch({
      type: 'RESET',
    })
    await contents.dispatch({
      type: 'RESET',
    })
    await contentcarouseldetail.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  const fetchCognitoUserExec = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master setups */
  const fetchMasterSetupsExecute = async ({ query }) => {
    CMSMASTERSETUPS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPS)
    mastersetups.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterSetups({ query }, mastersetups)
    mastersetups.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master contents */
  const fetchMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    contents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContents({ query }, contents)
    contents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCarouselContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCarouselContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    contentcarouseldetail.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCarouselContents(
      { query },
      contentcarouseldetail
    )
    contentcarouseldetail.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      setFinalProcessDone(false)
      const query = '?cognitoId=' + username
      fetchCognitoUserExec({ query })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      let query = '?id_in=' + id + '&'
      const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
      mastercountrylist.map((country, index) => {
        if (index === 0) {
          query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
        } else {
          query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
        }
      })
      fetchMasterSetupsExecute({ query })
    }
  }, [cognitouser.state.data])

  useEffect(() => {
    if (
      loading &&
      mastersetups.state.data &&
      mastersetups.state.isStatus &&
      !mastersetups.state.isLoading &&
      !finalProcessDone
    ) {
      if (mastersetups.state.data.length === 0) {
        // fetchResetExec()
        return props.history.push('/layout/list')
      }
      const webContentsIds = mastersetups.state.data[0]['WebContents']
        ? mastersetups.state.data[0]['WebContents'].map((content) => {
            return content.mastercontents.id
          })
        : []
      const mobContentsIds = mastersetups.state.data[0]['MobContents']
        ? mastersetups.state.data[0]['MobContents'].map((content) => {
            return content.mastercontents.id
          })
        : []
      const combinedIds = [...webContentsIds, ...mobContentsIds]
      const qs =
        combinedIds.length > 0
          ? QueryString.stringify({ id_in: combinedIds })
          : false
      const query = qs ? '?' + qs : '?id_in=-1'
      fetchMasterContentsExecute({ query })
    }
  }, [mastersetups.state])
  const processFinal = () => {
    console.log(mastersetups.state.data[0].WebContents)
    const finalWebContents = mastersetups.state.data[0].WebContents.map(
      (webcontent) => {
        const webContentId = webcontent.mastercontents.id
        let matchingContentFound =
          contents.state.data &&
          contents.state.data.filter((content) => {
            return webContentId.toString() === content.id.toString()
          })[0]
        const carouselFound =
          contentcarouseldetail.state.data &&
          contentcarouseldetail.state.data.filter((carouselcontent) => {
            return (
              matchingContentFound.CarouselContents &&
              matchingContentFound.CarouselContents.length > 0 &&
              matchingContentFound.CarouselContents.some(
                (i) => i.mastercontents.id === carouselcontent.id
              )
            )
          })
        console.log(carouselFound)
        if (carouselFound.length > 0) {
          const newCarouselContents = matchingContentFound.CarouselContents.map(
            (carouselCont) => {
              const carouselWithImg = carouselFound.filter(
                (i) => i.id === carouselCont.mastercontents.id
              )
              console.log(carouselWithImg)
              return { id: carouselCont.id, mastercontents: carouselWithImg[0] }
            }
          )
          console.log(newCarouselContents)
          matchingContentFound.CarouselContents = newCarouselContents
        }
        webcontent.mastercontents = matchingContentFound

        return webcontent
      }
    )

    const finalMobContents = mastersetups.state.data[0].MobContents.map(
      (mobcontent) => {
        const mobContentId = mobcontent.mastercontents.id
        let matchingContentFound =
          contents.state.data &&
          contents.state.data.filter((content) => {
            return mobContentId.toString() === content.id.toString()
          })[0]
        const carouselFound =
          contentcarouseldetail.state.data &&
          contentcarouseldetail.state.data.filter((carouselcontent) => {
            return (
              matchingContentFound.CarouselContents &&
              matchingContentFound.CarouselContents.length > 0 &&
              matchingContentFound.CarouselContents.some(
                (i) => i.mastercontents.id === carouselcontent.id
              )
            )
          })
        if (carouselFound.length > 0) {
          const newCarouselContents = matchingContentFound.CarouselContents.map(
            (carouselCont) => {
              const carouselWithImg = carouselFound.filter(
                (i) => i.id === carouselCont.mastercontents.id
              )
              return {
                id: carouselCont.id,
                mastercontents: carouselWithImg[0],
              }
            }
          )
          matchingContentFound.CarouselContents = newCarouselContents
        }
        mobcontent.mastercontents = matchingContentFound

        return mobcontent
      }
    )
    let finalMasterSetup = JSON.parse(
      JSON.stringify(mastersetups.state.data[0])
    )
    console.log(finalWebContents)
    finalMasterSetup.WebContents = finalWebContents
    finalMasterSetup.MobContents = finalMobContents
    // console.log(mastersetups)
    mastersetups.dispatch({
      type: 'SET',
      data: [finalMasterSetup],
    })
    // console.log(mastersetups)
    setFinalProcessDone(true)
  }
  useEffect(() => {
    if (
      loading &&
      contents.state.data &&
      contents.state.isStatus &&
      !contents.state.isLoading
    ) {
      const mastercontents = contents.state.data
      const CarouselContentsList = mastercontents.filter(
        (content) => content.type === 'Carousel'
      )
      const carouselsItems = []
      CarouselContentsList.map((carousel) => {
        carousel.CarouselContents &&
          carousel.CarouselContents.map((items) => {
            if (items && items.mastercontents && items.mastercontents.id) {
              if (
                !(
                  carouselsItems &&
                  carouselsItems.findIndex(
                    (i) => i === items.mastercontents.id
                  ) > -1
                )
              ) {
                carouselsItems.push(items.mastercontents.id)
              }
            }
          })
      })
      if (carouselsItems.length > 0) {
        const qs = QueryString.stringify({ id_in: carouselsItems })
        // console.log('QS', qs, mastercontents)
        fetchCarouselContentsExecute({
          query: '?' + qs,
        })
      } else {
        processFinal()
      }
    }
  }, [contents.state])

  useEffect(() => {
    if (
      loading &&
      contentcarouseldetail.state.data &&
      contentcarouseldetail.state.isStatus &&
      !contentcarouseldetail.state.isLoading
    ) {
      console.log('setup ', mastersetups.state.data)
      console.log('content ', contents.state.data)
      console.log('carouselcontents ', contentcarouseldetail.state.data)
      processFinal()
    }
  }, [contentcarouseldetail.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isMasterSetupReady =
    mastersetups.state.data &&
    mastersetups.state.data.length > 0 &&
    mastersetups.state.isStatus &&
    !mastersetups.state.isLoading
      ? true
      : false
  const isContentReady =
    contents.state.data && contents.state.isStatus && !contents.state.isLoading
      ? true
      : false
  const isCarouselContentReady =
    contentcarouseldetail.state.data &&
    contentcarouseldetail.state.isStatus &&
    !contentcarouseldetail.state.isLoading
      ? true
      : false

  if (
    !loading ||
    !isCognitoUserReady ||
    !isMasterSetupReady ||
    !isContentReady ||
    (!isCarouselContentReady && !finalProcessDone) ||
    !finalProcessDone
  ) {
    return <Spinner.Popup />
  }
  return props.children
}

export default Index
