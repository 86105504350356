import React, { lazy, Suspense } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import SectionPaper from './section_paper'

const useStyles = makeStyles((theme) => ({
  // root: { height: "90vh" },
}))

const Index = (props) => {
  const { noOfCol, customStyle, onClickFunc } = props
  const classes = useStyles()

  return (
    <Grid
      item
      xs={noOfCol ? noOfCol : 3}
      style={customStyle ? customStyle : {}}
      onClick={onClickFunc ? onClickFunc : null}
    >
      <SectionPaper>{props.children}</SectionPaper>
    </Grid>
  )
}

export default Index
