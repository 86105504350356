import axios from 'axios'
import appConf from '../../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const stormbornDomain = setup.setup.api.cms.url[process.env.NODE_ENV]

const index = async ({ query, token }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  }
  const fetchResult = await axios({
    method: 'GET',
    url: stormbornDomain + '/mastercontents' + query,
    headers: headers,
  })
  if (
    fetchResult &&
    fetchResult.data &&
    fetchResult.data &&
    fetchResult.data.length === 1
  ) {
    return true
  } else {
    return false
  }
}

export default index
