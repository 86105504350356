import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'CMS'
const server = null
const service = null
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          mastercongnitousers: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercognitousers',
            method: 'GET',
          },
          mastercongnitouserdetail: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercognitousers',
            method: 'GET',
          },
          mastercountries: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/mastercountries',
            method: 'GET',
          },
          role: {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ',
            },
            doc: 'https://stormborn-strapi.tr4ns.com/',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.cms.url[environment] + '/masteruserroles',
            method: 'GET',
          },
        },
      },
    },
  },
  routes: {
    label: 'User Manage',
    prefix: '/user/container',
  },
}

export default config
