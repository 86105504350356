const isNotNull = (data) => {
  const key = 'isNotNull'
  const { value } = data
  const isEmpty =
    value === '' || value === null || value === undefined ? true : false
  const error = isEmpty ? 'This field is required' : false
  return error ? error : true
}

export default isNotNull
