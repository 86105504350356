import React from 'react'

import FormHelperText from '@material-ui/core/FormHelperText'

/* modules */
import Feeds from './feeds'
/* modules */

const Invalid = ({ error }) => {
  console.log(error)
  if (!error) return null
  const feedData = { error }
  return (
    <FormHelperText error>
      <Feeds {...feedData} />
    </FormHelperText>
  )
}

export default Invalid
