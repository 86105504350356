const isJSON = data => {
  let value = data.value
  try {
    JSON.parse(value)
    return true
  } catch (e) {
    return 'invalid json format'
  }
}

export default isJSON
