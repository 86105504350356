import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import { SectionWrappListItem } from '../../lib-atomic-section'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { WindowSize } from '../../lib-atomic-functions'
import { GlobalContext } from '../../lib-atomic-context'
/* package */

/* modules */
import CONFIG from '../config'

import SectionContentFilter from '../../app-filter-section-select-content/default'
import SectionContentImage from '../../app-content-section-image/default'

/* CONSTANTS */

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    height: 140,
  },
  upper: {
    textTransform: 'uppercase',
  },
  camel: {
    textTransform: 'capitalize',
  },
}))

const formatDate = (from, to) => {
  return {
    formattedFrom: moment(from).format('DD-MMM-YYYY  HH:mm:ss'),
    formattedTo: moment(to).format('DD-MMM-YYYY  HH:mm:ss'),
  }
}
const Index = (props) => {
  const { width, height } = WindowSize()
  const classes = useStyles()
  const { contents, cognitouser } = useContext(GlobalContext)

  const [rawContent, setRawContent] = useState([])
  const [filteredContent, setFilteredContent] = useState([])

  useEffect(() => {
    const mastercontents = contents.state.data
    setRawContent(mastercontents)
    setFilteredContent(mastercontents)
  }, [])

  const handleToDetail = ({ id }) => {
    props.history.push('/content/detail/' + id)
  }

  // data props
  const filterProps = {
    ...props,
    rawContent,
    filteredContent,
    setFilteredContent,
  }
  // data props

  return (
    <Fragment>
      <SectionContentFilter {...filterProps} />
      {filteredContent.map((content, index) => {
        // console.log(content)
        const {
          id,
          name,
          type,
          contentVisibility,
          mastercountries,
          mastercontentsflag,
          homeImage,
          listImage,
          detailImage,
          PublishFrom,
          PublishTo,
        } = content
        const { formattedFrom, formattedTo } = formatDate(
          PublishFrom,
          PublishTo
        )

        const imageData = {
          images: [{ name: null, img: homeImage }],
          size: 'small',
        }

        return (
          <SectionWrappListItem
            key={index}
            noOfCol={width < 820 ? 12 : width < 1150 ? 6 : 3}
            customStyle={{
              cursor: 'pointer',
              overflow: 'auto',
              display: 'block',
            }}
            onClickFunc={() => handleToDetail({ id })}
          >
            <Typography variant="body2">
              <small className={classes.upper}>
                <b>{mastercountries.abbr}</b>
              </small>
              &nbsp; / &nbsp;
              <small className={classes.camel}>
                <b>{type}</b>
              </small>
              &nbsp; / &nbsp;
              <small className={classes.camel}>
                <b>{contentVisibility}</b>
              </small>
              &nbsp; / &nbsp;
              <small>
                <b>
                  {mastercontentsflag ? mastercontentsflag.status : 'Draft'}
                </b>
              </small>
            </Typography>
            <SectionContentImage {...imageData} />
            <div className={classes.root}>
              <Typography variant="body2">
                <b>
                  #{id}&nbsp;{name}
                </b>
              </Typography>
              <Typography variant="body2">
                <small>
                  <span>Publish From</span> <br />
                  <b>{formattedFrom}</b>
                </small>
                <br />
                <small>
                  <span>Publish To</span> <br />
                  <b>{formattedTo}</b>
                </small>
              </Typography>
            </div>
          </SectionWrappListItem>
        )
      })}
    </Fragment>
  )
}

export default Index
