import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Buttons, Inputs } from '../../lib-atomic'

import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'

import { GlobalContext } from '../../lib-atomic-context'

import CONFIG from '../config'

import SectionContentControls from '../../app-content-section-controls/default'
import SectionContentImage from '../../app-content-section-image/default'
import SectionContentImageCarousel from '../../app-content-section-image-carousel/default'

import Info from './info'

const useStyles = makeStyles((theme) => ({
  assets: {
    height: 'auto',
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { cognitouser, contentdetail, contentcarouseldetail } = useContext(
    GlobalContext
  )
  const {
    id,
    title,
    name,
    type,
    contentVisibility,
    mastercountries,
    mastercontentsflag,
    CarouselContents,
    homeImage,
    listImage,
    detailImage,
    PublishFrom,
    PublishTo,
  } = contentdetail.state.data

  const controlData = {
    ...props,
    user: cognitouser.state.data,
    item: contentdetail.state.data,
  }
  const imageData = {
    ...props,
    type,
    images: [
      { name: 'Home Image', img: homeImage ? homeImage : '' },
      { name: 'List Image', img: listImage ? listImage : '' },
      { name: 'Detail Image', img: detailImage ? detailImage : '' },
    ],
    size: 'large',
  }
  const carouselData = {
    ...props,
    type,
    contents: contentcarouseldetail.state.data,
    size: 'large',
  }
  console.log(contentdetail)
  const infoData = {
    ...props,
    info: contentdetail.state.data,
    carouselData,
    imageData,
  }

  return (
    <Fragment>
      <SectionContentControls {...controlData} />
      <div>
        <Info {...infoData} />
      </div>
    </Fragment>
  )
}

export default Index
