const addLS = (key, value) => {
  try {
    const ls = localStorage.getItem("ls");
    const obj = ls ? JSON.parse(ls) : {};
    obj[key] = value;
    const newLs = JSON.stringify(obj);
    localStorage.setItem("ls", newLs);
    return true;
  } catch (e) {
    // console.log(e)
    return false;
  }
};

const getLS = () => {
  try {
    const ls = localStorage.getItem("ls");
    
    const obj = ls ? JSON.parse(ls) : false;
   
    return obj;
  } catch (e) {
    return false;
  }
};

const removeLS = () => {
  try {
    return localStorage.removeItem("ls");
  } catch (e) {
    return false;
  }
};

export default {
  addLS,
  getLS,
  removeLS,
};
