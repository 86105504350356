import React, { useContext, Fragment, useState, useEffect } from 'react'

import { Buttons } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import { LocalStorage, FetchContextApiV2 } from '../../lib-atomic-functions'

import CheckerPopup from '../../app-popup-section-confirm/default'

import CONFIG from '../config'
import { I18n } from 'aws-amplify'

const CMSENDPOINTS = CONFIG.application.cms.api.endpoints

const CMSREPUBLISHLAYOUTCONFIG = {
  url: CMSENDPOINTS.publishupdatesetup.url,
  method: CMSENDPOINTS.publishupdatesetup.method,
  headers: CMSENDPOINTS.publishupdatesetup.headers,
  responseType: 'json',
}
const CMSCREATELIVESETUPCONFIG = {
  url: CMSENDPOINTS.createlivesetup.url,
  method: CMSENDPOINTS.createlivesetup.method,
  headers: CMSENDPOINTS.createlivesetup.headers,
  responseType: 'json',
}

const CMSCONTENTVERSIONCONFIG = {
  url: CMSENDPOINTS.updatecontentversion.url,
  method: CMSENDPOINTS.updatecontentversion.method,
  headers: CMSENDPOINTS.updatecontentversion.headers,
  responseType: 'json',
}
const CMSCREATEMASTERCOUNTRYLANGUAGESCONFIG = {
  url: CMSENDPOINTS.createmastercountrylang.url,
  method: CMSENDPOINTS.createmastercountrylang.method,
  headers: CMSENDPOINTS.createmastercountrylang.headers,
  responseType: 'json',
}
const CMSCOPYCONTENTSCONFIG = {
  url: CMSENDPOINTS.createcontents.url,
  method: CMSENDPOINTS.createcontents.method,
  headers: CMSENDPOINTS.createcontents.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTSVERSIONSCONFIG = {
  url: CMSENDPOINTS.createmastercontentsversions.url,
  method: CMSENDPOINTS.createmastercontentsversions.method,
  headers: CMSENDPOINTS.createmastercontentsversions.headers,
  responseType: 'json',
}

const CMSUPDATECONTENTCONFIG = {
  url: CMSENDPOINTS.updatecontent.url,
  method: CMSENDPOINTS.updatecontent.method,
  headers: CMSENDPOINTS.updatecontent.headers,
  responseType: 'json',
}
const CMSUPDATEDEVSETUPCONFIG = {
  url: CMSENDPOINTS.updatedevsetup.url,
  method: CMSENDPOINTS.updatedevsetup.method,
  headers: CMSENDPOINTS.updatedevsetup.headers,
  responseType: 'json',
}

const flagMap = {
  live: {
    draft: 4,
    approved: 5,
    published: 6,
  },
  uat: {
    draft: 1,
    approved: 2,
    published: 3,
  },
}
const flagMapForContent = {
  live: {
    draft: 5,
    pending: 6,
    approved: 7,
    live: 8,
  },
  uat: {
    draft: 1,
    pending: 4,
    approved: 2,
    live: 3,
  },
}
const flagMapSelected =
  process.env.REACT_APP_BUILD_ENV === 'production' ? flagMap.live : flagMap.uat

const flagMapSelectedForContent =
  process.env.REACT_APP_BUILD_ENV === 'production'
    ? flagMapForContent.live
    : flagMapForContent.uat

const Index = (props) => {
  const { token } = LocalStorage.getLS().strapi
  const { user, item, other } = props
  const { id, environment, mastersetupflag } = item
  // console.log(id)
  const {
    updatesetup,
    mastersetups,
    countrylanguage,
    mastercontentsversions,
    updatecontentversion,
    createmastercountrylang,
    createcontents,
    deletecontent,
    mastersetupspartner,
    updatecontent,
    createcontentversion,
    createlivesetup,
    updatedevsetup,
  } = useContext(GlobalContext)
  const resetContextItems = async () => {
    updatesetup.dispatch({
      type: 'RESET',
    })
    updatedevsetup.dispatch({
      type: 'RESET',
    })
    updatecontent.dispatch({
      type: 'RESET',
    })
    mastersetups.dispatch({
      type: 'RESET',
    })
    countrylanguage.dispatch({
      type: 'RESET',
    })
    mastercontentsversions.dispatch({
      type: 'RESET',
    })
    updatecontentversion.dispatch({
      type: 'RESET',
    })
    createmastercountrylang.dispatch({
      type: 'RESET',
    })
    deletecontent.dispatch({
      type: 'RESET',
    })
    createlivesetup.dispatch({
      type: 'RESET',
    })
    createcontentversion.dispatch({
      type: 'RESET',
    })
  }
  useEffect(() => {
    return () => resetContextItems()
  }, [])
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [actionStatusId, setActionStatusId] = useState(false)

  const fetchUpdateMasterContentVersionExecute = async ({ query, body }) => {
    CMSCONTENTVERSIONCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdateMasterSetup] = FetchContextApiV2(CMSCONTENTVERSIONCONFIG)
    const fetchResult = await fetchUpdateMasterSetup(
      { query, body },
      updatecontentversion
    )
    updatecontentversion.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchCreateMasterContentsVersionsExecute = async ({ body }) => {
    CMSMASTERCONTENTSVERSIONSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCreateContent] = FetchContextApiV2(
      CMSMASTERCONTENTSVERSIONSCONFIG
    )
    const fetchResult = await fetchCreateContent({ body }, createcontentversion)
    createcontentversion.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCreateMasterCountryLangExecute = async ({ query, body }) => {
    CMSCREATEMASTERCOUNTRYLANGUAGESCONFIG.headers.Authorization =
      'Bearer ' + token
    const [fetchUpdateMasterSetup] = FetchContextApiV2(
      CMSCREATEMASTERCOUNTRYLANGUAGESCONFIG
    )
    const fetchResult = await fetchUpdateMasterSetup(
      { query, body },
      createmastercountrylang
    )
    await createmastercountrylang.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchUpdateContentExecute = async ({ query, body }) => {
    CMSUPDATECONTENTCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdateContent] = FetchContextApiV2(CMSUPDATECONTENTCONFIG)
    const fetchResult = await fetchUpdateContent({ query, body }, updatecontent)
    return fetchResult
  }
  const createMasterCountryLangProd = async () => {
    if (countrylanguage.state.data.length === 1) {
      const newBody = {
        ...countrylanguage.state.data[0],
        id: null,
        environment: 'production',
      }
      await fetchCreateMasterCountryLangExecute({ body: newBody })
      props.history.go(0)
    }
  }

  const incrementContentVersion = async () => {
    const mastersetupsobj =
      mastersetups.state.data && mastersetups.state.data[0]
    console.log(mastersetupsobj)
    const selectedNewMCV = createmastercountrylang.state.data
      ? createmastercountrylang.state.data
      : mastersetupsobj.mastercontentsversions

    const versionArr = selectedNewMCV.version.split('.')
    const newVersion = versionArr
      .map((num, index) => {
        if (versionArr.length - 1 === index) {
          return (parseInt(num) + 1).toString()
        } else {
          return num
        }
      })
      .join('.')
    const body = {
      ...selectedNewMCV,
      version: newVersion,
      environment: 'production',
    }
    await fetchUpdateMasterContentVersionExecute({ query: '/' + body.id, body })
    props.history.go(0)
  }

  useEffect(() => {
    console.log(updatecontentversion)
    if (
      updatecontentversion.state.data &&
      updatecontentversion.state.isStatus &&
      !updatecontentversion.state.isLoading
    ) {
      // props.history.push('/layout/list')
      console.log('updated contentVersion, ', updatecontentversion)
      //props.history.go(0)
    }
  }, [updatecontentversion.state])

  useEffect(() => {
    console.log(updatesetup)
    if (
      updatesetup.state.data &&
      updatesetup.state.isStatus &&
      !updatesetup.state.isLoading
    ) {
      // props.history.push('/layout/list')
      console.log('updated mastersetups, ', updatesetup)
      //props.history.go(0)
    }
  }, [updatesetup.state])

  const updateRemovedContentFlagToDraft = async (dataPassed) => {
    if (dataPassed.MobContents.length !== 0) {
      await asyncForEach(dataPassed.MobContents, async (mobContent) => {
        if (mobContent.mastercontents.type === 'Carousel') {
          if (mobContent.mastercontents.CarouselContents.length !== 0) {
            await asyncForEach(
              mobContent.mastercontents.CarouselContents,
              async (carouselContent) => {
                const body = {
                  ...carouselContent.mastercontents,
                  Environment: 'development',
                  mastercontentsflag: flagMapSelectedForContent.draft,
                }
                console.log(body)
                const returnVal = await fetchUpdateContentExecute({
                  query: '/' + carouselContent.mastercontents.id,
                  body,
                })
              }
            )
          }
        }
        const body = {
          id: mobContent.mastercontents.id,
          Environment: 'development',
          mastercontentsflag: flagMapSelectedForContent.draft,
        }
        console.log(body)
        const returnVal = await fetchUpdateContentExecute({
          query: '/' + mobContent.mastercontents.id,
          body,
        })
      })
    }

    if (dataPassed.WebContents.length !== 0) {
      await asyncForEach(dataPassed.WebContents, async (webContent) => {
        if (webContent.mastercontents.type === 'Carousel') {
          if (webContent.mastercontents.CarouselContents.length !== 0) {
            await asyncForEach(
              webContent.mastercontents.CarouselContents,
              async (carouselContent) => {
                const body = {
                  ...carouselContent.mastercontents,
                  Environment: 'development',
                  mastercontentsflag: flagMapSelectedForContent.draft,
                }
                console.log(body)
                const returnVal = await fetchUpdateContentExecute({
                  query: '/' + carouselContent.mastercontents.id,
                  body,
                })
              }
            )
          }
        }
        const body = {
          id: webContent.mastercontents.id,
          Environment: 'development',
          mastercontentsflag: flagMapSelectedForContent.draft,
        }
        console.log(body)
        const returnVal = await fetchUpdateContentExecute({
          query: '/' + webContent.mastercontents.id,
          body,
        })
      })
    }
  }
  const checkContentFoundLiveFromOtherPlatform = (platform, copy, i) => {
    return copy[platform].some(
      (content) =>
        (content.mastercontents.mastercontentsflag.status === 'live' ||
          content.mastercontents.mastercontentsflag ===
            flagMapSelectedForContent.live) &&
        content.mastercontents.id === i.mastercontents.id &&
        checkCordinateFound(content)
    )
  }
  const publishUpdateSetupExec = async () => {
    const partner = other.partner
    console.log('oldLive? ', partner)
    const copy = await updateContentFlagToLive(
      mastersetups.state.data[0],
      false
    )
    console.log(copy)
    // update live to approved for those not found from copy(dev contents)
    const updateFollowingToApproved = {
      WebContents: copy.WebContents.filter(
        (i) =>
          !checkCordinateFound(i) &&
          !checkContentFoundLiveFromOtherPlatform('MobContents', copy, i)
      ),
      MobContents: copy.MobContents.filter(
        (i) =>
          !checkCordinateFound(i) &&
          !checkContentFoundLiveFromOtherPlatform('WebContents', copy, i)
      ),
    }
    const newContentsPrep = {
      WebContents: copy.WebContents.filter(
        (i) =>
          !updateFollowingToApproved.WebContents.some((i2) => i2.id === i.id)
      ),
      MobContents: copy.MobContents.filter(
        (i) =>
          !updateFollowingToApproved.MobContents.some((i2) => i2.id === i.id)
      ),
    }
    console.log(copy, updateFollowingToApproved, newContentsPrep)
    await updateRemovedContentFlagToDraft(updateFollowingToApproved)

    // const newBody = {
    //   ...mastersetups.state.data[0],
    //   environment: 'production',
    //   id: partner.id,
    //   mastersetupflag: {
    //     id: actionStatusId,
    //   },
    //   WebContents: copy.WebContents,
    //   MobContents: copy.MobContents,
    //   name: partner.name,
    // }
    const newBody = {
      ...mastersetups.state.data[0],
      environment: 'production',
      id: partner.id,
      mastersetupflag: {
        id: actionStatusId,
      },
      WebContents: newContentsPrep.WebContents.map((i) => {
        delete i['id']
        return i
      }),
      MobContents: newContentsPrep.MobContents.map((i) => {
        delete i['id']
        return i
      }),
      name: partner.name,
    }
    mastersetups.dispatch({
      type: 'RESET',
    })
    console.log(newBody)
    CMSREPUBLISHLAYOUTCONFIG.headers.Authorization = 'Bearer ' + token
    const [approveContent] = FetchContextApiV2(CMSREPUBLISHLAYOUTCONFIG)

    console.log(newBody)
    const x = await approveContent(
      { query: '/' + partner.id, body: JSON.parse(JSON.stringify(newBody)) },
      updatesetup
    )
    console.log(newBody, x)
    await updateDevSetupToDraftExec()
  }
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }
  const checkCordinateFound = (contentItem) => {
    const emptyCoordFound =
      contentItem.startX === null ||
      contentItem.startY === null ||
      contentItem.endX === null ||
      contentItem.endY === null ||
      (contentItem.startX.toString().replace(/\s/g, '') === '0' &&
        contentItem.startY.toString().replace(/\s/g, '') === '0' &&
        contentItem.endX.toString().replace(/\s/g, '') === '0' &&
        contentItem.endY.toString().replace(/\s/g, '') === '0') ||
      contentItem.startX.toString().replace(/\s/g, '') === '' ||
      contentItem.startY.toString().replace(/\s/g, '') === '' ||
      contentItem.endX.toString().replace(/\s/g, '') === '' ||
      contentItem.endY.toString().replace(/\s/g, '') === ''
    const emptyCoordFoundForPostLogin =
      contentItem.startXForPostLogin === null ||
      contentItem.startYForPostLogin === null ||
      contentItem.endXForPostLogin === null ||
      contentItem.endYForPostLogin === null ||
      (contentItem.startXForPostLogin.toString().replace(/\s/g, '') === '0' &&
        contentItem.startYForPostLogin.toString().replace(/\s/g, '') === '0' &&
        contentItem.endXForPostLogin.toString().replace(/\s/g, '') === '0' &&
        contentItem.endYForPostLogin.toString().replace(/\s/g, '') === '0') ||
      contentItem.startXForPostLogin.toString().replace(/\s/g, '') === '' ||
      contentItem.startYForPostLogin.toString().replace(/\s/g, '') === '' ||
      contentItem.endXForPostLogin.toString().replace(/\s/g, '') === '' ||
      contentItem.endYForPostLogin.toString().replace(/\s/g, '') === ''
    return !emptyCoordFound || !emptyCoordFoundForPostLogin
  }
  const updateContentFlagToLive = async (setupData, withoutId) => {
    const returnObj = {
      MobContents: [],
      WebContents: [],
    }
    await asyncForEach(setupData.MobContents, async (mobContent) => {
      var returnVal1 = { ...mobContent.mastercontents }
      if (checkCordinateFound(mobContent)) {
        if (mobContent.mastercontents.type === 'Carousel') {
          await asyncForEach(
            mobContent.mastercontents.CarouselContents,
            async (carouselContent) => {
              const body = {
                ...carouselContent.mastercontents,
                Environment: 'production',
                mastercontentsflag: flagMapSelectedForContent.live,
              }
              console.log(body)
              const returnVal = await fetchUpdateContentExecute({
                query: '/' + carouselContent.mastercontents.id,
                body,
              })
            }
          )
        }
        const body = {
          id: mobContent.mastercontents.id,
          Environment: 'production',
          mastercontentsflag: flagMapSelectedForContent.live,
        }
        console.log(body)
        const returnVal = await fetchUpdateContentExecute({
          query: '/' + mobContent.mastercontents.id,
          body,
        })
        returnVal1 = { ...returnVal1, ...returnVal.data.json }
      }
      console.log('updating ', returnVal1)
      const contentObj = {
        ...mobContent,
        mastercontents: returnVal1,
      }
      if (withoutId) {
        delete contentObj['id']
      }
      returnObj.MobContents.push(contentObj)
    })

    await asyncForEach(setupData.WebContents, async (webContent) => {
      var returnVal1 = { ...webContent.mastercontents }
      if (checkCordinateFound(webContent)) {
        if (webContent.mastercontents.type === 'Carousel') {
          await asyncForEach(
            webContent.mastercontents.CarouselContents,
            async (carouselContent) => {
              const body = {
                ...carouselContent.mastercontents,
                Environment: 'production',
                mastercontentsflag: flagMapSelectedForContent.live,
              }
              console.log(body)
              const returnVal = await fetchUpdateContentExecute({
                query: '/' + carouselContent.mastercontents.id,
                body,
              })
            }
          )
        }
        const body = {
          id: webContent.mastercontents.id,
          Environment: 'production',
          mastercontentsflag: flagMapSelectedForContent.live,
        }
        const returnVal = await fetchUpdateContentExecute({
          query: '/' + webContent.mastercontents.id,
          body,
        })
        returnVal1 = { ...returnVal1, ...returnVal.data.json }
      }
      const contentObj = {
        ...webContent,
        mastercontents: returnVal1,
      }
      if (withoutId) {
        delete contentObj['id']
      }
      returnObj.WebContents.push(contentObj)
    })

    return returnObj
  }
  const createPart2 = async () => {
    await publishCreateSetupExec()
    await createMasterCountryLangProd()
    await updateDevSetupToDraftExec()
    props.history.go(0)
  }
  useEffect(() => {
    if (
      createcontentversion.state.data &&
      createcontentversion.state.isStatus &&
      !createcontentversion.state.isLoading
    ) {
      // content version exist
      createPart2()
    }
  }, [createcontentversion.state])

  const createContentVersion = async () => {
    const setupObj = mastersetups.state.data[0]
    const body = {
      device: 'mob',
      environment: 'production',
      version: '1.0',
      name:
        setupObj.mastercountries.abbr.toUpperCase() +
        '-' +
        setupObj.masterlanguages.abbr.toUpperCase() +
        '-PROD',
      mastercountries: setupObj.mastercountries.id,
      masterlanguages: setupObj.masterlanguages.id,
    }
    fetchCreateMasterContentsVersionsExecute({ body })
  }
  const publishCreateSetupExec = async () => {
    const setupObj = mastersetups.state.data[0]
    // create copy of contents to mastercontents
    const copy = await updateContentFlagToLive(mastersetups.state.data[0], true)
    // using created copy create setup as prod

    const body = {
      ...mastersetups.state.data[0],
      environment: 'production',
      id: null,
      mastersetupflag: {
        id: actionStatusId,
      },
      WebContents: copy.WebContents,
      MobContents: copy.MobContents,
      mastercontentsversions: createcontentversion.state.data,
      name:
        setupObj.mastercountries.abbr.toUpperCase() +
        '-' +
        setupObj.masterlanguages.abbr.toUpperCase() +
        'PROD',
    }
    createlivesetup.dispatch({
      type: 'RESET',
    })
    CMSCREATELIVESETUPCONFIG.headers.Authorization = 'Bearer ' + token
    const [createSetup] = FetchContextApiV2(CMSCREATELIVESETUPCONFIG)
    await createSetup({ body }, createlivesetup)
  }

  const updateDevSetupToDraftExec = async () => {
    const body = {
      id: id,
      mastersetupflag: {
        id: flagMapSelected.draft,
      },
    }
    updatedevsetup.dispatch({
      type: 'RESET',
    })
    CMSUPDATEDEVSETUPCONFIG.headers.Authorization = 'Bearer ' + token
    const [updateDevSetup] = FetchContextApiV2(CMSUPDATEDEVSETUPCONFIG)
    await updateDevSetup({ query: '/' + id, body }, updatedevsetup)
  }

  const publishSetupExec = async () => {
    console.log('currLive: ', other.partner)
    console.log('currDev: ', mastersetups.state.data[0])

    if (other && other.partner && other.partner.id) {
      //  there is prod already
      await publishUpdateSetupExec()
      await incrementContentVersion()
    } else {
      // no prod atm
      await createContentVersion()
    }
    //props.history.go(0)
  }

  const handlePubilsh = () => {
    setActionStatusId(flagMapSelected.published) // set to approved
    setVisiblePopup(!visiblePopup)
  }

  const isDraft =
    !mastersetupflag || mastersetupflag.status === 'draft' ? true : false
  const isPublished =
    !mastersetupflag || mastersetupflag.status === 'published' ? true : false
  const isApprove =
    !isDraft && mastersetupflag.status === 'approved' ? true : false

  if (isPublished) return null

  const items = [
    {
      user: ['masterpublisher', 'creator+approver+publisher'],
      action: handlePubilsh,
      label: 'Publish',
      status: true,
    },
  ]

  const checkItems = ({ status, user }) => {
    return items.filter(
      (item) => item.status === status && item.user.indexOf(user) > -1
    )
  }

  const menus = checkItems({ status: isApprove, user: user[0].user.username })
  const popupData = {
    ...props,
    toggle: visiblePopup,
    setToggle: setVisiblePopup,
    action: publishSetupExec,
  }

  if (environment === 'production') return null

  return (
    <Fragment>
      {menus.map((menu, index) => {
        return (
          <Fragment>
            <Buttons.Normal key={index} onClick={() => menu.action()}>
              {menu.label}
            </Buttons.Normal>
            &nbsp;
          </Fragment>
        )
      })}
      <CheckerPopup {...popupData} />
    </Fragment>
  )
}

export default Index
