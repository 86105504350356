import React, { useContext, Fragment, useState, useEffect } from 'react'

import { Buttons } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import { LocalStorage, FetchContextApiV2 } from '../../lib-atomic-functions'

import CheckerPopup from '../../app-popup-section-confirm/default'

import { useSnackbar } from 'notistack'
import CONFIG from '../config'

const CMSENDPOINTS = CONFIG.application.cms.api.endpoints

const CMSAPPROVECONTENTCONFIG = {
  url: CMSENDPOINTS.approvesetup.url,
  method: CMSENDPOINTS.approvesetup.method,
  headers: CMSENDPOINTS.approvesetup.headers,
  responseType: 'json',
}

const flagMap = {
  live: {
    draft: 4,
    approved: 5,
    published: 6,
  },
  uat: {
    draft: 1,
    approved: 2,
    published: 3,
  },
}
const flagMapSelected =
  process.env.REACT_APP_BUILD_ENV === 'production' ? flagMap.live : flagMap.uat

const Index = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { token } = LocalStorage.getLS().strapi
  const { user, item } = props
  const { id, environment, mastersetupflag } = item

  const { mastersetups } = useContext(GlobalContext)
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [actionStatusId, setActionStatusId] = useState(false)

  const checkCordinateFound = (contentItem) => {
    const emptyCoordFound =
      contentItem.startX === null ||
      contentItem.startY === null ||
      contentItem.endX === null ||
      contentItem.endY === null ||
      (contentItem.startX.toString().replace(/\s/g, '') === '0' &&
        contentItem.startY.toString().replace(/\s/g, '') === '0' &&
        contentItem.endX.toString().replace(/\s/g, '') === '0' &&
        contentItem.endY.toString().replace(/\s/g, '') === '0') ||
      contentItem.startX.toString().replace(/\s/g, '') === '' ||
      contentItem.startY.toString().replace(/\s/g, '') === '' ||
      contentItem.endX.toString().replace(/\s/g, '') === '' ||
      contentItem.endY.toString().replace(/\s/g, '') === ''
    const emptyCoordFoundForPostLogin =
      contentItem.startXForPostLogin === null ||
      contentItem.startYForPostLogin === null ||
      contentItem.endXForPostLogin === null ||
      contentItem.endYForPostLogin === null ||
      (contentItem.startXForPostLogin.toString().replace(/\s/g, '') === '0' &&
        contentItem.startYForPostLogin.toString().replace(/\s/g, '') === '0' &&
        contentItem.endXForPostLogin.toString().replace(/\s/g, '') === '0' &&
        contentItem.endYForPostLogin.toString().replace(/\s/g, '') === '0') ||
      contentItem.startXForPostLogin.toString().replace(/\s/g, '') === '' ||
      contentItem.startYForPostLogin.toString().replace(/\s/g, '') === '' ||
      contentItem.endXForPostLogin.toString().replace(/\s/g, '') === '' ||
      contentItem.endYForPostLogin.toString().replace(/\s/g, '') === ''
    return !emptyCoordFound || !emptyCoordFoundForPostLogin
  }
  const approveContentExec = async () => {
    const mastersetupsobj = mastersetups.state.data[0]
    const draftFound =
      mastersetupsobj.MobContents.some(
        (content) =>
          checkCordinateFound(content) &&
          (!content.mastercontents.mastercontentsflag ||
            (content.mastercontents.mastercontentsflag &&
              (content.mastercontents.mastercontentsflag.status === 'draft' ||
                content.mastercontents.mastercontentsflag.status ===
                  'pending')))
      ) ||
      mastersetupsobj.WebContents.some(
        (content) =>
          checkCordinateFound(content) &&
          (!content.mastercontents.mastercontentsflag ||
            (content.mastercontents.mastercontentsflag &&
              (content.mastercontents.mastercontentsflag.status === 'draft' ||
                content.mastercontents.mastercontentsflag.status ===
                  'pending')))
      )

    if (draftFound) {
      const message =
        'Draft/Pending contents found. Please approve all content to proceed. :('
      enqueueSnackbar(message, {
        variant: 'warning',
      })
    } else {
      mastersetups.state.data = {
        ...mastersetups.state.data,
        mastersetupflag: {
          id: actionStatusId,
        },
      }
      const body = mastersetups.state.data
      CMSAPPROVECONTENTCONFIG.headers.Authorization = 'Bearer ' + token
      const [approveContent] = FetchContextApiV2(CMSAPPROVECONTENTCONFIG)
      const fetchResult = await approveContent(
        { query: '/' + id, body },
        mastersetups
      )
      mastersetups.dispatch({
        type: 'SET',
        data: { ...item, mastersetupflag: fetchResult.mastersetupflag },
      })
      props.history.go(0)
    }
  }

  const handleApprove = () => {
    setActionStatusId(flagMapSelected.approved) // set to approved
    setVisiblePopup(!visiblePopup)
  }

  const handleCancelApprove = () => {
    setActionStatusId(flagMapSelected.draft) // set to draft
    setVisiblePopup(!visiblePopup)
  }

  const isDraft =
    !mastersetupflag || mastersetupflag.status === 'draft' ? true : false
  const isPublished =
    !mastersetupflag || mastersetupflag.status === 'published' ? true : false
  const isApprove =
    !isDraft && !isPublished && mastersetupflag.status === 'approved'
      ? true
      : false

  if (isPublished) return null

  const items = [
    {
      user: ['masterapprover', 'creator+approver+publisher'],
      action: handleApprove,
      label: 'Approve',
      status: false,
    },
    {
      user: ['masterapprover', 'creator+approver+publisher'],
      action: handleCancelApprove,
      label: 'Revert to Draft',
      status: true,
    },
  ]

  const checkItems = ({ status, user }) => {
    return items.filter(
      (item) => item.status === status && item.user.indexOf(user) > -1
    )
  }

  const menus = checkItems({ status: isApprove, user: user[0].user.username })
  const popupData = {
    ...props,
    toggle: visiblePopup,
    setToggle: setVisiblePopup,
    action: approveContentExec,
  }

  if (environment === 'production') return null

  return (
    <Fragment>
      {menus.map((menu, index) => {
        return (
          <Fragment>
            <Buttons.Normal key={index} onClick={() => menu.action()}>
              {menu.label}
            </Buttons.Normal>
            &nbsp;
          </Fragment>
        )
      })}
      <CheckerPopup {...popupData} />
    </Fragment>
  )
}

export default Index
