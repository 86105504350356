import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Main from './layout/main'

import { SnackbarProvider } from 'notistack'

const styles = {
  success: { backgroundColor: '#20c771' },
  warning: { backgroundColor: '#ff6726' },
}
const App = () => {
  if (process.env.NODE_ENV === 'production')
    console.log = function no_console() {}
  return (
    <SnackbarProvider
      classes={{
        variantSuccess: styles.success,
        variantWarning: styles.warning,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      maxSnack={3}
    >
      <Switch>
        {/* <Route exact path="/">
        <Redirect to="/home" component={Main} />
      </Route> */}
        <Route path="/:module" component={Main} />
        {/* <Route exact path="/404" component={Err404} />
      <Route path="/:module" component={Main} />
      <Redirect exact from="/" to="/home" />
      <Route component={Err404} /> */}
      </Switch>
    </SnackbarProvider>
  )
}

export default App
