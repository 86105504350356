import React, { useContext, Fragment } from 'react'

/* package */
import { SectionWrappListItem } from '../../lib-atomic-section'
import { WindowSize } from '../../lib-atomic-functions'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { GlobalContext } from '../../lib-atomic-context'
/* package */

/* modules */
import CONFIG from '../config'

import SectionControls from '../../app-user-section-controls/default'

/* CONSTANTS */

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    height: 80,
  },
}))

const Index = (props) => {
  const { width, height } = WindowSize()
  const classes = useStyles()
  const { login, cognitouser, cognitouserlist, role } = useContext(
    GlobalContext
  )
  const cognitoUserData = cognitouserlist.state.data
  const userRoles = role.state.data
  console.log(cognitoUserData, userRoles)
  const handleToDetail = ({ id }) => {
    props.history.push('/user/detail/' + id)
  }

  const controlData = {
    ...props,
    user: cognitouser.state.data,
    item: false,
    other: false,
  }

  return (
    <Fragment>
      <SectionControls {...controlData} />
      {cognitoUserData &&
        cognitoUserData.map((user, index) => {
          console.log(user, 'user')
          const { id, name, cognitoId } = user
          const userRole = userRoles.filter(
            (i) => i.user.role.toString() === user.user.role.toString()
          )
          const role =
            userRole.length > 0 ? userRole[0].name.toUpperCase() : false
          return (
            <SectionWrappListItem
              key={index}
              noOfCol={width < 820 ? 12 : width < 1150 ? 6 : 3}
            >
              <div
                className={classes.root}
                onClick={() => handleToDetail({ id })}
              >
                <Typography variant="h5">
                  <b>{cognitoId}</b>
                </Typography>
                <Typography variant="body2">
                  <small>
                    <b>{role}</b>
                  </small>
                </Typography>
              </div>
            </SectionWrappListItem>
          )
        })}
    </Fragment>
  )
}

export default Index
