import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERSETUPS = {
  url: CMSENDPOINTS.mastersetups.url,
  method: CMSENDPOINTS.mastersetups.method,
  headers: CMSENDPOINTS.mastersetups.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTVERSION = {
  url: CMSENDPOINTS.mastercontentsversions.url,
  method: CMSENDPOINTS.mastercontentsversions.method,
  headers: CMSENDPOINTS.mastercontentsversions.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { path, params } = props.match
  const {
    cognitouser,
    countrylanguage,
    mastersetups,
    mastercontentsversions,
    createmastersetup,
    createcontentversion,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await createmastersetup.dispatch({
      type: 'RESET',
    })
    await createcontentversion.dispatch({
      type: 'RESET',
    })
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await mastersetups.dispatch({
      type: 'RESET',
    })
    await mastercontentsversions.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }
  /* --- cognitouser */
  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- cognitouser */
  const fetchCountryLanguagesExecute = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master setups */
  const fetchMasterSetupsExecute = async ({ query }) => {
    CMSMASTERSETUPS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPS)
    mastersetups.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterSetups({ query }, mastersetups)
    mastersetups.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- master contents versions */
  const fetchMasterContentsVersionsExecute = async ({ query }) => {
    CMSMASTERCONTENTVERSION.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContentsVersions] = FetchContextApiV2(
      CMSMASTERCONTENTVERSION
    )
    mastercontentsversions.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContentsVersions(
      { query },
      mastercontentsversions
    )
    mastercontentsversions.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      fetchMasterContentsVersionsExecute({})
      const query = '?cognitoId=' + username
      fetchCognitoUserExecute({ query })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      if (cognitouser.state.data.length > 0) {
        let query = '?_limit=500&'
        const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
        mastercountrylist.map((country, index) => {
          if (index === 0) {
            query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
          } else {
            query =
              query + '&mastercountries.abbr=' + country.mastercountry.abbr
          }
        })
        fetchMasterSetupsExecute({ query: query })
      }
    }
  }, [cognitouser.state])

  useEffect(() => {
    if (
      loading &&
      mastersetups.state.data &&
      mastersetups.state.isStatus &&
      !mastersetups.state.isLoading
    ) {
      const mastersetupsobj = mastersetups.state.data
      const mastercountrylist =
        cognitouser.state.data[0].MasterUCCountries.filter((countryItem) => {
          const filterOut = mastersetupsobj.some(
            (setupItem) =>
              setupItem.mastercountries.abbr === countryItem.mastercountry.abbr
          )
          return filterOut ? false : true
        })
      let query = '?environment=development&'
      mastercountrylist.map((country, index) => {
        if (index === 0) {
          query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
        } else {
          query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
        }
      })
      fetchCountryLanguagesExecute({
        // query: query + '&environment=development',
        query: query,
      })
    }
  }, [mastersetups.state])

  const isContentVersionReady =
    mastercontentsversions.state.data &&
    mastercontentsversions.state.isStatus &&
    !mastercontentsversions.state.isLoading
      ? true
      : false
  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isCountryLangReady =
    countrylanguage.state.data &&
    countrylanguage.state.isStatus &&
    !countrylanguage.state.isLoading
      ? true
      : false
  const isMasterSetupsReady =
    mastersetups.state.data &&
    mastersetups.state.isStatus &&
    !mastersetups.state.isLoading
      ? true
      : false
  if (
    !loading ||
    !isContentVersionReady ||
    !isCognitoUserReady ||
    !isCountryLangReady ||
    !isMasterSetupsReady
  ) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
