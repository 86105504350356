import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitousers.url,
  method: CMSENDPOINTS.mastercongnitousers.method,
  headers: CMSENDPOINTS.mastercongnitousers.headers,
  responseType: 'json',
}
const CMSMASTERCOGNITOUSERDETAIL = {
  url: CMSENDPOINTS.mastercongnitouserdetail.url,
  method: CMSENDPOINTS.mastercongnitouserdetail.method,
  headers: CMSENDPOINTS.mastercongnitouserdetail.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRIES = {
  url: CMSENDPOINTS.mastercountries.url,
  method: CMSENDPOINTS.mastercountries.method,
  headers: CMSENDPOINTS.mastercountries.headers,
  responseType: 'json',
}
const CMSMASTERUSERROLES = {
  url: CMSENDPOINTS.role.url,
  method: CMSENDPOINTS.role.method,
  headers: CMSENDPOINTS.role.headers,
  responseType: 'json',
}
const Index = (props) => {
  const { id } = props.match.params
  const { token, username } = LocalStorage.getLS().strapi
  const [locationKeys, setLocationKeys] = useState([])
  const { cognitouser, cognitouserdetail, role, country } =
    useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    cognitouser.dispatch({
      type: 'RESET',
    })
    cognitouserdetail.dispatch({
      type: 'RESET',
    })
    role.dispatch({
      type: 'RESET',
    })
    country.dispatch({
      type: 'RESET',
    })
  }

  /* --- cognitouser */
  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- cognitouser */

  /* --- cognitouser */

  const fetchUserCountryExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSERDETAIL.headers.Authorization = 'Bearer ' + token
    const [fetchUserCountry] = FetchContextApiV2(CMSMASTERCOGNITOUSERDETAIL)
    cognitouserdetail.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchUserCountry({ query }, cognitouserdetail)
    cognitouserdetail.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- userroles */

  const fetchUserRolesExecute = async () => {
    CMSMASTERUSERROLES.headers.Authorization = 'Bearer ' + token
    const [fetchUserRoles] = FetchContextApiV2(CMSMASTERUSERROLES)
    role.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchUserRoles({}, role)
    role.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- countries */

  const fetchMasterCountriesExecute = async () => {
    const [fetchMasterCountries] = FetchContextApiV2(CMSMASTERCOUNTRIES)
    country.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterCountries({}, country)
    country.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    const query = '?id=' + id
    const query2 = '?cognitoId=' + username
    fetchUserCountryExecute({ query })
    fetchCognitoUserExecute({ query: query2 })
    fetchUserRolesExecute()
    fetchMasterCountriesExecute({})
    return () => {
      fetchResetExec()
    }
  }, [])

  // useEffect(() => {
  //   if (cognitouserdetail.state.data) {
  //   }
  // }, [cognitouserdetail.state])

  // useEffect(() => {
  //   if (role.state.data) {
  //   }
  // }, [role.state])

  const isCognitoUserReady =
    cognitouser.state &&
    !cognitouser.state.isLoading &&
    cognitouser.state.data &&
    cognitouser.state.isStatus
      ? true
      : false

  const isCognitoUserDetailReady =
    cognitouserdetail.state &&
    !cognitouserdetail.state.isLoading &&
    cognitouserdetail.state.data &&
    cognitouserdetail.state.isStatus
      ? true
      : false

  const isUserRolesReady =
    role.state &&
    !role.state.isLoading &&
    role.state.data &&
    role.state.isStatus
      ? true
      : false

  const isMasterCountriesReady =
    country.state &&
    !country.state.isLoading &&
    country.state.data &&
    country.state.isStatus
      ? true
      : false
      ? true
      : false

  console.log(cognitouser, role, country)
  if (
    !isCognitoUserReady ||
    !isCognitoUserDetailReady ||
    !isUserRolesReady ||
    !isMasterCountriesReady
  ) {
    return <Spinner.Popup />
  }
  return props.children
}

export default Index
