export default {
  thor: {
    moduleCode: {
      production: 'SUB_STORMBORN',
      development: 'SUB_STORMBORN',
    },
    programId: null,
  },
  api: {
    cognito: {
      production: {
        api: {
          invokeUrl:
            'https://5bltcq602h.execute-api.us-west-2.amazonaws.com/prod',
        },
        cognito: {
          region: 'ap-southeast-2',
          user_pool_id: 'ap-southeast-2_DnPw7azaB',
          app_client_id: '3c2p16o2mj64ng0qpb67c00sqe',
        },
      },
      development: {
        api: {
          invokeUrl:
            'https://5bltcq602h.execute-api.us-west-2.amazonaws.com/prod',
        },
        cognito: {
          region: 'ap-southeast-2',
          user_pool_id: 'ap-southeast-2_DnPw7azaB',
          app_client_id: '3c2p16o2mj64ng0qpb67c00sqe',
        },
      },
    },
    login: {
      url: {
        production:
          'https://figsvqulre.execute-api.ap-southeast-2.amazonaws.com/production/stormborn-gui-strapi-authorization',
        development:
          'https://figsvqulre.execute-api.ap-southeast-2.amazonaws.com/production/stormborn-gui-strapi-authorization',
      },
    },
    logger: {
      url: {
        production:
          'https://mm7vd2m953.execute-api.ap-southeast-2.amazonaws.com/test/',
        development:
          'https://mm7vd2m953.execute-api.ap-southeast-2.amazonaws.com/test/',
      },
    },
    tranxactor: {
      url: {
        // UAT TX Server
        production: 'https://subcarduat.tr4ns.com/tx-auth',
        development: 'https://subcarduat.tr4ns.com/tx-auth',
        // live TX Server
        // production: 'https://rewards.subway.co.uk/tx-auth',
        // development: 'https://rewards.subway.co.uk/tx-auth',
      },
    },
    cms: {
      url: {
        // local(non-beta)
        // production: 'https://strapi.tranxactor.com',
        // development: 'https://strapi.tranxactor.com',
        // live(beta)
        // production: 'https://strapi-sub.tranxactor.com',
        // development: 'https://strapi-sub.tranxactor.com',
        // old uat(beta)
        // production:
        //   'http://strapi-elb-2088322287.ap-northeast-2.elb.amazonaws.com',
        // development:
        //   'http://strapi-elb-2088322287.ap-northeast-2.elb.amazonaws.com',

        production: 'https://stormborn-strapi.tr4ns.com',
        development: 'https://stormborn-strapi.tr4ns.com',
      },
    },
    gui: {
      url: {
        production: 'https://thq-stormborn-gui.tr4ns.com',
        development: 'https://thq-stormborn-gui.tr4ns.com',
      },
    },
    stormborn: {
      url: {
        // UAT TX Server
        production: 'https://subcarduat.tr4ns.com/tx-auth',
        development: 'https://subcarduat.tr4ns.com/tx-auth',
        mockapi: 'https://subcarduat.tr4ns.com/mobilegateway',
        api: 'https://subcarduat.tr4ns.com/tx-sub',
        // live TX Server
        // production: 'https://rewards.subway.co.uk/tx-auth',
        // development: 'https://rewards.subway.co.uk/tx-auth',
        // mockapi: 'https://rewards.subway.co.uk/mobilegateway',
        // api: 'https://rewards.subway.co.uk/tx-sub',
      },
    },
    subcard: {
      url: {
        // UAT rewards
        production: 'https://subcarduat.tr4ns.com',
        development: 'https://subcarduat.tr4ns.com',
        // Live rewards
        // production: 'https://rewards.subway.co.uk',
        // development: 'https://rewards.subway.co.uk',
      },
    },
    subway: {
      url: {
        // UAT rewards
        production: 'https://subwayuat.tr4ns.com',
        development: 'https://subwayuat.tr4ns.com',
        // Live rewards
        // production: 'https://rewards.subway.co.uk',
        // development: 'https://rewards.subway.co.uk',
      },
    },
  },
}
