import axios from 'axios'
import appConf from '../../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const stormbornDomain = setup.setup.api.cms.url[process.env.NODE_ENV]
const guiDomain = setup.setup.api.gui.url[process.env.NODE_ENV]

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
const index = async ({ queryType, userObj, ref, comment, token }) => {
  // queryType:
  // 1. ApprovalRequest
  // 2. PublishRequest
  // 3. AcceptPublish
  // 4. DeclinePublish
  // 5. AcceptContent
  // 6. DeclineContent
  // 7. RejectContent

  // 1. sending approval email
  // 2. sending publish email
  // 3. declined approval email
  // 4. declined publish email
  const requestApprovalQuery =
    '?user.username=masterapprover&user.username=creator%2Bapprover%2Bpublisher'
  const requestPublishQuery =
    '?user.username=masterpublisher&user.username=creator%2Bapprover%2Bpublisher'
  const approvalAcceptOrDeclineSetupQuery =
    '?user.username=masterpublisher&user.username=creator%2Bapprover%2Bpublisher'
  const approvalAcceptOrDeclineContentQuery =
    '?user.username=masterapprover&user.username=creator%2Bapprover%2Bpublisher'
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  }
  const query =
    queryType === 'ApprovalRequest'
      ? requestApprovalQuery
      : 'PublishRequest'
      ? requestPublishQuery
      : queryType === 'AcceptPublish' || queryType === 'DeclinePublish'
      ? approvalAcceptOrDeclineSetupQuery
      : approvalAcceptOrDeclineContentQuery
  const fetchResult = await axios({
    method: 'GET',
    url: stormbornDomain + '/mastercognitousers' + query + '&_limit=500',
    headers: headers,
  })
  // below return list of recipient cognito ids
  const filteredRecipients = fetchResult.data
    .filter((recipient) => {
      return recipient.MasterUCCountries.some(
        (e) => e.mastercountry.abbr === ref.mastercountries.abbr
      )
    })
    .map((recipient) => {
      return {
        cognitoId: recipient.cognitoId,
        cognitoEmail: recipient.cognitoEmail,
      }
    })
  console.log(filteredRecipients)
  if (queryType === 'ApprovalRequest' || queryType === 'PublishRequest') {
    // just create pending notification -> will send out email to recipients
    await asyncForEach(filteredRecipients, async (recipient) => {
      const domain2 =
        queryType === 'ApprovalRequest'
          ? '/content/detail/' + ref.id
          : '/layout/preview/' + ref.id
      const createEntryToNotification = {
        sender: userObj.cognitoId,
        senderEmail: userObj.cognitoEmail,
        // senderObject: userObj,
        // senderObject: null,
        recipientObject: recipient,
        emailType: queryType,
        referenceId: ref.id,
        referenceObject: {
          id: ref.id,
          name: ref.name,
          title: ref.title,
          description: ref.description,
          link: guiDomain + domain2,
        },
        emailFlag: 'pending',
        comment: comment,
      }
      console.log(
        guiDomain,
        'CREATING NOTIFICATION ENTRY FOR APPROVAL REQUEST: ',
        createEntryToNotification
      )
      const sendToNotificationTable = await axios({
        method: 'POST',
        url: stormbornDomain + '/notifications',
        headers: headers,
        data: createEntryToNotification,
      })
    })
  } else {
    // accepting or decline the content/setup
    // update if there is existing notification / create one to send out email
    // 1. fetch notification and see if there is one
    // const query =
    //   '?referenceId=' +
    //   ref.id +
    //   '&emailFlag=pendingApprovalRequest&emailFlag=pendingPublishRequest'
    // const notificationsSentFound = await axios({
    //   method: 'GET',
    //   url: stormbornDomain + '/notifications' + query,
    //   headers: headers,
    // })
    // console.log(notificationsSentFound.data)
    // if (notificationsSentFound.data.length > 0) {
    //   // update notification and accept/decline content
    //   await asyncForEach(notificationsSentFound.data, async (noti) => {
    //     const updatedNoti = {
    //       ...noti,
    //       emailType: queryType,
    //       emailFlag: 'sent',
    //       comment: comment,
    //     }
    //     console.log(
    //       'UPDATING NOTIFICATION ENTRY FOR ACCEPT/DECLINE: ',
    //       updatedNoti
    //     )
    //     // const sendToNotificationTable = await axios({
    //     //   method: 'POST',
    //     //   url: stormbornDomain + '/notifications',
    //     //   headers: headers,
    //     //   data: updatedNoti,
    //     // })
    //   })
    // }

    // create notification and accept/decline content
    await asyncForEach(filteredRecipients, async (recipient) => {
      const domain2 =
        queryType === 'AcceptContent' ||
        queryType === 'DeclineContent' ||
        queryType === 'RejectContent'
          ? '/content/detail/' + ref.id
          : '/layout/preview/' + ref.id
      const createEntryToNotification = {
        sender: userObj.cognitoId,
        senderEmail: userObj.cognitoEmail,
        // senderObject: userObj,
        // senderObject: null,
        recipientObject: recipient,
        emailType:
          queryType === 'AcceptPublish'
            ? 'SetupAcceptedRequest'
            : queryType === 'DeclinePublish'
            ? 'SetupDeclinedRequest'
            : queryType === 'AcceptContent'
            ? 'ContentAcceptedRequest'
            : queryType === 'DeclineContent'
            ? 'ContentDeclinedRequest'
            : 'ContentReject',
        referenceId: ref.id,
        referenceObject: {
          id: ref.id,
          name: ref.name,
          title: ref.title,
          description: ref.description,
          link: guiDomain + domain2,
        },
        emailFlag: 'pending',
        comment: comment,
      }
      console.log(
        guiDomain,
        'CREATING NOTIFICATION ENTRY FOR ACCEPT/DECLINE: ',
        createEntryToNotification
      )
      const sendToNotificationTable = await axios({
        method: 'POST',
        url: stormbornDomain + '/notifications',
        headers: headers,
        data: createEntryToNotification,
      })
    })
  }
}

export default index
