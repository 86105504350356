import React, { Fragment, useEffect, useState, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'

import { GlobalContext } from '../../lib-atomic-context'
import { Inputs, Buttons } from '../../lib-atomic'
import {
  FormHook,
  WindowSize,
  LocalStorage,
  FetchContextApiV2,
} from '../../lib-atomic-functions'

import CONFIG from '../config'

import Selected from './selected'

/* CONSTANTS */
const REGISTRATIONOBJECTS = CONFIG.application.objects
const ENDPOINTS = CONFIG.application.api.endpoints
const REGISTRATIONOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { itemsSelected, setSelected } = props
  const defaultValues = itemsSelected
    ? itemsSelected.map((item) => item.name)
    : []
  console.log(itemsSelected, ' defaultValues')
  const { role } = useContext(GlobalContext)
  const [selection, setSelection] = React.useState(defaultValues)
  // const [selected, setSelected] = React.useState([])

  useEffect(() => {
    fetchroleExec()
  }, [])

  const fetchroleExec = async () => {
    const [fetchRole] = FetchContextApiV2(REGISTRATIONOBJECTSCONFIG)
    const fetchResult = await fetchRole({ body: false }, role)
    role.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const handleChange = (event) => {
    const e = event.target.value
    const x = e[e.length - 1]
    const y = [x]
    if (!x) {
      setSelection([])
      setSelected([])
    } else {
      const filtered = role.state.data.filter(
        (item) => y.indexOf(item.name) > -1
      )
      setSelected(filtered)
      setSelection(y)
    }
  }

  if (!role.state.data) return null
  const roles = role.state.data

  return (
    <Select
      placeholder="Example"
      className={classes.select}
      labelId="demo-mutiple-checkbox-label"
      id="demo-mutiple-checkbox"
      multiple
      value={selection}
      onChange={handleChange}
      input={<Input />}
      renderValue={(selected) => <Selected selected={selected} />}
      // MenuProps={MenuProps}
    >
      {roles.map((role, index) => (
        <MenuItem key={index} value={role.name}>
          <Checkbox checked={selection.indexOf(role.name) > -1} />
          <ListItemText primary={role.name} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default Index
