import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Change Password'
const server = null
const service = null

const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          createcontent: {
            headers: {
              'Content-Type': 'application/json',
            },
            // url: setup.setup.api.cms.url[environment] + '/upload',
            url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'POST',
          },
          uploadimages: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[environment] + '/upload',
            // url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'POST',
          },
          updatecognitouser: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[environment] + '/mastercognitousers',
            method: 'PUT',
          },
          updatecontent: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'PUT',
          },
        },
      },
      objects: {
        id: {
          placeholder: '',
          name: 'id',
          disabled: true,
          initial: '',
          label: 'Content ID',
          helperText: false,
          rules: false,
        },
        name: {
          placeholder: '',
          name: 'name',
          initial: '',
          label: 'Content Name',
          helperText: false,
          rules: ['isNotNull'],
        },
        title: {
          placeholder: 'Will be displayed in the app',
          name: 'title',
          initial: '',
          label: 'Title',
          helperText: false,
          rules: false,
        },
        description: {
          placeholder: 'Will be displayed in the app',
          name: 'description',
          initial: '',
          label: 'Description',
          helperText: false,
          rules: false,
        },
        contenttype: {
          name: 'contenttype',
          initial: '',
          label: 'Content Type',
          type: 'select',
          options: [],
          helperText: false,
          rules: ['isNotNull'],
        },
        contentformat: {
          name: 'contentformat',
          initial: '',
          label: 'Content Format',
          type: 'select',
          options: [],
          helperText: false,
          rules: false,
        },
        contentvisibility: {
          name: 'contentvisibility',
          initial: '',
          label: 'Content Visibility',
          type: 'select',
          options: [],
          helperText: false,
          rules: ['isNotNull'],
        },
        publishfrom: {
          placeholder: '',
          name: 'publishfrom',
          initial: '',
          label: 'Publish From',
          helperText: false,
          rules: false,
        },
        publishto: {
          placeholder: '',
          name: 'publishto',
          initial: '',
          label: 'Publish To',
          helperText: false,
          rules: false,
        },
        externallink: {
          placeholder: '',
          name: 'externallink',
          initial: '',
          label: 'External Link',
          helperText: false,
          rules: false,
        },
        externallinkios: {
          placeholder: '',
          name: 'externallinkios',
          initial: '',
          label: 'External Link IOS',
          helperText: false,
          rules: false,
        },
        externallinkandroid: {
          placeholder: '',
          name: 'externallinkandroid',
          initial: '',
          label: 'External Link Android',
          helperText: false,
          rules: false,
        },
        buttonvisibility: {
          placeholder: '',
          name: 'buttonvisibility',
          initial: false,
          label: 'Button Visibility',
          helperText: false,
          rules: false,
        },
        buttontitle: {
          placeholder: '',
          name: 'buttontitle',
          initial: '',
          label: 'Button Title',
          helperText: false,
          rules: false,
        },
        buttondestination: {
          placeholder: '',
          name: 'buttondestination',
          initial: '',
          label: 'Button Destination In Web',
          helperText: false,
          rules: false,
        },
        buttondestinationinapp: {
          placeholder: '',
          name: 'buttondestinationinapp',
          initial: '',
          label: 'Button Destination In App',
          helperText: false,
          rules: false,
        },
        tandcvisibility: {
          placeholder: '',
          name: 'tandcvisibility',
          initial: false,
          label: 'Terms and Conditions Visiblity',
          helperText: false,
          rules: false,
        },
        tandcclick: {
          placeholder: '',
          name: 'tandcclick',
          initial: '',
          label: 'Terms and Conditions Link',
          helperText: false,
          rules: false,
        },
        expandedtandcvisibility: {
          placeholder: '',
          name: 'expandedtandcvisibility',
          initial: false,
          label: 'Expanded Terms and Conditions Visiblity',
          helperText: false,
          rules: false,
        },
        expandedtandccopy: {
          placeholder: '',
          name: 'expandedtandccopy',
          initial: '',
          label: 'Expanded Terms and Conditions Copy',
          helperText: false,
          rules: false,
        },
        code: {
          placeholder: '',
          name: 'code',
          initial: '',
          label: 'Code',
          helperText: false,
          rules: false,
        },
        mastercountries: {
          name: 'mastercountries',
          initial: '',
          label: 'Country',
          type: 'select',
          options: [],
          helperText: false,
          rules: ['isNotNull'],
        },
        masterlanguages: {
          name: 'masterlanguages',
          initial: '',
          label: 'Language',
          type: 'select',
          options: [],
          helperText: false,
          rules: ['isNotNull'],
        },
        homeimage: {
          placeholder: '',
          name: 'homeimage',
          initial: '',
          label: 'Home Image',
          helperText: false,
          rules: false,
        },
        listimage: {
          placeholder: '',
          name: 'listimage',
          initial: '',
          label: 'List Image',
          helperText: false,
          rules: false,
        },
        detailimage: {
          placeholder: '',
          name: 'detailimage',
          initial: '',
          label: 'Detail Image',
          helperText: false,
          rules: false,
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Edit Content Page',
    prefix: '/content/edit/:id',
    // prefix: "/:country/:lang/drag_and_drop"
  },
  environment,
  setup,
}

export default config
