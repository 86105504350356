import React, { useState } from 'react'

/* package */
import { GlobalProvider } from '../../lib-atomic-context'
import { SectionWrappSingle, SectionWrappSingleDefault } from '../../lib-atomic-section'
/* package */

/* container */
import Container from '../../app-layout-container/default'
/* container */

/* form */
import Content from '../layout/content'
/* form */

const Index = (props) => {
  const [isNotCarousel, setIsNotCarousel] = useState(true)
  const [detailVisible, setDetailVisible] = useState(false)
  const toggleDetailVisibility = () => {
    setDetailVisible(!detailVisible)
  }
  // return <div>Preview Page</div>
  return (
    <Container.LayoutPreview {...props}>
      <SectionWrappSingleDefault
        {...{
          ...props,
          maxWidth: 'lg',
        }}
      >
        <Content
          {...{
            ...props,
            isNotCarousel,
            setIsNotCarousel,
            detailVisible,
            toggleDetailVisibility,
          }}
        />
      </SectionWrappSingleDefault>
    </Container.LayoutPreview>
  )
}

export default Index
