import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Change Password'
const server = null
const service = null
const options = {
  preference_options: [
    { name: 'Preference Type 1', value: '1' },
    { name: 'Preference Type 2', value: '2' },
  ],
}
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          list: {
            headers: {
              'Content-Type': 'application/json',
            },
            doc: 'https://tr4ns10.tr4ns.com/Westfield-Reporting',
            //url: "/Westfield-Reporting/report21?",
            url: setup.setup.api.tranxactor.url[environment] + '/configs',
            method: 'GET',
          },
          updatemasterbanners: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[environment] + '/masterbanners',
            method: 'PUT',
          },
        },
      },
      objects: {
        page: {
          name: 'page',
          initial: '',
          label: 'Page',
          type: 'select',
          options: [
            { name: 'Activation', value: 'Activation' },
            { name: 'ActivationCongrat', value: 'ActivationCongrat' },
            { name: 'ContactUs', value: 'ContactUs' },
            { name: 'Dashboard', value: 'Dashboard' },
            { name: 'ForgottenPassword', value: 'ForgottenPassword' },
            { name: 'Login', value: 'Login' },
            { name: 'LostCard', value: 'LostCard' },
            // { name: "SubRewards", value: "SubRewards" },
            { name: 'Subsquad', value: 'Subsquad' },
            { name: 'PreloginHome', value: 'PreloginHome' },
            { name: 'Registration', value: 'Registration' },
            { name: 'ResetPassword', value: 'ResetPassword' },
            { name: 'Verification', value: 'Verification' },
          ],
          helperText: false,
          rules: false,
        },
        contentselect: {
          name: 'contentselect',
          initial: '',
          label: 'Contents',
          type: 'select',
          options: [],
          helperText: false,
          rules: false,
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Add Content To Banner',
    prefix: '/layout/banner/:id',
    // prefix: "/:country/:lang/drag_and_drop"
  },
  environment,
  setup,
}

export default config
