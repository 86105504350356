import React, { useContext, Fragment, useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import ActionApprove from './approve'
import ActionPublish from './publish'
import ActionEdit from './edit'
import ActionAddSetupContent from './add_setup_content'
import ActionAddBannerContent from './add_banner_content'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { user, item } = props // action props

  return (
    <Fragment>
      <div className={classes.root}>
        <ActionAddBannerContent {...props} />
        <ActionAddSetupContent {...props} />
        <ActionEdit {...props} />
        <ActionApprove {...props} />
        <ActionPublish {...props} />
      </div>
    </Fragment>
  )
}

export default Index
