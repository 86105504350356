import React from 'react'

import CampaignListContainer from '../sub-container/campaign-list-container'
import CampaignDetailContainer from '../sub-container/campaign-detail-container'
import CampaignCreateContainer from '../sub-container/campaign-create-container'
import CampaignEditContainer from '../sub-container/campaign-edit-container'

const CampaignList = (props) => {
  return (
    <CampaignListContainer {...props}>{props.children}</CampaignListContainer>
  )
}
const CampaignDetail = (props) => {
  return (
    <CampaignDetailContainer {...props}>
      {props.children}
    </CampaignDetailContainer>
  )
}
const CampaignCreate = (props) => {
  return (
    <CampaignCreateContainer {...props}>
      {props.children}
    </CampaignCreateContainer>
  )
}
const CampaignEdit = (props) => {
  return (
    <CampaignEditContainer {...props}>{props.children}</CampaignEditContainer>
  )
}

export default {
  CampaignList,
  CampaignDetail,
  CampaignCreate,
  CampaignEdit,
}
