import React from 'react'

import { SectionWrappSingle } from '../../lib-atomic-section'
/* package */

/* container */
import Container from '../../app-content-container/default'
/* container */

/* form */
import Content from '../layout/content'
/* form */

const Index = (props) => {
  return (
    <Container.ContentCarouselEdit {...props}>
      <SectionWrappSingle
        {...{
          ...props,
          maxWidth: 'lg',
        }}
      >
        {/* <div>edit carousel</div> */}
        <Content {...props} />
      </SectionWrappSingle>
    </Container.ContentCarouselEdit>
  )
}

export default Index
