import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

/*
: properties
children
size
fullWidth
*/
const redTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff744a',
      main: '#ff744a',
      dark: '#ff744a',
      contrastText: '#fff',
    },
  },
})

const normalRed = (props) => {
  return (
    <ThemeProvider theme={redTheme}>
      <Button {...props} variant="contained" color="primary" type="submit">
        {props.children}
      </Button>
    </ThemeProvider>
  )
}

export default normalRed
