import React, { lazy, Suspense } from 'react'

/* package */
import { GlobalProvider } from '../../lib-atomic-context'
import { SectionWrappSingle } from '../../lib-atomic-section'
/* package */

/* container */
import UserManageContainer from '../../app-user-container/default'
/* container */

/* form */
import Info from '../layout/info'
import Content from '../layout/content'
/* form */

const index = (props) => {
  return (
    <UserManageContainer.UserDetail {...props}>
      <SectionWrappSingle {...props}>
        <Info {...props} />
        <Content {...props} />
      </SectionWrappSingle>
    </UserManageContainer.UserDetail>
  )
}

export default index
