import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const env = process.env.NODE_ENV
const config = {
  details: {
    env,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          approvesetup: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastersetups',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'PUT',
          },
          publishsetup: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastersetups',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'POST',
          },
          publishupdatesetup: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastersetups',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'PUT',
          },
          createmastercontentsversions: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercontentsversions',
            // url: setup.setup.api.cms.url[env] + '/mastercontents',
            method: 'POST',
          },
          mastercontentsversions: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercontentsversions',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'GET',
          },
          updatecontentversion: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercontentsversions',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'PUT',
          },
          updatecontent: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercontents',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'PUT',
          },
          updatedevsetup: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastersetups',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'PUT',
          },
          createlivesetup: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastersetups',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'POST',
          },
          createmastercountrylang: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercountrylanguages',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'POST',
          },
          createcontents: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercontents',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'POST',
          },
          deletecontent: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[env] + '/mastercontents',
            // url: setup.setup.api.cms.url[env] + '/auth/local',
            method: 'DELETE',
          },
        },
      },
    },
  },
  routes: {
    label: false,
    prefix: false,
  },
  env,
  setup,
}

export default config
