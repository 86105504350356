import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { WindowSize } from "../lib-atomic-functions";
import ListItem from "@material-ui/core/ListItem";

const useStyles = ({ windowWidth }) => {
  const styleFormatted = {
    labelContainer: {
      backgroundColor: "transparent",
      height: 35,
      paddingLeft: 5,
      paddingRight: 30,
      paddingTop: 5,
      paddingBottom: 5,
      borderStyle: "solid",
      borderColor: "transparent",
      "&:hover": {
        backgroundColor: "#f2f2f2",
        // color: "red"
        // borderColor: "red",
        // borderStyle: "solid",
        // transitionProperty: "color",
        // transition: "color 0.5s ease"
      },
    },
    label: {
      backgroundColor: "transparent",
      fontWeight: 600,
      color: "#000",
      borderColor: "transparent",
      fontSize:
        windowWidth < 435
          ? 9
          : windowWidth < 500
          ? 11
          : windowWidth < 600
          ? 13
          : 16,
      "&:hover": {
        color: "#000",
        // transition: "color 0.5s ease",
        textDecoration: "none",
      },
    },
  };
  return makeStyles((theme) => styleFormatted)();
};
const App = (props) => {
  const { width, height } = WindowSize();

  const classes = useStyles({ windowWidth: width });
  const url = props.match ? props.match.url : "";
  const { label, link } = props;
  return (
    <div style={{ width: "100%" }}>
      <ListItem className={classes.labelContainer} onClick={link}>
        <Link className={classes.label} to={"#"}>
          {label.toUpperCase()}
        </Link>
      </ListItem>
    </div>
  );
};

export default App;
