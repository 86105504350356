import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
}))

const Index = ({ children }) => {
  const classes = useStyles()

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={1}>
        {children}
      </Grid>
    </Container>
  )
}

export default Index
