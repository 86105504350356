import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERSETUPS = {
  url: CMSENDPOINTS.mastersetups.url,
  method: CMSENDPOINTS.mastersetups.method,
  headers: CMSENDPOINTS.mastersetups.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTSCONFIG = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const CMSMASTERBANNERSCONFIG = {
  url: CMSENDPOINTS.masterbanners.url,
  method: CMSENDPOINTS.masterbanners.method,
  headers: CMSENDPOINTS.masterbanners.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const { cognitouser, mastersetups, allcontents, masterbanners } =
    useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await mastersetups.dispatch({
      type: 'RESET',
    })
    await allcontents.dispatch({
      type: 'RESET',
    })
    await masterbanners.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }
  const fetchCognitoUserExec = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- master setups */
  const fetchMasterSetupsExecute = async ({ query }) => {
    CMSMASTERSETUPS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPS)
    mastersetups.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterSetups({ query }, mastersetups)
    mastersetups.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- master banner */
  const fetchMasterBannersExecute = async ({ query }) => {
    CMSMASTERBANNERSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterBanners] = FetchContextApiV2(CMSMASTERBANNERSCONFIG)
    masterbanners.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterBanners({ query }, masterbanners)
    masterbanners.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchAllMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchAllMasterContents] = FetchContextApiV2(CMSMASTERCONTENTSCONFIG)
    allcontents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchAllMasterContents({ query }, allcontents)
    allcontents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      const query = '?cognitoId=' + username
      fetchCognitoUserExec({ query })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      let query = '?id_in=' + id + '&'
      const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
      mastercountrylist.map((country, index) => {
        if (index === 0) {
          query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
        } else {
          query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
        }
      })
      fetchMasterSetupsExecute({ query })
    }
  }, [cognitouser.state])

  useEffect(() => {
    if (
      loading &&
      mastersetups.state.data &&
      mastersetups.state.isStatus &&
      !mastersetups.state.isLoading
    ) {
      if (mastersetups.state.data.length === 0) {
        props.history.push('/layout/list')
      }
      const countryAbbr = mastersetups.state.data[0]
        ? mastersetups.state.data[0].mastercountries.abbr
        : false
      const langAbbr = mastersetups.state.data[0]
        ? mastersetups.state.data[0].masterlanguages.abbr
        : false
      const qs =
        'mastercountries.abbr=' +
        countryAbbr +
        '&masterlanguages.abbr=' +
        langAbbr

      const qs2 =
        'mastercountries.abbr=' +
        countryAbbr +
        '&masterlanguages.abbr=' +
        langAbbr +
        '&type=Page&type=Carousel&mastercontentsflag.status=approved'
      console.log(qs, ' QS. .. ')
      fetchAllMasterContentsExecute({ query: '?' + qs2 })
      fetchMasterBannersExecute({ query: '?' + qs })
    } else {
      console.log(' not querying....')
    }
  }, [mastersetups.state])

  useEffect(() => {
    console.log(allcontents.state, masterbanners.state, ' MASTER...')
  }, [allcontents.state, masterbanners.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isMasterSetupReady =
    mastersetups.state.data &&
    mastersetups.state.data.length > 0 &&
    mastersetups.state.isStatus &&
    !mastersetups.state.isLoading
      ? true
      : false

  const isAllContentsReady =
    allcontents.state.data &&
    allcontents.state.isStatus &&
    !allcontents.state.isLoading
      ? true
      : false
  const isBannersReady =
    masterbanners.state.data &&
    masterbanners.state.isStatus &&
    !masterbanners.state.isLoading
      ? true
      : false
  if (
    !loading ||
    !isCognitoUserReady ||
    !isMasterSetupReady ||
    !isAllContentsReady ||
    !isBannersReady
  ) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
