import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
  FetchLoggerApi,
} from '../../lib-atomic-functions'
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import ImageRender from '../image-picker/image-picker'
/* package */

import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'
import { useSnackbar } from 'notistack'
// import { NotificationPopup } from '../../lib-atomic-section'
/* modules */
import CONFIG from '../config'

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

/* CONSTANTS */
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects

const CMSMASTERCONTENTSCREATECONFIG = {
  url: CMSENDPOINTS.updatecontent.url,
  method: CMSENDPOINTS.updatecontent.method,
  headers: CMSENDPOINTS.updatecontent.headers,
  responseType: 'json',
}
const CMSUPLOADIMAGECONFIG = {
  url: CMSENDPOINTS.uploadimages.url,
  method: CMSENDPOINTS.uploadimages.method,
  headers: CMSENDPOINTS.uploadimages.headers,
  responseType: 'json',
}
/* CONSTANTS */
/* CONSTANTS */
const FormControlLabelDetails = withStyles((theme) => ({
  root: {
    left: '100px',
    // position: "relative"
  },
  paper: {
    // padding: theme.spacing(3),
    textAlign: 'center',
    color: 'black',
    marginBottom: '50px',
  },
  inline: {
    display: 'flex',
  },
}))(FormControlLabel)
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#098d42',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chip: {
    margin: 2,
    height: 37,
    borderRadius: 18.5,
    color: 'white',
    backgroundColor: '#42a5f5',
    cursor: 'auto',
    '&:hover': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
    '&:focus': {
      color: 'white',
      backgroundColor: '#42a5f5',
    },
  },
  chipClose: {
    color: 'white',
    '&:hover': {
      color: '#e6e6e6',
    },
    '&:focus': {
      color: '#e6e6e6',
    },
  },
}))
const flagMap = {
  live: {
    draft: 5,
    pending: 6,
    approved: 7,
    live: 8,
  },
  uat: {
    draft: 1,
    pending: 4,
    approved: 2,
    live: 3,
  },
}
const flagMapSelected =
  process.env.REACT_APP_BUILD_ENV === 'production' ? flagMap.live : flagMap.uat

const Index = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  // const [isOpen, setIsOpen] = useState(false)
  // const [message, setMessage] = useState('')

  const { token, username } = LocalStorage.getLS().strapi
  const {
    isNotCarousel,
    setIsNotCarousel,
    detailVisible,
    toggleDetailVisibility,
  } = props
  const { width, height } = WindowSize()
  const [loading, setLoading] = useState(false)
  const [imageUploadIndex, setImageUploadIndex] = useState(0)
  const [imageUploadList, setImageUploadList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [langList, setLangList] = useState([])
  const classes = useStyles()
  const {
    countrylanguage,
    cognitouser,
    uploadhomeimage,
    contentdetail,
    contentdetailvalidity,
    updatecontent,
  } = useContext(GlobalContext)
  console.log(contentdetail, contentdetailvalidity)
  const contentdetailobj = contentdetail.state.data
  const mastercountrylanguageobj = countrylanguage.state.data

  // assign initial value
  Object.keys(OBJECT1).map((key) => {
    console.log(contentdetailobj, key)
    if (key === 'publishfrom') {
      if (OBJECT1[key].value) {
        OBJECT1[key].value = ''
      }
      OBJECT1[key].initial = contentdetailobj.PublishFrom
    } else if (key === 'publishto') {
      if (OBJECT1[key].value) {
        OBJECT1[key].value = ''
      }
      OBJECT1[key].initial = contentdetailobj.PublishTo
    } else if (key === 'contenttype') {
      OBJECT1[key].initial = contentdetailobj.type
    } else if (key === 'contentformat') {
      OBJECT1[key].initial = contentdetailobj.contentFormat
    } else if (key === 'contentvisibility') {
      OBJECT1[key].initial = contentdetailobj.contentVisibility
    } else if (key === 'externallink') {
      OBJECT1[key].initial = contentdetailobj.externalLink
    } else if (key === 'externallinkios') {
      OBJECT1[key].initial = contentdetailobj.externalLinkiOS
    } else if (key === 'externallinkandroid') {
      OBJECT1[key].initial = contentdetailobj.externalLinkAndroid
    } else if (key === 'buttonvisibility') {
      OBJECT1[key].initial = contentdetailobj.buttonVisibility
    } else if (key === 'buttontitle') {
      OBJECT1[key].initial = contentdetailobj.ButtonTitle
    } else if (key === 'buttondestination') {
      OBJECT1[key].initial = contentdetailobj.buttonDestination
    } else if (key === 'buttondestinationinapp') {
      OBJECT1[key].initial = contentdetailobj.buttonDestinationInApp
    } else if (key === 'tandcvisibility') {
      OBJECT1[key].initial = contentdetailobj.termsAndConditionsVisibility
    } else if (key === 'tandcclick') {
      OBJECT1[key].initial = contentdetailobj.termsAndConditionsLink
    } else if (key === 'expandedtandcvisibility') {
      OBJECT1[key].initial =
        contentdetailobj.expandedTermsAndConditionsVisibility
    } else if (key === 'expandedtandccopy') {
      OBJECT1[key].initial = contentdetailobj.expandedTermsAndConditionsCopy
    } else if (key === 'code') {
      OBJECT1[key].initial = contentdetailobj.Code
    } else if (key === 'homeimage') {
      if (contentdetailobj.homeImage !== null) {
        OBJECT1[key].initial = [contentdetailobj.homeImage]
      }
    } else if (key === 'listimage') {
      if (contentdetailobj.listImage !== null) {
        OBJECT1[key].initial = [contentdetailobj.listImage]
      }
    } else if (key === 'detailimage') {
      if (contentdetailobj.detailImage !== null) {
        OBJECT1[key].initial = [contentdetailobj.detailImage]
      }
    } else if (key === 'mastercountries') {
      OBJECT1[key].initial = contentdetailobj[key].id
      console.log(contentdetailobj[key], OBJECT1[key].initial)
    } else if (key === 'masterlanguages') {
      OBJECT1[key].initial = contentdetailobj[key].id
      console.log(OBJECT1[key].initial)
    } else {
      OBJECT1[key].initial = contentdetailobj[key]
    }
  })
  useEffect(() => {
    setHomeImageFile(OBJECT1.homeimage.initial)
    setListImageFile(OBJECT1.listimage.initial)
    setDetailImageFile(OBJECT1.detailimage.initial)
    return async () => {
      await resetUpdateContentExecute()
      await resetUploadImageExecute()
    }
  }, [])
  /* --- cognitouser */

  const fetchUpdateContentExecute = async ({ query, body, oldData }) => {
    CMSMASTERCONTENTSCREATECONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdateContent] = FetchContextApiV2(
      CMSMASTERCONTENTSCREATECONFIG
    )
    const fetchResult = await fetchUpdateContent({ query, body }, updatecontent)
    updatecontent.dispatch({
      type: 'SET',
      data: fetchResult,
    })

    /* -------------- */
    /* start: logger */
    await FetchLoggerApi({
      username,
      api: CMSMASTERCONTENTSCREATECONFIG,
      page: 'content-edit',
      oldData: oldData,
      newData: body,
    })
    /* end: logger */
    /* -------------- */
  }

  const resetUpdateContentExecute = () => {
    updatecontent.dispatch({
      type: 'RESET',
    })
  }

  const uploadImageExecute = async ({ query, body }) => {
    CMSUPLOADIMAGECONFIG.headers.Authorization = 'Bearer ' + token
    const [uploadImage] = FetchContextApiV2(CMSUPLOADIMAGECONFIG)
    const fetchResult = await uploadImage({ query, body }, uploadhomeimage)
    uploadhomeimage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const resetUploadImageExecute = () => {
    uploadhomeimage.dispatch({
      type: 'RESET',
    })
  }

  // drop zone
  const [homeImageFile, setHomeImageFile] = useState([])
  const [listImageFile, setListImageFile] = useState([])
  const [detailImageFile, setDetailImageFile] = useState([])

  const handleHomeImage = (acceptedFiles) => {
    setHomeImageFile(
      acceptedFiles.map((file) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          reader,
        })
      })
    )
  }
  const handleListImage = (acceptedFiles) => {
    setListImageFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    )
  }
  const handleDetailImage = (acceptedFiles) => {
    setDetailImageFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    )
  }

  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     homeImageFile.forEach((file) => URL.revokeObjectURL(file.preview))
  //   },
  //   [homeImageFile]
  // )
  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     listImageFile.forEach((file) => URL.revokeObjectURL(file.preview))
  //   },
  //   [listImageFile]
  // )
  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     detailImageFile.forEach((file) => URL.revokeObjectURL(file.preview))
  //   },
  //   [detailImageFile]
  // )

  const spliceHomeImage = (index) => {
    if (typeof homeImageFile === 'object') {
      setHomeImageFile([])
    } else {
      var oldThumbFiles = [...homeImageFile]
      oldThumbFiles.splice(index, 1)
      setHomeImageFile(oldThumbFiles)
    }
  }
  const spliceListImage = (index) => {
    var oldThumbFiles = [...listImageFile]
    oldThumbFiles.splice(index, 1)
    setListImageFile(oldThumbFiles)
  }
  const spliceDetailImage = (index) => {
    var oldThumbFiles = [...detailImageFile]
    oldThumbFiles.splice(index, 1)
    setDetailImageFile(oldThumbFiles)
  }

  useEffect(() => {
    const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
    const countryList = mastercountrylist
      .sort((a, b) => (a.mastercountry.name > b.mastercountry.name ? 1 : -1))
      .map((item) => {
        return { name: item.mastercountry.name, value: item.mastercountry.id }
      })

    const langList1 = mastercountrylanguageobj.map((item) => {
      return { name: item.masterlanguages.name, value: item.masterlanguages.id }
    })

    const langList = mastercountrylanguageobj
      .map((item) => {
        return {
          name: item.masterlanguages.name,
          value: item.masterlanguages.id,
        }
      })
      .filter((item, pos) => {
        return langList1.findIndex((t) => t.name === item.name) == pos
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))

    setCountryList(countryList)
    setLangList(langList)
  }, [])

  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true)
      if (
        (contentdetailobj.mastercontentsflag &&
          contentdetailobj.mastercontentsflag.status === 'approved') ||
        contentdetailobj.Environment === 'production'
      ) {
        // notify user that approved content cannot be edited
        // setMessage('Approved content cannot be modified :(')
        // setIsOpen(true)
        const message = 'Approved or production content cannot be modified :('
        enqueueSnackbar(message, {
          variant: 'warning',
        })
        setLoading(false)
      } else {
        console.log('submitted', values)
        const { params } = props.match
        const { id } = params
        const body = {
          id: id,
          name: values.name,
          title: isNotCarousel ? values.title : null,
          description: isNotCarousel ? values.description : null,
          type: values.contenttype,
          contentFormat: isNotCarousel ? values.contentformat : null,
          PublishFrom:
            values.publishfrom === null || values.publishfrom === ''
              ? null
              : moment(values.publishfrom),
          PublishTo:
            values.publishto === null || values.publishto === ''
              ? null
              : moment(values.publishto),
          externalLink: isNotCarousel ? values.externallink : null,
          masterlanguages: values.masterlanguages && values.masterlanguages,
          mastercountries: values.mastercountries && values.mastercountries,
          contentVisibility: values.contentvisibility,
          buttonVisibility: isNotCarousel ? values.buttonvisibility : null,
          buttonDestination: isNotCarousel ? values.buttondestination : null,
          termsAndConditionsVisibility: isNotCarousel
            ? values.tandcvisibility
            : null,
          expandedTermsAndConditionsCopy: isNotCarousel
            ? values.expandedtandccopy
            : null,
          termsAndConditionsLink: isNotCarousel ? values.tandcclick : null,
          ButtonTitle: isNotCarousel ? values.buttontitle : null,
          buttonDestinationInApp: isNotCarousel
            ? values.buttondestinationinapp
            : null,
          Code: isNotCarousel ? values.code : null,
          externalLinkiOS: isNotCarousel ? values.externallinkios : null,
          externalLinkAndroid: isNotCarousel
            ? values.externallinkandroid
            : null,
          mastercontentsflag: flagMapSelected.draft,
          CarouselContents: [],
          homeImage: isNotCarousel
            ? values.homeimage && values.homeimage
            : null,
          listImage: isNotCarousel
            ? values.listimage && values.listimage
            : null,
          detailImage: isNotCarousel
            ? values.detailimage && values.detailimage
            : null,
        }
        const query = '/' + values.id
        fetchUpdateContentExecute({ body, query, oldData: contentdetailobj })
      }
    }
  }
  const { values, onChange, onCheck, onPick, onSubmit, error } =
    FormHook.useFormHook(handleSubmit, OBJECT1)

  const formObject = {}
  Object.keys(OBJECT1).map((fieldItemName) => {
    if (fieldItemName === 'mastercountries') {
      OBJECT1[fieldItemName].options = countryList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'masterlanguages') {
      OBJECT1[fieldItemName].options = langList
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'contenttype') {
      OBJECT1[fieldItemName].options = [
        { name: 'Campaign', value: 'Campaign' },
        { name: 'Carousel', value: 'Carousel' },
        { name: 'History', value: 'History' },
        { name: 'InviteAFriend', value: 'InviteAFriend' },
        { name: 'IOU', value: 'IOU' },
        { name: 'News', value: 'News' },
        { name: 'Page', value: 'Page' },
        { name: 'Promotions', value: 'Promotions' },
        { name: 'PromotionsTargeted', value: 'PromotionsTargeted' },
        { name: 'QRCode', value: 'QRCode' },
        { name: 'Rewards', value: 'Rewards' },
        { name: 'StoreLocator', value: 'StoreLocator' },
        { name: 'Others', value: 'Others' },
        { name: 'External', value: 'External' },
        { name: 'iRO', value: 'iRO' },
        { name: 'MobileOrder', value: 'MobileOrder' },
      ]
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange: (e) => {
          console.log(e.target.value, isNotCarousel)
          if (e.target.value === 'Carousel') {
            setIsNotCarousel(false)
          } else {
            setIsNotCarousel(true)
          }
          return onChange(e)
        },
      }
    } else if (fieldItemName === 'contentformat') {
      OBJECT1[fieldItemName].options = [
        { name: 'Format1', value: 'Format1' },
        { name: 'Format2', value: 'Format2' },
        { name: 'Format3', value: 'Format3' },
        { name: 'Format4', value: 'Format4' },
      ]
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (fieldItemName === 'contentvisibility') {
      OBJECT1[fieldItemName].options = [
        { name: 'Pre Login', value: 'prelogin' },
        { name: 'Post Login', value: 'postlogin' },
      ]
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    } else if (
      fieldItemName === 'publishfrom' ||
      fieldItemName === 'publishto'
    ) {
      formObject[fieldItemName] = {
        ...props,
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: moment(values[fieldItemName]).format('LL'),
        formatDisplay: 'dd MMM yyyy',
        onPick,
      }
    } else if (fieldItemName === 'buttonvisibility') {
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange: (e) => onCheck(e),
      }
    } else if (fieldItemName === 'tandcvisibility') {
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange: (e) => onCheck(e),
      }
    } else if (fieldItemName === 'expandedtandcvisibility') {
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange: (e) => onCheck(e),
      }
    } else {
      formObject[fieldItemName] = {
        ...OBJECT1[fieldItemName],
        error: error[fieldItemName],
        value: values[fieldItemName],
        onChange,
      }
    }
  })
  useEffect(() => {
    if (uploadhomeimage.state.data && uploadhomeimage.state.isStatus) {
      resetUpdateContentExecute({})
      const index = imageUploadIndex + 1
      const proceed = index < imageUploadList.length
      console.log(imageUploadList, index, proceed)
      setImageUploadIndex(index)
      if (proceed) {
        const token = LocalStorage.getLS().strapi
        const newHeader = {
          ...CMSUPLOADIMAGECONFIG.headers,
          Authorization: 'Bearer ' + token.token,
        }
        uploadImageExecute({ newHeader, body: imageUploadList[index] })
      } else {
        props.history.push('/content/list')
      }
    }
  }, [uploadhomeimage.state])
  useEffect(() => {
    if (updatecontent.state.data && updatecontent.state.isStatus) {
      const id = updatecontent.state.data.id
      const preparedArr = []
      if (
        homeImageFile.length === 1 &&
        homeImageFile[0].preview !== undefined
      ) {
        const formDHome = new FormData()
        formDHome.append('files', homeImageFile[0])
        formDHome.append('refId', id)
        formDHome.append('ref', 'mastercontents')
        formDHome.append('field', 'homeImage')
        preparedArr.push(formDHome)
      }

      if (
        listImageFile.length === 1 &&
        listImageFile[0].preview !== undefined
      ) {
        const formDList = new FormData()
        formDList.append('files', listImageFile[0])
        formDList.append('refId', id)
        formDList.append('ref', 'mastercontents')
        formDList.append('field', 'listImage')
        preparedArr.push(formDList)
      }

      if (
        detailImageFile.length === 1 &&
        detailImageFile[0].preview !== undefined
      ) {
        const formDDetail = new FormData()
        formDDetail.append('files', detailImageFile[0])
        formDDetail.append('refId', id)
        formDDetail.append('ref', 'mastercontents')
        formDDetail.append('field', 'detailImage')
        preparedArr.push(formDDetail)
      }
      setImageUploadList(preparedArr)

      const token = LocalStorage.getLS().strapi
      const newHeader = {
        ...CMSUPLOADIMAGECONFIG.headers,
        Authorization: 'Bearer ' + token.token,
      }
      console.log(preparedArr)
      resetUpdateContentExecute({})
      if (preparedArr.length > 0) {
        uploadImageExecute({ newHeader, body: preparedArr[0] })
      } else {
        props.history.push('/content/list')
      }
    }
  }, [updatecontent.state])
  const submitBtn = {
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }
  const toggleBtn = {
    style: { marginTop: 30, marginBottom: 40, float: 'right' },
    onClick: () => toggleDetailVisibility(),
  }
  return (
    <div
      style={{
        flex: 1,
        marginTop: 15,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'wrap',
        position: 'relative',
        width: '100%',
        height: 'auto',
      }}
    >
      {/* <NotificationPopup {...{ isOpen, setIsOpen, messagePassed: message }} /> */}
      <form onSubmit={onSubmit} noValidate>
        <>
          <div
            style={{
              display: width > 1000 ? 'flex' : 'block',
              width: '100%',
            }}
          >
            <div
              style={{
                width: isNotCarousel
                  ? width > 1000
                    ? detailVisible
                      ? '50%'
                      : '100%'
                    : '100%'
                  : '100%',
                padding: 10,
              }}
            >
              <Inputs.Text {...formObject.id} />
              <Inputs.Text {...formObject.name} />
              <Inputs.Select {...formObject.mastercountries} />
              <Inputs.Select {...formObject.masterlanguages} />
              <Inputs.Select {...formObject.contenttype} />
              {isNotCarousel && <Inputs.Text {...formObject.code} />}
              {isNotCarousel && (
                <>
                  <Inputs.Text {...formObject.title} />
                  <Inputs.Textarea
                    rowsMax={8}
                    rows={4}
                    {...formObject.description}
                  />
                </>
              )}
              <Inputs.Date {...formObject.publishfrom} />

              <Inputs.Date {...formObject.publishto} />

              <Inputs.Select {...formObject.contentvisibility} />
              {isNotCarousel && (
                <div
                  style={{
                    display: 'flex',
                    // height: '100%',
                    width: '100%',
                    margin: 'auto',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 10,
                  }}
                >
                  <div style={{}}>
                    <p
                      style={{
                        padding: 10,
                        paddingTop: 10,
                        paddingBottom: 5,
                      }}
                    >
                      Home Image
                    </p>
                    <ImageRender
                      {...{
                        OBJECT1,
                        fieldName: 'homeimage',
                        imgFile: homeImageFile,
                        handleImg: handleHomeImage,
                        spliceImage: spliceHomeImage,
                      }}
                    />
                  </div>
                  <div style={{}}>
                    <p
                      style={{
                        padding: 10,
                        paddingTop: 10,
                        paddingBottom: 5,
                      }}
                    >
                      List Image
                    </p>
                    <ImageRender
                      {...{
                        OBJECT1,
                        fieldName: 'listimage',
                        imgFile: listImageFile,
                        handleImg: handleListImage,
                        spliceImage: spliceListImage,
                      }}
                    />
                  </div>
                  <div style={{}}>
                    <p
                      style={{
                        padding: 10,
                        paddingTop: 10,
                        paddingBottom: 5,
                      }}
                    >
                      Detail Image
                    </p>
                    <ImageRender
                      {...{
                        OBJECT1,
                        fieldName: 'detailimage',
                        imgFile: detailImageFile,
                        handleImg: handleDetailImage,
                        spliceImage: spliceDetailImage,
                      }}
                    />
                  </div>
                </div>
              )}
              {isNotCarousel && !detailVisible && (
                <div
                  style={{
                    display: 'inline',
                    color: 'grey',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    margin: 'auto',
                  }}
                >
                  <Buttons.Text {...toggleBtn}>
                    {!detailVisible
                      ? 'Show Detailed Functionality'
                      : 'Hide Detailed Functionality'}
                  </Buttons.Text>
                </div>
              )}
            </div>
            {detailVisible
              ? isNotCarousel && (
                  <div
                    style={{
                      width: width > 1000 ? '50%' : '100%',
                      padding: 10,
                    }}
                  >
                    <Inputs.Select {...formObject.contentformat} />

                    <Inputs.Text {...formObject.externallink} />
                    <Inputs.Text {...formObject.externallinkios} />
                    <Inputs.Text {...formObject.externallinkandroid} />

                    <div
                      style={{
                        display: 'flex',
                        // height: '100%',
                        width: '100%',
                        margin: 'auto',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          justifyContent: 'center',
                          width: 150,
                          paddingLeft: 10,
                          marginRight: 15,
                        }}
                      >
                        Button Visibility
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          // width: 'auto',
                          textAlign: 'end',
                          justifyContent: 'center',
                          float: 'left',
                        }}
                      >
                        <FormControlLabelDetails
                          control={
                            <IOSSwitch {...formObject.buttonvisibility} />
                          }
                          id="panel1d-header"
                          aria-controls="panel1d-content"
                        />
                      </div>
                    </div>
                    <Inputs.Text {...formObject.buttontitle} />
                    <Inputs.Text {...formObject.buttondestination} />
                    <Inputs.Text {...formObject.buttondestinationinapp} />

                    <div
                      style={{
                        display: 'flex',
                        // height: '100%',
                        width: '100%',
                        margin: 'auto',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          justifyContent: 'center',
                          width: 250,
                          paddingLeft: 10,
                          marginRight: 15,
                        }}
                      >
                        Terms & Conditions Visibility
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          // width: 'auto',
                          textAlign: 'end',
                          justifyContent: 'center',
                          float: 'left',
                        }}
                      >
                        <FormControlLabelDetails
                          control={
                            <IOSSwitch {...formObject.tandcvisibility} />
                          }
                          id="panel1d-header"
                          aria-controls="panel1d-content"
                        />
                      </div>
                    </div>

                    <Inputs.Text {...formObject.tandcclick} />
                    <div
                      style={{
                        display: 'flex',
                        // height: '100%',
                        width: '100%',
                        margin: 'auto',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          justifyContent: 'center',
                          width: 300,
                          paddingLeft: 10,
                          marginRight: 15,
                        }}
                      >
                        Expanded Terms & Conditions Visibility
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          // width: 'auto',
                          textAlign: 'end',
                          justifyContent: 'center',
                          float: 'left',
                        }}
                      >
                        <FormControlLabelDetails
                          control={
                            <IOSSwitch
                              {...formObject.expandedtandcvisibility}
                            />
                          }
                          id="panel1d-header"
                          aria-controls="panel1d-content"
                        />
                      </div>
                    </div>
                    <Inputs.Textarea
                      rowsMax={8}
                      rows={4}
                      {...formObject.expandedtandccopy}
                    />
                    {isNotCarousel && detailVisible && (
                      <div
                        style={{
                          display: 'inline',
                          color: 'grey',
                          cursor: 'pointer',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'center',
                          margin: 'auto',
                        }}
                      >
                        <Buttons.Text {...toggleBtn}>
                          {!detailVisible
                            ? 'Show Detailed Functionality'
                            : 'Hide Detailed Functionality'}
                        </Buttons.Text>
                      </div>
                    )}
                  </div>
                )
              : null}
          </div>
        </>
        <Spinner.Loader color="#fff" size={24} loading={loading} />
        <div style={{ height: 50 }} />
        <Buttons.Normal {...submitBtn}>UPDATE</Buttons.Normal>
      </form>
    </div>
  )
}

export default Index
