import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'
/* package */
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { SectionListItem } from '../../lib-atomic-section'
import { WindowSize } from '../../lib-atomic-functions'
/* package */

/* modules */

/* CONSTANTS */

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const Index = (props) => {
  const { width, height } = WindowSize()
  const { cognitouser } = useContext(GlobalContext)
  const [showCreateBtn, setShowCreateBtn] = useState(false)
  const [contentTypeSelected, setContentTypeSelected] = useState(null)
  const [countrySelected, setCountrySelected] = useState(null)
  const [visibilitySelected, setVisibilitySelected] = useState(null)
  const [statusFlagSelected, setStatusFlagSelected] = useState(null)
  const [orderBy, setOrderBy] = useState('updated_at')
  const [isAsc, setIsAsc] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { rawContent, setFilteredContent } = props
  const mastercountrylist = cognitouser.state.data[0].MasterUCCountries.map(
    (country) => {
      return {
        name: country.mastercountry.name,
        value: country.mastercountry,
      }
    }
  )
  useEffect(() => {
    // only creator can create content
    const userrole = cognitouser.state.data[0].user.username
    if (
      userrole === 'mastercreator' ||
      userrole === 'creator+approver+publisher'
    ) {
      setShowCreateBtn(true)
    } else {
      setShowCreateBtn(false)
    }
  }, [])
  useEffect(() => {
    const userrole = cognitouser.state.data[0].user.username
    if (
      userrole === 'mastercreator' ||
      userrole === 'creator+approver+publisher'
    ) {
      setShowCreateBtn(true)
    } else {
      setShowCreateBtn(false)
    }
  }, [cognitouser.state])
  useEffect(() => {
    const filtered = rawContent.filter((content) => {
      const condition1 = contentTypeSelected
        ? contentTypeSelected === 'All'
          ? true
          : content.type === contentTypeSelected
        : true
      const condition2 = countrySelected
        ? countrySelected === 'All'
          ? true
          : content.mastercountries.abbr === countrySelected.abbr
        : true
      const condition3 = visibilitySelected
        ? visibilitySelected === 'All'
          ? true
          : content.contentVisibility === visibilitySelected
        : true
      const condition4 = statusFlagSelected
        ? statusFlagSelected === 'All'
          ? true
          : statusFlagSelected === 'draft'
          ? content.mastercontentsflag === null ||
            content.mastercontentsflag.status === statusFlagSelected
          : content.mastercontentsflag &&
            content.mastercontentsflag.status === statusFlagSelected
        : true
      return condition1 && condition2 && condition3 && condition4
    })
    const variableToSortBy = orderBy ? orderBy : 'id'
    const sorted =
      filtered &&
      filtered.sort((a, b) => {
        if (variableToSortBy === 'id') {
          return isAsc
            ? a[variableToSortBy] - b[variableToSortBy]
            : b[variableToSortBy] - a[variableToSortBy]
        } else {
          return isAsc
            ? moment(a[variableToSortBy]) - moment(b[variableToSortBy])
            : moment(b[variableToSortBy]) - moment(a[variableToSortBy])
        }
      })
    setFilteredContent(sorted)
  }, [
    rawContent,
    contentTypeSelected,
    countrySelected,
    visibilitySelected,
    statusFlagSelected,
    orderBy,
    isAsc,
  ])

  const optionAll = [{ name: 'All', value: 'All' }]
  const typeSelector = {
    name: 'typetoggle',
    initial: [],
    label: 'Content Type',
    type: 'select',
    options: [
      ...optionAll,
      { name: 'Campaign', value: 'Campaign' },
      { name: 'Carousel', value: 'Carousel' },
      { name: 'History', value: 'History' },
      { name: 'InviteAFriend', value: 'InviteAFriend' },
      { name: 'IOU', value: 'IOU' },
      { name: 'News', value: 'News' },
      { name: 'Page', value: 'Page' },
      { name: 'Promotions', value: 'Promotions' },
      { name: 'PromotionsTargeted', value: 'PromotionsTargeted' },
      { name: 'QRCode', value: 'QRCode' },
      { name: 'Rewards', value: 'Rewards' },
      { name: 'StoreLocator', value: 'StoreLocator' },
      { name: 'Others', value: 'Others' },
      { name: 'External', value: 'External' },
      { name: 'iRO', value: 'iRO' },
      { name: 'MobileOrder', value: 'MobileOrder' },
    ],
    helperText: false,
    rules: false,
    value: contentTypeSelected,
    onChange: (e) => {
      setContentTypeSelected(e.target.value)
    },
  }

  const statusFlagSelector = {
    name: 'statusFlagToggle',
    initial: [],
    label: 'Status',
    type: 'select',
    options: [
      ...optionAll,
      { name: 'Draft', value: 'draft' },
      { name: 'Pending', value: 'pending' },
      { name: 'Approved', value: 'approved' },
      { name: 'Live', value: 'live' },
    ],
    helperText: false,
    rules: false,
    value: statusFlagSelected,
    onChange: (e) => {
      setStatusFlagSelected(e.target.value)
    },
  }

  const visibilitySelector = {
    name: 'visibilityToggle',
    initial: [],
    label: 'Visibility',
    type: 'select',
    options: [
      ...optionAll,
      { name: 'Pre Login', value: 'prelogin' },
      { name: 'Post Login', value: 'postlogin' },
    ],
    helperText: false,
    rules: false,
    value: visibilitySelected,
    onChange: (e) => {
      setVisibilitySelected(e.target.value)
    },
  }

  const countrySelector = {
    name: 'countrytoggle',
    initial: [],
    label: 'Content Country',
    type: 'select',
    options: [...optionAll, ...mastercountrylist],
    helperText: false,
    rules: false,
    value: countrySelected,
    onChange: (e) => {
      setCountrySelected(e.target.value)
    },
  }

  const orderBySelector = {
    name: 'orderBy',
    initial: [],
    label: 'Order By',
    type: 'select',
    // style: { width: '100%' },
    options: [
      {
        name: 'ID',
        value: 'id',
        link: () => {
          setOrderBy('id')
          setAnchorEl(!anchorEl)
        },
      },
      {
        name: 'Create Date',
        value: 'created_at',
        link: () => {
          setOrderBy('created_at')
          setAnchorEl(!anchorEl)
        },
      },
      {
        name: 'Update Date',
        value: 'updated_at',
        link: () => {
          setOrderBy('updated_at')
          setAnchorEl(!anchorEl)
        },
      },
    ],
    helperText: false,
    rules: false,
    value: orderBy,
    onClick: (e) => {
      console.log(e)
      handleClick(e)
    },
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const submitButton = {
    fullWidth: true,
    disabled: !showCreateBtn,
    onClick: () => {
      props.history.push('/content/create')
    },
  }
  return (
    <Fragment>
      <div
        style={{
          display: width < 1200 ? 'inline' : 'flex',
          height: '100%',
          width: '100%',
          margin: 'auto',
          // marginRight: width < 1200 ? (width < 600 ? 'auto' : 'auto') : 'auto',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: width < 600 ? '100%' : width < 1200 ? '70%' : 'auto',
            height: '100%',
            // marginLeft: 20,
            // marginRight: width < 700 ? '10%' : 10,
            justifyContent: 'space-between',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              display: 'inline-block',
              height: '100%',
              width: width < 600 ? '30%' : width < 1200 ? 160 : 160,
              margin: 'auto',
              justifyContent: 'center',
            }}
          >
            <Buttons.Text {...orderBySelector}>
              {
                orderBySelector.options.filter((i) => i.value === orderBy)[0]
                  .name
              }
            </Buttons.Text>
          </div>

          <div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {orderBySelector.options.map((menuItem) => {
                return (
                  <div
                    style={{
                      // padding: 10,
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        margin: 'auto',
                      }}
                    >
                      <SectionListItem
                        {...{
                          props,
                          ...{
                            label: menuItem.name,
                            link: menuItem.link,
                          },
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </Popover>
          </div>
          <div
            style={{
              display: width < 1200 ? 'flex' : 'inline',
              height: '100%',
              margin: 'auto',
              // marginRight: width < 600 ? 'auto' : '20%',
              justifyContent: width < 1200 ? 'space-between' : 'center',
            }}
          >
            <Buttons.Text onClick={() => setIsAsc(!isAsc)}>
              {!isAsc ? (
                <KeyboardArrowUpIcon fill="green" />
              ) : (
                <KeyboardArrowDownIcon fill="green" />
              )}
            </Buttons.Text>
          </div>
          <div
            style={{
              display: width < 1200 ? 'inline-block' : 'none',
              height: '100%',
              width: width < 600 ? '40%' : '40%',
              // margin: 10,
              // marginTop: width < 1200 ? 20 : 10,
              justifyContent: 'flex-end',
            }}
          >
            <Buttons.Normal {...submitButton}>
              {width < 800 ? '+' : '+ Create New Content'}
            </Buttons.Normal>
          </div>
        </div>
        <div
          style={{
            display: width < 750 ? 'inline' : 'inline-block',
            // width: 170,
            // margin: 10,
            justifyContent: 'center',
            minWidth: 170,
          }}
        >
          <Inputs.Select {...typeSelector} />
        </div>
        <div
          style={{
            display: width < 750 ? 'inline' : 'inline-block',
            // width: 170,
            // margin: 10,
            justifyContent: 'center',
            minWidth: 170,
          }}
        >
          <Inputs.Select {...countrySelector} />
        </div>
        <div
          style={{
            display: width < 750 ? 'inline' : 'inline-block',
            // width: 170,
            // margin: 10,
            justifyContent: 'center',
            minWidth: 170,
          }}
        >
          <Inputs.Select {...visibilitySelector} />
        </div>
        <div
          style={{
            display: width < 750 ? 'inline' : 'inline-block',
            // width: 170,
            // margin: 10,
            justifyContent: 'center',
            minWidth: 170,
          }}
        >
          <Inputs.Select {...statusFlagSelector} />
        </div>
        <div
          style={{
            display: width < 1200 ? 'none' : 'inline-block',
            // width: 170,
            // margin: 10,
            justifyContent: 'center',
            minWidth: 170,
          }}
        >
          <Buttons.Normal {...submitButton}>
            + Create New Content
          </Buttons.Normal>
        </div>
      </div>
    </Fragment>
  )
}

export default Index
