import React, { useContext, Fragment, useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  small: {
    height: 'auto',
    // minHeight: 130,
    // maxHeight: 130,
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  large: {
    height: 'auto',
    // minHeight: 300,
    // maxHeight: 300,
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Index = (props) => {
  const [imgWidth1, setImgWidth1] = useState('N/A')
  const [imgHeight1, setImgHeight1] = useState('N/A')
  const [imgWidth2, setImgWidth2] = useState('N/A')
  const [imgHeight2, setImgHeight2] = useState('N/A')
  const [imgWidth3, setImgWidth3] = useState('N/A')
  const [imgHeight3, setImgHeight3] = useState('N/A')
  const classes = useStyles()
  const { type, images, size, isDetail } = props
  const sizeStyle = size === 'small' ? classes.small : classes.large
  const heightSizeForDetailPage =
    props.match &&
    (props.match.path === '/content/detail/:id' ||
      props.match.path === '/campaign/detail/:id')
      ? null
      : size === 'small'
      ? 130
      : 300
  if (type === 'Carousel') return false
  const ImageComponent = ({ image, index }) => {
    const width =
      image.name === 'Home Image'
        ? imgWidth1
        : image.name === 'List Image'
        ? imgWidth2
        : imgWidth3
    const height =
      image.name === 'Home Image'
        ? imgHeight1
        : image.name === 'List Image'
        ? imgHeight2
        : imgHeight3
    return (
      <div
        key={index}
        className={sizeStyle}
        style={{
          minHeight: heightSizeForDetailPage && heightSizeForDetailPage,
          maxHeight: heightSizeForDetailPage && heightSizeForDetailPage,
        }}
      >
        {image && image.img && image.img.url ? (
          <div>
            {image.name && image.name !== 'Detail Image' && (
              <p
                style={{
                  color: '#616161',
                  fontWeight: 700,
                  textAlign: 'left',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {image.name} (width: {width}, height: {height})
              </p>
            )}
            <img
              width="100%"
              height="100%"
              src={image.img ? image.img.url : ''}
              onLoad={({ target: img }) => {
                // console.log(img)
                if (image.name === 'Home Image') {
                  setImgWidth1(img.offsetWidth)
                  setImgHeight1(img.offsetHeight)
                } else if (image.name === 'List Image') {
                  setImgWidth2(img.offsetWidth)
                  setImgHeight2(img.offsetHeight)
                } else if (image.name === 'Detail Image') {
                  setImgWidth3(img.offsetWidth)
                  setImgHeight3(img.offsetHeight)
                }
              }}
            />
          </div>
        ) : (
          <div>
            {image.name && (
              <p
                style={{
                  color: '#616161',
                  fontWeight: 700,
                  textAlign: 'left',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto',
                  paddingTop: 50,
                  paddingBottom: 10,
                }}
              >
                {image.name}
              </p>
            )}
            <p
              style={{
                height: 'auto',
                minHeight: 300,
                maxHeight: 300,
                backgroundColor: 'grey',
                color: 'white',
                fontWeight: 700,
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto',
                paddingTop: 50,
              }}
            >
              Image Not Available
            </p>
          </div>
        )}
      </div>
    )
  }

  return (
    <Fragment>
      {images.map((image, index) => {
        if (isDetail) {
          if (image.name === 'Detail Image') {
            return <ImageComponent {...{ image, index }} />
          }
        } else {
          if (image.name !== 'Detail Image') {
            return <ImageComponent {...{ image, index }} />
          }
        }
      })}
    </Fragment>
  )
}

export default Index
