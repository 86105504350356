import React, { useState, useEffect } from 'react'

/* mui */
import Popover from '@material-ui/core/Popover'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { ModuleText } from '../../lib-atomic-section'
/* mui */

import CONFIG from '../config'

/* CONSTANTS */
const MEDIAPATH = CONFIG.setup.assets.media
/* CONSTANTS */
var Format1 = {
  container: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    flex: '0 1 auto',
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
    paddingBottom: 10,
  },
  description: {
    flex: '0 1 auto',
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
    paddingBottom: 10,
  },
}
var Format2 = {
  container: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    flex: '0 1 auto',
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
    paddingBottom: 10,
  },
  description: {
    flex: '0 1 auto',
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
    paddingBottom: 0,
  },
}
var Format3 = {
  container: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignContent: 'stretch',
    alignItems: 'stretch',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
    paddingBottom: 10,
    //  order: "0",
    flex: '0 1 auto',
    alignSelf: 'center',
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
    //  order: "0",
    flex: '0 1 auto',
    alignSelf: 'center',
    textAlign: 'center',
  },
}
var Format4 = {
  container: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'stretch',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    flex: '0 1 auto',
    position: 'absolute',
    top: 20,
    fontSize: 25,
    fontWeight: 700,
    color: '#098d42',
  },
  description: {
    flex: '0 1 auto',
    position: 'absolute',
    bottom: 20,
    justifyContent: 'flex-end',
    fontSize: 18,
    fontWeight: 500,
    color: '#fdbe10',
  },
}
const fetchFormat = (formatName) => {
  if (formatName === 'Format1') {
    return Format1
  } else if (formatName === 'Format2') {
    return Format2
  } else if (formatName === 'Format3') {
    return Format3
  } else {
    return Format4
  }
}
/* CONSTANTS */
const useStyles = ({ formatt }) => {
  const formatFetched = fetchFormat(Object.keys(formatt)[0])
  formatFetched.title.fontSize = Object.values(formatt)[0].titleFontSize
  formatFetched.description.fontSize =
    Object.values(formatt)[0].descriptionFontSize
  return makeStyles((theme) => formatFetched)()
}
/* CONSTANTS */

const isBetweenTwoDate = (theDate, PublishFrom, PublishTo) => {
  const noFromDate = PublishFrom === null || PublishFrom === ''
  const noToDate = PublishTo === null || PublishTo === ''
  const publishFrom = noFromDate
    ? ' '
    : moment(PublishFrom).format('YYYY MM DD')
  const publishTo = noToDate ? ' ' : moment(PublishTo).format('YYYY MM DD')
  const isBetweenFromTo =
    publishFrom === ' ' && publishTo === ' '
      ? true
      : publishFrom === ' ' && publishTo !== ' '
      ? moment(theDate).isBefore(moment(publishTo).format('YYYY MM DD'))
      : publishFrom !== ' ' && publishTo === ' '
      ? moment(theDate).isAfter(moment(publishFrom).format('YYYY MM DD'))
      : moment(theDate).isBetween(
          moment(publishFrom).format('YYYY MM DD'),
          moment(publishTo).format('YYYY MM DD')
        )
  return { publishFrom, publishTo, isBetweenFromTo }
}
const Index = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { country, lang } = props.match.params
  const { layout, hideDate, theDate, removeOpacity } = props
  const { masterlayout, mastercontents } = layout
  const { type, contentFormat } = mastercontents
  // once format field added we can replace it
  var format = fetchFormat(contentFormat)

  format.title = { ...format.title, fontSize: mastercontents.titleFontSize }
  format.description = {
    ...format.description,
    fontSize: mastercontents.descriptionFontSize,
  }

  const formatt = {}
  formatt[contentFormat] = {
    titleFontSize: mastercontents.titleFontSize,
    descriptionFontSize: mastercontents.descriptionFontSize,
  }
  const classes = useStyles({ formatt })

  const { publishFrom, publishTo, isBetweenFromTo } = isBetweenTwoDate(
    theDate,
    mastercontents.PublishFrom,
    mastercontents.PublishTo
  )
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const imgSrc = mastercontents.homeImage ? mastercontents.homeImage.url : ''

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div
      style={{
        position: 'relative',
        // borderRadius: 10,
        display: 'flex',
        height: '100%',
        width: '100%',
        // background: imgSrc,
        // backgroundSize: 'contain',
        // //   background: image,
        // backgroundPosition: '50% 50%',
        //   backgroundSize: "cover",
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: removeOpacity ? 0 : isBetweenFromTo ? 1 : 0.5,
          backgroundColor: isBetweenFromTo ? 'transparent' : 'white',
        }}
      />
      <img
        src={imgSrc}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
      {/* detail of item */}
      {hideDate ? null : (
        <div>
          <div
            onClick={handleClick}
            style={{
              cursor: 'pointer',
              zIndex: 99,
              backgroundColor: '#098d42',
              width: 30,
              height: 30,
              borderRadius: 10,
              position: 'absolute',
              top: 10,
              left: 10,
              padding: 5,
            }}
          >
            <FindInPageIcon
              style={{
                color: '#ffffff',
                paddingRight: 9,
                paddingBottom: 9,
                fontSize: 30,
              }}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {/* setup id */}
            <div
              style={{
                padding: 10,
                height: 'auto',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Setup Content ID:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">{layout.id}</ModuleText>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: 'black',
                    margin: 'auto',
                    marginTop: 10,
                    marginBottom: -10,
                  }}
                ></div>
              </div>
            </div>
            {/* setup id */}
            <div
              style={{
                padding: 10,
                height: 'auto',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Mastercontents ID:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">
                  <span
                    style={{
                      color: '#098d42',
                      cursor: 'pointer',
                      fontWeight: 700,
                    }}
                    onClick={() => {
                      window.open(
                        '/content/detail/' + layout.mastercontents.id,
                        '_blank'
                      )
                      // props.history.push(
                      //   '/content/detail/' + layout.mastercontents.id
                      // )
                    }}
                  >
                    {layout.mastercontents.id}
                  </span>
                </ModuleText>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Name:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">
                  {layout.mastercontents.name}
                </ModuleText>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <ModuleText fontWeight={700}>
                  Content Expire:&nbsp;&nbsp;&nbsp;&nbsp;
                </ModuleText>
                <ModuleText variant="caption">
                  {publishFrom === ' ' && publishTo === ' '
                    ? 'No Expiration'
                    : publishFrom + ' ~ ' + publishTo}
                </ModuleText>
              </div>
            </div>
          </Popover>
        </div>
      )}
      {hideDate ? null : (
        <div
          style={{
            zIndex: 99,
            backgroundColor: '#098d42',
            width: 'auto',
            height: 'auto',
            borderRadius: 10,
            position: 'absolute',
            bottom: 10,
            left: 10,
          }}
        >
          {publishFrom === ' ' && publishTo === ' ' ? (
            <p style={{ color: '#ffffff', margin: 6 }}>No Expiration</p>
          ) : (
            <p style={{ color: '#ffffff', margin: 6 }}>
              {publishFrom} ~ {publishTo}
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default Index
