import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const environment = process.env.NODE_ENV
const project = 'Change Password'
const server = null
const service = null

const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    cms: {
      api: {
        endpoints: {
          createtandc: {
            headers: {
              'Content-Type': 'application/json',
            },
            // url: setup.setup.api.cms.url[environment] + '/upload',
            url: setup.setup.api.cms.url[environment] + '/mastertandcs',
            method: 'POST',
          },
          createmastercampaign: {
            headers: {
              'Content-Type': 'application/json',
            },
            // url: setup.setup.api.cms.url[environment] + '/upload',
            url: setup.setup.api.cms.url[environment] + '/mastercampaigns',
            method: 'POST',
          },
          createmastercampaignanswersheet: {
            headers: {
              'Content-Type': 'application/json',
            },
            // url: setup.setup.api.cms.url[environment] + '/upload',
            url:
              setup.setup.api.cms.url[environment] +
              '/mastercampaignanswersheets',
            method: 'POST',
          },
          uploadimages: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: setup.setup.api.cms.url[environment] + '/upload',
            // url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'POST',
          },
          updatecognitouser: {
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: null
            // },
            url: setup.setup.api.cms.url[environment] + '/mastercognitousers',
            method: 'PUT',
          },
          logs: {
            headers: {
              'Content-Type': 'application/json',
            },
            url: 'https://mm7vd2m953.execute-api.ap-southeast-2.amazonaws.com/test/',
            // url: setup.setup.api.cms.url[environment] + '/mastercontents',
            method: 'POST',
          },
        },
      },
      objects: {
        name: {
          placeholder: '',
          name: 'name',
          initial: '',
          label: 'Content Name',
          helperText: false,
          rules: ['isNotNull'],
        },
        mastercountries: {
          name: 'mastercountries',
          initial: '',
          label: 'Country',
          type: 'select',
          options: [],
          helperText: false,
          rules: ['isNotNull'],
        },
        masterlanguages: {
          name: 'masterlanguages',
          initial: '',
          label: 'Language',
          type: 'select',
          options: [],
          helperText: false,
          rules: ['isNotNull'],
        },
        mastercontents: {
          name: 'mastercontents',
          initial: '',
          label: 'Content',
          type: 'select',
          options: [],
          helperText: false,
          rules: ['isNotNull'],
        },
        // mastertandc: {
        //   name: 'mastertandc',
        //   initial: '',
        //   label: 'Terms and Conditions',
        //   type: 'select',
        //   options: [],
        //   helperText: false,
        //   rules: ['isNotNull'],
        // },
        publishfrom: {
          placeholder: '',
          name: 'publishfrom',
          initial: '',
          label: 'Publish From',
          helperText: false,
          rules: ['isNotNull'],
        },
        publishto: {
          placeholder: '',
          name: 'publishto',
          initial: '',
          label: 'Publish To',
          helperText: false,
          rules: ['isNotNull'],
        },
        campaigntype: {
          placeholder: '',
          name: 'campaigntype',
          initial: '',
          label: 'Campaign Type',
          helperText: false,
          rules: ['isNotNull'],
        },
        campaigncode: {
          placeholder: '',
          name: 'campaigncode',
          initial: '',
          label: 'Campaign Code',
          helperText: false,
          rules: false,
        },
        tandcURL: {
          placeholder: '',
          name: 'tandcURL',
          initial: '',
          label: 'T & C URL',
          helperText: false,
          rules: false,
        },
        tandcTitle: {
          placeholder: '',
          name: 'tandcTitle',
          initial: '',
          label: 'T & C Title',
          helperText: false,
          rules: false,
        },
        tandcContent: {
          placeholder: '',
          name: 'tandcContent',
          initial: '',
          label: 'T & C Content',
          helperText: false,
          rules: false,
        },
        acceptText: {
          placeholder: '',
          name: 'acceptText',
          initial: '',
          label: 'Accept Text',
          helperText: false,
          rules: false,
        },
        cancelText: {
          placeholder: '',
          name: 'cancelText',
          initial: '',
          label: 'Cancel Text',
          helperText: false,
          rules: false,
        },
        tAndCPhrase1: {
          placeholder: '',
          name: 'tAndCPhrase1',
          initial: '',
          label: 'T & C Print Prefix',
          helperText: false,
          rules: false,
        },
        tAndCPhrase2: {
          placeholder: '',
          name: 'tAndCPhrase2',
          initial: '',
          label: 'T & C Print Link',
          helperText: false,
          rules: false,
        },
        tAndCPhrase3: {
          placeholder: '',
          name: 'tAndCPhrase3',
          initial: '',
          label: 'T & C Print Suffix',
          helperText: false,
          rules: false,
        },
        successMessageOverWrite: {
          placeholder: '',
          name: 'successMessageOverWrite',
          initial: '',
          label: 'Success Message Custom Print',
          helperText: false,
          rules: false,
        },
      },
      responses: {
        fail: 'Incorrect email or password',
        unverified: 'Please verify your account by ticking the recaptcha.',
      },
    },
  },
  routes: {
    label: 'Create Campaign Page',
    prefix: '/campaign/create',
    // prefix: "/:country/:lang/drag_and_drop"
  },
  environment,
  setup,
}

export default config
