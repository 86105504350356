import React from 'react'

/* package */
import { SectionWrappList } from '../../lib-atomic-section'
/* package */

/* container */
import UserManageContainer from '../../app-user-container/default'
/* container */

/* form */
import Content from '../layout/content'
/* form */

const index = (props) => {
  return (
    <UserManageContainer.UserList {...props}>
      <SectionWrappList {...props}>
        <Content {...props} />
      </SectionWrappList>
    </UserManageContainer.UserList>
  )
}

export default index
