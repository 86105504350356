import React, { useContext, Fragment, useState, useEffect } from 'react'
import moment from 'moment'
/* package */
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { SectionListItem } from '../../lib-atomic-section'
import { WindowSize } from '../../lib-atomic-functions'
/* package */

/* modules */

/* CONSTANTS */

// const CMSMASTERSETUPCONFIG = {
//   url: CMSENDPOINTS.updatemastersetup.url,
//   method: CMSENDPOINTS.updatemastersetup.method,
//   headers: CMSENDPOINTS.updatemastersetup.headers,
//   responseType: "json",
// };
/* CONSTANTS */

const Index = (props) => {
  const { width, height } = WindowSize()
  const { cognitouser, mastersetups } = useContext(GlobalContext)
  const [showCreateBtn, setShowCreateBtn] = useState(false)
  const [countrySelected, setCountrySelected] = useState(null)
  const [environmentSelected, setEnvironmentSelected] = useState(null)
  const [orderBy, setOrderBy] = useState('mastercountries')
  const [isAsc, setIsAsc] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const { rawContent, setFilteredContent } = props
  console.log(cognitouser)
  const mastercountrylist = cognitouser.state.data[0].MasterUCCountries.map(
    (country) => {
      return {
        name: country.mastercountry.name,
        value: country.mastercountry,
      }
    }
  )
  useEffect(() => {
    const mastersetupsobj = mastersetups.state.data
    console.log(mastersetupsobj, cognitouser.state.data[0].MasterUCCountries)
    const mastercountrylist = cognitouser.state.data[0].MasterUCCountries.filter(
      (countryItem) => {
        const filterOut = mastersetupsobj.some(
          (setupItem) =>
            setupItem.mastercountries.abbr === countryItem.mastercountry.abbr
        )
        return filterOut ? false : true
      }
    )
    if (mastercountrylist.length !== 0) {
      setShowCreateBtn(true)
    }
  }, [])
  useEffect(() => {
    const filtered = rawContent.filter((content) => {
      const condition = countrySelected
        ? countrySelected === 'All'
          ? true
          : content.mastercountries.abbr === countrySelected.abbr
        : true
      const condition2 = environmentSelected
        ? environmentSelected === 'All'
          ? true
          : content.environment === environmentSelected
        : true
      return condition && condition2
      // return condition
    })
    const variableToSortBy = orderBy ? orderBy : 'id'
    console.log(filtered, variableToSortBy)
    const sorted =
      filtered &&
      filtered.sort((a, b) => {
        if (variableToSortBy === 'id') {
          return isAsc
            ? a[variableToSortBy] - b[variableToSortBy]
            : b[variableToSortBy] - a[variableToSortBy]
        } else if (variableToSortBy === 'mastercountries') {
          const compare =
            b[variableToSortBy].abbr < a[variableToSortBy].abbr
              ? isAsc
                ? 1
                : -1
              : b[variableToSortBy].abbr > a[variableToSortBy].abbr
              ? isAsc
                ? -1
                : 1
              : 0

          return compare
        } else if (variableToSortBy === 'environment') {
          return isAsc
            ? a[variableToSortBy] - b[variableToSortBy]
            : b[variableToSortBy] - a[variableToSortBy]
        } else {
          return isAsc
            ? moment(a[variableToSortBy]) - moment(b[variableToSortBy])
            : moment(b[variableToSortBy]) - moment(a[variableToSortBy])
        }
      })
    console.log(sorted)
    setFilteredContent(sorted)
  }, [rawContent, countrySelected, environmentSelected, orderBy, isAsc])

  const optionAll = [{ name: 'All', value: 'All' }]
  const countrySelector = {
    name: 'countrytoggle',
    initial: [],
    label: 'Content Country',
    type: 'select',
    options: [...optionAll, ...mastercountrylist],
    helperText: false,
    rules: false,
    value: countrySelected,
    onChange: (e) => {
      setCountrySelected(e.target.value)
    },
  }

  const environmentSelector = {
    name: 'environmentToggle',
    initial: [],
    label: 'Environment',
    type: 'select',
    options: [
      ...optionAll,
      { name: 'Production', value: 'production' },
      { name: 'Development', value: 'development' },
    ],
    helperText: false,
    rules: false,
    value: environmentSelected,
    onChange: (e) => {
      setEnvironmentSelected(e.target.value)
    },
  }

  const orderBySelector = {
    name: 'orderBy',
    initial: [],
    label: 'Order By',
    type: 'select',
    // style: { width: '100%' },
    options: [
      {
        name: 'ID',
        value: 'id',
        link: () => {
          setOrderBy('id')
          setAnchorEl(!anchorEl)
        },
      },
      {
        name: 'Country',
        value: 'mastercountries',
        link: () => {
          setOrderBy('mastercountries')
          setAnchorEl(!anchorEl)
        },
      },
      {
        name: 'Create Date',
        value: 'created_at',
        link: () => {
          setOrderBy('created_at')
          setAnchorEl(!anchorEl)
        },
      },
      {
        name: 'Update Date',
        value: 'updated_at',
        link: () => {
          setOrderBy('updated_at')
          setAnchorEl(!anchorEl)
        },
      },
    ],
    helperText: false,
    rules: false,
    value: orderBy,
    onClick: (e) => {
      console.log(e)
      handleClick(e)
    },
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const submitButton = {
    fullWidth: true,
    disabled: !showCreateBtn,
    onClick: () => {
      props.history.push('/layout/create')
    },
  }
  return (
    <Fragment>
      <div
        style={{
          display: width < 1200 ? 'inline' : 'flex',
          height: '100%',
          width: '100%',
          margin: 'auto',
          marginRight: width < 1200 ? (width < 600 ? 'auto' : 'auto') : 'auto',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: width < 600 ? '100%' : width < 1200 ? '70%' : 'auto',
            height: '100%',
            marginLeft: 20,
            marginRight: width < 700 ? '10%' : 10,
            justifyContent: 'space-between',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              display: 'inline-block',
              height: '100%',
              width: width < 600 ? '30%' : width < 1200 ? 200 : 200,
              margin: 'auto',
              justifyContent: 'center',
            }}
          >
            <Buttons.Text {...orderBySelector}>
              {
                orderBySelector.options.filter((i) => i.value === orderBy)[0]
                  .name
              }
            </Buttons.Text>
          </div>

          <div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {orderBySelector.options.map((menuItem) => {
                return (
                  <div
                    style={{
                      // padding: 10,
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        margin: 'auto',
                      }}
                    >
                      <SectionListItem
                        {...{
                          props,
                          ...{
                            label: menuItem.name,
                            link: menuItem.link,
                          },
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </Popover>
          </div>
          <div
            style={{
              display: width < 1200 ? 'flex' : 'inline',
              height: '100%',
              margin: 'auto',
              marginRight: width < 600 ? 'auto' : '20%',
              justifyContent: width < 1200 ? 'space-between' : 'center',
            }}
          >
            <Buttons.Text onClick={() => setIsAsc(!isAsc)}>
              {!isAsc ? (
                <KeyboardArrowUpIcon fill="green" />
              ) : (
                <KeyboardArrowDownIcon fill="green" />
              )}
            </Buttons.Text>
          </div>
          <div
            style={{
              display: width < 1200 ? 'inline-block' : 'none',
              height: '100%',
              width: width < 600 ? '40%' : '40%',
              margin: 10,
              marginTop: width < 1200 ? 20 : 10,
              justifyContent: 'flex-end',
            }}
          >
            <Buttons.Normal {...submitButton}>
              {width < 800 ? '+' : '+ Create New Setup'}
            </Buttons.Normal>
          </div>
        </div>
        <div
          style={{
            display: width < 750 ? 'inline' : 'inline-block',
            width: 200,
            margin: 10,
          }}
        >
          <Inputs.Select {...countrySelector} />
        </div>
        <div
          style={{
            display: width < 750 ? 'inline' : 'inline-block',
            width: 200,
            margin: 10,
          }}
        >
          <Inputs.Select {...environmentSelector} />
        </div>
        <div
          style={{
            display: width < 1200 ? 'none' : 'inline-block',
            height: '100%',
            width: 200,
            margin: 'auto',
            marginTop: width < 1200 ? 20 : 'auto',
          }}
        >
          <Buttons.Normal {...submitButton}>+ Create New Setup</Buttons.Normal>
        </div>
      </div>
    </Fragment>
  )
}

export default Index
