import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCampaign = {
  url: CMSENDPOINTS.campaign.url,
  method: CMSENDPOINTS.campaign.method,
  headers: CMSENDPOINTS.campaign.headers,
  responseType: 'json',
}
const CMSMASTERCampaignLIST = {
  url: CMSENDPOINTS.campaignlist.url,
  method: CMSENDPOINTS.campaignlist.method,
  headers: CMSENDPOINTS.campaignlist.headers,
  responseType: 'json',
}
const CMSMASTERCampaignANSWERCONFIG = {
  url: CMSENDPOINTS.mastercampaignanswersheet.url,
  method: CMSENDPOINTS.mastercampaignanswersheet.method,
  headers: CMSENDPOINTS.mastercampaignanswersheet.headers,
  responseType: 'json',
}
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTS = {
  url: CMSENDPOINTS.mastercontents.url,
  method: CMSENDPOINTS.mastercontents.method,
  headers: CMSENDPOINTS.mastercontents.headers,
  responseType: 'json',
}
const CMSMASTERTANDC = {
  url: CMSENDPOINTS.mastertandc.url,
  method: CMSENDPOINTS.mastertandc.method,
  headers: CMSENDPOINTS.mastertandc.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { path, params } = props.match
  const { id } = params
  console.log('id: ', id)
  const {
    cognitouser,
    countrylanguage,
    mastertandc,
    contents,
    campaign,
    campaignlist,
    mastercampaignanswersheet,
    updatemastercampaign,
    updatemastercampaignanswersheet,
    updatetandc,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await updatemastercampaign.dispatch({
      type: 'RESET',
    })
    await updatemastercampaignanswersheet.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await mastertandc.dispatch({
      type: 'RESET',
    })
    await contents.dispatch({
      type: 'RESET',
    })
    await campaign.dispatch({
      type: 'RESET',
    })
    await campaignlist.dispatch({
      type: 'RESET',
    })
    await mastercampaignanswersheet.dispatch({
      type: 'RESET',
    })
    await updatetandc.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  /* --- master campaignlist */
  const fetchMasterCampaignListExecute = async ({ query }) => {
    CMSMASTERCampaignLIST.headers.Authorization = 'Bearer ' + token
    const [fetchMasterCampaign] = FetchContextApiV2(CMSMASTERCampaignLIST)
    campaignlist.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterCampaign({ query }, campaignlist)
    campaignlist.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  /* --- cognitouser */
  const fetchCampaignExec = async ({ query }) => {
    CMSMASTERCampaign.headers.Authorization = 'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSMASTERCampaign)
    campaign.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCall({ query }, campaign)
    campaign.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCampaignAsnwerExec = async ({ query }) => {
    CMSMASTERCampaignANSWERCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSMASTERCampaignANSWERCONFIG)
    mastercampaignanswersheet.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCall({ query }, mastercampaignanswersheet)
    mastercampaignanswersheet.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCognitoUserExecute = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- cognitouser */
  const fetchCountryLanguagesExecute = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master contents */
  const fetchMasterContentsExecute = async ({ query }) => {
    CMSMASTERCONTENTS.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContents] = FetchContextApiV2(CMSMASTERCONTENTS)
    contents.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContents({ query }, contents)
    contents.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- master t and c */
  const fetchMasterTAndCExecute = async ({ query }) => {
    CMSMASTERTANDC.headers.Authorization = 'Bearer ' + token
    const [fetchMasterTAndC] = FetchContextApiV2(CMSMASTERTANDC)
    mastertandc.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterTAndC({ query }, mastertandc)
    mastertandc.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  useEffect(() => {
    if (loading) {
      const query = '?cognitoId=' + username
      fetchCognitoUserExecute({ query })
    }
  }, [loading])
  const initFunc = async () => {
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (
      loading &&
      cognitouser.state.data &&
      cognitouser.state.isStatus &&
      !cognitouser.state.isLoading
    ) {
      if (cognitouser.state.data.length > 0) {
        let query = '?id_in=' + id + '&'
        const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
        mastercountrylist.map((country, index) => {
          if (index === 0) {
            query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
          } else {
            query =
              query + '&mastercountries.abbr=' + country.mastercountry.abbr
          }
        })
        fetchCampaignExec({ query })
      }
    }
  }, [cognitouser.state])

  useEffect(() => {
    if (
      loading &&
      campaign.state.data &&
      campaign.state.isStatus &&
      !campaign.state.isLoading
    ) {
      if (campaign.state.data.length > 0) {
        let query = '?'
        let query2 = '?_limit=500&Environment=development&type=Campaign&'
        let query3 = '?masterCampaignId=' + id
        const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
        mastercountrylist.map((country, index) => {
          if (index === 0) {
            query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
            query2 =
              query2 + 'mastercountries.abbr=' + country.mastercountry.abbr
          } else {
            query =
              query + '&mastercountries.abbr=' + country.mastercountry.abbr
            query2 =
              query2 + '&mastercountries.abbr=' + country.mastercountry.abbr
          }
        })
        fetchCountryLanguagesExecute({ query })
        fetchMasterContentsExecute({ query: query2 })
        fetchMasterTAndCExecute({ query })
        fetchCampaignAsnwerExec({ query: query3 })
        fetchMasterCampaignListExecute({ query })
      } else {
        props.history.push('/campaign/list')
      }
    }
  }, [campaign.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isCountryLangReady =
    countrylanguage.state.data &&
    countrylanguage.state.isStatus &&
    !countrylanguage.state.isLoading
      ? true
      : false
  const isContentsReady =
    contents.state.data && contents.state.isStatus && !contents.state.isLoading
      ? true
      : false
  const isTandCReady =
    mastertandc.state.data &&
    mastertandc.state.isStatus &&
    !mastertandc.state.isLoading
      ? true
      : false
  const isCampaignReady =
    campaign.state.data && campaign.state.isStatus && !campaign.state.isLoading
      ? true
      : false
  const isCampaignListReady =
    campaignlist.state.data &&
    campaignlist.state.isStatus &&
    !campaignlist.state.isLoading
      ? true
      : false
  if (
    !loading ||
    !isCognitoUserReady ||
    !isCountryLangReady ||
    !isContentsReady ||
    !isTandCReady ||
    !isCampaignReady ||
    !isCampaignListReady
  ) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
