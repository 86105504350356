import React, { useEffect, useContext } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'

/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitousers.url,
  method: CMSENDPOINTS.mastercongnitousers.method,
  headers: CMSENDPOINTS.mastercongnitousers.headers,
  responseType: 'json',
}
const CMSMASTERCOGNITOUSERS = {
  url: CMSENDPOINTS.mastercongnitousers.url,
  method: CMSENDPOINTS.mastercongnitousers.method,
  headers: CMSENDPOINTS.mastercongnitousers.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRIES = {
  url: CMSENDPOINTS.mastercountries.url,
  method: CMSENDPOINTS.mastercountries.method,
  headers: CMSENDPOINTS.mastercountries.headers,
  responseType: 'json',
}
const CMSMASTERUSERROLES = {
  url: CMSENDPOINTS.role.url,
  method: CMSENDPOINTS.role.method,
  headers: CMSENDPOINTS.role.headers,
  responseType: 'json',
}
const Index = (props) => {
  const { token, username } = LocalStorage.getLS().strapi
  const { path, params } = props.match
  const { authuser, cognitouser, cognitouserlist, role } = useContext(
    GlobalContext
  )

  /* --- reset */
  const fetchResetExec = async () => {
    cognitouserlist.dispatch({
      type: 'RESET',
    })
    role.dispatch({
      type: 'RESET',
    })
  }

  /* --- cognitouser */

  const fetchCognitoUserExec = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  /* --- cognitouser */
  const fetchCognitoUserListExecute = async () => {
    CMSMASTERCOGNITOUSERS.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUserList] = FetchContextApiV2(CMSMASTERCOGNITOUSERS)
    const fetchResult = await fetchCognitoUserList({}, cognitouserlist)
    const { MasterUCCountries } = authuser.state.data[0]
    const cleanedFetchResult = fetchResult.data.json.filter((user) =>
      user.MasterUCCountries.some((country) =>
        MasterUCCountries.some(
          (cogUserCountry) =>
            cogUserCountry.mastercountry.abbr === country.mastercountry.abbr
        )
      )
    )
    cognitouserlist.dispatch({
      type: 'SET',
      data: { data: { json: cleanedFetchResult }, status: true },
    })
  }

  const fetchUserRolesExecute = async () => {
    CMSMASTERUSERROLES.headers.Authorization = 'Bearer ' + token
    const [fetchUserRoles] = FetchContextApiV2(CMSMASTERUSERROLES)
    role.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchUserRoles({}, role)
    role.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  // const fetchMasterCountriesExecute = async ({ newHeader, query }) => {
  //   CMSMASTERCOUNTRIES.headers.Authorization = token
  //   const [fetchMasterCountries] = FetchContextApiV2(CMSMASTERCOUNTRIES)
  //   const fetchResult = await fetchMasterCountries(
  //     { newHeader, query },
  //     mastercountries
  //   )
  //   mastercountries.dispatch({
  //     type: 'SET',
  //     mastercountries: fetchResult,
  //   })
  // }

  useEffect(() => {
    const query = '?cognitoId=' + username
    fetchCognitoUserExec({ query })
    fetchCognitoUserListExecute()
    fetchUserRolesExecute()
    return () => {
      fetchResetExec()
    }
  }, [])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isCognitoUserListReady =
    cognitouserlist.state.data &&
    cognitouserlist.state.isStatus &&
    !cognitouserlist.state.isLoading
      ? true
      : false
  const isUserRolesReady =
    role.state.data && role.state.isStatus && !cognitouserlist.state.isLoading
      ? true
      : false
  if (!isCognitoUserReady || !isCognitoUserListReady || !isUserRolesReady) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
