import React, { useContext, Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const useStyles = makeStyles((theme) => ({
  small: {
    height: 'auto',
    minHeight: 130,
    maxHeight: 130,
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  large: {
    height: 'auto',
    minHeight: 300,
    maxHeight: 300,
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  item: {
    cursor: 'pointer',
    // borderRadius: 10,
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'left',
    alignContent: 'stretch',
    alignItems: 'stretch',
    backgroundColor: '#fff',
    height: 250,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  },
}))

const Index = (props) => {
  const [imgWidth, setImgWidth] = useState(0)
  const [imgHeight, setImgHeight] = useState(0)
  const classes = useStyles()
  const { type, contents, size } = props
  const sizeStyle = size === 'small' ? classes.small : classes.large

  if (type !== 'Carousel') return false

  const handleToDetail = ({ id }) => {
    props.history.push('/content/detail/' + id)
  }

  return (
    <Fragment>
      <Carousel
        showStatus={false}
        showThumbs={false}
        width={'100%'}
        height={'100%'}
        infiniteLoop={true}
        interval={8000}
        style={{ backgroundColor: 'transparent' }}
      >
        {contents.map((content) => {
          const {
            id,
            name,
            contentVisibility,
            type,
            contentFormat,
            homeImage,
            detailImage,
          } = content
          const imageItem = homeImage ? 'url(' + homeImage.url + ')' : ''
          return (
            <Fragment>
              {name && (
                <p
                  style={{
                    color: '#616161',
                    fontWeight: 700,
                    textAlign: 'left',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                    paddingTop: 50,
                    paddingBottom: 10,
                    paddingLeft: 33,
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{ color: '#098d42', cursor: 'pointer' }}
                    // onClick={() => {
                    //   props.history.push('/content/detail/' + id, '_blank')
                    //   // props.history.go(0)
                    // }}
                  >
                    <Link to={'/content/detail/' + id} target="_blank">
                      {name}
                    </Link>
                  </span>{' '}
                  (width: {content.imgWidth ? content.imgWidth : 'N/A'}, height:{' '}
                  {content.imgHeight ? content.imgHeight : 'N/A'})
                </p>
              )}

              {homeImage && homeImage.url ? (
                <img
                  width="100%"
                  onLoad={({ target: img }) => {
                    // console.log(img)
                    content['imgWidth'] = img.offsetWidth
                    content['imgHeight'] = img.offsetHeight
                    setImgWidth(img.offsetWidth)
                    setImgHeight(img.offsetHeight)
                  }}
                  src={homeImage.url}
                  onClick={() => handleToDetail({ id })}
                />
              ) : (
                <div>
                  <p
                    style={{
                      height: 'auto',
                      minHeight: 300,
                      maxHeight: 300,
                      backgroundColor: 'grey',
                      color: 'white',
                      fontWeight: 700,
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 'auto',
                      paddingTop: 50,
                    }}
                  >
                    Image Not Available
                  </p>
                </div>
              )}
            </Fragment>
          )
        })}
      </Carousel>
    </Fragment>
  )
}

export default Index
