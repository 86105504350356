import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTDETAILCONFIG = {
  url: CMSENDPOINTS.contentdetail.url,
  method: CMSENDPOINTS.contentdetail.method,
  headers: CMSENDPOINTS.contentdetail.headers,
  responseType: 'json',
}
const CMSMASTERSETUPSCONFIG = {
  url: CMSENDPOINTS.mastersetups.url,
  method: CMSENDPOINTS.mastersetups.method,
  headers: CMSENDPOINTS.mastersetups.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const {
    cognitouser,
    countrylanguage,
    contentdetail,
    contentcarouseldetail,
    mastersetups,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await contentdetail.dispatch({
      type: 'RESET',
    })
    await contentcarouseldetail.dispatch({
      type: 'RESET',
    })
    await mastersetups.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  const fetchCognitoUserExec = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchCountryLanguagesExec = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchContentDetailExec = async ({ query }) => {
    CMSMASTERCONTENTDETAILCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContentDetail] = FetchContextApiV2(
      CMSMASTERCONTENTDETAILCONFIG
    )
    contentdetail.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContentDetail({ query }, contentdetail)
    contentdetail.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchMasterSetupsExec = async ({ query }) => {
    CMSMASTERSETUPSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterSetups] = FetchContextApiV2(CMSMASTERSETUPSCONFIG)
    mastersetups.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterSetups({ query }, mastersetups)
    mastersetups.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchContentCarouselDetailExec = async () => {
    if (
      loading &&
      contentdetail.state.data &&
      contentdetail.state.isStatus &&
      !contentdetail.state.isLoading
    ) {
      const { CarouselContents, mastercountries, masterlanguages } =
        contentdetail.state.data
      console.log(mastercountries)
      if (CarouselContents.length < 1) {
        contentcarouseldetail.dispatch({ type: 'LOAD' })
        contentcarouseldetail.dispatch({
          type: 'SET',
          data: { status: true, data: { json: [] } },
        })
      } else {
        const idInOrder = []
        CarouselContents.map((items) => {
          if (items && items.mastercontents && items.mastercontents.id) {
            if (
              !(
                idInOrder &&
                idInOrder.findIndex((i) => i === items.mastercontents.id) > -1
              )
            ) {
              idInOrder.push(items.mastercontents.id)
            }
          }
        })
        console.log(idInOrder)
        const parameters = CarouselContents.map((content) => {
          return QueryString.stringify({ id_in: content.mastercontents.id })
        })
        console.log(parameters)
        const query = '?' + parameters.join('&')
        CMSMASTERCONTENTDETAILCONFIG.headers.Authorization = 'Bearer ' + token
        const [fetchMasterContentCarouselDetail] = FetchContextApiV2(
          CMSMASTERCONTENTDETAILCONFIG
        )
        contentcarouseldetail.dispatch({ type: 'LOAD' })
        const fetchResult = await fetchMasterContentCarouselDetail(
          { query },
          contentcarouseldetail
        )
        console.log(fetchResult)
        const processed = {
          status: fetchResult.status,
          data: {
            blob: false,
            json: fetchResult.data.json.sort(
              (a, b) => idInOrder.indexOf(a.id) - idInOrder.indexOf(b.id)
            ),
          },
        }
        contentcarouseldetail.dispatch({
          type: 'SET',
          data: processed,
        })
      }

      const queryForSetupFetch =
        '?mastercountries.abbr=' +
        mastercountries.abbr +
        '&masterlanguages.abbr=' +
        masterlanguages.abbr
      fetchMasterSetupsExec({ query: queryForSetupFetch })
    }
  }

  useEffect(() => {
    if (loading) {
      const query = '?cognitoId=' + username
      const query2 = '/' + id
      fetchCognitoUserExec({ query })
      fetchContentDetailExec({ query: query2 })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    fetchContentCarouselDetailExec()
  }, [contentdetail.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isContentDetailReady =
    contentdetail.state.data &&
    contentdetail.state.isStatus &&
    !contentdetail.state.isLoading
      ? true
      : false
  const isContentCarouselDetailReady =
    contentcarouseldetail.state.data &&
    contentcarouseldetail.state.isStatus &&
    !contentcarouseldetail.state.isLoading
      ? true
      : false

  const isMasterSetupsReady =
    mastersetups.state.data &&
    mastersetups.state.isStatus &&
    !mastersetups.state.isLoading
      ? true
      : false

  if (
    !loading ||
    !isCognitoUserReady ||
    !isContentDetailReady ||
    !isContentCarouselDetailReady ||
    !isMasterSetupsReady
  ) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
