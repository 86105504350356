import React, { useEffect, useContext, useState } from 'react'
import QueryString from 'query-string'

/* packages */
import { GlobalContext } from '../../lib-atomic-context'
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
/* packages */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: 'json',
}
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: 'json',
}
const CMSMASTERCampaign = {
  url: CMSENDPOINTS.campaign.url,
  method: CMSENDPOINTS.campaign.method,
  headers: CMSENDPOINTS.campaign.headers,
  responseType: 'json',
}
const CMSMASTERCONTENTDETAILCONFIG = {
  url: CMSENDPOINTS.contentdetail.url,
  method: CMSENDPOINTS.contentdetail.method,
  headers: CMSENDPOINTS.contentdetail.headers,
  responseType: 'json',
}
const CMSMASTERCampaignANSWERCONFIG = {
  url: CMSENDPOINTS.mastercampaignanswersheet.url,
  method: CMSENDPOINTS.mastercampaignanswersheet.method,
  headers: CMSENDPOINTS.mastercampaignanswersheet.headers,
  responseType: 'json',
}
const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const {
    cognitouser,
    countrylanguage,
    contentdetail,
    campaign,
    mastercampaignanswersheet,
    deletecampaignanswer,
  } = useContext(GlobalContext)

  /* --- reset */
  const fetchResetExec = async () => {
    await cognitouser.dispatch({
      type: 'RESET',
    })
    await countrylanguage.dispatch({
      type: 'RESET',
    })
    await contentdetail.dispatch({
      type: 'RESET',
    })
    await campaign.dispatch({
      type: 'RESET',
    })
    await mastercampaignanswersheet.dispatch({
      type: 'RESET',
    })
    await deletecampaignanswer.dispatch({
      type: 'RESET',
    })
    setLoading(true)
  }

  /* --- master campaign */
  const fetchMasterCampaignExecute = async ({ query }) => {
    CMSMASTERCampaign.headers.Authorization = 'Bearer ' + token
    const [fetchMasterCampaign] = FetchContextApiV2(CMSMASTERCampaign)
    campaign.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterCampaign({ query }, campaign)
    campaign.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchMasterCampaignAnswerExecute = async ({ query }) => {
    CMSMASTERCampaignANSWERCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchCall] = FetchContextApiV2(CMSMASTERCampaignANSWERCONFIG)
    mastercampaignanswersheet.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCall({ query }, mastercampaignanswersheet)
    mastercampaignanswersheet.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const fetchCognitoUserExec = async ({ query }) => {
    CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
    const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
    cognitouser.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCognitoUser({ query }, cognitouser)
    cognitouser.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchCountryLanguagesExec = async ({ query }) => {
    CMSMASTERCOUNTRYLANGUAGES.headers.Authorization = 'Bearer ' + token
    const [fetchCountryLanguages] = FetchContextApiV2(CMSMASTERCOUNTRYLANGUAGES)
    countrylanguage.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchCountryLanguages({ query }, countrylanguage)
    countrylanguage.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const fetchContentDetailExec = async ({ query }) => {
    CMSMASTERCONTENTDETAILCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContentDetail] = FetchContextApiV2(
      CMSMASTERCONTENTDETAILCONFIG
    )
    contentdetail.dispatch({ type: 'LOAD' })
    const fetchResult = await fetchMasterContentDetail({ query }, contentdetail)
    contentdetail.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  useEffect(() => {
    console.log('3', loading)
    if (loading) {
      const query = '?cognitoId=' + username
      const query2 = '/' + id
      const query3 = '?masterCampaignId=' + id
      fetchCognitoUserExec({ query })
      fetchMasterCampaignExecute({ query: query2 })
      fetchMasterCampaignAnswerExecute({ query: query3 })
    }
  }, [loading])
  const initFunc = async () => {
    console.log('1')
    await fetchResetExec()
  }

  useEffect(() => {
    initFunc()
  }, [])

  useEffect(() => {
    if (campaign.state.data) {
      console.log(campaign.state.data)
    }
  }, [campaign.state])

  const isCognitoUserReady =
    cognitouser.state.data &&
    cognitouser.state.isStatus &&
    !cognitouser.state.isLoading
      ? true
      : false
  const isCampaignReady =
    campaign.state.data && campaign.state.isStatus && !campaign.state.isLoading
      ? true
      : false

  const isCampaignAnswerSheetReady =
    mastercampaignanswersheet.state.data &&
    mastercampaignanswersheet.state.isStatus &&
    !mastercampaignanswersheet.state.isLoading
      ? true
      : false

  if (
    !loading ||
    !isCognitoUserReady ||
    !isCampaignReady ||
    !isCampaignAnswerSheetReady
  ) {
    return <Spinner.Popup />
  }

  return props.children
}

export default Index
