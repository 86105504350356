import React from 'react'
import SinglePageWrapper from './single_page_wrapper'
import Users from './users'
import Contents from './contents'

const index = (props) => {
  return (
    <div>
      <SinglePageWrapper {...props} />
      <Users {...props} />
      <Contents {...props} />
    </div>
  )
}

export default index
