import appConf from '../app-conf'
const setup = appConf[process.env.REACT_APP_CLIENT]
const env = process.env.NODE_ENV
const config = {
  details: {
    env,
  },
  application: {
    cms: {
      api: {
        endpoints: false,
      },
    },
  },
  routes: {
    label: false,
    prefix: false,
  },
  env,
  setup,
}

export default config
