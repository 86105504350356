import React from 'react'

import LoginContainer from '../sub-container/login-container'
import RegistrationContainer from '../sub-container/registration-container'

const Login = (props) => {
  return <LoginContainer {...props}>{props.children}</LoginContainer>
}

const Registration = (props) => {
  return (
    <RegistrationContainer {...props}>{props.children}</RegistrationContainer>
  )
}

export default {
  Login,
  Registration,
}
