import React, { useContext, Fragment, useState, useEffect } from 'react'

import { Buttons } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  LocalStorage,
  FetchContextApiV2,
  FetchLoggerApi,
  FetchNotificationSendApi,
} from '../../lib-atomic-functions'

import CommentPopup from '../commentPopup/index.js'
import { useSnackbar } from 'notistack'

import CONFIG from '../config'

const CMSENDPOINTS = CONFIG.application.cms.api.endpoints

const CMSAPPROVECONTENTCONFIG = {
  url: CMSENDPOINTS.approvecontent.url,
  method: CMSENDPOINTS.approvecontent.method,
  headers: CMSENDPOINTS.approvecontent.headers,
  responseType: 'json',
}

const Index = (props) => {
  const { user, item } = props
  const { id, mastercontentsflag } = item

  const handleApprove = () => {
    props.history.push('/content/detail/noti/' + id)
  }

  const isLive =
    mastercontentsflag && mastercontentsflag.status === 'live' ? true : false

  const items = [
    {
      user: [
        'mastercreator',
        'masterapprover',
        'masterpublisher',
        'creator+approver+publisher',
      ],
      action: handleApprove,
      label: 'Next Action',
      status: true,
    },
  ]

  return (
    <Fragment>
      {!isLive &&
        items.map((menu, index) => {
          return (
            <Fragment>
              <Buttons.Normal key={index} onClick={() => menu.action()}>
                {menu.label}
              </Buttons.Normal>
              &nbsp;
            </Fragment>
          )
        })}
    </Fragment>
  )
}

export default Index
