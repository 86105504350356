import React, { lazy, Suspense, useContext, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  FetchContextApiV2,
  LocalStorage,
} from '../../lib-atomic-functions'
import { SectionPaper } from '../../lib-atomic-section'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
/* package */

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'
/* modules */
import CONFIG from '../config'

/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects

const CMSMASTERBANNERCONFIG = {
  url: CMSENDPOINTS.updatemasterbanners.url,
  method: CMSENDPOINTS.updatemasterbanners.method,
  headers: CMSENDPOINTS.updatemasterbanners.headers,
  responseType: 'json',
}
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
}))
const Index = (props) => {
  const { token, username } = LocalStorage.getLS().strapi
  const { id } = props.match.params
  const [loading, setLoading] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const [bannerContents, setBannerContents] = useState([])
  const [warningItems, setWarningItems] = useState([])
  const initialForm = {
    contentselect: '',
  }

  // assign initial value
  Object.keys(OBJECT1).map((key) => {
    OBJECT1[key].initial = initialForm[key]
  })
  const classes = useStyles()
  const { width, height } = WindowSize()

  const { getFontSizes } = WindowFont()

  /* state */
  const { masterbanners, updatemasterbanners, allcontents, mastersetups } =
    useContext(GlobalContext)
  const resetContextItems = async () => {
    masterbanners.dispatch({
      type: 'RESET',
    })
    updatemasterbanners.dispatch({
      type: 'RESET',
    })
    allcontents.dispatch({
      type: 'RESET',
    })
    mastersetups.dispatch({
      type: 'RESET',
    })
  }
  const masterbannerscontents = masterbanners.state.data
  const mastercontents = allcontents.state.data

  /* --- master setups */
  const fetchUpdateMasterBannerExecute = async ({ query, body }) => {
    CMSMASTERBANNERCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchUpdateMasterBanner] = FetchContextApiV2(CMSMASTERBANNERCONFIG)
    const fetchResult = await fetchUpdateMasterBanner(
      { query, body },
      updatemasterbanners
    )
    updatemasterbanners.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const resetUpdateBannerExec = async () => {
    updatemasterbanners.dispatch({ type: 'RESET' })
  }
  /* state */

  /* state */

  const handleAdd = async () => {
    console.log('Adding...')
    console.log('values: ', values)
  }
  const { values, onChange, onCheck, onSubmit, error } = FormHook.useFormHook(
    handleAdd,
    OBJECT1
  )

  const contentNameList = mastercontents
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((item) => {
      return { name: item.name, value: item }
    })
  OBJECT1.contentselect.options = contentNameList

  const page = {
    ...OBJECT1.page,
    error: error.page,
    value: values.page,
    onChange,
  }
  const contentselect = {
    ...OBJECT1.contentselect,
    error: error.contentselect,
    value: values.contentselect,
    onChange,
  }
  const sortArray = (arrayPassed) => {
    return arrayPassed.sort((a, b) => (a.page > b.page ? 1 : -1))
  }
  useEffect(() => {
    setBannerContents(sortArray(masterbannerscontents[0].pages))
    return () => resetContextItems()
  }, [])
  useEffect(() => {
    if (updatemasterbanners.state.data && saveClicked) {
      setSaveClicked(false)
      if (!updatemasterbanners.state.isStatus) {
        console.log('Wrong!')
      } else {
        props.history.push('/layout/preview/' + id)
      }
    }
  }, [updatemasterbanners.state])
  const addButton = {
    style: { marginLeft: 10 },
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
    onClick: async (e) => {
      e.preventDefault()
      const objectToBeAdded = {
        page: values.page,
        mastercontents: values.contentselect,
      }
      let concat1 = JSON.parse(JSON.stringify(bannerContents))
      const sameNameFound = concat1.findIndex(
        (item) => item.page === values.page
      )
      if (sameNameFound > -1) {
        concat1[sameNameFound] = objectToBeAdded
        setBannerContents(sortArray(concat1))
      } else {
        setBannerContents(sortArray([...concat1, objectToBeAdded]))
      }
      // if (values.platform === "Web") {
      //   setWebContents([...webContents, objectToBeAdded]);
      // } else {
      //   setMobContents([...mobContents, objectToBeAdded]);
      // }
    },
  }
  const submitButton = {
    style: { marginRight: 10, width: 150 },
    // fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }

  const removeButton = {
    // style: { marginLeft: 10 },
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }
  const cancelButton = {
    style: { marginRight: 10, width: 150 },
    // fullWidth: true,
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        marginBottom: 60,
        height: height,
      }}
    >
      {/* left box */}
      <div
        style={{
          width: '30%',
          justifyContent: 'flex-start',
          paddingRight: 10,
          //   margin: "auto",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{ paddingLeft: 5, paddingBottom: 16, fontWeight: 900 }}
        >
          Add Contents
        </Typography>
        <SectionPaper>
          <Typography variant="h6" gutterBottom>
            Available Contents
          </Typography>
          <form onSubmit={onSubmit} noValidate>
            <Inputs.Select {...page} />
            <Inputs.Select {...contentselect} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* <Buttons.Normal {...cancelButton}>Cancel</Buttons.Normal> */}
              <Buttons.Normal {...addButton}>Add</Buttons.Normal>
            </div>
          </form>
        </SectionPaper>
      </div>

      {/* middle box */}
      <div
        style={{
          width: '100%',
          height: 'auto',
          marginLeft: 50,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              margin: 'auto',
              width: '40%',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Banner items to be available
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              margin: 'auto',
              width: '60%',
              justifyContent: 'flex-end',
            }}
          >
            <Buttons.Normal
              {...submitButton}
              onClick={async () => {
                if (!loading) {
                  setLoading(true)

                  const submitObj = JSON.parse(JSON.stringify(bannerContents))

                  const query = '/' + masterbannerscontents[0].id
                  const finalSubmitObj = {
                    ...submitObj,
                    pages: bannerContents,
                  }
                  setSaveClicked(true)
                  await fetchUpdateMasterBannerExecute({
                    query,
                    body: finalSubmitObj,
                  })

                  // const pageNotAddedYet = JSON.parse(
                  //   JSON.stringify(page.options)
                  // ).filter((pageName) => {
                  //   const pageFound = bannerContents.findIndex(
                  //     (item) => item.page === pageName.name
                  //   )
                  //   return pageFound === -1
                  // })
                  // if (pageNotAddedYet.length > 0) {
                  //   setWarningItems(pageNotAddedYet)
                  // } else {
                  //   const submitObj = JSON.parse(JSON.stringify(bannerContents))

                  //   const query = '/' + masterbannerscontents[0].id
                  //   const finalSubmitObj = {
                  //     ...submitObj,
                  //     pages: bannerContents,
                  //   }
                  //   setSaveClicked(true)
                  //   await fetchUpdateMasterBannerExecute({
                  //     query,
                  //     body: finalSubmitObj,
                  //   })
                  // }
                }
              }}
            >
              Save
            </Buttons.Normal>
            <Spinner.Loader color="#fff" size={24} loading={loading} />
            <Buttons.Normal
              {...cancelButton}
              onClick={() => {
                props.history.push('/layout/preview/' + id)
              }}
            >
              Cancel
            </Buttons.Normal>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'wrap',
            position: 'relative',
            width: '100%',
            height: 'auto',
            marginTop: 15,
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Page Name</TableCell>
                  <TableCell>Content Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bannerContents.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.page}</TableCell>
                    <TableCell align="left">
                      <b
                        style={{
                          color: '#098d42',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          window.open(
                            '/content/detail/' + row.mastercontents.id,
                            '_blank'
                          )
                        }}
                      >
                        {row.mastercontents.name}
                      </b>
                    </TableCell>
                    <TableCell align="left">
                      <Buttons.Normal
                        {...removeButton}
                        onClick={() => {
                          const newContents = JSON.parse(
                            JSON.stringify(bannerContents)
                          )
                          newContents.splice(index, 1)
                          setBannerContents(sortArray(newContents))
                        }}
                      >
                        Remove
                      </Buttons.Normal>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div
          style={{
            fontWeight: 500,
            fontSize: 15,
            textAlign: 'right',
            color: 'red',
            paddingTop: 10,
            paddingRight: 5,
          }}
        >
          {warningItems.length > 0 ? 'Please add ' : null}
          <span
            style={{
              fontWeight: 700,
            }}
          >
            {warningItems.length > 0
              ? warningItems.map((warnItem, index) => {
                  if (
                    warningItems.length - 1 === index &&
                    warningItems.length !== 1
                  ) {
                    return ' and ' + warnItem.value
                  } else if (warningItems.length - 2 === index && index !== 0) {
                    return ', ' + warnItem.value
                  } else {
                    return ' ' + warnItem.value
                  }
                })
              : null}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Index
