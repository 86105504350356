import React from 'react'

import ContentListContainer from '../sub-container/content-list-container'
import ContentDetailContainer from '../sub-container/content-detail-container'
import ContentDetailNotificationContainer from '../sub-container/content-detail-noti-container'
import ContentCreateContainer from '../sub-container/content-create-container'
import ContentEditContainer from '../sub-container/content-edit-container'
import ContentCarouselEditContainer from '../sub-container/content-carousel-edit-container'

const ContentList = (props) => {
  return (
    <ContentListContainer {...props}>{props.children}</ContentListContainer>
  )
}
const ContentDetail = (props) => {
  return (
    <ContentDetailContainer {...props}>{props.children}</ContentDetailContainer>
  )
}
const ContentDetailNotification = (props) => {
  return (
    <ContentDetailNotificationContainer {...props}>
      {props.children}
    </ContentDetailNotificationContainer>
  )
}
const ContentCreate = (props) => {
  return (
    <ContentCreateContainer {...props}>{props.children}</ContentCreateContainer>
  )
}
const ContentEdit = (props) => {
  return (
    <ContentEditContainer {...props}>{props.children}</ContentEditContainer>
  )
}
const ContentCarouselEdit = (props) => {
  return (
    <ContentCarouselEditContainer {...props}>
      {props.children}
    </ContentCarouselEditContainer>
  )
}

export default {
  ContentList,
  ContentDetail,
  ContentDetailNotification,
  ContentCreate,
  ContentEdit,
  ContentCarouselEdit,
}
