import React, { useState, useContext, Fragment } from 'react'

import ServiceAmplify from '../../../services/AWS-amplify'
import { GlobalContext } from '../../lib-atomic-context'
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import {
  FormHook,
  LocalStorage,
  FetchContextApiV2,
  FetchLoggerApi,
} from '../../lib-atomic-functions'

import CONFIG from '../config'

/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.objects
const ENDPOINTS = CONFIG.application.api.endpoints
const LOGINOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}
const AUTHFAILRESPONSE = CONFIG.application.responses.authFail
/* CONSTANTS */

const Index = (props) => {
  const { logon } = useContext(GlobalContext)
  const [pageError, setPageError] = useState(false)
  const [loading, setLoading] = useState(false)

  const setLogonExec = async ({ token }) => {
    try {
      LOGINOBJECTSCONFIG.headers.Authorization = token
      const [fetchLogin] = FetchContextApiV2(LOGINOBJECTSCONFIG)
      const fetchResult = await fetchLogin({ body: false }, logon)
      logon.dispatch({
        type: 'SET',
        data: fetchResult,
      })

      /* -------------- */
      /* start: logger */
      await FetchLoggerApi({
        username: null,
        api: LOGINOBJECTSCONFIG,
        page: 'auth-login',
        oldData: null,
        newData: fetchResult,
      })
      /* end: logger */
      /* -------------- */

      return fetchResult
    } catch (e) {
      throw e
    }
  }

  const handleLogin = async () => {
    if (!loading) {
      setLoading(true)
      const user = await ServiceAmplify.signin({ ...values })
      try {
        if (user.error) throw AUTHFAILRESPONSE
        setPageError(false)
        const loginResponse = await setLogonExec({
          token: user.response.signInUserSession.idToken.jwtToken,
        })
        console.log(loginResponse)
        LocalStorage.addLS('strapi', {
          token: loginResponse.data.json.token.jwt,
          role: loginResponse.data.json.token.user.role.name,
          username: loginResponse.data.json.token.cognitoUsername,
        })
        const roleName = loginResponse.data.json.token.user.role.name
        setLoading(false)
        if (roleName === 'creator+approver+publisher') {
          props.history.push('/user/list')
        } else {
          props.history.push('/content/list')
        }
      } catch (e) {
        setLoading(false)
        setPageError(e)
      }
    }
  }

  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleLogin,
    LOGINOBJECTS
  )

  const username = {
    ...LOGINOBJECTS.username,
    error: error.username,
    value: values.username,
    onChange,
  }
  const password = {
    ...LOGINOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
  }

  /* -- data props --   */
  const invlidData = { error: [pageError] }
  const submitButton = { fullWidth: true }
  /* -- data props --   */

  return (
    <Fragment>
      <form onSubmit={onSubmit} noValidate>
        {pageError && <Invalid {...invlidData} />}
        <Inputs.Text {...username} />
        <Inputs.Password {...password} />
        <br />
        <br />
        <Buttons.Normal {...submitButton}>Login</Buttons.Normal>
        <Spinner.Loader color="#fff" size={24} loading={loading} />
      </form>
    </Fragment>
  )
}

export default Index
