import React, { useEffect, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'

import { GlobalContext } from '../../lib-atomic-context'
import { FetchContextApiV2 } from '../../lib-atomic-functions'

import CONFIG from '../config'
import Selected from './selected'

/* CONSTANTS */
// const REGISTRATIONOBJECTS = CONFIG.application.objects
const ENDPOINTS = CONFIG.application.api.endpoints
const REGISTRATIONOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
  },
}))

const Index = (props) => {
  const classes = useStyles()
  const { itemsSelected, setSelected } = props
  const defaultValues = itemsSelected
    ? itemsSelected.map((item) => item.name)
    : []
  console.log(defaultValues, ' ---defaultValues')
  const { country, cognitouser, cognitouserdetail, member } =
    useContext(GlobalContext)
  const [selection, setSelection] = React.useState(defaultValues)
  // const [selected, setSelected] = React.useState([])

  useEffect(() => {
    fetchCountryExec()
  }, [])

  const fetchCountryExec = async () => {
    const [fetchCountry] = FetchContextApiV2(REGISTRATIONOBJECTSCONFIG)
    const fetchResult = await fetchCountry({ body: false }, country)
    country.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }

  const handleChange = (event) => {
    const filtered = country.state.data.filter(
      (item) => event.target.value.indexOf(item.name) > -1
    )
    setSelection(event.target.value)
    setSelected(filtered)
  }

  if (!country.state.data) return null
  const usercountries = cognitouser.state.data
  console.log(usercountries, ' usercountries')
  const countries = country.state.data
  const countriesFiltered = usercountries.filter((user) =>
    user.MasterUCCountries.some((country) =>
      countries.some(
        (countrySource) => countrySource.abbr === country.mastercountry.abbr
      )
    )
  )
  const countriesClean = countriesFiltered[0].MasterUCCountries.map(
    (clean) => clean.mastercountry
  )
  // const countriesClean = countries

  return (
    <Select
      placeholder="Example"
      className={classes.select}
      labelId="demo-mutiple-checkbox-label"
      id="demo-mutiple-checkbox"
      multiple
      value={selection}
      onChange={handleChange}
      input={<Input />}
      renderValue={(selected) => <Selected selected={selected} />}
      // MenuProps={MenuProps}
    >
      {countriesClean.map((country, index) => (
        <MenuItem key={index} value={country.name}>
          <Checkbox checked={selection.indexOf(country.name) > -1} />
          <ListItemText primary={country.name} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default Index
