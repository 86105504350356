import React, { lazy, Fragment, useContext, useState, useEffect } from 'react'
import moment from 'moment'

/* package */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Buttons, Inputs } from '../../lib-atomic'
import { GlobalContext } from '../../lib-atomic-context'
import {
  FormHook,
  FetchContextApiV2,
  LocalStorage,
  FetchCheckContentEnvApi,
} from '../../lib-atomic-functions'

import CircularProgress from '@material-ui/core/CircularProgress'
import { SectionPaper } from '../../lib-atomic-section'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
/* package */

import {
  WindowSize,
  WindowLayout,
  WindowFont,
} from '../../lib-atomic-functions'

import { useSnackbar } from 'notistack'
/* module */
import Spinner from '../../app-module-section-spinner/default'
/* module */

import CONFIG from '../config'

/* CONSTANTS */
const CONTENTENVIRONMENT = CONFIG.environment
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints
const OBJECT1 = CONFIG.application.cms.objects

const CMSMASTERCONTENTSCONFIG = {
  url: CMSENDPOINTS.updatemastercontent.url,
  method: CMSENDPOINTS.updatemastercontent.method,
  headers: CMSENDPOINTS.updatemastercontent.headers,
  responseType: 'json',
}

/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '55%',
    minWidth: 300,
    maxWidth: 500,
  },
  throbber: {
    color: '#ffffff',
  },
}))
const Index = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { token } = LocalStorage.getLS().strapi
  const { params } = props.match
  const { id } = params
  const [isPromotion, setIsPromotion] = useState(false)
  const [disableTypeSelector, setDisableTypeSelector] = useState(false)
  const [webContents, setWebContents] = useState([])
  const [loading, setLoading] = useState(false)
  const [parentLoader, setParentLoader] = useState(false)
  const [carouselItems, setCarouselItems] = useState([])
  const [carouselContents, setCarouselContents] = useState([])
  // const initialForm = {
  //   contentselect: true,
  // }
  // // assign initial value
  // Object.keys(OBJECT1).map((key) => {
  //   OBJECT1[key].initial = initialForm[key]
  // })
  const classes = useStyles()
  const { width, height } = WindowSize()

  const { getFontSizes } = WindowFont()

  /* state */
  const { contents, contentdetail, contentsupdate, updatecontent } = useContext(
    GlobalContext
  )
  const contentdetailobj = contentdetail.state.data
  const mastercontents = contents.state.data

  /* --- update mastercontent */
  const fetchUpdateMasterContentsExecute = async ({ query, body }) => {
    CMSMASTERCONTENTSCONFIG.headers.Authorization = 'Bearer ' + token
    const [fetchMasterContentsUpdate] = FetchContextApiV2(
      CMSMASTERCONTENTSCONFIG
    )
    const fetchResult = await fetchMasterContentsUpdate(
      { query, body },
      updatecontent
    )
    updatecontent.dispatch({
      type: 'SET',
      data: fetchResult,
    })
  }
  const resetUpdateMasterContentsExecute = async () => {
    updatecontent.dispatch({
      type: 'RESET',
    })
  }
  /* --- update mastercontent */
  /* state */

  const handleAdd = async () => {
    console.log('Adding...')
    console.log('values: ', values)
  }
  const { values, onChange, onCheck, onSubmit, error } = FormHook.useFormHook(
    handleAdd,
    OBJECT1
  )
  useEffect(() => {
    const parsedMasterContents = JSON.parse(JSON.stringify(mastercontents))

    console.log(parsedMasterContents)
    const carouselList = parsedMasterContents.filter((item) => {
      console.log(item.type)
      return item.id.toString() === id.toString()
    })
    console.log(carouselList)
    const sortedCarouselList = carouselList.sort((a, b) =>
      a.name > b.name ? 1 : -1
    )
    if (sortedCarouselList && sortedCarouselList.length === 1) {
      setCarouselContents(sortedCarouselList[0])
      setWebContents(sortedCarouselList)
      // console.log(sortedCarouselList[0])
      // const parsedCarousel = JSON.parse(
      //   JSON.stringify(sortedCarouselList[0])
      // )&mastercontentsflag.status=approved&Environment=development

      const contentList = parsedMasterContents
        .filter((item) => {
          // console.log('item ', item)
          return (
            item.type !== 'Carousel' &&
            (isPromotion
              ? item.type === 'Promotions'
              : item.type !== 'Promotions') &&
            sortedCarouselList[0] &&
            sortedCarouselList[0].contentVisibility === item.contentVisibility
            // && (item.mastercontentsflag &&
            // item.mastercontentsflag.status === 'approved') &
            // (item.Environment === 'development')
          )
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((item) => {
          return { name: item.name, value: item }
        })
      setCarouselItems(contentList)
    }

    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////
  }, [contents.state, mastercontents, isPromotion])

  const carouselselect = {
    ...OBJECT1.carouselselect,
    options: carouselContents,
    error: error.carouselselect,
    value: values.carouselselect,
    onChange,
  }
  // useEffect(() => {
  //   const parsedMasterContents = JSON.parse(JSON.stringify(mastercontents))

  //   const contentList = parsedMasterContents.mastercontents
  //     .filter((item) => {
  //       console.log('item ', item.contentVisibility)
  //       return (
  //         item.type !== 'Carousel' &&
  //         carouselselect.value &&
  //         carouselselect.value.contentVisibility === item.contentVisibility
  //       )
  //     })
  //     .sort((a, b) => (a.name > b.name ? 1 : -1))
  //     .map((item) => {
  //       return { name: item.name, value: item }
  //     })
  //   setCarouselItems(contentList)
  //   // OBJECT1.contentselect.options = contentList;
  // }, [carouselselect.value])

  const typeSelector = {
    name: 'typetoggle',
    initial: null,
    label: 'Content Type',
    type: 'select',
    options: [
      { name: 'Offer Carousel', value: true },
      { name: 'Non Offer Carousel', value: false },
    ],
    // disabled: disableTypeSelector,
    helperText: false,
    rules: false,
    value: isPromotion,
    onChange: (e) => {
      setIsPromotion(e.target.value)
      contentselect.value = null
    },
  }

  const contentselect = {
    ...OBJECT1.contentselect,
    options: carouselItems,
    error: error.contentselect,
    value: values.contentselect,
    onChange,
  }
  const sortArray = (arrayPassed) => {
    console.log(arrayPassed)
    return arrayPassed.sort((a, b) => (a.name > b.name ? 1 : -1))
  }
  useEffect(() => {
    return async () => {
      await resetUpdateMasterContentsExecute({})
    }
  }, [])
  const addButton = {
    style: { marginLeft: 10 },
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
    onClick: async (e) => {
      e.preventDefault()
      if (!disableTypeSelector) {
        setDisableTypeSelector(true)
      }
      console.log('TRIGGERED: ', webContents, values)
      let copiedWebContents = JSON.parse(JSON.stringify(webContents))
      copiedWebContents[0].CarouselContents = copiedWebContents[0].CarouselContents.filter(
        (i) => {
          return isPromotion
            ? i.mastercontents.type === 'Promotions'
            : i.mastercontents.type !== 'Promotions'
        }
      )
      console.log(copiedWebContents, values)
      const checkIfWebContentsAlreadyHaveItem = copiedWebContents.filter(
        (item) => item.id === carouselContents.id
      )
      if (checkIfWebContentsAlreadyHaveItem.length > 0) {
        // item already in webContents
        const innerCheck = checkIfWebContentsAlreadyHaveItem[0].CarouselContents.filter(
          (item) =>
            (isPromotion
              ? item.mastercontents.type === 'Promotions'
              : item.mastercontents.type !== 'Promotions') &&
            (item.mastercontents.id === values.contentselect.id ||
              item.mastercontents.name === values.contentselect.name)
        )
        if (innerCheck.length === 0) {
          if (
            values.contentselect !== '' &&
            (isPromotion
              ? values.contentselect.type === 'Promotions'
              : values.contentselect.type !== 'Promotions')
          ) {
            copiedWebContents
              .filter((item) => item.id === carouselContents.id)[0]
              .CarouselContents.push({
                // id: null,
                mastercontents: values.contentselect,
              })
          }
        }
        const sorted = sortArray(copiedWebContents)
        setWebContents(sorted)
      }
    },
  }
  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
  }, [webContents])
  const submitButton = {
    style: { marginLeft: 10, width: 150 },
    // fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }

  const removeButton = {
    // style: { marginLeft: 10 },
    fullWidth: true,
    // disabled: newUpdate.length !== mastersetup[0].length ? true : false,
  }
  const cancelButton = {
    style: { marginRight: 10, width: 150 },
    // fullWidth: true,
  }
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{
            width: '100%',
            paddingLeft: 25,
            paddingTop: 10,
            paddingBottom: 16,
            fontWeight: 900,
          }}
        >
          Add Contents To Carousel: {carouselContents && carouselContents.name}
        </Typography>
        <div
          style={{
            display: 'flex',
            margin: 'auto',
            justifyContent: 'flex-end',
          }}
        >
          <Buttons.Normal
            {...submitButton}
            onClick={async () => {
              console.log(webContents)
              if (!parentLoader) {
                setParentLoader(true)
                if (webContents.length === 0) {
                  props.history.push('/content/list')
                } else {
                  if (webContents[0].CarouselContents.length === 0) {
                    const message = 'Carousel need contents :('
                    enqueueSnackbar(message, {
                      variant: 'warning',
                    })
                    setParentLoader(false)
                  } else if (
                    webContents[0].mastercontentsflag.status === 'approved'
                  ) {
                    const message = 'Carousel is not in draft state :('
                    enqueueSnackbar(message, {
                      variant: 'warning',
                    })
                    setParentLoader(false)
                  } else {
                    const query =
                      '?id_in=' +
                      webContents[0].id +
                      '&mastercontentsflag.status=draft'
                    const isGoodToEdit = await FetchCheckContentEnvApi({
                      query,
                      token,
                    })
                    if (!isGoodToEdit) {
                      const message = 'Carousel is not in draft state :('
                      enqueueSnackbar(message, {
                        variant: 'warning',
                      })
                      setParentLoader(false)
                    } else {
                      asyncForEach(webContents, async (item, index) => {
                        const newContent = JSON.parse(JSON.stringify(item))
                        // maybe check if content's flag is been updated to draft
                        // if they are changed to draft (e.g. someone changed state in the meantime), we should block this save procedure.
                        // question: this is in loop. what if first one is good to proceed but the second one has issue?
                        //           it will partially update and partially warn.
                        // const query =
                        //   '?id_in=' +
                        //   newContent.id +
                        //   '&mastercontentsflag.status=approved'
                        // const token = LocalStorage.getLS().strapi
                        // const isGoodToEdit = await FetchCheckContentEnvApi({
                        //   query,
                        //   token,
                        // })
                        // if (!isGoodToEdit) {
                        //   const message =
                        //     'Some child contents are in draft state :( Please approve all the contents and try again.'
                        //   enqueueSnackbar(message, {
                        //     variant: 'warning',
                        //   })
                        //   setParentLoader(false)
                        // } else {
                        const query = '/' + newContent.id
                        const token = LocalStorage.getLS().strapi
                        const newHeader = {
                          ...CMSMASTERCONTENTSCONFIG.headers,
                          Authorization: 'Bearer ' + token.token,
                        }
                        console.log(query)
                        await fetchUpdateMasterContentsExecute(
                          { newHeader, query, body: newContent },
                          updatecontent
                        )
                        // if (index === webContents.length - 1 && parentLoader) {
                        setParentLoader(false)
                        props.history.push('/content/list')
                        // }
                        // }
                      })
                    }
                  }
                }
              }
            }}
          >
            Save
          </Buttons.Normal>
          <Spinner.Loader color="#fff" size={24} loading={parentLoader} />
          <div style={{ width: 10 }} />
          <Buttons.Normal
            {...cancelButton}
            onClick={() => {
              props.history.push('/content/list')
            }}
          >
            Cancel
          </Buttons.Normal>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
          marginBottom: 60,
          height: height,
        }}
      >
        {/* left box */}
        <div
          style={{
            width: '30%',
            minWidth: '30%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            height: 'auto',
            marginTop: 80,
            paddingRight: 10,
          }}
        >
          <SectionPaper {...props}>
            <Typography variant="h6" gutterBottom>
              Available Carousel & Contents
            </Typography>
            <form onSubmit={onSubmit} noValidate>
              <Inputs.Select {...typeSelector} />
              <Inputs.Select {...contentselect} />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                }}
              >
                {/* <Buttons.Normal {...cancelButton}>Cancel</Buttons.Normal> */}
                <Buttons.Normal {...addButton}>Add</Buttons.Normal>
              </div>
            </form>
          </SectionPaper>
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 'auto',
            }}
          >
            {/* middle box */}
            <div
              style={{
                width: '1000%',
                minWidth: '50%',
                height: 'auto',
                marginLeft: 50,
              }}
            >
              {/* <div style={{ display: "flex", width: "100%" }}> */}
              <Typography variant="h6" gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography variant="h6" gutterBottom>
                Carousel structure to be saved
              </Typography>
              {/* </div> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'wrap',
                  position: 'relative',
                  width: '100%',
                  height: 'auto',
                  marginTop: 15,
                }}
              >
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <CircularProgress color="primary" />
                      ) : (
                        webContents.map((row, index) => (
                          <Fragment>
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                <span
                                  style={{
                                    color: '#098d42',
                                    cursor: 'pointer',
                                    fontWeight: 700,
                                  }}
                                  onClick={() => {
                                    window.open(
                                      '/content/detail/' + row.id,
                                      '_blank'
                                    )
                                  }}
                                >
                                  {row.id}
                                </span>
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell></TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>

                            {row.CarouselContents.length === 0
                              ? null
                              : row.CarouselContents.map(
                                  (innerItem, index2) => {
                                    return (
                                      <TableRow key={innerItem.id}>
                                        <TableCell component="th" scope="row">
                                          <span
                                            style={{
                                              color: '#098d42',
                                              cursor: 'pointer',
                                              fontWeight: 700,
                                            }}
                                            onClick={() => {
                                              window.open(
                                                '/content/detail/' +
                                                  innerItem.mastercontents.id,
                                                '_blank'
                                              )
                                            }}
                                          >
                                            {innerItem.mastercontents.id}
                                          </span>
                                        </TableCell>
                                        <TableCell>
                                          &nbsp;&nbsp; &nbsp;&nbsp;
                                          <SubdirectoryArrowRightIcon />
                                        </TableCell>
                                        <TableCell align="left">
                                          {innerItem.mastercontents.name}
                                        </TableCell>
                                        <TableCell align="left">
                                          <Buttons.Normal
                                            {...removeButton}
                                            onClick={() => {
                                              const currIndexParent = webContents
                                                .map((i) => {
                                                  return i.id
                                                })
                                                .indexOf(row.id)
                                              const newWebContents = JSON.parse(
                                                JSON.stringify(webContents)
                                              )
                                              const currIndex = row.CarouselContents.map(
                                                (i) => {
                                                  return i.mastercontents.name
                                                }
                                              ).indexOf(
                                                innerItem.mastercontents.name
                                              )
                                              newWebContents[
                                                currIndexParent
                                              ].CarouselContents.splice(
                                                currIndex,
                                                1
                                              )
                                              setWebContents(
                                                sortArray(newWebContents)
                                              )
                                            }}
                                          >
                                            Remove
                                          </Buttons.Normal>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  }
                                )}
                          </Fragment>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
